
import * as React from 'react';

import axios, { AxiosError } from "axios";

import './FuelEuSupportTool.css'; // スタイルのために追加
import { commonSortAsc, commonSortDesc } from "../../../components/CommonSort/CommonSort";

// MUI @mui/material/
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';
import { BootstrapInput2 } from '../../../components/styled/styled';
import MenuItem from '@mui/material/MenuItem';
import { SelectTheme } from '../../../components/theme/SelectTheme';
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import AccordionIcon from '../../../assets/images/accordion_close.svg';
import PoolingGroupSettingDialog from './PoolingGroupSettingDialog';

// Axios Error
interface ErrorData {
  message: string;
  status: number;
  error: string;
}

// 登録データの型を定義
interface RegistdData {
  pooling_group_name: string;
  pooling_group_imo_list: string[];
}

// 親コンポーネントから持ち回る値
export type Props = {
  setAuthCheck: (value: boolean) => void;
  setAuthMessage: (value: any) => void;

  SeachInfo: any;
  setSeachInfo: (value: any) => void;
}

function formatNumber(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// Fuel EU Support Tool Component--------------------------------------------------------------------
export const FuelEuSupportTool: React.FC<Props> = props => {

  const [yearMap, setYearMap] = React.useState<any>([]);
  const [thisYear, setThisYear] = React.useState<any>("");

  const [jwt, setJwt] = React.useState<string>();
  const [userId, setUserId] = React.useState<string>();

  // URL
  const [base_url, setBase_url] = React.useState(`${process.env.REACT_APP_API_URL}/EuEtsFuelEu/SupportTool/`);

  let initFlg = true;

  // 初回のみ実行する処理
  React.useEffect(() => {

    // Yearプルダウンに表示するmap生成--------------
    let dateObj = new Date();
    let todayYear = dateObj.getFullYear();
    let yearList = ["2024"];

    setThisYear(String(todayYear));

    // 画面初期表示用にセット
    setSelectedYear(String(todayYear));

    // Yearが2024になるまで、Mapに年を追加（2024年が最古）
    while (2024 != todayYear) {
      yearList.unshift(String(todayYear))
      todayYear = todayYear - 1;
    }
    setYearMap(yearList);

    // API呼び出し
    // Loading表示
    setLoading(true)
    setSuccess(false)

    let payloadUserId = ""
    let payloadJwt = ""
    const pay_json = localStorage.getItem("payload")
    if (pay_json !== null) {
      const pay = JSON.parse(pay_json)
      payloadUserId = pay["user_id"];
      payloadJwt = pay["jwt"];
      setUserId(payloadUserId);
      setJwt(payloadJwt);
    }

    if (initFlg === true) {
      const url = `${base_url}Init/%23?&user=${payloadUserId}&year=${String(yearList[0])}`
      getPoolingGroupInfo(url, payloadJwt);
      initFlg = false
    }

  }, []);

  // Loading--------------------------------------------------------------------
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(true);

  // 取得データ
  const [totalVessels, setTotalVessels] = React.useState<any>()
  const [ytdTotalInfo, setYtdTotalInfo] = React.useState<any>()
  const [eoyTotalInfo, setEoyTotalInfo] = React.useState<any>()
  const [ytdGroupList, setYtdGroupList] = React.useState<any>([])
  const [ytdNotGroupTotal, setNotYtdGroupTotal] = React.useState<any>()
  const [ytdNotGroupedVesselList, setYtdNotGroupedVesselList] = React.useState<any>([])
  const [eoyGroupList, setEoyGroupList] = React.useState<any>([])
  const [eoyNotGroupTotal, setEoyNotGroupTotal] = React.useState<any>([])
  const [eoyNotGroupedVesselList, setEoyNotGroupedVesselList] = React.useState<any>([])
  const [ytdAllVesselsList, setYtdAllVesselsList] = React.useState<any>([])
  const [eoyAllVesselsList, setEoyAllVesselsList] = React.useState<any>([])

  // PoolingGoupの情報を取得
  const getPoolingGroupInfo = (url, jwt) => {
    console.log("Pooling Goup Support Tool Init");

    const options = {
      headers: {
        'Authorization': jwt,
      }
    }
    console.log("URL", url);
    axios.get(url, options).then((res) => {

      console.log("axios", res);

      // 認可チェック
      if (res.data === 401) {
        props.setAuthCheck(true)
        props.setAuthMessage("不正な操作が行われた可能性があります。")
      } else if (res.data === 500) {
        props.setAuthCheck(true)
        props.setAuthMessage("JWTが改ざんされている可能性があります。")
      } else {
        props.setAuthCheck(false)
      }

      setTotalVessels(res.data.total_vessels)
      setYtdTotalInfo(res.data.ytd_total_list)
      setEoyTotalInfo(res.data.eoy_total_list)
      setYtdGroupList(res.data.ytd_pooling_group_list)
      setNotYtdGroupTotal(res.data.ytd_not_grouped_total)
      setYtdNotGroupedVesselList(res.data.ytd_not_grouped_vessels_list)
      setEoyGroupList(res.data.eoy_pooling_group_list)
      setEoyNotGroupTotal(res.data.eoy_not_grouped_total)
      setEoyNotGroupedVesselList(res.data.eoy_not_grouped_vessels_list)
      setYtdAllVesselsList(res.data.ytd_all_vessels_list)
      setEoyAllVesselsList(res.data.eoy_all_vessels_list)

      // GroupedVesselのソートで使用するオブジェクト作成し、ソート状態を初期化する（PooligGroupの件数分、空オブジェクトを作成）
      let tmpActivSortNoList: ActiveSortNo[] = [];
      for (let i = 0; i < res.data.ytd_pooling_group_list.length; i++) {
        tmpActivSortNoList.push({ item: '', seq: 0, sort: '' });
      }
      setActiveSortNoListYtDGroupedVesselList(tmpActivSortNoList);
      setActiveSortNoListEoYGroupedVesselList(tmpActivSortNoList);

      // Loading解除
      setLoading(false)
      setSuccess(true)
    })
      .catch((e: AxiosError<ErrorData>) => {
        // reset_all()
        setLoading(false)
        setSuccess(true)
          if (e.response === undefined) {
          console.log("undefinedError: " + e);
          return;
        }
        console.log(e + ", message: " + e.response.data.message)
      });
  }

  // Year--------------------------------------------------------------------
  const [selectedYear, setSelectedYear] = React.useState<string>("");
  const selectYear = (event: SelectChangeEvent) => {
    setSelectedYear(event.target.value)
    onClickYear()
  };
  const selectYearAction = (year) => {
    setLoading(true)
    setSuccess(false)

    // 過去年の検索の場合は、End of Yearを表示、それ以外の場合はYear to Dateを表示
    if (year !== thisYear) {
      setActiveTab(1);
    }
    else {
      setActiveTab(0);
    }

    // Total部初期化
    setYtdTotalInfo(undefined);
    setEoyTotalInfo(undefined);

    // ソートの状態をリセット
    setActiveSortNoYtDNotGroupedVessel({ item: '', seq: 0, sort: '' });
    setActiveSortNoEoYNotGroupedVessel({ item: '', seq: 0, sort: '' });

    const url = `${base_url}Init/%23?&user=${userId}&year=${year}`
    getPoolingGroupInfo(url, jwt)
  }

  const [changeClickYear, setChangeClickYear] = React.useState<number>(0);

  const onClickYear = () => {
    setChangeClickYear((prev) => (prev + 1));
  }
  React.useEffect(() => {
    if (changeClickYear > 0) {
      selectYearAction(selectedYear)
    }
  }, [changeClickYear])

  // ソート機能--------------------------------------------------------------------
  type ActiveSortNo = {
    item: string;
    seq: number;
    sort: string;
  }

  // Year to DateのGrouped Vessel Listのソート
  const [activeSortNoListYtDGroupedVesselList, setActiveSortNoListYtDGroupedVesselList] = React.useState<ActiveSortNo[]>([]); // seq: 文字列→1, 数値→2
  const sortAscYtDGroupedVesselList = (item: string, seq: number, listData: any, index: number) => { //昇順に並び替え
    const { newRows, newActiveSortNo } = commonSortAsc(item, seq, listData, activeSortNoListYtDGroupedVesselList[index], "vessel_name");
    
    // ytdGroupListの該当要素のGroupedVesselListをソート後のもので上書き
    setYtdGroupList(prevList => 
      prevList.map((group, i) => 
        i === index ? { ...group, GroupedVesselList: newRows } : group
      )
    );  
    // 該当Groupのソート情報を上書き
    setActiveSortNoListYtDGroupedVesselList(prevState => {
      const newState = [...prevState];
      newState[index] = newActiveSortNo;
      return newState;
    });
  }
  const sortDescYtDGroupedVesselList = (item: string, seq: number, listData: any, index: number) => { //降順に並び替え
    const { newRows, newActiveSortNo } = commonSortDesc(item, seq, listData, activeSortNoListYtDGroupedVesselList[index], "vessel_name");

    // ytdGroupListの該当要素のGroupedVesselListをソート後のもので上書き
    setYtdGroupList(prevList => 
      prevList.map((group, i) => 
        i === index ? { ...group, GroupedVesselList: newRows } : group
      )
    );  
    // 該当Groupのソート情報を上書き
    setActiveSortNoListYtDGroupedVesselList(prevState => {
      const newState = [...prevState];
      newState[index] = newActiveSortNo;
      return newState;
    });
  }
  // End of YearのGrouped Vessel Listのソート
  const [activeSortNoListEoYGroupedVesselList, setActiveSortNoListEoYGroupedVesselList] = React.useState<ActiveSortNo[]>([]); // seq: 文字列→1, 数値→2
  const sortAscEoYGroupedVesselList = (item: string, seq: number, listData: any, index: number) => { //昇順に並び替え
    const { newRows, newActiveSortNo } = commonSortAsc(item, seq, listData, activeSortNoListEoYGroupedVesselList[index], "vessel_name");
    
    // eoyGroupListの該当要素のGroupedVesselListをソート後のもので上書き
    setEoyGroupList(prevList => 
      prevList.map((group, i) => 
        i === index ? { ...group, GroupedVesselList: newRows } : group
      )
    );  
    // 該当Groupのソート情報を上書き
    setActiveSortNoListEoYGroupedVesselList(prevState => {
      const newState = [...prevState];
      newState[index] = newActiveSortNo;
      return newState;
    });
  }
  const sortDescEoYGroupedVesselList = (item: string, seq: number, listData: any, index: number) => { //降順に並び替え
    const { newRows, newActiveSortNo } = commonSortDesc(item, seq, listData, activeSortNoListEoYGroupedVesselList[index], "vessel_name");

    // eoyGroupListの該当要素のGroupedVesselListをソート後のもので上書き
    setEoyGroupList(prevList => 
      prevList.map((group, i) => 
        i === index ? { ...group, GroupedVesselList: newRows } : group
      )
    );  
    // 該当Groupのソート情報を上書き
    setActiveSortNoListEoYGroupedVesselList(prevState => {
      const newState = [...prevState];
      newState[index] = newActiveSortNo;
      return newState;
    });
  }

  // Year to DateのNot Grouped Vessel Listのソート
  const [activeSortNoYtDNotGroupedVessel, setActiveSortNoYtDNotGroupedVessel] = React.useState<ActiveSortNo>({ item: '', seq: 0, sort: '' }); // seq: 文字列→1, 数値→2
  const sortAscYtDNotGroupedVesselList = (item: string, seq: number) => { //昇順に並び替え
    const { newRows, newActiveSortNo } = commonSortAsc(item, seq, ytdNotGroupedVesselList, activeSortNoYtDNotGroupedVessel, "vessel_name");
    setYtdNotGroupedVesselList(newRows);
    setActiveSortNoYtDNotGroupedVessel(newActiveSortNo);
  }
  const sortDescYtDNotGroupedVesselList = (item: string, seq: number) => { //降順に並び替え
    const { newRows, newActiveSortNo } = commonSortDesc(item, seq, ytdNotGroupedVesselList, activeSortNoYtDNotGroupedVessel, "vessel_name");
    setYtdNotGroupedVesselList(newRows);
    setActiveSortNoYtDNotGroupedVessel(newActiveSortNo);
  }
  // End of YearのNot Grouped Vessel Listのソート
  const [activeSortNoEoYNotGroupedVessel, setActiveSortNoEoYNotGroupedVessel] = React.useState<ActiveSortNo>({ item: '', seq: 0, sort: '' }); // seq: 文字列→1, 数値→2
  const sortAscEoYNotGroupedVesselList = (item: string, seq: number) => { //昇順に並び替え
    const { newRows, newActiveSortNo } = commonSortAsc(item, seq, eoyNotGroupedVesselList, activeSortNoEoYNotGroupedVessel, "vessel_name");
    setEoyNotGroupedVesselList(newRows);
    setActiveSortNoEoYNotGroupedVessel(newActiveSortNo);
  }
  const sortDescEoYNotGroupedVesselList = (item: string, seq: number) => { //降順に並び替え
    const { newRows, newActiveSortNo } = commonSortDesc(item, seq, eoyNotGroupedVesselList, activeSortNoEoYNotGroupedVessel, "vessel_name");
    setEoyNotGroupedVesselList(newRows);
    setActiveSortNoEoYNotGroupedVessel(newActiveSortNo);
  }

  // タブがクリックされた時に呼び出されるハンドラー関数
  const [activeTab, setActiveTab] = React.useState(0);
  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  // アコーディオンの開閉
  const accordion = (e: React.MouseEvent<HTMLElement>) => {
    // 開閉判定
    const isAccordion = e.currentTarget.parentElement?.getAttribute("data-is-accordion");
    if (isAccordion === "0") {
      e.currentTarget.parentElement?.classList.add("pooling-group-accordion");
      e.currentTarget.parentElement?.setAttribute("data-is-accordion", "1");
    } else if (isAccordion === "1") {
      e.currentTarget.parentElement?.classList.remove("pooling-group-accordion");
      e.currentTarget.parentElement?.setAttribute("data-is-accordion", "0");
      
    }
  }

  // Borrowing変更
  const handleChangeBorrowing_ytd = (event, index, limit) => {

    // 入力値が空の場合はそのまま許可
    if (event === '') {
      // 新しい配列を作成し、対象の要素を更新
      const newList = [...ytdNotGroupedVesselList];
      newList[index] = { ...newList[index], borrowing: '' };
      setYtdNotGroupedVesselList(newList);
      return;
    }

    // 入力値を整数に変換し、条件を満たす場合のみ更新
    if (/^(0|[1-9][0-9]*)(\.[0-9]*)?$/.test(event) && !isNaN(Number(event)) && Number(event) <= Number(limit)) {
      // 新しい配列を作成し、対象の要素を更新
      const newList = [...ytdNotGroupedVesselList];
      newList[index] = { ...newList[index], borrowing: event };
      setYtdNotGroupedVesselList(newList);
    }
  }

  // Borrowing変更
  const handleChangeBorrowing_eoy = (event, index, limit) => {

    // 入力値が空の場合はそのまま許可
    if (event === '') {
      // 新しい配列を作成し、対象の要素を更新
      const newList = [...eoyNotGroupedVesselList];
      newList[index] = { ...newList[index], borrowing: '' };
      setEoyNotGroupedVesselList(newList);
      return;
    }

    // 入力値を整数に変換し、条件を満たす場合のみ更新
    if (/^(0|[1-9][0-9]*)(\.[0-9]*)?$/.test(event) && !isNaN(Number(event)) && Number(event) <= Number(limit)) {
      // 新しい配列を作成し、対象の要素を更新
      const newList = [...eoyNotGroupedVesselList];
      newList[index] = { ...newList[index], borrowing: event };
      setEoyNotGroupedVesselList(newList);
    }
  }

  const handleBlurBorrowing_ytd = (event, index, imo, operator) => {

    let eventVal = event.target.value
    
    if (eventVal === "") {
      // 空でフォーカス外れた場合は、0を設定し、更新
      const newList = [...ytdNotGroupedVesselList];
      newList[index] = { ...newList[index], borrowing: "0" };
      setYtdNotGroupedVesselList(newList);
    }

    // インプット作成
    const value = {  
      "imo": imo,
      "operator": operator,
      "borrowing": eventVal,
      "eoy_borrowing": "",
    }

    // Borrowing登録API呼び出し
    registBorrowing(value)
  };

  const handleBlurBorrowing_eoy = (event, index, imo, operator) => {
    
    let eventVal = event.target.value
    
    if (eventVal === "") {
      // 空でフォーカス外れた場合は、0を設定し、更新
      const newList = [...eoyNotGroupedVesselList];
      newList[index] = { ...newList[index], borrowing: "0" };
      setEoyNotGroupedVesselList(newList);
    }

    // インプット作成
    const value = {  
      "imo": imo,
      "operator": operator,
      "borrowing": "",
      "eoy_borrowing": eventVal,
    }

    // Borrowing登録API呼び出し
    registBorrowing(value)
  };

  // Borrowing登録
  const registBorrowing = (value) => {
    console.log("registBorrowing")

    if (jwt !== undefined) {
      console.log("registBorrowing", value)

      const formData = new FormData();
      Object.keys(value).forEach(key => {
        formData.append(key, value[key]);
      });
      const options = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': jwt
        }
      }
      const url = `${base_url}Borrowing/%23/?&user=${userId}&year=${selectedYear}`
      console.log("url[registBorrowingスタート]:", url);
      axios.post(url, formData, options).then((res) => {
        console.log("API Response", res.data);

        // 認可チェック
        if (res.data === 401) {
          props.setAuthCheck(true)
          props.setAuthMessage("不正な操作が行われた可能性があります。")
        } else if (res.data === 500) {
          props.setAuthCheck(true)
          props.setAuthMessage("JWTが改ざんされている可能性があります。")
        } else {
          props.setAuthCheck(false)
        }

        setInsertCompleatFlg(true);

      })
      .catch((e: AxiosError<ErrorData>) => {
        // 親画面ローディング解除
        setLoading(false);
        setSuccess(true);
        if (e.response === undefined) {
          console.log("undefinedError: " + e);
          return;
        }
        console.log(e + ", message: " + e.response.data.message);
      });
    }
  }

  // PoolingGroupSettingダイアログ関連--------------------------------------------------------------------
  const [openDialogPoolingGroupSetting, setOpenDialogPoolingGroupSetting] = React.useState(false);
  const handleClickOpenDialogPoolingGroupSetting = () => {
    setOpenDialogPoolingGroupSetting(true);
  };
  const [saveDialogPoolingGroupSetting, setSaveDialogPoolingGroupSetting] = React.useState(false);
  const [poolingGroupMojiList, setPoolingGroupMojiList] = React.useState<any>();
  // Saveボタン押下検知
  React.useEffect(() => {
    if (saveDialogPoolingGroupSetting) {

      setSaveDialogPoolingGroupSetting(false);

      setYtdTotalInfo(undefined);
      setEoyTotalInfo(undefined);

      // PoolingGroup登録API呼び出し
      // 登録データ作成
      let registData: RegistdData[] = [];
 
      for (let i = 0; i < poolingGroupMojiList.length; i++) {
        if (poolingGroupMojiList[i] !== "-") {
          let registVal: RegistdData = {
            "pooling_group_name": poolingGroupMojiList[i],
            "pooling_group_imo_list": []
          }
          registData.push(registVal);
        }
      }

      // どちらのリストを使うか判定
      let tmpAllVesselsList: any = [];
      if (selectedYear === thisYear) {
        tmpAllVesselsList = ytdAllVesselsList;
      }
      else {
        tmpAllVesselsList = eoyAllVesselsList;
      }

      registData.forEach(function(element1, index1) {

        tmpAllVesselsList.forEach(function(element2, index2) {

          if (element1.pooling_group_name === element2.group) {

            let tempImoList = element1.pooling_group_imo_list;

            if (!tempImoList.includes(element2.imo)) {
              tempImoList.push(element2.imo);
            }
            registData[index1].pooling_group_imo_list = tempImoList;
          }       
        });
      });
      
      // 画面ローディング表示
      setLoading(true);
      setSuccess(false);

      console.log("registData:" + registData);
      registPoolingGroup(registData);

    }
  }, [saveDialogPoolingGroupSetting])

  // 画面の初期表示再描画
  const [insertCompleatFlg, setInsertCompleatFlg] = React.useState(false);
  React.useEffect(() => {
    if (insertCompleatFlg) {

      setInsertCompleatFlg(false);

      // 初期表示API再呼び出し
      const url = `${base_url}Init/%23?&user=${userId}&year=${selectedYear}`
      getPoolingGroupInfo(url, jwt);

    }
  }, [insertCompleatFlg])

  // PoolingGroup登録
  const registPoolingGroup = (value) => {
    console.log("registPoolingGroup")

    console.log('Data to send:', JSON.stringify(value));

    if (jwt !== undefined) {
      console.log("registPoolingGroupスタート", value)

      const options = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': jwt
        }
      }
      const url = `${base_url}PoolingTable/%23/?&user=${userId}&year=${selectedYear}`
      console.log("url[registPoolingGroupスタート]:", url);
      axios.post(url, JSON.stringify(value), options).then((res) => {
        console.log("API Response", res.data);

        // 認可チェック
        if (res.data === 401) {
          props.setAuthCheck(true)
          props.setAuthMessage("不正な操作が行われた可能性があります。")
        } else if (res.data === 500) {
          props.setAuthCheck(true)
          props.setAuthMessage("JWTが改ざんされている可能性があります。")
        } else {
          props.setAuthCheck(false)
        }

        setInsertCompleatFlg(true);

      })
      .catch((e: AxiosError<ErrorData>) => {
        // 親画面ローディング解除
        setLoading(false);
        setSuccess(true);
        if (e.response === undefined) {
          console.log("undefinedError: " + e);
          return;
        }
        console.log(e + ", message: " + e.response.data.message);
      });
    }
  }

  return (
    <>
      <Box sx={{width: "97.5%", height: "98.75%", marginLeft: "1.2%"}}>
        {/* 条件プルダウン */}
        <Box sx={{ height: "82px"}}>
          <Box sx={{height: "32px"}}></Box>
          <Stack direction="row" sx={{ height: "100%", }}>          

            {/* Year */}
            <Typography align='left' sx={{ marginTop: "6px", marginRight: "12px", color: 'white', fontSize: 14, fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
              Year
            </Typography>
            <ThemeProvider theme={SelectTheme}>
              <FormControl>
                <Select
                  IconComponent={() =>
                    <div style={{ position: "absolute", top: "17px", left: "100px" }}>
                      <InputAdornment position="start" disablePointerEvents>
                        <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.7)", borderRight: "solid 2px rgb(255, 255, 255, 0.7)", width: "8px", height: "8px", transform: "rotate(135deg)" }}></Box>
                      </InputAdornment>
                    </div>
                  }
                  MenuProps={{
                    MenuListProps: {
                      disablePadding: true,

                    },
                    style: { zIndex: 100000, }
                  }}
                  sx={{
                    width: 128,
                    height: 32,
                    color: "white",
                    borderRadius: 30,
                    ":hover": { backgroundColor: "#7a86a0" }
                  }}
                  input={<BootstrapInput2 />}
                  onChange={selectYear}
                  value={selectedYear}
                >
                  {yearMap.map((year) => {
                    return (
                      <MenuItem key={year} value={year}>{year}</MenuItem>
                    )
                  }
                  )}
                </Select>
              </FormControl>
            </ThemeProvider>
            <Box sx={{width: "30px"}}></Box>
            <Typography sx={{ marginTop: "6px", width: "97px", color: 'rgb(255, 255, 255, 0.5)', fontSize: 15, fontWeight: "700", fontFamily: 'Roboto', }}>
              Total Vessels
            </Typography>
            <Typography sx={{ marginTop: "6px", width: "11px", color: 'rgb(255, 255, 255, 0.5)', fontSize: 15, fontWeight: "700", fontFamily: 'Roboto', }}>
              :
            </Typography>
            <Typography sx={{ marginTop: "6px", width: "120px", color: 'white', fontSize: 15, fontFamily: 'Roboto', }}>
              {totalVessels !== undefined && totalVessels !== "" ? formatNumber(totalVessels) : 0 }
            </Typography>
          </Stack>
        </Box>

        {/* Total */}
        <Stack direction="row" sx={{ height: "215px"}}>

          {/* Year to Date Total */}
          <Box className="eu-support-tool-total-area" sx={{ width: "49%", filter: `brightness(${selectedYear === thisYear ? "100%" : "70%"})`}}>
            {/* タイトル */}
            <Stack direction="row" sx={{ height: "50px" }}>
              <Typography sx={{ width: "100%", marginTop: "20px", marginLeft: "24px", color: 'white', fontSize: 15, fontFamily: 'Roboto', fontWeight: '700' }}>
                Year to Date
              </Typography>
            </Stack>
            {/* CB合計、コスト合計 */}
            <Stack direction="row" sx={{ height: "70px", width: "100%"}}>
              <Box sx={{ width: "31%" }}>
                <Typography sx={{ width: "220px", height: "20px", marginLeft: "44px", color: 'rgb(255, 255, 255, 0.5)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '700' }}>
                  Total Compliance Balance 
                </Typography>
                <Typography sx={{ width: "220px", height: "35px", marginLeft: "44px", color: 'white', fontSize: 25, fontFamily: 'Roboto', fontWeight: '400' }}>
                  {selectedYear === thisYear && ytdTotalInfo !== undefined ? formatNumber(ytdTotalInfo.total_cb) : "-" }
                </Typography>
              </Box>
              <Box sx={{ width: "69%" }}>
                <Typography sx={{  width: "250px", height: "20px", color: 'rgb(255, 255, 255, 0.5)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '700' }}>
                  Total Compliance Balance Cost 
                </Typography>
                <Stack direction={"row"} sx={{ width: "220px", height: "35px" }}>
                  <Typography sx={{ color: 'white', fontSize: 25, fontFamily: 'Roboto', fontWeight: '400' }}>
                    {selectedYear === thisYear && ytdTotalInfo !== undefined ? formatNumber(ytdTotalInfo.total_cb_cost) : "-" }
                  </Typography>
                  <Typography sx={{ color: 'rgb(255, 255, 255, 0.5)', fontSize: 24, fontFamily: 'Roboto', fontWeight: '400' }}>
                    &nbsp;€
                  </Typography>
                </Stack>
              </Box>
            </Stack>

            {/* Pooling、Banking、Borrowing */}
            <Stack sx={{ height: "70px", width: "100%"}}>
              <Stack direction="row" 
                sx={{ 
                  height: "61px", width: "calc(100% - 75px)", marginTop: "3px", marginLeft: "40px", 
                  backgroundColor: "rgba(136, 136, 151, 0.06)", boxShadow: '10px 10px 15px -10px' 
                }}>
                {/* Pooling Group */}
                <Box sx={{ width: "20%" }}>
                  <Typography sx={{ marginTop: "8px", marginLeft: "40px", width: "110px", height: "20px", color: 'rgb(255, 255, 255, 0.5)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '700' }}>
                    Pooling Group 
                  </Typography>
                  <Typography sx={{ marginLeft: "40px", color: 'white', fontSize: 18, fontFamily: 'Roboto', fontWeight: '400' }}>
                    {selectedYear === thisYear && ytdTotalInfo !== undefined ? formatNumber(ytdTotalInfo.pooling_group) : "-" }
                  </Typography>
                </Box>
                {/* 区切り線 */}
                <Box sx={{ width: "1%" }}>
                  <Stack className='eu-support-tool-total-area-separate-bar' alignItems={"center"} justifyContent={"center"}>
                    <Box className="bar"></Box>
                  </Stack>
                </Box>
                {/* Banking Vessels */}
                <Box sx={{ width: "18%" }}>
                  <Typography sx={{ marginTop: "8px", marginLeft: "22px", width: "130px", height: "20px", color: 'rgb(255, 255, 255, 0.5)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '700' }}>
                    Banking Vessels
                  </Typography>
                  <Typography sx={{ marginLeft: "22px", color: 'white', fontSize: 18, fontFamily: 'Roboto', fontWeight: '400' }}>
                    {selectedYear === thisYear && ytdTotalInfo !== undefined ? formatNumber(ytdTotalInfo.banking_vessels) : "-" }
                  </Typography>
                </Box>
                {/* Banking CB */}
                <Box sx={{  width: "17%" }}>
                  <Typography sx={{ marginTop: "8px", marginLeft: "17px", width: "100px", height: "20px", color: 'rgb(255, 255, 255, 0.5)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '700' }}>
                    Banking CB 
                  </Typography>
                  <Typography sx={{ marginLeft: "17px", color: 'white', fontSize: 18, fontFamily: 'Roboto', fontWeight: '400' }}>
                    {selectedYear === thisYear && ytdTotalInfo !== undefined ? formatNumber(ytdTotalInfo.banking_cb) : "-" }
                  </Typography>
                </Box>
                {/* 区切り線 */}
                <Box sx={{ width: "1%" }}>
                  <Stack className='eu-support-tool-total-area-separate-bar' alignItems={"center"} justifyContent={"center"}>
                    <Box className="bar"></Box>
                  </Stack>
                </Box>
                {/* Borrowing Vessels */}
                <Box sx={{ width: "21%" }}>
                  <Typography sx={{ marginTop: "8px", marginLeft: "25px", width: "140px", height: "20px", color: 'rgb(255, 255, 255, 0.5)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '700' }}>
                    Borrowing Vessels 
                  </Typography>
                  <Typography sx={{ marginLeft: "25px", color: 'white', fontSize: 18, fontFamily: 'Roboto', fontWeight: '400' }}>
                    {selectedYear === thisYear && ytdTotalInfo !== undefined ? formatNumber(ytdTotalInfo.borrowing_vessels) : "-" }
                  </Typography>
                </Box>
                {/* Borrowing CB */}
                <Box sx={{ width: "26%" }}>
                  <Typography sx={{ marginTop: "8px", marginLeft: "17px", width: "140px", height: "20px", color: 'rgb(255, 255, 255, 0.5)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '700' }}>
                    Borrowing CB 
                  </Typography>
                  <Typography sx={{ marginLeft: "17px", color: 'white', fontSize: 18, fontFamily: 'Roboto', fontWeight: '400' }}>
                    {selectedYear === thisYear && ytdTotalInfo !== undefined ? formatNumber(ytdTotalInfo.borrowing_cb) : "-" }
                  </Typography>
                </Box>
              </Stack>          
            </Stack>
          </Box>

          {/* 間隔空ける */}
          <Box sx={{ height: "197px", width: "2%"}}></Box>

          {/* End of Year */}
          <Box className="eu-support-tool-total-area" sx={{ width: "49%"}}>
            {/* タイトル */}
            <Stack direction="row" sx={{ height: "50px" }}>
              <Typography sx={{ width: "100%", marginTop: "20px", marginLeft: "24px", color: 'white', fontSize: 15, fontFamily: 'Roboto', fontWeight: '700' }}>
                {selectedYear === thisYear ? "End of Year ( Estimated )" : "End of Year"}
              </Typography>
            </Stack>
            {/* CB合計、コスト合計 */}
            <Stack direction="row" sx={{ height: "70px", width: "100%"}}>
              <Box sx={{ width: "31%" }}>
                <Typography sx={{ width: "220px", height: "20px", marginLeft: "44px", color: 'rgb(255, 255, 255, 0.5)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '700' }}>
                  Total Compliance Balance 
                </Typography>
                <Typography sx={{ width: "220px", height: "35px", marginLeft: "44px", color: 'white', fontSize: 25, fontFamily: 'Roboto', fontWeight: '400' }}>
                  {eoyTotalInfo !== undefined ? formatNumber(eoyTotalInfo.total_cb) : "-" }
                </Typography>
              </Box>
              <Box sx={{ width: "69%" }}>
                <Typography sx={{  width: "250px", height: "20px", color: 'rgb(255, 255, 255, 0.5)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '700' }}>
                  Total Compliance Balance Cost 
                </Typography>
                <Stack direction={"row"} sx={{ width: "220px", height: "35px" }}>
                  <Typography sx={{ color: 'white', fontSize: 25, fontFamily: 'Roboto', fontWeight: '400' }}>
                    {eoyTotalInfo !== undefined ? formatNumber(eoyTotalInfo.total_cb_cost) : "-" }
                  </Typography>
                  <Typography sx={{ color: 'rgb(255, 255, 255, 0.5)', fontSize: 24, fontFamily: 'Roboto', fontWeight: '400' }}>
                    &nbsp;€
                  </Typography>
                </Stack>
              </Box>
            </Stack>

            {/* Pooling、Banking、Borrowing */}
            <Stack sx={{ height: "70px", width: "100%"}}>
              <Stack direction="row" 
                sx={{ 
                  height: "61px", width: "calc(100% - 75px)", marginTop: "3px", marginLeft: "40px", 
                  backgroundColor: "rgba(136, 136, 151, 0.06)", boxShadow: '10px 10px 15px -10px' 
                }}>
                {/* Pooling Group */}
                <Box sx={{ width: "20%" }}>
                  <Typography sx={{ marginTop: "8px", marginLeft: "40px", width: "110px", height: "20px", color: 'rgb(255, 255, 255, 0.5)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '700' }}>
                    Pooling Group 
                  </Typography>
                  <Typography sx={{ marginLeft: "40px", color: 'white', fontSize: 18, fontFamily: 'Roboto', fontWeight: '400' }}>
                    {eoyTotalInfo !== undefined ? formatNumber(eoyTotalInfo.pooling_group) : "-" }
                  </Typography>
                </Box>
                {/* 区切り線 */}
                <Box sx={{ width: "1%" }}>
                  <Stack className='eu-support-tool-total-area-separate-bar' alignItems={"center"} justifyContent={"center"}>
                    <Box className="bar"></Box>
                  </Stack>
                </Box>
                {/* Banking Vessels */}
                <Box sx={{ width: "18%" }}>
                  <Typography sx={{ marginTop: "8px", marginLeft: "22px", width: "130px", height: "20px", color: 'rgb(255, 255, 255, 0.5)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '700' }}>
                    Banking Vessels
                  </Typography>
                  <Typography sx={{ marginLeft: "22px", color: 'white', fontSize: 18, fontFamily: 'Roboto', fontWeight: '400' }}>
                    {eoyTotalInfo !== undefined ? formatNumber(eoyTotalInfo.banking_vessels) : "-" }
                  </Typography>
                </Box>
                {/* Banking CB */}
                <Box sx={{  width: "17%" }}>
                  <Typography sx={{ marginTop: "8px", marginLeft: "17px", width: "100px", height: "20px", color: 'rgb(255, 255, 255, 0.5)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '700' }}>
                    Banking CB 
                  </Typography>
                  <Typography sx={{ marginLeft: "17px", color: 'white', fontSize: 18, fontFamily: 'Roboto', fontWeight: '400' }}>
                    {eoyTotalInfo !== undefined ? formatNumber(eoyTotalInfo.banking_cb) : "-" }
                  </Typography>
                </Box>
                {/* 区切り線 */}
                <Box sx={{ width: "1%" }}>
                  <Stack className='eu-support-tool-total-area-separate-bar' alignItems={"center"} justifyContent={"center"}>
                    <Box className="bar"></Box>
                  </Stack>
                </Box>
                {/* Borrowing Vessels */}
                <Box sx={{ width: "21%" }}>
                  <Typography sx={{ marginTop: "8px", marginLeft: "17px", width: "140px", height: "20px", color: 'rgb(255, 255, 255, 0.5)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '700' }}>
                    Borrowing Vessels 
                  </Typography>
                  <Typography sx={{ marginLeft: "17px", color: 'white', fontSize: 18, fontFamily: 'Roboto', fontWeight: '400' }}>
                    {eoyTotalInfo !== undefined ? formatNumber(eoyTotalInfo.borrowing_vessels) : "-" }
                  </Typography>
                </Box>
                {/* Borrowing CB */}
                <Box sx={{ width: "26%" }}>
                  <Typography sx={{ marginTop: "8px", marginLeft: "17px", width: "140px", height: "20px", color: 'rgb(255, 255, 255, 0.5)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '700' }}>
                    Borrowing CB 
                  </Typography>
                  <Typography sx={{ marginLeft: "17px", color: 'white', fontSize: 18, fontFamily: 'Roboto', fontWeight: '400' }}>
                    {eoyTotalInfo !== undefined ? formatNumber(eoyTotalInfo.borrowing_cb) : "-" }
                  </Typography>
                </Box>
              </Stack>          
            </Stack>
          </Box>
        </Stack>
        
        {/* List */}
        <Box className="eu-support-tool-list-area">
          <Box sx={{ width: "calc(100% - 46px)", marginLeft: "24px", height: "calc(100% - 80px)"}} >

            <Box sx={{ width: "100%", height: "20px"}}>
            </Box>
            {/* ↓↓ タブ ↓↓ */}
            <Button
              sx={{
                  width: "120px",
                  height: "31px",
                  borderBottomLeftRadius: "0px",
                  borderBottomRightRadius: "0px",
                  fontSize: "14px",
                  fontFamily: "'Roboto', 'Meiryo', sans-serif",
                  fontWeight: "bold",
                  color: `${activeTab === 0 ? "rgba(255, 255, 255, 0.8)" : "rgba(255, 255, 255, 0.4)"}`,
                  textTransform: 'none',
                  ":hover": { bgcolor: "rgba(255, 255, 255, 0.6)", color: "rgba(255, 255, 255, 0.8)", },
                  marginRight: "5px",
                  border: "1px solid #5A5A5A",
                  borderBottomWidth: "2px",
                  backgroundColor: `${activeTab === 0 ? "rgba(224, 224, 224, 0.15)" : "rgba(121, 121, 121, 0.1)"}`,
                  borderBottomColor: `${activeTab === 0 ? "#E66300" : "#5A5A5A"}`,                
                }}
              onClick={() => handleTabClick(0)}
              disabled={selectedYear === thisYear ? false : true}
              >
              <Stack direction={"row"}>
                <Box sx={{ textAlign: "center" }}>Year to Date</Box>
              </Stack>
            </Button>
            <Button
              sx={{
                  width: "120px",
                  height: "31px",
                  borderBottomLeftRadius: "0px",
                  borderBottomRightRadius: "0px",
                  fontSize: "14px",
                  fontFamily: "'Roboto', 'Meiryo', sans-serif",
                  fontWeight: "bold",
                  color: `${activeTab === 1 ? "rgba(255, 255, 255, 0.8)" : "rgba(255, 255, 255, 0.4)"}`,
                  textTransform: 'none',
                  ":hover": { bgcolor: "rgba(255, 255, 255, 0.6)", color: "rgba(255, 255, 255, 0.8)", },
                  border: "1px solid #5A5A5A",
                  borderBottomWidth: "2px",
                  backgroundColor: `${activeTab === 1 ? "rgba(224, 224, 224, 0.15)" : "rgba(121, 121, 121, 0.1)"}`,
                  borderBottomColor: `${activeTab === 1 ? "#E66300" : "#5A5A5A"}`,                 
                }}
              onClick={() => handleTabClick(1)}
              >
              <Stack direction={"row"}>
                <Box sx={{ textAlign: "center" }}>End of Year</Box>
              </Stack>
            </Button>
            {/* ↑↑ タブ ↑↑ */}

            {/* Pooling List */}
            {success ?
              <>
              {activeTab === 0 ?
                // Year of Date
                <Box 
                  sx={{
                    height: "calc(100% - 50px)",
                    borderTop:"1px solid #3F3E3E", 
                    borderTopWidth: "2px",
                  }}>
                  {/* リスト */}
                  <Box sx={{ height: "100%", width: "calc(100% - 6px)", marginLeft: "6px"}}>
                    {/* ヘッダー */}
                    <Stack sx={{ borderBottom: "1px dotted rgb(255, 255, 255, 0.5)", height: "46px", position: "relative"}}>
                      <Box sx={{ fontSize: "14px", fontWeight: "bold", color: "rgba(255, 255, 255, 0.5)",
                          position: "absolute", left: "15.6%", top: "22px",
                        }}>
                        Total Compliance Balance
                      </Box>
                      <Box sx={{ fontSize: "14px", fontWeight: "bold", color: "rgba(255, 255, 255, 0.5)",
                          position: "absolute", left: "30.8%", top: "22px",
                        }}>
                        Total Compliance Balance Cost
                      </Box>
                    </Stack>

                    {/* Pooling Group */}
                    <div className='fueleu-support-tool-list-scroll'>
                      {ytdGroupList.length !== 0 ?
                        <>
                          {
                            ytdGroupList.map((element, index) => {
                              return (
                                <>
                                  <div className="fueleu-support-tool-list" key={"accordion_grouped_ytd_" + index} data-is-accordion="0">
                                    <div className="fueleu-support-tool-list-summary" onClick={(event) => accordion(event)}>
                                      <div>
                                        <img src={AccordionIcon} alt="SVG"/>
                                      </div>
                                      <div className="fueleu-support-tool-summary-group-name">
                                        <span>Pooling Group {element.group_name}</span>                      
                                      </div>
                                      <div className="fueleu-support-tool-summary-total-cb">
                                        <span>{element.total_cb !== "0.0" ? formatNumber(element.total_cb) : "0"}</span>
                                      </div>
                                      <div className="fueleu-support-tool-summary-total-cb-cost">
                                        <span>{formatNumber(element.total_cb_cost)}</span>
                                        <span>€</span>
                                      </div>
                                    </div>
                                    <div className="fueleu-support-tool-list-detail">
                                      <div style={{width: "calc(100% - 63px)", height: "50px", marginLeft: "35px", position: "relative"}}>
                                        <Box style={{ width: "46.25%", height: "25px", left: "36.5%", top: "22px", position: 'absolute', background: 'rgba(83, 84, 89, 0.50)', }}>
                                          <Typography align="center" sx={{ marginTop: "2px", color: 'rgba(255, 255, 255, 0.50)', fontSize: 14, fontFamily: 'Roboto', fontWeight: 'bold', wordWrap: 'break-word' }}>
                                            Compliance Balance (TonCO2eq)
                                          </Typography>
                                        </Box>
                                      </div>
                                      <div className="pooling-group-list-table-wrapper">
                                        <table className='pooling-group-list-table'>
                                          <thead>
                                            <tr>
                                              <th className="pooling-group-list-vesselName">
                                                <div className='common-sort-list-header-left'>
                                                  <div>
                                                    <span>Vessel Name</span>
                                                  </div>
                                                  <div className="common-sort-container">
                                                    <div className="common-sort-icon">
                                                      <div className={(activeSortNoListYtDGroupedVesselList[index].item === "vessel_name" && activeSortNoListYtDGroupedVesselList[index].sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAscYtDGroupedVesselList('vessel_name', 1, element.GroupedVesselList, index)}></div>
                                                      <div className={(activeSortNoListYtDGroupedVesselList[index].item === "vessel_name" && activeSortNoListYtDGroupedVesselList[index].sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDescYtDGroupedVesselList('vessel_name', 1, element.GroupedVesselList, index)}></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </th>
                                              <th className="pooling-group-list-operator">
                                                <div className='common-sort-list-header-left'>
                                                  <div>
                                                    <span>Operator</span>
                                                  </div>
                                                  <div className="common-sort-container">
                                                    <div className="common-sort-icon">
                                                      <div className={(activeSortNoListYtDGroupedVesselList[index].item === "operator" && activeSortNoListYtDGroupedVesselList[index].sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAscYtDGroupedVesselList('operator', 1, element.GroupedVesselList, index)}></div>
                                                      <div className={(activeSortNoListYtDGroupedVesselList[index].item === "operator" && activeSortNoListYtDGroupedVesselList[index].sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDescYtDGroupedVesselList('operator', 1, element.GroupedVesselList, index)}></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </th>
                                              <th className="pooling-group-list-distance">
                                                <div className='common-sort-list-header-right'>
                                                  <div>
                                                    <span>Distance (NM)</span>
                                                  </div>
                                                  <div className="common-sort-container">
                                                    <div className="common-sort-icon">
                                                      <div className={(activeSortNoListYtDGroupedVesselList[index].item === "distance" && activeSortNoListYtDGroupedVesselList[index].sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAscYtDGroupedVesselList('distance', 2, element.GroupedVesselList, index)}></div>
                                                      <div className={(activeSortNoListYtDGroupedVesselList[index].item === "distance" && activeSortNoListYtDGroupedVesselList[index].sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDescYtDGroupedVesselList('distance', 2, element.GroupedVesselList, index)}></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </th>
                                              <th className="pooling-group-list-foc">
                                                <div className='common-sort-list-header-right'>
                                                  <div>
                                                    <span>FOC (MT)</span>
                                                  </div>
                                                  <div className="common-sort-container">
                                                    <div className="common-sort-icon">
                                                      <div className={(activeSortNoListYtDGroupedVesselList[index].item === "foc" && activeSortNoListYtDGroupedVesselList[index].sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAscYtDGroupedVesselList('foc', 2, element.GroupedVesselList, index)}></div>
                                                      <div className={(activeSortNoListYtDGroupedVesselList[index].item === "foc" && activeSortNoListYtDGroupedVesselList[index].sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDescYtDGroupedVesselList('foc', 2, element.GroupedVesselList, index)}></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </th>
                                              <th className="pooling-group-list-year-to-date">
                                                <div className='common-sort-list-header-right'>
                                                  <div>
                                                    <span>Year to Date</span>
                                                  </div>
                                                  <div className="common-sort-container">
                                                    <div className="common-sort-icon">
                                                      <div className={(activeSortNoListYtDGroupedVesselList[index].item === "year_to_date" && activeSortNoListYtDGroupedVesselList[index].sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAscYtDGroupedVesselList('year_to_date', 2, element.GroupedVesselList, index)}></div>
                                                      <div className={(activeSortNoListYtDGroupedVesselList[index].item === "year_to_date" && activeSortNoListYtDGroupedVesselList[index].sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDescYtDGroupedVesselList('year_to_date', 2, element.GroupedVesselList, index)}></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </th> 
                                              <th className="pooling-group-list-last-year">
                                                <div className='common-sort-list-header-right'>
                                                  <div>
                                                    <span>Last Year</span>
                                                  </div>
                                                  <div className="common-sort-container">
                                                    <div className="common-sort-icon">
                                                      <div className={(activeSortNoListYtDGroupedVesselList[index].item === "last_year" && activeSortNoListYtDGroupedVesselList[index].sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAscYtDGroupedVesselList('last_year', 2, element.GroupedVesselList, index)}></div>
                                                      <div className={(activeSortNoListYtDGroupedVesselList[index].item === "last_year" && activeSortNoListYtDGroupedVesselList[index].sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDescYtDGroupedVesselList('last_year', 2, element.GroupedVesselList, index)}></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </th>
                                              <th className="pooling-group-list-borrowing-limit">
                                                <div className='common-sort-list-header-right'>
                                                  <div>
                                                    <span>Borrowing Limit</span>
                                                  </div>
                                                  <div className="common-sort-container">
                                                    <div className="common-sort-icon">
                                                      <div className={"common-sort-icon-ascending"} ></div>
                                                      <div className={"common-sort-icon-descending"} ></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </th>
                                              <th className="pooling-group-list-borrowing">
                                                <div className='common-sort-list-header-right'>
                                                  <div>
                                                    <span>Borrowing</span>
                                                  </div>
                                                  <div className="common-sort-container">
                                                    <div className="common-sort-icon">
                                                      <div className={"common-sort-icon-ascending"} ></div>
                                                      <div className={"common-sort-icon-descending"} ></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </th>
                                              <th className="pooling-group-list-banking">
                                                <div className='common-sort-list-header-right'>
                                                  <div>
                                                    <span>Banking</span>
                                                  </div>
                                                  <div className="common-sort-container">
                                                    <div className="common-sort-icon">
                                                      <div className={"common-sort-icon-ascending"} ></div>
                                                      <div className={"common-sort-icon-descending"} ></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </th>
                                              <th className="pooling-group-list-total">
                                                <div className='common-sort-list-header-right'>
                                                  <div>
                                                    <span>Total</span>
                                                  </div>
                                                  <div className="common-sort-container">
                                                    <div className="common-sort-icon">
                                                      <div className={(activeSortNoListYtDGroupedVesselList[index].item === "total" && activeSortNoListYtDGroupedVesselList[index].sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAscYtDGroupedVesselList('total', 2, element.GroupedVesselList, index)}></div>
                                                      <div className={(activeSortNoListYtDGroupedVesselList[index].item === "total" && activeSortNoListYtDGroupedVesselList[index].sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDescYtDGroupedVesselList('total', 2, element.GroupedVesselList, index)}></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </th>
                                              <th className="pooling-group-list-penalty-factor">
                                                <div className='common-sort-list-header-right'>
                                                  <div>
                                                    <span>Penalty Factor</span>
                                                  </div>
                                                  <div className="common-sort-container">
                                                    <div className="common-sort-icon">
                                                      <div className={(activeSortNoListYtDGroupedVesselList[index].item === "penalty_factor" && activeSortNoListYtDGroupedVesselList[index].sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAscYtDGroupedVesselList('penalty_factor', 2, element.GroupedVesselList, index)}></div>
                                                      <div className={(activeSortNoListYtDGroupedVesselList[index].item === "penalty_factor" && activeSortNoListYtDGroupedVesselList[index].sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDescYtDGroupedVesselList('penalty_factor', 2, element.GroupedVesselList, index)}></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </th>
                                              <th className="pooling-group-list-cost-eur">
                                                <div className='common-sort-list-header-right'>
                                                  <div>
                                                    <span>Cost (€)</span>
                                                  </div>
                                                  <div className="common-sort-container">
                                                    <div className="common-sort-icon">
                                                      <div className={"common-sort-icon-ascending"} ></div>
                                                      <div className={"common-sort-icon-descending"} ></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </th>                           
                                            </tr>             
                                          </thead>
                                          <tbody>
                                            {element.GroupedVesselList.length !== 0 ?
                                              <>
                                                {
                                                  element.GroupedVesselList.map((element1, index) => {
                                                    return(
                                                      <tr>
                                                        <td className='pooling-group-list-vesselName'>
                                                          <span className="inner-text" title={`${element1.vessel_name}`}>
                                                            {element1.vessel_name}
                                                          </span>
                                                        </td>
                                                        <td className='pooling-group-list-operator'>
                                                          <span className="inner-text" title={`${element1.operator}`}>
                                                            {element1.operator}
                                                          </span>
                                                        </td>
                                                        <td className='pooling-group-list-distance'>
                                                          <span className="inner-text" title={`${element1.distance}`}>
                                                            {formatNumber(element1.distance)}
                                                          </span>
                                                        </td>
                                                        <td className='pooling-group-list-foc'>
                                                          <span className="inner-text" title={`${element1.foc}`}>
                                                            {formatNumber(element1.foc)}
                                                          </span>
                                                        </td>
                                                        <td className='pooling-group-list-year-to-date'>
                                                          <span className="inner-text" title={`${element1.year_to_date}`}>
                                                            {element1.year_to_date}
                                                          </span>
                                                        </td>
                                                        <td className='pooling-group-list-last-year'>
                                                          <span className="inner-text" title={`${element1.last_year}`}>
                                                            {element1.last_year}
                                                          </span>
                                                        </td>
                                                        <td className='pooling-group-list-borrowing-limit'>
                                                          <span>
                                                            -
                                                          </span>
                                                        </td>
                                                        <td className='pooling-group-list-borrowing'>
                                                          <span>
                                                            -
                                                          </span>
                                                        </td>
                                                        <td className='pooling-group-list-banking'>
                                                          <span>
                                                            -
                                                          </span>
                                                        </td>
                                                        <td className='pooling-group-list-total'>
                                                          <span className="inner-text" title={`${element1.total}`}>
                                                            {element1.total}
                                                          </span>
                                                        </td>
                                                        <td className='pooling-group-list-penalty-factor'>
                                                          <span className="inner-text" title={`${element1.penalty_factor}`}>
                                                            {element1.penalty_factor === 1 ? element1.penalty_factor.toFixed(1) : element1.penalty_factor}
                                                          </span>
                                                        </td>
                                                        <td className='pooling-group-list-cost-eur'>
                                                          <span>
                                                            -
                                                          </span>
                                                        </td>
                                                      </tr>
                                                    )
                                                  })
                                                }
                                              </>
                                              :
                                              <></>
                                            }
                                          </tbody>
                                        </table>
                                      </div>
                                      <div style={{height: "25px"}}></div>
                                    </div>
                                  </div>
                                  <div style={{height: "15px"}}></div>
                                </>
                              )
                            })
                          }
                        </>
                        :
                        <></>
                      }
                      <div className="fueleu-support-tool-list" key={"accordion_not_grouped_ytd"} data-is-accordion="0">
                        <div className="fueleu-support-tool-list-summary" onClick={(event) => accordion(event)}>
                          <div>
                            <img src={AccordionIcon} alt="SVG"/>
                          </div>
                          <div className="fueleu-support-tool-summary-group-name">
                            <span>Not grouped Vessels</span>                      
                          </div>
                          <div className="fueleu-support-tool-summary-total-cb">
                            <span>{ytdNotGroupTotal !== undefined ? formatNumber(ytdNotGroupTotal.total_cb): "-"}</span>
                          </div>
                          <div className="fueleu-support-tool-summary-total-cb-cost">
                            <span>{ytdNotGroupTotal !== undefined ? formatNumber(ytdNotGroupTotal.total_cb_cost): "-"}</span>
                            <span>€</span>
                          </div>
                        </div>
                        <div className="fueleu-support-tool-list-detail">
                          <div style={{width: "calc(100% - 63px)", height: "50px", marginLeft: "35px", position: "relative"}}>
                            <Box style={{ width: "46.25%", height: "25px", left: "36.5%", top: "22px", position: 'absolute', background: 'rgba(83, 84, 89, 0.50)', }}>
                              <Typography align="center" sx={{ marginTop: "2px", color: 'rgba(255, 255, 255, 0.50)', fontSize: 14, fontFamily: 'Roboto', fontWeight: 'bold', wordWrap: 'break-word' }}>
                                Compliance Balance (TonCO2eq)
                              </Typography>
                            </Box>
                          </div>
                          <div className="pooling-group-list-table-wrapper">
                            <table className='pooling-group-list-table'>
                              <thead>
                                <tr>
                                  <th className="pooling-group-list-vesselName">
                                    <div className='common-sort-list-header-left'>
                                      <div>
                                        <span>Vessel Name</span>
                                      </div>
                                      <div className="common-sort-container">
                                        <div className="common-sort-icon">
                                          <div className={(activeSortNoYtDNotGroupedVessel.item === "vessel_name" && activeSortNoYtDNotGroupedVessel.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAscYtDNotGroupedVesselList('vessel_name', 1)}></div>
                                          <div className={(activeSortNoYtDNotGroupedVessel.item === "vessel_name" && activeSortNoYtDNotGroupedVessel.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDescYtDNotGroupedVesselList('vessel_name', 1)}></div>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <th className="pooling-group-list-operator">
                                    <div className='common-sort-list-header-left'>
                                      <div>
                                        <span>Operator</span>
                                      </div>
                                      <div className="common-sort-container">
                                        <div className="common-sort-icon">
                                          <div className={(activeSortNoYtDNotGroupedVessel.item === "operator" && activeSortNoYtDNotGroupedVessel.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAscYtDNotGroupedVesselList('operator', 1)}></div>
                                          <div className={(activeSortNoYtDNotGroupedVessel.item === "operator" && activeSortNoYtDNotGroupedVessel.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDescYtDNotGroupedVesselList('operator', 1)}></div>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <th className="pooling-group-list-distance">
                                    <div className='common-sort-list-header-right'>
                                      <div>
                                        <span>Distance (NM)</span>
                                      </div>
                                      <div className="common-sort-container">
                                        <div className="common-sort-icon">
                                          <div className={(activeSortNoYtDNotGroupedVessel.item === "distance" && activeSortNoYtDNotGroupedVessel.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAscYtDNotGroupedVesselList('distance', 2)}></div>
                                          <div className={(activeSortNoYtDNotGroupedVessel.item === "distance" && activeSortNoYtDNotGroupedVessel.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDescYtDNotGroupedVesselList('distance', 2)}></div>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <th className="pooling-group-list-foc">
                                    <div className='common-sort-list-header-right'>
                                      <div>
                                        <span>FOC (MT)</span>
                                      </div>
                                      <div className="common-sort-container">
                                        <div className="common-sort-icon">
                                          <div className={(activeSortNoYtDNotGroupedVessel.item === "foc" && activeSortNoYtDNotGroupedVessel.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAscYtDNotGroupedVesselList('foc', 2)}></div>
                                          <div className={(activeSortNoYtDNotGroupedVessel.item === "foc" && activeSortNoYtDNotGroupedVessel.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDescYtDNotGroupedVesselList('foc', 2)}></div>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <th className="pooling-group-list-year-to-date">
                                    <div className='common-sort-list-header-right'>
                                      <div>
                                        <span>Year to Date</span>
                                      </div>
                                      <div className="common-sort-container">
                                        <div className="common-sort-icon">
                                          <div className={(activeSortNoYtDNotGroupedVessel.item === "year_to_date" && activeSortNoYtDNotGroupedVessel.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAscYtDNotGroupedVesselList('year_to_date', 2)}></div>
                                          <div className={(activeSortNoYtDNotGroupedVessel.item === "year_to_date" && activeSortNoYtDNotGroupedVessel.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDescYtDNotGroupedVesselList('year_to_date', 2)}></div>
                                        </div>
                                      </div>
                                    </div>
                                  </th> 
                                  <th className="pooling-group-list-last-year">
                                    <div className='common-sort-list-header-right'>
                                      <div>
                                        <span>Last Year</span>
                                      </div>
                                      <div className="common-sort-container">
                                        <div className="common-sort-icon">
                                          <div className={(activeSortNoYtDNotGroupedVessel.item === "last_year" && activeSortNoYtDNotGroupedVessel.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAscYtDNotGroupedVesselList('last_year', 2)}></div>
                                          <div className={(activeSortNoYtDNotGroupedVessel.item === "last_year" && activeSortNoYtDNotGroupedVessel.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDescYtDNotGroupedVesselList('last_year', 2)}></div>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <th className="pooling-group-list-borrowing-limit">
                                    <div className='common-sort-list-header-right'>
                                      <div>
                                        <span>Borrowing Limit</span>
                                      </div>
                                      <div className="common-sort-container">
                                        <div className="common-sort-icon">
                                          <div className={(activeSortNoYtDNotGroupedVessel.item === "borrowing_limit" && activeSortNoYtDNotGroupedVessel.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAscYtDNotGroupedVesselList('borrowing_limit', 2)}></div>
                                          <div className={(activeSortNoYtDNotGroupedVessel.item === "borrowing_limit" && activeSortNoYtDNotGroupedVessel.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDescYtDNotGroupedVesselList('borrowing_limit', 2)}></div>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <th className="pooling-group-list-borrowing">
                                    <div className='common-sort-list-header-right'>
                                      <div>
                                        <span>Borrowing</span>
                                      </div>
                                      <div className="common-sort-container">
                                        <div className="common-sort-icon">
                                          <div className={(activeSortNoYtDNotGroupedVessel.item === "borrowing" && activeSortNoYtDNotGroupedVessel.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAscYtDNotGroupedVesselList('borrowing', 2)}></div>
                                          <div className={(activeSortNoYtDNotGroupedVessel.item === "borrowing" && activeSortNoYtDNotGroupedVessel.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDescYtDNotGroupedVesselList('borrowing', 2)}></div>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <th className="pooling-group-list-banking">
                                    <div className='common-sort-list-header-right'>
                                      <div>
                                        <span>Banking</span>
                                      </div>
                                      <div className="common-sort-container">
                                        <div className="common-sort-icon">
                                          <div className={(activeSortNoYtDNotGroupedVessel.item === "banking" && activeSortNoYtDNotGroupedVessel.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAscYtDNotGroupedVesselList('banking', 2)}></div>
                                          <div className={(activeSortNoYtDNotGroupedVessel.item === "banking" && activeSortNoYtDNotGroupedVessel.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDescYtDNotGroupedVesselList('banking', 2)}></div>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <th className="pooling-group-list-total">
                                    <div className='common-sort-list-header-right'>
                                      <div>
                                        <span>Total</span>
                                      </div>
                                      <div className="common-sort-container">
                                        <div className="common-sort-icon">
                                          <div className={(activeSortNoYtDNotGroupedVessel.item === "total" && activeSortNoYtDNotGroupedVessel.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAscYtDNotGroupedVesselList('total', 2)}></div>
                                          <div className={(activeSortNoYtDNotGroupedVessel.item === "total" && activeSortNoYtDNotGroupedVessel.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDescYtDNotGroupedVesselList('total', 2)}></div>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <th className="pooling-group-list-penalty-factor">
                                    <div className='common-sort-list-header-right'>
                                      <div>
                                        <span>Penalty Factor</span>
                                      </div>
                                      <div className="common-sort-container">
                                        <div className="common-sort-icon">
                                          <div className={(activeSortNoYtDNotGroupedVessel.item === "penalty_factor" && activeSortNoYtDNotGroupedVessel.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAscYtDNotGroupedVesselList('penalty_factor', 2)}></div>
                                          <div className={(activeSortNoYtDNotGroupedVessel.item === "penalty_factor" && activeSortNoYtDNotGroupedVessel.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDescYtDNotGroupedVesselList('penalty_factor', 2)}></div>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <th className="pooling-group-list-cost-eur">
                                    <div className='common-sort-list-header-right'>
                                      <div>
                                        <span>Cost (€)</span>
                                      </div>
                                      <div className="common-sort-container">
                                        <div className="common-sort-icon">
                                          <div className={(activeSortNoYtDNotGroupedVessel.item === "cost" && activeSortNoYtDNotGroupedVessel.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAscYtDNotGroupedVesselList('cost', 2)}></div>
                                          <div className={(activeSortNoYtDNotGroupedVessel.item === "cost" && activeSortNoYtDNotGroupedVessel.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDescYtDNotGroupedVesselList('cost', 2)}></div>
                                        </div>
                                      </div>
                                    </div>
                                  </th>                           
                                </tr>             
                              </thead>
                              <tbody>
                                {ytdNotGroupedVesselList.length !== 0 ?
                                  <>
                                    {
                                      ytdNotGroupedVesselList.map((element, index) => {
                                        return (                       
                                          <tr>
                                            <td className='pooling-group-list-vesselName'>
                                              <span className="inner-text" title={`${element.vessel_name}`}>
                                                {element.vessel_name}
                                              </span>
                                            </td>
                                            <td className='pooling-group-list-operator'>
                                              <span className="inner-text" title={`${element.operator}`}>
                                                {element.operator}
                                              </span>
                                            </td>
                                            <td className='pooling-group-list-distance'>
                                              <span className="inner-text" title={`${element.distance}`}>
                                                {formatNumber(element.distance)}
                                              </span>
                                            </td>
                                            <td className='pooling-group-list-foc'>
                                              <span className="inner-text" title={`${element.foc}`}>
                                                {formatNumber(element.foc)}
                                              </span>
                                            </td>
                                            <td className='pooling-group-list-year-to-date'>
                                              <span className="inner-text" title={`${element.year_to_date}`}>
                                                {formatNumber(element.year_to_date)}
                                              </span>
                                            </td>
                                            <td className='pooling-group-list-last-year'>
                                              <span className="inner-text" title={`${element.last_year}`}>
                                                {formatNumber(element.last_year)}
                                              </span>
                                            </td>
                                            <td className='pooling-group-list-borrowing-limit'>
                                              <span className="inner-text" title={`${element.borrowing_limit}`}>
                                                {element.borrowing_limit}
                                              </span>
                                            </td>
                                            <td className='pooling-group-list-borrowing'>
                                              <Input
                                                value={element.borrowing} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeBorrowing_ytd(event.target.value, index, element.borrowing_limit) }}
                                                onBlur={(event) => handleBlurBorrowing_ytd(event, index, element.imo, element.operator)}
                                                type='tel'
                                                sx={{
                                                  marginRight: "10px",
                                                  width: "65px", fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                                  "& .MuiInput-input": { height: "26px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                                  ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                                  ":hover:after": { borderBottom: "0px solid green", },
                                                  ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                                  ":after": { borderBottom: "2px solid white", },
                                                  ":before": { borderBottom: "2px solid #A4A7B1", },
                                                }} />
                                            </td>
                                            <td className='pooling-group-list-banking'>
                                              <span className="inner-text" title={`${element.banking}`}>
                                                {formatNumber(element.banking)}
                                              </span>
                                            </td>
                                            <td className='pooling-group-list-total'>
                                              <span className="inner-text" title={`${element.total}`}>
                                                {formatNumber(element.total)}
                                              </span>
                                            </td>
                                            <td className='pooling-group-list-penalty-factor'>
                                              <span className="inner-text" title={`${element.penalty_factor}`}>
                                                {element.penalty_factor === 1 ? element.penalty_factor.toFixed(1) : element.penalty_factor}
                                              </span>
                                            </td>
                                            <td className='pooling-group-list-cost-eur'>
                                              <span className="inner-text" title={`${element.cost}`}>
                                                {formatNumber(element.cost)}
                                              </span>
                                            </td>
                                          </tr>
                                        )
                                      })
                                    }
                                  </>
                                  :
                                  <></>
                                }
                              </tbody>
                            </table>
                          </div>
                          {/* List下部の余白 */}
                          <div style={{height: "25px"}}></div>
                        </div>
                      </div>
                      {/* アコーディオン間の余白 */}
                      <div style={{height: "15px"}}></div>
                    </div>
                    
                  </Box>
                  

                </Box>
                :
                // End of Year
                <Box 
                  sx={{
                    height: "calc(100% - 50px)",
                    borderTop:"1px solid #3F3E3E", 
                    borderTopWidth: "2px",
                  }}>
                  {/* リスト */}
                  <Box sx={{ height: "100%", width: "calc(100% - 6px)", marginLeft: "6px"}}>
                    {/* ヘッダー */}
                    <Stack sx={{ borderBottom: "1px dotted rgb(255, 255, 255, 0.5)", height: "46px", position: "relative"}}>
                      <Box sx={{ fontSize: "14px", fontWeight: "bold", color: "rgba(255, 255, 255, 0.5)",
                          position: "absolute", left: "15.6%", top: "22px",
                        }}>
                        Total Compliance Balance
                      </Box>
                      <Box sx={{ fontSize: "14px", fontWeight: "bold", color: "rgba(255, 255, 255, 0.5)",
                          position: "absolute", left: "30.8%", top: "22px",
                        }}>
                        Total Compliance Balance Cost
                      </Box>
                    </Stack>

                    {/* Pooling Group */}
                    <div className='fueleu-support-tool-list-scroll'>
                      {eoyGroupList.length !== 0 ?
                        <>
                          {
                            eoyGroupList.map((element, index) => {
                              return (
                                <>
                                  <div className="fueleu-support-tool-list" key={"accordion_grouped_eoy_" + index} data-is-accordion="0">
                                    <div className="fueleu-support-tool-list-summary" onClick={(event) => accordion(event)}>
                                      <div>
                                        <img src={AccordionIcon} alt="SVG"/>
                                      </div>
                                      <div className="fueleu-support-tool-summary-group-name">
                                        <span>Pooling Group {element.group_name}</span>                      
                                      </div>
                                      <div className="fueleu-support-tool-summary-total-cb">
                                        <span>{element.total_cb !== "0.0" ? formatNumber(element.total_cb) : "0"}</span>
                                      </div>
                                      <div className="fueleu-support-tool-summary-total-cb-cost">
                                        <span>{formatNumber(element.total_cb_cost)}</span>
                                        <span>€</span>
                                      </div>
                                    </div>
                                    <div className="fueleu-support-tool-list-detail">
                                      <div style={{width: "calc(100% - 63px)", height: "50px", marginLeft: "35px", position: "relative"}}>
                                        <Box style={{ width: "46.25%", height: "25px", left: "36.5%", top: "22px", position: 'absolute', background: 'rgba(83, 84, 89, 0.50)', }}>
                                          <Typography align="center" sx={{ marginTop: "2px", color: 'rgba(255, 255, 255, 0.50)', fontSize: 14, fontFamily: 'Roboto', fontWeight: 'bold', wordWrap: 'break-word' }}>
                                            Compliance Balance (TonCO2eq)
                                          </Typography>
                                        </Box>
                                      </div>
                                      <div className="pooling-group-list-table-wrapper">
                                        <table className='pooling-group-list-table'>
                                          <thead>
                                            <tr>
                                              <th className="pooling-group-list-vesselName">
                                                <div className='common-sort-list-header-left'>
                                                  <div>
                                                    <span>Vessel Name</span>
                                                  </div>
                                                  <div className="common-sort-container">
                                                    <div className="common-sort-icon">
                                                      <div className={(activeSortNoListEoYGroupedVesselList[index].item === "vessel_name" && activeSortNoListEoYGroupedVesselList[index].sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAscEoYGroupedVesselList('vessel_name', 1, element.GroupedVesselList, index)}></div>
                                                      <div className={(activeSortNoListEoYGroupedVesselList[index].item === "vessel_name" && activeSortNoListEoYGroupedVesselList[index].sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDescEoYGroupedVesselList('vessel_name', 1, element.GroupedVesselList, index)}></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </th>
                                              <th className="pooling-group-list-operator">
                                                <div className='common-sort-list-header-left'>
                                                  <div>
                                                    <span>Operator</span>
                                                  </div>
                                                  <div className="common-sort-container">
                                                    <div className="common-sort-icon">
                                                      <div className={(activeSortNoListEoYGroupedVesselList[index].item === "operator" && activeSortNoListEoYGroupedVesselList[index].sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAscEoYGroupedVesselList('operator', 1, element.GroupedVesselList, index)}></div>
                                                      <div className={(activeSortNoListEoYGroupedVesselList[index].item === "operator" && activeSortNoListEoYGroupedVesselList[index].sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDescEoYGroupedVesselList('operator', 1, element.GroupedVesselList, index)}></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </th>
                                              <th className="pooling-group-list-distance">
                                                <div className='common-sort-list-header-right'>
                                                  <div>
                                                    <span>Distance (NM)</span>
                                                  </div>
                                                  <div className="common-sort-container">
                                                    <div className="common-sort-icon">
                                                      <div className={(activeSortNoListEoYGroupedVesselList[index].item === "distance" && activeSortNoListEoYGroupedVesselList[index].sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAscEoYGroupedVesselList('distance', 2, element.GroupedVesselList, index)}></div>
                                                      <div className={(activeSortNoListEoYGroupedVesselList[index].item === "distance" && activeSortNoListEoYGroupedVesselList[index].sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDescEoYGroupedVesselList('distance', 2, element.GroupedVesselList, index)}></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </th>
                                              <th className="pooling-group-list-foc">
                                                <div className='common-sort-list-header-right'>
                                                  <div>
                                                    <span>FOC (MT)</span>
                                                  </div>
                                                  <div className="common-sort-container">
                                                    <div className="common-sort-icon">
                                                      <div className={(activeSortNoListEoYGroupedVesselList[index].item === "foc" && activeSortNoListEoYGroupedVesselList[index].sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAscEoYGroupedVesselList('foc', 2, element.GroupedVesselList, index)}></div>
                                                      <div className={(activeSortNoListEoYGroupedVesselList[index].item === "foc" && activeSortNoListEoYGroupedVesselList[index].sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDescEoYGroupedVesselList('foc', 2, element.GroupedVesselList, index)}></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </th>
                                              <th className="pooling-group-list-year-to-date">
                                                <div className='common-sort-list-header-right'>
                                                  <div>
                                                    <span>End of Year</span>
                                                  </div>
                                                  <div className="common-sort-container">
                                                    <div className="common-sort-icon">
                                                      <div className={(activeSortNoListEoYGroupedVesselList[index].item === "end_of_year" && activeSortNoListEoYGroupedVesselList[index].sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAscEoYGroupedVesselList('end_of_year', 2, element.GroupedVesselList, index)}></div>
                                                      <div className={(activeSortNoListEoYGroupedVesselList[index].item === "end_of_year" && activeSortNoListEoYGroupedVesselList[index].sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDescEoYGroupedVesselList('end_of_year', 2, element.GroupedVesselList, index)}></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </th> 
                                              <th className="pooling-group-list-last-year">
                                                <div className='common-sort-list-header-right'>
                                                  <div>
                                                    <span>Last Year</span>
                                                  </div>
                                                  <div className="common-sort-container">
                                                    <div className="common-sort-icon">
                                                      <div className={(activeSortNoListEoYGroupedVesselList[index].item === "last_year" && activeSortNoListEoYGroupedVesselList[index].sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAscEoYGroupedVesselList('last_year', 2, element.GroupedVesselList, index)}></div>
                                                      <div className={(activeSortNoListEoYGroupedVesselList[index].item === "last_year" && activeSortNoListEoYGroupedVesselList[index].sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDescEoYGroupedVesselList('last_year', 2, element.GroupedVesselList, index)}></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </th>
                                              <th className="pooling-group-list-borrowing-limit">
                                                <div className='common-sort-list-header-right'>
                                                  <div>
                                                    <span>Borrowing Limit</span>
                                                  </div>
                                                  <div className="common-sort-container">
                                                    <div className="common-sort-icon">
                                                      <div className={"common-sort-icon-ascending"} ></div>
                                                      <div className={"common-sort-icon-descending"} ></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </th>
                                              <th className="pooling-group-list-borrowing">
                                                <div className='common-sort-list-header-right'>
                                                  <div>
                                                    <span>Borrowing</span>
                                                  </div>
                                                  <div className="common-sort-container">
                                                    <div className="common-sort-icon">
                                                      <div className={"common-sort-icon-ascending"} ></div>
                                                      <div className={"common-sort-icon-descending"} ></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </th>
                                              <th className="pooling-group-list-banking">
                                                <div className='common-sort-list-header-right'>
                                                  <div>
                                                    <span>Banking</span>
                                                  </div>
                                                  <div className="common-sort-container">
                                                    <div className="common-sort-icon">
                                                      <div className={"common-sort-icon-ascending"} ></div>
                                                      <div className={"common-sort-icon-descending"} ></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </th>
                                              <th className="pooling-group-list-total">
                                                <div className='common-sort-list-header-right'>
                                                  <div>
                                                    <span>Total</span>
                                                  </div>
                                                  <div className="common-sort-container">
                                                    <div className="common-sort-icon">
                                                      <div className={(activeSortNoListEoYGroupedVesselList[index].item === "total" && activeSortNoListEoYGroupedVesselList[index].sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAscEoYGroupedVesselList('total', 2, element.GroupedVesselList, index)}></div>
                                                      <div className={(activeSortNoListEoYGroupedVesselList[index].item === "total" && activeSortNoListEoYGroupedVesselList[index].sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDescEoYGroupedVesselList('total', 2, element.GroupedVesselList, index)}></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </th>
                                              <th className="pooling-group-list-penalty-factor">
                                                <div className='common-sort-list-header-right'>
                                                  <div>
                                                    <span>Penalty Factor</span>
                                                  </div>
                                                  <div className="common-sort-container">
                                                    <div className="common-sort-icon">
                                                      <div className={(activeSortNoListEoYGroupedVesselList[index].item === "penalty_factor" && activeSortNoListEoYGroupedVesselList[index].sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAscEoYGroupedVesselList('penalty_factor', 2, element.GroupedVesselList, index)}></div>
                                                      <div className={(activeSortNoListEoYGroupedVesselList[index].item === "penalty_factor" && activeSortNoListEoYGroupedVesselList[index].sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDescEoYGroupedVesselList('penalty_factor', 2, element.GroupedVesselList, index)}></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </th>
                                              <th className="pooling-group-list-cost-eur">
                                                <div className='common-sort-list-header-right'>
                                                  <div>
                                                    <span>Cost (€)</span>
                                                  </div>
                                                  <div className="common-sort-container">
                                                    <div className="common-sort-icon">
                                                      <div className={"common-sort-icon-ascending"} ></div>
                                                      <div className={"common-sort-icon-descending"} ></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </th>                           
                                            </tr>             
                                          </thead>
                                          <tbody>
                                            {element.GroupedVesselList.length !== 0 ?
                                              <>
                                                {
                                                  element.GroupedVesselList.map((element1, index) => {
                                                    return(
                                                      <tr>
                                                        <td className='pooling-group-list-vesselName'>
                                                          <span className="inner-text" title={`${element1.vessel_name}`}>
                                                            {element1.vessel_name}
                                                          </span>
                                                        </td>
                                                        <td className='pooling-group-list-operator'>
                                                          <span className="inner-text" title={`${element1.operator}`}>
                                                            {element1.operator}
                                                          </span>
                                                        </td>
                                                        <td className='pooling-group-list-distance'>
                                                          <span className="inner-text" title={`${element1.distance}`}>
                                                            {formatNumber(element1.distance)}
                                                          </span>
                                                        </td>
                                                        <td className='pooling-group-list-foc'>
                                                          <span className="inner-text" title={`${element1.foc}`}>
                                                            {formatNumber(element1.foc)}
                                                          </span>
                                                        </td>
                                                        <td className='pooling-group-list-year-to-date'>
                                                          <span className="inner-text" title={`${element1.end_of_year}`}>
                                                            {element1.end_of_year}
                                                          </span>
                                                        </td>
                                                        <td className='pooling-group-list-last-year'>
                                                          <span className="inner-text" title={`${element1.last_year}`}>
                                                            {element1.last_year}
                                                          </span>
                                                        </td>
                                                        <td className='pooling-group-list-borrowing-limit'>
                                                          <span>
                                                            -
                                                          </span>
                                                        </td>
                                                        <td className='pooling-group-list-borrowing'>
                                                          <span>
                                                            -
                                                          </span>
                                                        </td>
                                                        <td className='pooling-group-list-banking'>
                                                          <span>
                                                            {element1.banking !== undefined ? element1.banking : "-"}
                                                          </span>
                                                        </td>
                                                        <td className='pooling-group-list-total'>
                                                          <span className="inner-text" title={`${element1.total}`}>
                                                            {element1.total}
                                                          </span>
                                                        </td>
                                                        <td className='pooling-group-list-penalty-factor'>
                                                          <span className="inner-text" title={`${element1.penalty_factor}`}>
                                                            {element1.penalty_factor === 1 ? element1.penalty_factor.toFixed(1) : element1.penalty_factor}
                                                          </span>
                                                        </td>
                                                        <td className='pooling-group-list-cost-eur'>
                                                          <span>
                                                            -
                                                          </span>
                                                        </td>
                                                      </tr>
                                                    )
                                                  })
                                                }
                                              </>
                                              :
                                              <></>
                                            }
                                          </tbody>
                                        </table>
                                      </div>
                                      <div style={{height: "25px"}}></div>
                                    </div>
                                  </div>
                                  <div style={{height: "15px"}}></div>
                                </>
                              )
                            })
                          }
                        </>
                        :
                        <></>
                      }
                      <div className="fueleu-support-tool-list" key={"accordion_not_grouped_eoy"} data-is-accordion="0">
                        <div className="fueleu-support-tool-list-summary" onClick={(event) => accordion(event)}>
                          <div>
                            <img src={AccordionIcon} alt="SVG"/>
                          </div>
                          <div className="fueleu-support-tool-summary-group-name">
                            <span>Not grouped Vessels</span>                      
                          </div>
                          <div className="fueleu-support-tool-summary-total-cb">
                            <span>{eoyNotGroupTotal !== undefined ? formatNumber(eoyNotGroupTotal.total_cb): "-"}</span>
                          </div>
                          <div className="fueleu-support-tool-summary-total-cb-cost">
                            <span>{eoyNotGroupTotal !== undefined ? formatNumber(eoyNotGroupTotal.total_cb_cost): "-"}</span>
                            <span>€</span>
                          </div>
                        </div>
                        <div className="fueleu-support-tool-list-detail">
                          <div style={{width: "calc(100% - 63px)", height: "50px", marginLeft: "35px", position: "relative"}}>
                            <Box style={{ width: "46.25%", height: "25px", left: "36.5%", top: "22px", position: 'absolute', background: 'rgba(83, 84, 89, 0.50)', }}>
                              <Typography align="center" sx={{ marginTop: "2px", color: 'rgba(255, 255, 255, 0.50)', fontSize: 14, fontFamily: 'Roboto', fontWeight: 'bold', wordWrap: 'break-word' }}>
                                Compliance Balance (TonCO2eq)
                              </Typography>
                            </Box>
                          </div>
                          <div className="pooling-group-list-table-wrapper">
                            <table className='pooling-group-list-table'>
                              <thead>
                                <tr>
                                  <th className="pooling-group-list-vesselName">
                                    <div className='common-sort-list-header-left'>
                                      <div>
                                        <span>Vessel Name</span>
                                      </div>
                                      <div className="common-sort-container">
                                        <div className="common-sort-icon">
                                          <div className={(activeSortNoEoYNotGroupedVessel.item === "vessel_name" && activeSortNoEoYNotGroupedVessel.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAscEoYNotGroupedVesselList('vessel_name', 1)}></div>
                                          <div className={(activeSortNoEoYNotGroupedVessel.item === "vessel_name" && activeSortNoEoYNotGroupedVessel.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDescEoYNotGroupedVesselList('vessel_name', 1)}></div>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <th className="pooling-group-list-operator">
                                    <div className='common-sort-list-header-left'>
                                      <div>
                                        <span>Operator</span>
                                      </div>
                                      <div className="common-sort-container">
                                        <div className="common-sort-icon">
                                          <div className={(activeSortNoEoYNotGroupedVessel.item === "operator" && activeSortNoEoYNotGroupedVessel.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAscEoYNotGroupedVesselList('operator', 1)}></div>
                                          <div className={(activeSortNoEoYNotGroupedVessel.item === "operator" && activeSortNoEoYNotGroupedVessel.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDescEoYNotGroupedVesselList('operator', 1)}></div>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <th className="pooling-group-list-distance">
                                    <div className='common-sort-list-header-right'>
                                      <div>
                                        <span>Distance (NM)</span>
                                      </div>
                                      <div className="common-sort-container">
                                        <div className="common-sort-icon">
                                          <div className={(activeSortNoEoYNotGroupedVessel.item === "distance" && activeSortNoEoYNotGroupedVessel.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAscEoYNotGroupedVesselList('distance', 2)}></div>
                                          <div className={(activeSortNoEoYNotGroupedVessel.item === "distance" && activeSortNoEoYNotGroupedVessel.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDescEoYNotGroupedVesselList('distance', 2)}></div>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <th className="pooling-group-list-foc">
                                    <div className='common-sort-list-header-right'>
                                      <div>
                                        <span>FOC (MT)</span>
                                      </div>
                                      <div className="common-sort-container">
                                        <div className="common-sort-icon">
                                          <div className={(activeSortNoEoYNotGroupedVessel.item === "foc" && activeSortNoEoYNotGroupedVessel.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAscEoYNotGroupedVesselList('foc', 2)}></div>
                                          <div className={(activeSortNoEoYNotGroupedVessel.item === "foc" && activeSortNoEoYNotGroupedVessel.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDescEoYNotGroupedVesselList('foc', 2)}></div>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <th className="pooling-group-list-year-to-date">
                                    <div className='common-sort-list-header-right'>
                                      <div>
                                        <span>End of Year</span>
                                      </div>
                                      <div className="common-sort-container">
                                        <div className="common-sort-icon">
                                          <div className={(activeSortNoEoYNotGroupedVessel.item === "end_of_year" && activeSortNoEoYNotGroupedVessel.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAscEoYNotGroupedVesselList('end_of_year', 2)}></div>
                                          <div className={(activeSortNoEoYNotGroupedVessel.item === "end_of_year" && activeSortNoEoYNotGroupedVessel.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDescEoYNotGroupedVesselList('end_of_year', 2)}></div>
                                        </div>
                                      </div>
                                    </div>
                                  </th> 
                                  <th className="pooling-group-list-last-year">
                                    <div className='common-sort-list-header-right'>
                                      <div>
                                        <span>Last Year</span>
                                      </div>
                                      <div className="common-sort-container">
                                        <div className="common-sort-icon">
                                          <div className={(activeSortNoEoYNotGroupedVessel.item === "last_year" && activeSortNoEoYNotGroupedVessel.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAscEoYNotGroupedVesselList('last_year', 2)}></div>
                                          <div className={(activeSortNoEoYNotGroupedVessel.item === "last_year" && activeSortNoEoYNotGroupedVessel.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDescEoYNotGroupedVesselList('last_year', 2)}></div>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <th className="pooling-group-list-borrowing-limit">
                                    <div className='common-sort-list-header-right'>
                                      <div>
                                        <span>Borrowing Limit</span>
                                      </div>
                                      <div className="common-sort-container">
                                        <div className="common-sort-icon">
                                          <div className={(activeSortNoEoYNotGroupedVessel.item === "borrowing_limit" && activeSortNoEoYNotGroupedVessel.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAscEoYNotGroupedVesselList('borrowing_limit', 2)}></div>
                                          <div className={(activeSortNoEoYNotGroupedVessel.item === "borrowing_limit" && activeSortNoEoYNotGroupedVessel.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDescEoYNotGroupedVesselList('borrowing_limit', 2)}></div>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <th className="pooling-group-list-borrowing">
                                    <div className='common-sort-list-header-right'>
                                      <div>
                                        <span>Borrowing</span>
                                      </div>
                                      <div className="common-sort-container">
                                        <div className="common-sort-icon">
                                          <div className={(activeSortNoEoYNotGroupedVessel.item === "borrowing" && activeSortNoEoYNotGroupedVessel.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAscEoYNotGroupedVesselList('borrowing', 2)}></div>
                                          <div className={(activeSortNoEoYNotGroupedVessel.item === "borrowing" && activeSortNoEoYNotGroupedVessel.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDescEoYNotGroupedVesselList('borrowing', 2)}></div>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <th className="pooling-group-list-banking">
                                    <div className='common-sort-list-header-right'>
                                      <div>
                                        <span>Banking</span>
                                      </div>
                                      <div className="common-sort-container">
                                        <div className="common-sort-icon">
                                          <div className={(activeSortNoEoYNotGroupedVessel.item === "banking" && activeSortNoEoYNotGroupedVessel.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAscEoYNotGroupedVesselList('banking', 2)}></div>
                                          <div className={(activeSortNoEoYNotGroupedVessel.item === "banking" && activeSortNoEoYNotGroupedVessel.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDescEoYNotGroupedVesselList('banking', 2)}></div>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <th className="pooling-group-list-total">
                                    <div className='common-sort-list-header-right'>
                                      <div>
                                        <span>Total</span>
                                      </div>
                                      <div className="common-sort-container">
                                        <div className="common-sort-icon">
                                          <div className={(activeSortNoEoYNotGroupedVessel.item === "total" && activeSortNoEoYNotGroupedVessel.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAscEoYNotGroupedVesselList('total', 2)}></div>
                                          <div className={(activeSortNoEoYNotGroupedVessel.item === "total" && activeSortNoEoYNotGroupedVessel.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDescEoYNotGroupedVesselList('total', 2)}></div>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <th className="pooling-group-list-penalty-factor">
                                    <div className='common-sort-list-header-right'>
                                      <div>
                                        <span>Penalty Factor</span>
                                      </div>
                                      <div className="common-sort-container">
                                        <div className="common-sort-icon">
                                          <div className={(activeSortNoEoYNotGroupedVessel.item === "penalty_factor" && activeSortNoEoYNotGroupedVessel.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAscEoYNotGroupedVesselList('penalty_factor', 2)}></div>
                                          <div className={(activeSortNoEoYNotGroupedVessel.item === "penalty_factor" && activeSortNoEoYNotGroupedVessel.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDescEoYNotGroupedVesselList('penalty_factor', 2)}></div>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <th className="pooling-group-list-cost-eur">
                                    <div className='common-sort-list-header-right'>
                                      <div>
                                        <span>Cost (€)</span>
                                      </div>
                                      <div className="common-sort-container">
                                        <div className="common-sort-icon">
                                          <div className={(activeSortNoEoYNotGroupedVessel.item === "cost" && activeSortNoEoYNotGroupedVessel.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAscEoYNotGroupedVesselList('cost', 2)}></div>
                                          <div className={(activeSortNoEoYNotGroupedVessel.item === "cost" && activeSortNoEoYNotGroupedVessel.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDescEoYNotGroupedVesselList('cost', 2)}></div>
                                        </div>
                                      </div>
                                    </div>
                                  </th>                           
                                </tr>             
                              </thead>
                              <tbody>
                                {eoyNotGroupedVesselList.length !== 0 ?
                                  <>
                                    {
                                      eoyNotGroupedVesselList.map((element, index) => {
                                        return (                       
                                          <tr>
                                            <td className='pooling-group-list-vesselName'>
                                              <span className="inner-text" title={`${element.vessel_name}`}>
                                                {element.vessel_name}
                                              </span>
                                            </td>
                                            <td className='pooling-group-list-operator'>
                                              <span className="inner-text" title={`${element.operator}`}>
                                                {element.operator}
                                              </span>
                                            </td>
                                            <td className='pooling-group-list-distance'>
                                              <span className="inner-text" title={`${element.distance}`}>
                                                {formatNumber(element.distance)}
                                              </span>
                                            </td>
                                            <td className='pooling-group-list-foc'>
                                              <span className="inner-text" title={`${element.foc}`}>
                                                {formatNumber(element.foc)}
                                              </span>
                                            </td>
                                            <td className='pooling-group-list-year-to-date'>
                                              <span className="inner-text" title={`${element.end_of_year}`}>
                                                {formatNumber(element.end_of_year)}
                                              </span>
                                            </td>
                                            <td className='pooling-group-list-last-year'>
                                              <span className="inner-text" title={`${element.last_year}`}>
                                                {formatNumber(element.last_year)}
                                              </span>
                                            </td>
                                            <td className='pooling-group-list-borrowing-limit'>
                                              <span className="inner-text" title={`${element.borrowing_limit}`}>
                                                {element.borrowing_limit}
                                              </span>
                                            </td>
                                            <td className='pooling-group-list-borrowing'>
                                              <Input
                                                value={element.borrowing} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeBorrowing_eoy(event.target.value, index, element.borrowing_limit) }}
                                                onBlur={(event) => handleBlurBorrowing_eoy(event, index, element.imo, element.operator)}
                                                type='tel'
                                                sx={{
                                                  marginRight: "10px",
                                                  width: "65px", fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                                  "& .MuiInput-input": { height: "26px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                                  ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                                  ":hover:after": { borderBottom: "0px solid green", },
                                                  ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                                  ":after": { borderBottom: "2px solid white", },
                                                  ":before": { borderBottom: "2px solid #A4A7B1", },
                                                }} />
                                            </td>
                                            <td className='pooling-group-list-banking'>
                                              <span className="inner-text" title={`${element.banking}`}>
                                                {formatNumber(element.banking)}
                                              </span>
                                            </td>
                                            <td className='pooling-group-list-total'>
                                              <span className="inner-text" title={`${element.total}`}>
                                                {formatNumber(element.total)}
                                              </span>
                                            </td>
                                            <td className='pooling-group-list-penalty-factor'>
                                              <span className="inner-text" title={`${element.penalty_factor}`}>
                                                {element.penalty_factor === 1 ? element.penalty_factor.toFixed(1) : element.penalty_factor}
                                              </span>
                                            </td>
                                            <td className='pooling-group-list-cost-eur'>
                                              <span className="inner-text" title={`${element.cost}`}>
                                                {formatNumber(element.cost)}
                                              </span>
                                            </td>
                                          </tr>
                                        )
                                      })
                                    }
                                  </>
                                  :
                                  <></>
                                }
                              </tbody>
                            </table>
                          </div>
                          {/* List下部の余白 */}
                          <div style={{height: "25px"}}></div>
                        </div>
                      </div>
                      {/* アコーディオン間の余白 */}
                      <div style={{height: "15px"}}></div>
                    </div>
                    
                  </Box>
                  

                </Box>          
              }
              </>
              :
              <Box>
                {loading && (
                  <CircularProgress
                    size={68}
                    sx={{
                      color: "grey",
                      position: 'absolute',
                      top: 500,
                      left: 910,
                      zIndex: 1,
                    }}
                  />
                )}
              </Box>
            }
          </Box>

          {/* Poolingグループ作成ダイアログを開くボタン */}
          <Stack sx={{ height: "85px"}}>
            <Button
              variant="contained"
              onClick={handleClickOpenDialogPoolingGroupSetting}
              sx={{
                // この二つで中央揃えにできる。
                display: "block",
                margin: "auto",

                width: "254px",
                // background: '#E66300',
                background: "#E66300",
                borderRadius: 30,
                // color: "#fff",
                color: "#fff",
                ":hover": {
                  bgcolor: "#FFFFFF",
                  color: "#40475D",
                },
                textTransform: 'none'
              }}
              >
              <Typography align="center" sx={{ fontSize: 15, fontFamily: "'Roboto', 'Meiryo', sans-serif", fontWeight: "700" }}>+ Pooling Group Setting</Typography>
            </Button>
          </Stack>
          {/* Pooling Group Setting Daialog */}
          <PoolingGroupSettingDialog
            openDialogFlg = {openDialogPoolingGroupSetting}
            setOpenDialogFlg={(updateValue: boolean) => setOpenDialogPoolingGroupSetting(updateValue)}
            onClickSaveFlg = {saveDialogPoolingGroupSetting}
            setOnClickSaveFlg={(updateValue: boolean) => setSaveDialogPoolingGroupSetting(updateValue)}
            selectedYear = {selectedYear}
            ytdGroupList = {ytdGroupList}
            eoyGroupList = {eoyGroupList}
            ytdAllVesselsList = {ytdAllVesselsList}
            setYtdAllVesselsList = {(updateValue: any) => setYtdAllVesselsList(updateValue)}
            eoyAllVesselsList = {eoyAllVesselsList}
            setEoyAllVesselsList = {(updateValue: any) => setEoyAllVesselsList(updateValue)}
            setPoolingGroupMojiList = {(updateValue: any) => setPoolingGroupMojiList(updateValue)}
            setAuthCheck = {(updateValue: boolean) => props.setAuthCheck(updateValue)}
            setAuthMessage = {(updateValue: any) => props.setAuthMessage(updateValue)}
            SeachInfo = {props.SeachInfo}
            setSeachInfo = {(updateValue: any) => props.setSeachInfo(updateValue)}
          /> 

        </Box>
      </Box> 
    </>
  );
} 

export default FuelEuSupportTool;