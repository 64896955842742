import * as React from 'react';

import { FC, useEffect, useRef } from "react";
import L, { Icon } from "leaflet";
// import { Marker, MarkerProps } from "react-leaflet";
import { Marker, Source, Layer } from "react-map-gl";

// import iconShip from './icon-ship.png';
// import iconShipUnselected from './icon-ship-unselected.png';
import iconShip from '../../assets/images/icon-ship.png';
import iconShipUnselected from '../../assets/images/icon-ship-unselected.png';

import iconUrlBeaufort0 from '../../assets/images/windSpeed-0.png';
import iconUrlBeaufort1 from '../../assets/images/windSpeed-1.png';
import iconUrlBeaufort2 from '../../assets/images/windSpeed-2.png';
import iconUrlBeaufort3 from '../../assets/images/windSpeed-3.png';
import iconUrlBeaufort4 from '../../assets/images/windSpeed-4.png';
import iconUrlBeaufort5 from '../../assets/images/windSpeed-5.png';
import iconUrlBeaufort6 from '../../assets/images/windSpeed-6.png';
import iconUrlBeaufort7 from '../../assets/images/windSpeed-7.png';
import iconUrlBeaufort8 from '../../assets/images/windSpeed-8.png';
import iconUrlBeaufort9 from '../../assets/images/windSpeed-9.png';
import iconUrlBeaufort10 from '../../assets/images/windSpeed-10.png';
import iconUrlBeaufort11 from '../../assets/images/windSpeed-11.png';
import iconUrlBeaufort12 from '../../assets/images/windSpeed-12.png';

interface  RotableShipMarkerProps {
    timestamp: number;
    plot: number;
    index: number;
    rotationAngle: number;
    onClickMap: (plot: number, timestamp: number) => void;
    longitude: number;
    latitude: number;
};

interface RotableWindMarkerProps {
    timestamp: any;
    plot: number;
    index: any;
    rotationAngle: number;
    onClickMap: any;
    beaufortNumber: number;
    longitude: number;
    latitude: number;
  }

const beaufortIconUrl = (num) =>{
    let iconUrl;
    if(num == 1){
        iconUrl = iconUrlBeaufort1;
    }else if(num == 2){
        iconUrl = iconUrlBeaufort2;
    }else if(num == 3){
        iconUrl = iconUrlBeaufort3;
    }else if(num == 4){
        iconUrl = iconUrlBeaufort4;
    }else if(num == 5){
        iconUrl = iconUrlBeaufort5;
    }else if(num == 6){
        iconUrl = iconUrlBeaufort6;
    }else if(num == 7){
        iconUrl = iconUrlBeaufort7;
    }else if(num == 8){
        iconUrl = iconUrlBeaufort8;
    }else if(num == 9){
        iconUrl = iconUrlBeaufort9;
    }else if(num == 10){
        iconUrl = iconUrlBeaufort10;
    }else if(num == 11){
        iconUrl = iconUrlBeaufort11;
    }else if(num == 12){
        iconUrl = iconUrlBeaufort12;
    }else{
        iconUrl = iconUrlBeaufort0;
    }
    return iconUrl
}

export const CustomMarker: FC<RotableShipMarkerProps> = ({
    timestamp,
    plot,
    index,
    rotationAngle,
    onClickMap,
    longitude,
    latitude,
}) => {
    const iconSrc = plot === index ? iconShip : iconShipUnselected; // アイコンURLを直接指定
    const [cursorStyle, setCursorStyle] = React.useState<string>('auto'); // カーソルの初期状態
    const isSelected = plot === index; // 選択状態の確認

    // マーカーにカーソルを合わせた時のイベントハンドラ
    const handleMouseEnter = () => setCursorStyle('pointer');
    const handleMouseLeave = () => setCursorStyle('auto');

    const circleGeoJson = {
        type: 'FeatureCollection',
        features: [
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [longitude, latitude], // 選択されたマーカーの座標
                },
                properties: {},
            },
        ],
    };

    return (
        <>
            {/* 選択されている場合に白い半透明の円を描画 */}
            {isSelected && (
                <Source type="geojson" data={circleGeoJson}>
                    <Layer
                        id="circle-layer"
                        type="circle"
                        paint={{
                            'circle-radius': 60, // 円の半径
                            'circle-color': 'white', // 円の色
                            'circle-opacity': 0.2, // 半透明
                        }}
                    />
                </Source>
            )}
            <Marker longitude={longitude} latitude={latitude} style={{ zIndex: 10 }}>
            <div
                onClick={() => onClickMap(plot, timestamp)}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                style={{
                    transform: `rotate(${rotationAngle}deg)`,
                    transformOrigin: "center",
                    cursor: cursorStyle, // カーソルのスタイルを適用
                }}
            >
                {/* アイコンURLを直接渡す */}
                <img
                    src={iconSrc}
                    alt="Ship Marker"
                    style={{ width: "24px", height: "24px", objectFit: 'contain' }}
                />
            </div>
            </Marker>
        </>
    );
};

export const CustomBeaufortMarker: FC<RotableWindMarkerProps> = ({
    timestamp,
    plot,
    index,
    rotationAngle,
    onClickMap,
    beaufortNumber,
    longitude,
    latitude,
  }) => {
    return (
      <Marker longitude={longitude} latitude={latitude}>
        <div
          onClick={() => onClickMap(plot, timestamp)}
          style={{
            transform: `rotate(${rotationAngle - 180}deg)`,
            transformOrigin: "center",
          }}
        >
          {/* Beaufort アイコンURLを直接指定 */}
          <img
            src={beaufortIconUrl(beaufortNumber)}
            alt="Beaufort Marker"
            style={{ width: "100px", height: "100px", filter: "invert(1)", }}
          />
        </div>
      </Marker>
    );
};
