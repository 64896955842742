
import * as React from 'react';

import axios, { AxiosError } from "axios";

import './FleetOverview.css'; // スタイルのために追加
import { commonSortAsc, commonSortDesc } from "../../components/CommonSort/CommonSort";
import { Main, Drawer, BootstrapInput } from '../../components/styled/styled';
import { callAuth } from '../../components/components/auth';
import { SearchContext } from '../../App';
import { MyAppBar } from '../../components/components/AppBar';
import { SelectTheme } from '../../components/theme/SelectTheme';

// MUI @mui/material/
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import Button from '@mui/material/Button';

// Dialog
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

// アラームアイコン
import AlarmBlue from '../../assets/images/alarm_blue.svg';
import AlarmRed from '../../assets/images/alarm_red.svg';
import NoReceived_svg from '../../assets/images/no-receiived.svg';
import Problem_icon_svg from '../../assets/images/problem_icon.svg';

// モーダル
import ModalHeaderLine from '../../assets/images/flov_modal_header_line.svg';
import ModalSeparateLine from '../../assets/images/flov_modal_separate_line.svg';
import ModalModalArrivalPort from '../../assets/images/flov_modal_arrivalport.svg';
import ModalModalDeparturePort from '../../assets/images/flov_modal_departureport.svg';

// Mapbox
import Map, { MapRef } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { CircularProgress, FormControl, Grid, IconButton, Input, InputAdornment, MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import SearchIcon from '@mui/icons-material/Search';
import StarIcon from '@mui/icons-material/Star';
import mapboxgl from 'mapbox-gl';
import { Marker } from 'mapbox-gl';
import { MainEngineGraph } from './components/MainEngineGraph';
import ShipDirectionIndicator from './components/ShipDirectionIndicator';
import ProgressTimeline from './components/ProgressTimeline';

const MAPBOX_TOKEN = 'pk.eyJ1IjoiYXNvZ3VyYSIsImEiOiJjbHhzZTBmM2gxamZmMmtweG8wOWlpYzY2In0.TwO56LGA-SX83v-Ggm86RA';
mapboxgl.accessToken = MAPBOX_TOKEN;

interface payload {
  role: string | null
  user_id: string | null
  group_id: string | null
  company_id: string | null
  gid: string | null
  gidList: Array<string>
  imoList: Array<string>
  jwt: string | null
}

// Axios Error
interface ErrorData {
  message: string;
  status: number;
  error: string;
}

// 船データ型
type TypeShipData = {
  imo: string;
  favorite: string;
  status: string;
  vesselName: string;

  coordinates: number[];

  grosstongue: string;
  vesselType: string;
  latestUpdate: string;
  course: string;
  beaufort: string;
  windDirection: string;
  portOfDeparture
  portOfArrival
  actualTimeOfDeparture
  estimatedTimeOfArrival
  logSpeed
  logSpeedMax
  meRPM
  meRPMMax
  meLoad
  foc
  cpCurveAlarm
  ciiYearToDate
  ciiEndOfYear
  euaYearToDate
  euaEndOfYear
  cbYearToDate
  cbEndOfYear
  state
}

// マーカーリスト
let markerList: Marker[] = [];

function formatNumber(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function FleetOverview({ setSeachInfo }) {
  console.log("FleetOverview開始");

  // userId有効期限チェック----------------------------------------------------------------------------------------------------------------------------
  const [message, setMessage] = React.useState<string>();
  const [userCheck, setUserCheck] = React.useState(false);
  let init = true

  const [user_id, setUser_id] = React.useState<string>();
  const [group_id, setGroup_id] = React.useState<string>();
  const [company_id, setCompany_id] = React.useState<string>();
  const [gid, setGid] = React.useState<string>();
  const [gidList, setGidList] = React.useState<string>();
  const [imoList, setImoList] = React.useState<string>();
  const [jwt, setJwt] = React.useState<string>();
  const [role, setRole] = React.useState<string>();

  // Map関連
  const mapContainer = React.useRef<HTMLDivElement | null>(null);
  const map = React.useRef<mapboxgl.Map | null>(null);
  const [selectedShip, setSelectedShip] = React.useState<string | null>(null);
  const [originalView, setOriginalView] = React.useState<{ center: [number, number]; zoom: number }>({
    center: [135, 44.0],
    zoom: 1.4,
  });
  const [showModal, setShowModal] = React.useState(false);
  const [isListOpen, setIsListOpen] = React.useState(true);
  const [ships, setShips] = React.useState<TypeShipData[]>([]);
  const [hoveredShip, setHoveredShip] = React.useState<string | null>(null);


  React.useEffect(() => {
    if (init === true) {
      // localStrage取得----------------------------------------------------------------------------------------------------------------------------
      let jwt_ls
      let role_ls
      if (localStorage.getItem("payload")) {
        const pay_json = localStorage.getItem("payload")
        if (pay_json !== null) {
          const pay = JSON.parse(pay_json)
          role_ls = pay["role"]
          jwt_ls = pay["jwt"]
          setUser_id(pay["user_id"])
          setGroup_id(pay["group_id"])
          setCompany_id(pay["company_id"])
          setGid(pay["gid"])
          setGidList(pay["gidList"])
          setImoList(pay["imoList"])
          setJwt(jwt_ls)
          setRole(pay["role"])
          setSeachInfo({ imoNo: `${SeachInfo.imoNo}`, from: SeachInfo.from, to: SeachInfo.to, gid: pay["gid"], gidList: pay["gidList"], imoList: pay["imoList"] })
        }
      }

      if (jwt_ls) {
        callAuth(jwt_ls).then(result => {
          if (result) {
            console.log("認証成功[FleetOverview]");

            let url = ""
            let sub = result["sub"]
  
            // ブラウザ更新による初期表示か、他画面から遷移の初期表示か判定。他画面から遷移の初期表示の場合、SeachInfo.gidは既にセットされている。
            if (SeachInfo.gid === "") {
              url = `${base_url}%23?user=${sub}&init=${init}`
            }
            else {
              url = `${base_url}%23?user=${sub}&GID=${SeachInfo.gid}`
            }

            // Loading解除
            setLoading(true);
            setSuccess(false);

            getVesselList(url, jwt_ls, role_ls)
            
            setMessage("Authentication success.")
          } else {
            console.log("認証失敗[FleetOverview]");
            setUserCheck(true)
            setMessage("Authentication error.")
            localStorage.clear()
          }
        });
      }
    }

    init = false
  }, []);

  // Navigation----------------------------------------------------------------------------------------------------------------------------
  const handleClickFacorite = (imo, favorite) => {
    setLoading(true)
    setSuccess(false)
    const url = `${base_url}%23?&user=${user_id}&favorite=${favorite}&GID=${SeachInfo.gid}&imo=${imo}`
    getVesselList(url, jwt, role)
  };

  // WADATSUMIログイン画面遷移----------------------------------------------------------------------------------------------------------------------------
  const handleClickWADATSUMI = () => {
    window.location.replace(`${process.env.REACT_APP_WADATSUMIURL}`)
  };

  // Group--------------------------------------------------------------------
  const selectGroup = (event: SelectChangeEvent) => {
    
    let val = event.target.value as string;
    let gid = "";

    if (val === "ALL") {
      gid = "admin";
    }
    else if (val === "My Fleet") {
      gid = "Favorite";
    }
    else {
      gid = val;
    }
    
    // ソートの状態をリセット
    setActiveSortNo({ item: '', seq: 0, sort: '' });

    setSeachInfo({ imoNo: ``, from: SeachInfo.from, to: SeachInfo.to, gid: `${gid}`, gidList: SeachInfo.gidList, imoList: SeachInfo.imoList })
    onClickGroup()
  };
  const selectGroupAction = (gid) => {
    setLoading(true)
    setSuccess(false)
    const url = `${base_url}%23?&user=${user_id}&GID=${gid}`
    getVesselList(url, jwt, role)
  }
  
  const [changeClickGroup, setChangeClickGroup] = React.useState<number>(0);

  const onClickGroup = () => {
    setChangeClickGroup((prev) => (prev + 1));
  }
  React.useEffect(() => {
    if (changeClickGroup > 0) {
      selectGroupAction(SeachInfo.gid)
    }
  }, [changeClickGroup])
  
  // VesselList取得データ
  const [rows, setRows] = React.useState<any>([])
  // VesselList取得データ（元データ保持用）
  const [rowsDefoult, setRowsDefoult] = React.useState<any>([])
  
  // VesselList取得--------------------------------------------------------------------
  const getVesselList = (url, jwt, role) => {

    console.log("FleetOVerview url:" + url)

    const options = {
      headers: {
        'Authorization': jwt,
      }
    }
    axios.get(url, options).then((res) => {
      console.log("axios[FleetOverview]", res)
 
      setSeachInfo({ imoNo: `${SeachInfo.imoNo}`, from: SeachInfo.from, to: SeachInfo.to, gid: res.data.datas.gid, gidList: res.data.datas.gidList, imoList: res.data.datas.imoList })
  
      setUser_id(res.data.datas.user_id)
      setGroup_id(res.data.datas.group_id)
      setCompany_id(res.data.datas.company_id)
  
      if (role !== undefined) {
        localStorage.clear()
        let Payload: payload
        Payload = {
          role: role,
          user_id: res.data.datas.user_id,
          group_id: res.data.datas.group_id,
          company_id: res.data.datas.company_id,
          gid: res.data.datas.gid,
          gidList: res.data.datas.gidList,
          imoList: res.data.datas.imoList,
          jwt: jwt
        }
        localStorage.setItem("payload", JSON.stringify(Payload))
      }
      setRows(res.data.datas.rows)
      setUserCheck(false)
      setShipsData(res.data.datas.rows);
  
      // 元データ保持用のオブジェクトにもセット
      setRowsDefoult(res.data.datas.rows)

      // console.log("beforeday:" + beforeday)
      // console.log("LatestUpdate:" + res.data.datas.rows[0])
      // Date(element.LatestUpdate) < beforeday
  
      // Loading解除
      setLoading(false)
      setSuccess(true)
    })
      .catch((e: AxiosError<ErrorData>) => {
        setLoading(false)
        setSuccess(true)
        if (e.response === undefined) {
          console.log("undefinedError: " + e);
          return;
        }
        console.log(e + ", message: " + e.response.data.message);
      });
  }
  
  // コンテクスト取得----------------------------------------------------------------------------------------------------------------------------
  const SeachInfo: any = React.useContext(SearchContext);

  // URL----------------------------------------------------------------------------------------------------------------------------
  const [base_url, setBase_url] = React.useState(`${process.env.REACT_APP_API_URL}/FleetOverview/`);

  // Drawer（右側のVesselList部分（この画面を開いている間は常にtrue））--------------------------------------------------------------------------------
  const [openDrwer, setOpenDrwer] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpenDrwer(true);
  };
  const handleDrawerClose = () => {
    setOpenDrwer(false);
  };

  // Loading--------------------------------------------------------------------
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(true);


  // 時系列データ取得--------------------------------------------------------------------
  const [authCheck, setAuthCheck] = React.useState(false);
  const [authMessage, setAuthMessage] = React.useState("");

  // API呼び出し
  const getVesselInfo = () => {

    if (SeachInfo.imoNo !== "" && SeachInfo.from !== null && SeachInfo.to !== null) {

      const options = {
        headers: {
          'Authorization': jwt,
        }
      }
      const url = `${base_url}` // BaseURL＋必要なインプットを設定
      console.log("url:", url);
      axios.get(url, options).then((res) => {
        console.log("axios", res)

        // 認可チェック
        if (res.data === 401) {
          setAuthCheck(true)
          setAuthMessage("不正な操作が行われた可能性があります。")
        } else if (res.data === 500) {
          setAuthCheck(true)
          setAuthMessage("JWTが改ざんされている可能性があります。")
        } else {
          setAuthCheck(false)
        }

        // APIからの戻りをフロント側の変数に設定する処理


        // Loading解除
        setLoading(false);
        setSuccess(true);
      })
        .catch((e: AxiosError<ErrorData>) => {
          if (e.response === undefined) {
            console.log("undefinedError: " + e);
            return;
          }
          console.log(e + ", message: " + e.response.data.message);
        });
    }
  }

    // VesselListのフィルタ処理
  const handleFilterVesselList = (value) => {
 
    // 予備品名フィルタ値
    let filterValVesselName = String(value).toUpperCase();

    // 一覧（元データ）を取得
    let tableData = rowsDefoult.concat();

    // フィルタに入力ありの場合は処理
    let filterTableData = tableData;
    if (filterValVesselName !== "") {
      // フィルタ処理
      filterTableData = [];
      for (const rowData of tableData) {
        // フィルタ対象のテーブル項目値を取得
        const colDataPartsName = String(rowData.vesselName).toUpperCase();

        // フィルタ入力値がVesselNameに一致するかチェック
        let pushFlgVesselName = false;

        if (filterValVesselName === "") {
          pushFlgVesselName = true;
        } else if (colDataPartsName.indexOf(filterValVesselName) > -1) {
          pushFlgVesselName = true;
        }

        // 一致があった場合は追加
        if (pushFlgVesselName) {
          filterTableData.push(rowData);
        }
      }
    }
    // フィルタした一覧を画面表示
    setRows(filterTableData);
  }

  // ソート機能--------------------------------------------------------------------
  type ActiveSortNo = {
    item: string;
    seq: number;
    sort: string;
  }

  const [activeSortNo, setActiveSortNo] = React.useState<ActiveSortNo>({ item: '', seq: 0, sort: '' }); // seq: 文字列→1, 数値→2
  const sortAsc = (item: string, seq: number) => { //昇順に並び替え
    const { newRows, newActiveSortNo } = commonSortAsc(item, seq, rows, activeSortNo, "vesselName");
    setRows(newRows);
    setActiveSortNo(newActiveSortNo);
  }
  const sortDesc = (item: string, seq: number) => { //降順に並び替え
    const { newRows, newActiveSortNo } = commonSortDesc(item, seq, rows, activeSortNo, "vesselName");
    setRows(newRows);
    setActiveSortNo(newActiveSortNo);
  }

  // 現在の日付を取得
  const currentDate = new Date();

  // 7日前の日付を計算
  const beforeday = new Date(currentDate);
  beforeday.setDate(currentDate.getDate() - 4);

  const setShipsData = (apiResponseData: any[]) => {
    console.log("setShipsData");
    try {
      // 現在の日付
      const currentDate = new Date();
      // 4日前の日付
      const beforeday = new Date(currentDate);
      beforeday.setDate(currentDate.getDate() - 4);
  
      // APIレスポンスを整形して画面表示用データに変換
      const formattedShips: TypeShipData[] = apiResponseData.map((ship: any) => {
        // アラームのロジックを適用して `status` を決定
        let alarm_status: string = "Good"; // 初期値（問題なし）
  
        if (new Date(ship.latestUpdate) < beforeday) {
          alarm_status = "Warning";
        } else if (parseInt(ship.cpCurveAlarm) >= 1) {
          alarm_status = "Warning";
        } else if (ship.ciiYearToDate === "D" || ship.ciiYearToDate === "E") {
          alarm_status = "Warning";
        } else if (ship.ciiEndOfYear === "D" || ship.ciiEndOfYear === "E") {
          alarm_status = "Warning";
        } else {
          alarm_status = "Good";
        }
  
        // return {
        //   imo: ship.imo || "0000000",
        //   favorite: ship.favorite || "unchecked",
        //   status: alarm_status,
        //   vesselName: ship.vesselName || "Unknown",
        //   coordinates: ship.latitude && ship.longitude
        //     ? [parseFloat(ship.longitude), parseFloat(ship.latitude)]
        //     : [142, 35],
        //   grosstongue: ship.grosstongue || "999999",
        //   vesselType: ship.vesselType || "Unknown",
        //   latestUpdate: ship.latestUpdate ? formatDate(ship.latestUpdate) : "2025/01/27",
        //   course: ship.course || "259",
        //   beaufort: ship.beaufort || "4",
        //   windDirection: ship.windDirection || "152.08",
        //   portOfDeparture: ship.portOfDeparture || "Yongning",
        //   portOfArrival: ship.portOfArrival || "Marina di Camerota",
        //   actualTimeOfDeparture: ship.actualTimeOfDeparture || "2025/01/10 13:30",
        //   estimatedTimeOfArrival: ship.estimatedTimeOfArrival || "2025/3/27 11:00",
        //   logSpeed: ship.logSpeed || "14",
        //   logSpeedMax: ship.logSpeedMax || "18",
        //   meRPM: ship.meRPM || "67.9",
        //   meRPMMax: ship.meRPMMax || "100",
        //   meLoad: ship.meLoad || "42.3",
        //   foc: ship.foc || "21.4",
        //   cpCurveAlarm: ship.cpCurveAlarm,
        //   ciiYearToDate: ship.ciiYearToDate || "No Data",
        //   ciiEndOfYear: ship.ciiEndOfYear || "No Data",
        //   euaYearToDate: ship.euaYearToDate || "0",
        //   euaEndOfYear: ship.euaEndOfYear || "0",
        //   cbYearToDate: ship.cbYearToDate || "0",
        //   cbEndOfYear: ship.cbEndOfYear || "0",
        // };

        // ToDo 7000501：Sample Vessel E でも以下のToDoの項目は確認が必要。その他の船に関しても当船の項目は最低限値が入っている必要あり。
        return {
          imo: ship.imo,
          favorite: ship.favorite,
          status: alarm_status,
          vesselName: ship.vesselName,
          coordinates: [parseFloat(ship.longitude), parseFloat(ship.latitude)],
          grosstongue: ship.grosstongue,
          vesselType: ship.vesselType,
          latestUpdate: formatDate(ship.latestUpdate),
          // ToDo noonreportのcourseが空文字の場合があるか確認
          course: ship.course === "" ? "0" : ship.course,
          // ToDo noonreportのbeaufortがNaNまたは空文字の場合があるか確認
          beaufort: ship.beaufort === "" || ship.beaufort === null ? "0" : ship.beaufort,
          // ToDo noonreportのwindDirectionが空文字の場合があるか確認
          windDirection: ship.windDirection === "" ? "0" : ship.windDirection,
          portOfDeparture: ship.portOfDeparture,
          portOfArrival: ship.portOfArrival,
          actualTimeOfDeparture: ship.actualTimeOfDeparture,
          estimatedTimeOfArrival: ship.estimatedTimeOfArrival,
          // ToDo noonreportのlogSpeedが空文字の場合があるか確認
          logSpeed: ship.logSpeed || "-",
          // ToDo Lambdaからべたで返している。noonreportにlogSpeedMaxが追加されたらLambdaを改修。こちらは修正なし。
          logSpeedMax: ship.logSpeedMax,
          // ToDo noonreportのmeRPMが空文字の場合があるか確認
          meRPM: ship.meRPM || "-",
          // ToDo Lambdaからべたで返している。noonreportにmeRPMMaxが追加されたらLambdaを改修。こちらは修正なし。
          meRPMMax: ship.meRPMMax,
          // ToDo noonreportのmeLoadが空文字の場合があるか確認
          meLoad: ship.meLoad || "-",
          // ToDo noonreportのtotal_focが空文字の場合があるか確認
          foc: ship.foc || "0",
          cpCurveAlarm: ship.cpCurveAlarm,
          ciiYearToDate: ship.ciiYearToDate,
          ciiEndOfYear: ship.ciiEndOfYear,
          euaYearToDate: ship.euaYearToDate,
          euaEndOfYear: ship.euaEndOfYear,
          cbYearToDate: ship.cbYearToDate,
          cbEndOfYear: ship.cbEndOfYear,
          state: ship.state,
        };
      });
  
      // 整形されたデータを状態に設定
      setShips(formattedShips);
    
      console.log("Formatted ships data:", formattedShips);
    } catch (error) {
      console.error("Error while setting ships data:", error);
    }
  };
  

  // Map表示
  React.useEffect(() => {
    // コンポーネントの再レンダリングで地図ロードをし直さない
    if (mapContainer.current && !map.current) {
      console.log("Map container element:", mapContainer.current);
      // 地図の初期化
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        //ToDo BEMACアカウントで作成・反映する
        style: 'mapbox://styles/asogura/clxxywwi3000r01prdpgphb7g/draft',
        center: originalView.center,
        zoom: originalView.zoom,
      });
      console.log("Map instance:", map.current);
  
      // カスタムコントロールの追加
      const zoomControl = document.createElement('div');
      zoomControl.style.position = 'absolute';
      zoomControl.style.bottom = '20px';
      zoomControl.style.right = '20px';
      zoomControl.style.display = 'flex';
      zoomControl.style.flexDirection = 'column';
  
      const zoomInButton = document.createElement('button');
      zoomInButton.textContent = '＋';
      zoomInButton.style.margin = '5px';
      zoomInButton.style.padding = '10px';
      zoomInButton.style.borderRadius = '5px';
      zoomInButton.style.backgroundColor = '#fff';
      zoomInButton.style.cursor = 'pointer';
      zoomInButton.addEventListener('click', () => {
        if (map.current) {
          const currentZoom = map.current.getZoom();
          map.current.flyTo({ zoom: currentZoom + 1 });
        }
      });
  
      const zoomOutButton = document.createElement('button');
      zoomOutButton.textContent = '－';
      zoomOutButton.style.margin = '5px';
      zoomOutButton.style.padding = '10px';
      zoomOutButton.style.borderRadius = '5px';
      zoomOutButton.style.backgroundColor = '#fff';
      zoomOutButton.style.cursor = 'pointer';
      zoomOutButton.addEventListener('click', () => {
        if (map.current) {
          const currentZoom = map.current.getZoom();
          map.current.flyTo({ zoom: currentZoom - 1 });
        }
      });
  
      zoomControl.appendChild(zoomInButton);
      zoomControl.appendChild(zoomOutButton);
  
      // カスタムコントロールを地図に追加
      map.current.getContainer().appendChild(zoomControl);
    }
    console.log("ships:"+ships);
    setMap(ships)
  }, [originalView.center, originalView.zoom, ships]);
  
  // Mapの初期化、Markerの表示を行う
  const setMap = (ships: TypeShipData[]) => {

    // 船ごとのカラーマッピング
    const colorMap: Record<string, { innerColor: string; outerColor: string }> = {
      'Warning': { innerColor: 'rgba(253, 197, 197)', outerColor: 'rgba(251, 52, 52)' },
      'Attension': { innerColor: 'rgba(255, 255, 255)', outerColor: 'rgba(227, 227, 15)' },
      'Good': { innerColor: 'rgba(185, 203, 248)', outerColor: 'rgba(13, 74, 231)' },
      default: { innerColor: 'rgba(255, 255, 255)', outerColor: 'rgba(255, 255, 255)' },
    };
  
    // マーカー重複予防
    for (let marker of markerList) {
      marker.remove();
    }
  
    ships.forEach((ship) => {

      const isHovered = hoveredShip === ship.vesselName;

      // クリック範囲（hitbox）
      const hitbox = document.createElement('div');
      hitbox.style.width = '40px';
      hitbox.style.height = '40px';
      hitbox.style.position = 'absolute';
      hitbox.style.left = '0';
      hitbox.style.top = '0';
      hitbox.style.display = 'flex';
      hitbox.style.alignItems = 'center'; 
      hitbox.style.justifyContent = 'center'; 
      hitbox.style.borderRadius = '50%';
      hitbox.style.cursor = 'pointer';
      hitbox.style.backgroundColor = 'rgba(255, 255, 255, 0)';
      hitbox.style.transition = 'background-color 0.3s ease, box-shadow 0.3s ease';

      // 実際のアイコン
      const markerElement = document.createElement('div');
      markerElement.style.width = '8px';
      markerElement.style.height = '8px';
      markerElement.style.borderRadius = '50%';
      markerElement.style.cursor = 'pointer';
      markerElement.style.display = 'block';
      const { innerColor, outerColor } = colorMap[ship.status] || colorMap.default;
      markerElement.style.background = `radial-gradient(${innerColor}, ${outerColor})`;
      // Warningのときはシャドウを表示
      if (ship.status === "Warning") {
        // markerElement.style.boxShadow = "0 0 10px rgba(251, 52, 52, 0.8), 0 0 20px rgba(251, 52, 52, 0.5)";
        // markerElement.style.animation = "warningGlow 1.5s infinite alternate";
        const glowCircle = document.createElement("div");
        glowCircle.classList.add("warning-glow");
        hitbox.appendChild(glowCircle);
      }
      markerElement.style.border = `1px solid ${outerColor}`;
      markerElement.style.position = 'absolute';
      markerElement.style.top = '50%';
      markerElement.style.left = '50%';
      markerElement.style.transform = 'translate(-50%, -50%)';

      // ホバー時の白い円
      const applyHoverEffect = () => {
        setHoveredShip(ship.vesselName);
        hitbox.style.border = '1px solid rgba(255, 255, 255, 0.5)';
        hitbox.style.background = 'radial-gradient(circle, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0.2) 60%, rgba(255, 255, 255, 0.5) 100%)';
      };

      const removeHoverEffect = () => {
        setHoveredShip(null);
        hitbox.style.border = '2px solid rgba(255, 255, 255, 0)';
        hitbox.style.background = 'rgba(255, 255, 255, 0)';
      };

      // ホバー状態なら初期状態で適用
      if (isHovered) {
        applyHoverEffect();
      }

      // ホバーイベントを設定
      [hitbox, markerElement].forEach(el => {
        el.addEventListener('mouseenter', applyHoverEffect);
        el.addEventListener('mouseleave', removeHoverEffect);
      });

      hitbox.appendChild(markerElement);
      hitbox.addEventListener('click', () => {
        handleShipClick(ship.vesselName);
      });

      // クリック範囲を追加したマーカーをMapboxに追加
      const newMarker = new mapboxgl.Marker({ element: hitbox, offset: [0, 0] })
        .setLngLat(ship.coordinates as [number, number])
        .addTo(map.current!);
      markerList.push(newMarker);

    });
  };

  React.useEffect(() => {
    setMap(ships);
  }, [hoveredShip]);

// 選択された船に応じたズーム制御を追加
React.useEffect(() => {
  if (selectedShip) {
    const ship = ships.find((ship) => ship.vesselName === selectedShip);
    if (ship) {
      const coordinates = ship.coordinates;

      const offsetCoord: [number, number] = [
        coordinates[0]+0.6,
        coordinates[1]+0.4,
      ];
      
      // 選択された船の位置にズーム
      map.current!.flyTo({
        center: offsetCoord,
        zoom: 8,
        speed: 1.2,
      });

      // 選択船のマーカーを強調（白い円を追加）
      markerList.forEach(marker => {
        const markerElement = marker.getElement();
        const isSelected = marker.getLngLat().lng === ship.coordinates[0] && marker.getLngLat().lat === ship.coordinates[1];

        markerElement.style.border = isSelected
          ? '1px solid rgba(255, 255, 255, 0.5)'
          : '1px solid rgba(255, 255, 255, 0)';

        markerElement.style.background = isSelected
          ? 'radial-gradient(circle, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0.2) 60%, rgba(255, 255, 255, 0.5) 100%)'
          : 'rgba(255, 255, 255, 0)';
      });

      // モーダル表示
      setTimeout(() => {
        setShowModal(true);
      }, 500);
    }
  } else {
    
    // すべてのマーカーの白い円を消す
    markerList.forEach(marker => {
      const markerElement = marker.getElement();
      markerElement.style.border = '1px solid rgba(255, 255, 255, 0)';
      markerElement.style.background = 'rgba(255, 255, 255, 0)';
    });


    // 元のビューに戻る
    map.current!.flyTo({
      center: originalView.center,
      zoom: originalView.zoom,
      speed: 1.2,
    });
    setShowModal(false);
  }
}, [selectedShip, ships]);
  
// 船を選択したときの処理
const handleShipClick = (shipName: string) => {
  setSelectedShip((prevSelectedShip) => {
    return prevSelectedShip === shipName ? null : shipName;
  });
};

// 日付をフォーマットする関数
const formatDate = (isoDate: string): string => {
  const date = new Date(isoDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}/${month}/${day}`;
};
  
    // Mapbox----------------------------------------------------------------------------------------------------------------------------
    const mapRef = React.useRef<MapRef | null>(null);
    const handleZoomIn = () => {
      console.log("handleZoomIn1");
      if (mapRef.current) {
        console.log("handleZoomIn2");
        const currentZoom = mapRef.current.getZoom(); // 現在のズームレベルを取得
        mapRef.current.flyTo({ zoom: currentZoom + 1 }); // ズームイン
      }
    };
    
    const handleZoomOut = () => {
      console.log("handleZoomOut1");
      if (mapRef.current) {
        console.log("handleZoomOut2");
        const currentZoom = mapRef.current.getZoom(); // 現在のズームレベルを取得
        mapRef.current.flyTo({ zoom: currentZoom - 1 }); // ズームアウト
      }
    };

    const getShipShadowStyle = (type: 'inset' | 'outset' = 'outset') => {
      const ship = ships.find(s => s.vesselName === selectedShip);
      if (!ship) return '';
  
      const shadowPrefix = type === 'inset' ? 'inset ' : '';
      
      switch (ship.status) {
        case 'Warning':
          return `${shadowPrefix}-3px 3px 3px rgba(251, 52, 52, 0.8)`;
        case 'Attension':
          return `${shadowPrefix}-3px 3px 3px rgba(227, 227, 15, 0.8)`;
        case 'Good':
          return `${shadowPrefix}-3px 3px 3px rgba(13, 74, 231, 0.8)`;
        default:
          return `${shadowPrefix}0px 0px 10px rgba(13, 74, 231, 0.7)`;
      }
    };

    const renderCiiIcon = (ciiValue: string) => {
      const ciiStyles: Record<string, { bgColor: string; showAlert: boolean }> = {
        A: { bgColor: "rgba(0, 144, 206, 0.4)", showAlert: false },
        B: { bgColor: "rgba(6, 165, 80, 0.4)", showAlert: false },
        C: { bgColor: "rgba(212, 195, 38, 0.4)", showAlert: false },
        D: { bgColor: "rgba(255, 103, 103, 0.45)", showAlert: true },
        E: { bgColor: "rgba(255, 47, 47, 0.45)", showAlert: true },
        "": { bgColor: "transparent", showAlert: false }, // No Data
      };
    
      const { bgColor, showAlert } = ciiStyles[ciiValue] || ciiStyles[""];
    
      return (
        <Stack
          justifyContent="center"
          sx={{
            position: "relative",
            width: "20px",
            height: "20px",
            bgcolor: bgColor,
            boxShadow:  "2px 2px 4px rgba(0, 0, 0, 0.5)",
          }}
        >
          {ciiValue ? (
            <Typography align="center" sx={{ marginTop: "2px", color: "#ffffff", fontSize: "14px", fontWeight: "bold" }}>
              {ciiValue}
            </Typography>
          ) : (
            <Typography align="center" sx={{ color: "rgba(255, 255, 255, 0.6)", fontSize: "12px" }}>
              No Data
            </Typography>
          )}
    
          {showAlert && (
            <Stack sx={{ position: "absolute", top: "88%", left: "100%", transform: "translate(-50%, -50%)",  bgcolor: "transparent" }} alignItems="center">
              <img src={Problem_icon_svg} alt="iconLike" style={{ height: "13px", width: "16px" }} />
            </Stack>
          )}
        </Stack>
      );
    };

  return (
    <Box height="100vh" sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* useId有効期限切れダイアログ */}
      <Dialog
        open={userCheck}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
              height: "100%",
              maxHeight: "210px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            bgcolor: "#9ea2ad",
            height: "50px",
            color: "white",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}>
          UserId Update
        </DialogTitle>
        <DialogContent
          sx={{
            background: "linear-gradient(#40475d, #0d1532)",
            height: "100px",
          }}>
          <Typography align="left" sx={{ marginTop: "32px", color: 'white', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', wordWrap: 'break-word' }}>
            {message === "" ? "" : message}
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            height: "50px",
            bgcolor: "#474C53",
          }}>
          <Button
            sx={{
              marginLeft: "0px",
              marginRight: "-8px",
              backgroundColor: "#e66300",
              width: "150px",
              height: "50px",
              padding: "0",
              fontSize: "18px",
              fontFamily: "'Roboto Bold', 'Meiryo', sans-serif",
              fontWeight: "bold",
              color: "white",
              borderRadius: "0px",
              borderBottomRightRadius: "2px",
              ":hover": {
                bgcolor: "#FFFFFF",
                color: "#40475D",
              },
              textTransform: 'none'
            }}
            onClick={handleClickWADATSUMI}>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {/* 認可チェックダイアログ */}
      <Dialog
        open={authCheck}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
              height: "100%",
              maxHeight: "210px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            bgcolor: "#9ea2ad",
            height: "50px",
            color: "white",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}>
          Auth check
        </DialogTitle>
        <DialogContent
          sx={{
            background: "linear-gradient(#40475d, #0d1532)",
            height: "100px",
          }}>
          <Typography align="left" sx={{ marginTop: "32px", color: 'white', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', wordWrap: 'break-word' }}>
            {authMessage === "" ? "" : authMessage}
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            height: "50px",
            bgcolor: "#474C53",
          }}>
          <Button
            sx={{
              marginLeft: "0px",
              marginRight: "-8px",
              backgroundColor: "#e66300",
              width: "150px",
              height: "50px",
              padding: "0",
              fontSize: "18px",
              fontFamily: "'Roboto Bold', 'Meiryo', sans-serif",
              fontWeight: "bold",
              color: "white",
              borderRadius: "0px",
              borderBottomRightRadius: "2px",
              ":hover": {
                bgcolor: "#FFFFFF",
                color: "#40475D",
              },
              textTransform: 'none'
            }}
            onClick={() => setAuthCheck(false)}>
            OK
          </Button>
        </DialogActions>
      </Dialog>


      {/* menu bar */}
      <MyAppBar company_id={company_id} group_id={group_id} user_id={user_id} role={role} />

      {/* side bar */}
      <Drawer
        variant="permanent"
        open={openDrwer}
        sx={{
          flexShrink: 0,
          width: 450,
          '& .MuiDrawer-paper': {
            width: '450px', // Figmaより
            boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.7)", // Figmaより
            background: '#282F35',
            boxSizing: 'border-box',
            // borderRight: "1px solid rgb(201,201,201,0.2)" // Figmaよりコメントアウト
          },
        }}>
        

        {/* 検索フォーム */}
        <Box sx={{ height: "60px", marginTop: "100px"}}>
          {/* <Grid container spacing={0} sx={{ height: "100%",backgroundColor: "red" }}> */}
          <Grid container spacing={0} sx={{ height: "100%"}}>
            <Stack direction="row" sx={{ height: "100%", marginLeft: "40px" }} alignItems={"center"} justifyContent={"flex-start"}>
              <Typography align='left' sx={{ marginRight: "16px", color: 'white', fontSize: 14, fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
                Group
              </Typography>
              <ThemeProvider theme={SelectTheme}>
                <FormControl>
                  <Select
                    IconComponent={() =>
                      <div style={{ position: "absolute", top: "20px", left: "115px" }}>
                        <InputAdornment position="start" disablePointerEvents>
                          <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.7)", borderRight: "solid 2px rgb(255, 255, 255, 0.7)", width: "8px", height: "8px", transform: "rotate(135deg)" }}></Box>
                        </InputAdornment>
                      </div>
                    }
                    MenuProps={{
                      MenuListProps: {
                        disablePadding: true,

                      },
                      style: { zIndex: 100000, }
                    }}
                    sx={{
                      width: 140,
                      height: 40,
                      color: "white",
                      borderRadius: 30,
                      ":hover": { backgroundColor: "#7a86a0" }
                    }}
                    input={<BootstrapInput />}
                    onChange={selectGroup}
                    value={SeachInfo.gid === "admin" ? "ALL": SeachInfo.gid === "Favorite" ? "My Fleet": SeachInfo.gid}
                  >
                    {SeachInfo.gidList.map((gid) => {
                      return (
                        <MenuItem 
                          key={gid === "admin" ? "ALL": gid === "Favorite" ? "My Fleet": gid} 
                          value={gid === "admin" ? "ALL": gid === "Favorite" ? "My Fleet": gid}
                          >
                            {gid === "admin" ? "ALL": gid === "Favorite" ? "My Fleet": gid}
                        </MenuItem>
                      )
                    }
                    )}
                  </Select>
                </FormControl>
              </ThemeProvider>
            </Stack>
            <Stack sx={{width: "30px"}}></Stack>
            <Stack direction="row" sx={{ height: "100%", position: "relative"}} alignItems={"center"} justifyContent={"flex-start"}>
              <SearchIcon sx={{ marginRight: "6px", width: "20px", height: "20px", color: "white", 
                position:"absolute", left:"5px", zIndex:10000
              }} />
              <FormControl>
                <Input id="vesselNameFilter" type="email"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleFilterVesselList(event.target.value) }}
                  placeholder="Vessel Name" autoComplete="off"
                  sx={{
                    fontFamily: 'Roboto', fontWeight: '500', border: '0px grey solid', color: "white", fontSize: "15px", 
                    paddingLeft:"30px",background: "rgba(255, 255, 255, 0.15)",
                    "& .MuiInput-input": { height: "23px", width: "110px", borderRadius: '0px', border: '0px white solid', textAlign: "left", },
                    ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                    ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                    ":after": { borderBottom: "2px solid white", },
                    ":before": { borderBottom: "2px solid #A4A7B1", }, fontStyle:"italic"
                  }}/>
              </FormControl>
            </Stack>
          </Grid>
        </Box>

        {/* vessel list */}
        <Box sx={{ height: "84%", marginTop:"37px", marginLeft: "32px", marginRight: "32px" }}>
        {/* <Box sx={{ height: "84%", marginTop:"37px", marginLeft: "32px", marginRight: "32px", backgroundColor: "blue" }}> */}
          {/* Total Vessels 表示 */}
          <Typography
            sx={{
              position: "absolute",
              right: "50px",
              color: "rgb(255, 255, 255, 0.5)",
              fontSize: 15,
              fontWeight: "500",
              fontFamily: 'Roboto',
              paddingTop: "8px",
            }}
          >
            Total Vessels: {rows.length !== 0 ? rows.length : 0}
          </Typography>
          <div className="flov-vessel-list-wrapper">
            <div className="flov-vessel-list-area-wrapper">
              <table className="flov-vessel-list-table">
                <thead>
                  <tr>
                    <th className="favorite">
                      <span></span>
                    </th>
                    <th className="alarm">
                      <span>
                      </span>
                    </th>
                    <th className="vesselName">
                      <div className='common-sort-list-header-left'>
                        <div>
                          <span>Vessel Name</span>
                        </div>
                        <div className="common-sort-container">
                          <div className="common-sort-icon">
                            <div className={(activeSortNo.item === "vesselName" && activeSortNo.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAsc('vesselName', 1)}></div>
                            <div className={(activeSortNo.item === "vesselName" && activeSortNo.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDesc('vesselName', 1)}></div>
                          </div>
                        </div>
                      </div>
                    </th>
                    
                  </tr>
                  <tr></tr>
                </thead>
                {success ?
                  <tbody>
                    {rows.length !== 0 ?
                      <>
                        {
                          rows.map((element, index) => {
                            return (
                              <tr 
                                key={element.imo}
                                onClick={() => handleShipClick(element.vesselName)}
                                onMouseEnter={() => setHoveredShip(element.vesselName)}
                                onMouseLeave={() => setHoveredShip(null)}
                                className={hoveredShip === element.vesselName ? "hovered" : ""}
                                >
                                <td className="favorite">
                                  <IconButton
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleClickFacorite(element.imo, element.favorite);
                                    }}
                                    sx={{
                                      fontFamily: "'Roboto Bold', 'Meiryo', sans-serif",
                                      fontWeight: "bold",
                                      color: `${element.favorite === "checked" ? "yellow" : "grey"}`,
                                      ":hover": {
                                        color: `${element.favorite === "checked" ? "orange" : "pink"}`,
                                      }
                                    }}
                                  >
                                    <StarIcon sx={{ width: "17px", height: "22px", opacity: 0.6 }} />
                                  </IconButton>
                                </td>
                                <td className="alarm">
                                  {new Date(element.latestUpdate) < beforeday ? <img src={AlarmRed} className="alarm-blur" alt="alarm" />
                                    : parseInt(element.cpCurveAlarm) >= 1 ? <img src={AlarmRed} className="alarm-blur" alt="alarm" />
                                      : element.ciiYearToDate === "D" ? <img src={AlarmRed} className="alarm-blur" alt="alarm" />
                                        : element.ciiYearToDate === "E" ? <img src={AlarmRed} className="alarm-blur" alt="alarm" />
                                          : element.ciiEndOfYear === "D" ? <img src={AlarmRed} className="alarm-blur" alt="alarm" />
                                            : element.ciiEndOfYear === "E" ? <img src={AlarmRed} className="alarm-blur" alt="alarm" />
                                              :<img src={AlarmBlue} alt="alarm" />
                                  }
                                </td>
                                <td className="vesselName">
                                  <Stack>
                                    <Typography align='left' sx={{ color: '#ffffff', width: "100%", fontSize: "15px", }}>{element.vesselName}</Typography>
                                  </Stack>
                                </td>
                                <td className="padding5">
                                  <span></span>
                                </td>
                              </tr>
                            )
                          })
                        }
                      </>
                      :
                      ""
                    }
                  </tbody>
                  :
                  <Box>
                    {loading && (
                      <CircularProgress
                        size={68}
                        sx={{
                          color: "grey",
                          position: 'absolute',
                          top: 500,
                          left: 950,
                          zIndex: 1,
                        }}
                      />
                    )}
                  </Box>
                }
              </table>
            </div>
          </div>
        </Box>
      </Drawer >

      {/* メイン部分 */}
      <Main sx={{ padding: 0, position: 'relative'}}>
        <div ref={mapContainer} className="map-container" />
        <Box className="map-legend">
          <div className="legend-item">
            <div className="legend-icon warning"></div>
            <span>Warning</span>
          </div>
          <div className="legend-item">
            <div className="legend-icon attention"></div>
            <span>Attention</span>
          </div>
          <div className="legend-item">
            <div className="legend-icon good"></div>
            <span>Good</span>
          </div>
        </Box>
        <div
          className={`ship-info-modal ${showModal ? 'visible' : 'hidden'}`}
          style={{ filter: `drop-shadow(${getShipShadowStyle('outset')})` }}
          >
          {(() => {
            const ship = ships.find((s) => s.vesselName === selectedShip);
            if (!ship) return null;
            return (
              <>
                <div className='modal-header-area'>
                  <div className="modal-vesselName">{ship.vesselName}</div>
                  <div className="modal-grosstongue-vesselType">{ship.grosstongue}GT - {ship.vesselType}</div>  
                </div>
                <div className="modal-header-line-arer">
                    <img className="modal-header-line" src={ModalHeaderLine} alt="SVG" />
                </div>
                <div className="modal-lastUpdate-area">
                  <div className="modal-lastUpdate-label">Latest Update</div>
                  <div className="modal-lastUpdate-value">{ship.latestUpdate}</div>
                  <div>
                    {new Date(ship.latestUpdate) < beforeday ?
                      <Stack sx={{ width: "100%", marginLeft: "5px" }} alignItems={"left"}>
                        <img src={NoReceived_svg} alt="iconLike" style={{ height: "20px", width: "20px" }} />
                      </Stack>
                      :
                      ""
                    }
                  </div>
                  <div style={{ display: "flex", justifyContent: "flex-end", width: "60%" }}>
                    <div className='modal-state-label'>State</div>
                    <div className='modal-state-value'>{ship.state}</div>
                  </div>
                </div>
                <div className="modal-top-area">
                  <div className="modal-beaufort-area">
                    <div>
                      <ShipDirectionIndicator course={ship.course} beaufort={ship.beaufort} windDirection={ship.windDirection} />
                      {/* ↓デバッグ用 */}
                      {/* <ShipDirectionIndicator course="0" beaufort="12" windDirection="180" /> */}
                    </div>
                  </div>
                  <div className="modal-port-area">
                    <div className="port-svg-container">
                      <div>
                        <img className="departure-port-csv" src={ModalModalDeparturePort} alt="SVG" />
                      </div>
                      <div>
                        <img className="arrival-port-csv" src={ModalModalArrivalPort} alt="SVG" />
                      </div>
                    </div>
                    <div>
                      <ProgressTimeline departureTime={ship.actualTimeOfDeparture} arrivalTime={ship.estimatedTimeOfArrival} />
                    </div>
                    <div className="port-name-container">
                      <div>
                        {ship.portOfDeparture}
                      </div>
                      <div>
                        {ship.portOfArrival}
                      </div>
                    </div>
                    <div className="port-time-container">
                      <div>
                        <span className="port-time-label">ATD</span> {ship.actualTimeOfDeparture} (LT)
                      </div>
                      <div>
                        <span className="port-time-label">ETA</span> {ship.estimatedTimeOfArrival} (LT)
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-middle-area">
                  <div className='me-graph-area'>
                    <div className="me-logSpeed-chart-container">
                      <MainEngineGraph
                        title='LOG Speed'
                        maxValue={ship.logSpeedMax}
                        dataValue={ship.logSpeed}
                        dataUnit='knot'
                        />
                    </div>
                    <div className="me-meRPM-chart-container">
                      <MainEngineGraph
                        title='M/E RPM'
                        maxValue={ship.meRPMMax}
                        dataValue={ship.meRPM}
                        dataUnit='rpm'
                        />
                    </div>
                    <div className="me-meLoad-chart-container">
                      <MainEngineGraph
                        title='M/E Load'
                        maxValue={100}
                        dataValue={ship.meLoad}
                        dataUnit='%'
                        />
                    </div>
                  </div>
                  <div className="modal-separate-line-arer">
                    <img className="modal-separate-line" src={ModalSeparateLine} alt="SVG" />
                  </div>
                  <div className="modal-foc-cp-area">
                    {/* FOC */}
                    <div className="modal-foc-area">
                      <div className="modal-foc-label">FOC</div>
                      <div className="modal-foc-value">{ship.foc}</div>
                      <div className="modal-foc-unit">MT/day</div>
                    </div>

                    {/* CP Curve Alarm */}
                    <div className="modal-cp-area">
                      <div className="modal-cp-label">CP Curve Alarm</div>
                      <div className="modal-cp-value">{ship.cpCurveAlarm}</div>
                      <div className="modal-cp-unit">time(s)</div>
                      {parseInt(ship.cpCurveAlarm) >= 1 ? (
                        <Stack className="modal-cp-icon">
                          <img src={Problem_icon_svg} alt="iconLike" style={{ height: "13px", width: "16px" }} />
                        </Stack>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="modal-bottom-area">
                  <div className="modal-cii-area">
                    <div className="modal-cii-title">CII</div>
                    <div>
                      <div className="modal-yod-label">Year to Date</div>
                      <div className="modal-cii-icon">{renderCiiIcon(ship.ciiYearToDate)}</div>
                    </div>
                    <div>
                      <div className="modal-eoy-label">End of Year</div>
                      <div className="modal-cii-icon">{renderCiiIcon(ship.ciiEndOfYear)}</div>
                    </div>
                  </div>
                  <div className="modal-separate-line-arer2">
                    <img className="modal-separate-line2" src={ModalSeparateLine} alt="SVG" />
                  </div>
                  <div className="modal-eua-area">
                    <div className="modal-eua-title">EUA</div>
                    <div>
                      <div className="modal-eua-yod-label">Year to Date</div>
                      <div className="modal-eua-yod-value">{formatNumber(ship.euaYearToDate)}</div>
                    </div>
                    <div>
                      <div className="modal-eua-eoy-label">End of Year</div>
                      <div className="modal-eua-eoy-value">{formatNumber(ship.euaEndOfYear)}</div>
                    </div>
                  </div>
                  <div className="modal-separate-line-arer3">
                    <img className="modal-separate-line3" src={ModalSeparateLine} alt="SVG" />
                  </div>
                  <div className="modal-complianceBalance-area">
                    <div className="modal-complianceBalance-title">Compliance Balance</div>
                    <div>
                      <div className="modal-complianceBalance-yod-label">Year to Date</div>
                      <div className="modal-complianceBalance-yod-value">{formatNumber(ship.cbYearToDate)}</div>
                    </div>
                    <div>
                      <div className="modal-complianceBalance-eoy-label">End of Year</div>
                      <div className="modal-complianceBalance-eoy-value">{formatNumber(ship.cbEndOfYear)}</div>
                    </div>
                  </div>
                </div>
              </>
            );
          })()}
        </div>
      </Main>
    </Box>
  );
} 