
import * as React from 'react';

import axios, { AxiosError } from "axios";

import './CiiSimulation.css'; // スタイルのために追加
import { BootstrapInput } from '../../../components/styled/styled';
import { CiiEditConditionsDialog } from './CiiEditConditionsDialog';

// MUI @mui/material/
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

// MUI @mui/icons-material/
import SettingsIcon from '@mui/icons-material/Settings';

// Dialog
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

// Select Date
import Select, { SelectChangeEvent } from '@mui/material/Select';

// アイコン
import RightArrow from '../../../assets/images/right_arrow.svg';
import EditConditionsIcon from '../../../assets/images/simulation_edit_condition.svg';
import Input from '@mui/material/Input';
import { ThemeProvider } from '@mui/material/styles';
import { SelectTheme } from '../../../components/theme/SelectTheme';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';

// Axios Error
interface ErrorData {
  message: string;
  status: number;
  error: string;
}

// 親コンポーネントから持ち回る値
export type Props = {
  changeVesselFlg: boolean;
  setChangeVesselFlg: (value: boolean) => void;
  vesselMaster: any;
  setVesselMaster: (value: any) => void;
  focFormulaBallast: any[];
  setFocFormulaBallast: (value: any[]) => void;
  focFormulaLaden: any[];
  setFocFormulaLaden: (value: any[]) => void;
  focFormuAux: any;
  setFocFormuAux: (value: any) => void;
  setAuthCheck: (value: boolean) => void;
  setAuthMessage: (value: any) => void;

  SeachInfo: any;
  setSeachInfo: (value: any) => void;
}

function formatNumber(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// CII Simulation Component--------------------------------------------------------------------
export const CiiSimulation: React.FC<Props> = props => {

  // Loading--------------------------------------------------------------------
  const [loading_UpperBody, setLoading_UpperBody] = React.useState(false);
  const [success_UpperBody, setSuccess_UpperBody] = React.useState(true);
  const [loading_LowerBody, setLoading_LowerBody] = React.useState(false);
  const [success_LowerBody, setSuccess_LowerBody] = React.useState(true);

  const [jwt, setJwt] = React.useState<string>();
  const [userId, setUserId] = React.useState<string>();

  // URL
  const [base_url, setBase_url] = React.useState(`${process.env.REACT_APP_API_URL}/CiiAnalysis/Simulation/`);
  // 燃料リスト
  const [fuelOileList, setFuelOileList] = React.useState<any>([]);
  // Leg情報リスト
  const [legInfoList, setLegInfoList] = React.useState<any>([]);

  const [lastYear, setLastYear] = React.useState<string>('');

  // 初期処理
  React.useEffect(() => {

    // 前年を取得
    setLastYear(String(new Date().getFullYear() - 1));

    // Loading表示
    setLoading_UpperBody(true)
    setSuccess_UpperBody(false)
    setLoading_LowerBody(true)
    setSuccess_LowerBody(false)

    // VoyagePlanタブ選択状態にする。
    setActiveSimulationTab(0);
    
    // API呼び出し
    // 暫定対応：：初回のみ、UserIDをpayloadから取得
    let payloadUserId = ""
    let payloadJwt = ""
    const pay_json = localStorage.getItem("payload")
    if (pay_json !== null) {
      const pay = JSON.parse(pay_json)
      payloadUserId = pay["user_id"];
      payloadJwt = pay["jwt"];
      setUserId(payloadUserId);
      setJwt(payloadJwt);
    }

    if (props.SeachInfo.imoNo !== undefined && props.SeachInfo.imoNo !== "") {
      const url = `${base_url}Init/%23?&user=${payloadUserId}&imo=${props.SeachInfo.imoNo}&plan=Voyage`
      getSimulationResultInit(url, payloadJwt);
    }
    else {
      // Loading解除
      setLoading_UpperBody(false)
      setSuccess_UpperBody(true)
      setLoading_LowerBody(false)
      setSuccess_LowerBody(true)
    }
  }, []);

  // VesselName変更
  React.useEffect(() => {

    // Loading表示
    setLoading_UpperBody(true)
    setSuccess_UpperBody(false)
    setLoading_LowerBody(true)
    setSuccess_LowerBody(false)

    // VoyagePlan選択状態に変更
    setActiveSimulationTab(0);

    reset_all()
    
    // 親コンポーネントからchangeVesselFlgがtrueで飛んできた場合、検索処理実行
    if (props.changeVesselFlg === true) {
      const url = `${base_url}Init/%23?&user=${userId}&imo=${props.SeachInfo.imoNo}&plan=Voyage`     
      getSimulationResultInit(url, jwt);
    }
    else {
      // Loading解除
      setLoading_UpperBody(false)
      setSuccess_UpperBody(true)
      setLoading_LowerBody(false)
      setSuccess_LowerBody(true)
    }
    
    // clickSearchFlgをfalseに更新
    props.setChangeVesselFlg(false);

  }, [props.changeVesselFlg]);

  // ダイアログ関連--------------
  const [openDialogEditConditions, setOpenDialogEditConditions] = React.useState(false);
  const handleClickOpenDialogEditConditions = () => {
    setOpenDialogEditConditions(true);
  };
  
  // legInfoListが更新されたとき（EditConditionsでSave押下後に呼ばれる）
  const [onClickSaveFlg, setOnClickSaveFlg] = React.useState(false);
  React.useEffect(() => {

    if (onClickSaveFlg) {
      // 画面下部の初期化
      // FOC
      setFOC([])
      setFOC_Simulation([]);
      setFOC_YAXIS({ tickInterval: 400, max: 2000 })
      // CII
      setCIIRATING([])
      setCII([])
      setCII_Simulation([]);
      setCIIAll([])
      setCIIAll_Simulation([]);
      setCIILASTYEAR("")
      // 画面下部の右側
      setVoyageInformationTotal(undefined);
      setVoyageInformationUnit(undefined);
      setVoyageInformationForDisplay(undefined);

      setOnClickSaveFlg(false);
    }
  }, [onClickSaveFlg]);

  // VoyageプランのSimulationボタン押下
  const handleClickSimulationVoyage = () => {

    // Loading表示
    setLoading_LowerBody(true)
    setSuccess_LowerBody(false)

    // SimulationResultの右側初期化
    setVoyageInformationTotal(undefined);

    // API呼び出し
    const url = `${base_url}VoyagePlan/%23?&user=${userId}&imo=${props.SeachInfo.imoNo}&plan=Voyage`   
    getSimulationResultVoyage(url, jwt);
  };

  // SpeedプランのSimulationボタン押下
  const handleClickSimulationSpeed = () => {

    let checkResult: any = [];

    // 入力チェック
    // FuelRateの合計が100%になるかのチェック
    let tmpFuelRate1 = 0
    let tmpFuelRate2 = 0
    let tmpFuelRate3 = 0
    let tmpTotalFuelRate = 0
    if (SpeedPlan_FuelRate1 !== "") { tmpFuelRate1 = Number(SpeedPlan_FuelRate1) }
    if (SpeedPlan_FuelRate2 !== "") { tmpFuelRate2 = Number(SpeedPlan_FuelRate2) }
    if (SpeedPlan_FuelRate3 !== "") { tmpFuelRate3 = Number(SpeedPlan_FuelRate3) }
    tmpTotalFuelRate = tmpFuelRate1 + tmpFuelRate2 + tmpFuelRate3
    if (tmpTotalFuelRate !== 100) {
      checkResult.push("Please enter FuelRate so that the total is 100.")
    }

    if (checkResult.length !== 0) {
      // エラーメッセージを作成しalertで表示

      let error_string = "";

      checkResult.forEach(function(element) {
        if (error_string !== "") {
          error_string = error_string + "\n";
        }
        error_string = error_string + element;
      });
      alert(error_string);
      return;
    }

    // Loading表示
    setLoading_LowerBody(true)
    setSuccess_LowerBody(false)

    // SimulationResultの右側初期化
    setVoyageInformationTotal(undefined);

    // 登録用データ作成
    let tempFuelString = "";

    // fuel登録用の文字列作成
    if (SpeedPlan_Fuel1 !== "-") {
      tempFuelString = "(" + SpeedPlan_Fuel1 + "," + SpeedPlan_FuelRate1 + ")"
    }
    if (SpeedPlan_Fuel2 !== "-") {

      if (tempFuelString !== "") {
        tempFuelString = tempFuelString + ","
      }

      tempFuelString = tempFuelString + "(" + SpeedPlan_Fuel2 + "," + SpeedPlan_FuelRate2 + ")"
    }
    if (SpeedPlan_Fuel3 !== "-") {

      if (tempFuelString !== "") {
        tempFuelString = tempFuelString + ","
      }

      tempFuelString = tempFuelString + "(" + SpeedPlan_Fuel3 + "," + SpeedPlan_FuelRate3 + ")"
    }

    const value = {
      // SpeedPlan
      "imo": String(props.SeachInfo.imoNo),
      "salling_rate": SpeedPlan_Sailing,
      "dispracement_rate": SpeedPlan_Ballast,
      "log_speed_ballast": SpeedPlan_Log_Speed_Ballast,
      "log_speed_laden": SpeedPlan_Log_Speed_Laden,
      "fuel": tempFuelString,
    }

    // API呼び出し
    const url = `${base_url}SpeedPlan/%23?&user=${userId}&imo=${props.SeachInfo.imoNo}&plan=Speed`   
    getSimulationResultSpeed(url, value, jwt);
  };
  
  // 初期表示用Simulationデータ取得
  const getSimulationResultInit = (url, jwt) => {
    console.log("Simulation Init");

    const options = {
      headers: {
        'Authorization': jwt,
      }
    }
    console.log("URL", url);
    axios.get(url, options).then((res) => {

      console.log("axios", res);

      // 認可チェック
      if (res.data === 401) {
        props.setAuthCheck(true)
        props.setAuthMessage("不正な操作が行われた可能性があります。")
      } else if (res.data === 500) {
        props.setAuthCheck(true)
        props.setAuthMessage("JWTが改ざんされている可能性があります。")
      } else {
        props.setAuthCheck(false)
      }

      props.setVesselMaster(res.data.VESSELMASTER);
      props.setFocFormulaBallast(res.data.FOCFormulas.ME_Ballast)
      props.setFocFormulaLaden(res.data.FOCFormulas.ME_Laden)
      props.setFocFormuAux(res.data.FOCFormulas.AuxiliryEquipment)
      setFuelOileList(res.data.FuelOilList)
      setLegInfoList(res.data.SimulationInformationVoyageList)

      setCII(res.data.MonthlyCiiScoreList_YeartoDate);
      setCII_Simulation(res.data.MonthlyCiiScoreList_Simulation);
      setFOC(res.data.FOCList_YeartoDate);
      setFOC_Simulation(res.data.FOCList_Simulation);
      setCIIAll(res.data.TotalCiiScoreList_YeartoDate);
      setCIIAll_Simulation(res.data.TotalCiiScoreList_Simulation);
      setCIILASTYEAR(res.data.CiiScore_LastYear);
      setCIIRATING(res.data.CII_RATING);
      setFOC_YAXIS(res.data.FOC_YAXIS)
      setFOCyAxisMin(0)
      setFOCyAxisMax(res.data.FOC_YAXIS.max)
      setFOCyAxisMaxShow(0)
      setFOCyAxisMaxShow(res.data.FOC_YAXIS.max)
      setVoyageInformationTotal(res.data.VoyageInformationTotal);
      setVoyageInformationUnit(res.data.VoyageInformationUnit);
      setVoyageInformationForDisplay(res.data.VoyageInformationTotal);

      if (res.data.SimulationInformationLogSpeedList.length > 0) {
        setSpeedPlan_TimeToEndofYear(res.data.SimulationInformationLogSpeedList[0].time_to_endofyear);
        setSpeedPlan_Sailing(res.data.SimulationInformationLogSpeedList[0].servise_rate_salling);
        setSpeedPlan_Ballast(res.data.SimulationInformationLogSpeedList[0].displacement_rate_ballast);
        setSpeedPlan_Log_Speed_Ballast(res.data.SimulationInformationLogSpeedList[0].avg_speed_ballast);
        setSpeedPlan_Log_Speed_Laden(res.data.SimulationInformationLogSpeedList[0].avg_speed_laden);

        // Fuelの設定値作成
        let tmpFuel1 = "-";
        let tmpFuelRate1 = "";
        let tmpFuel2 = "-";
        let tmpFuelRate2 = "";
        let tmpFuel3 = "-";
        let tmpFuelRate3 = "";
        if (res.data.SimulationInformationLogSpeedList[0].fuel !== "" ) {         
          res.data.SimulationInformationLogSpeedList[0].fuel.forEach(function(fuelInfo, index) {   
            // idexは3が最大
            if (index === 0) {
              tmpFuel1 = fuelInfo.fuel_type;
              tmpFuelRate1 = fuelInfo.fuel_rate;
            }
            else if (index === 1) {
              tmpFuel2 = fuelInfo.fuel_type;
              tmpFuelRate2 = fuelInfo.fuel_rate;
            }
            else if (index === 2) {
              tmpFuel3 = fuelInfo.fuel_type;
              tmpFuelRate3 = fuelInfo.fuel_rate;
            }
        
          });
        }
        setSpeedPlan_Fuel1(tmpFuel1);
        setSpeedPlan_Fuel2(tmpFuel2);
        setSpeedPlan_Fuel3(tmpFuel3);
        setSpeedPlan_FuelRate1(tmpFuelRate1);
        setSpeedPlan_FuelRate2(tmpFuelRate2);
        setSpeedPlan_FuelRate3(tmpFuelRate3);
        setSpeedPlan_Distance(res.data.SimulationInformationLogSpeedList[0].distance);
        setSpeedPlan_Foc(res.data.SimulationInformationLogSpeedList[0].foc);
      }

      // Loading解除
      setLoading_UpperBody(false)
      setSuccess_UpperBody(true)
      setLoading_LowerBody(false)
      setSuccess_LowerBody(true)
    })
      .catch((e: AxiosError<ErrorData>) => {
        reset_all()
        setLoading_UpperBody(false)
        setSuccess_UpperBody(true)
        setLoading_LowerBody(false)
        setSuccess_LowerBody(true)
        if (e.response === undefined) {
          console.log("undefinedError: " + e);
          return;
        }
        console.log(e + ", message: " + e.response.data.message)
      });
  }

  // VoyagePlanのSimulationデータ取得
  const getSimulationResultVoyage = (url, jwt) => {
    console.log("Simulation Voyage Plan");

    const options = {
      headers: {
        'Authorization': jwt,
      }
    }
    console.log("url[SimulationVoyagePlan]:", url);
    axios.get(url, options).then((res) => {

      console.log("axios", res);

      // 認可チェック
      if (res.data === 401) {
        props.setAuthCheck(true)
        props.setAuthMessage("不正な操作が行われた可能性があります。")
      } else if (res.data === 500) {
        props.setAuthCheck(true)
        props.setAuthMessage("JWTが改ざんされている可能性があります。")
      } else {
        props.setAuthCheck(false)
      }

      setCII(res.data.MonthlyCiiScoreList_YeartoDate);
      setCII_Simulation(res.data.MonthlyCiiScoreList_Simulation);
      setFOC(res.data.FOCList_YeartoDate);
      setFOC_Simulation(res.data.FOCList_Simulation);
      setCIIAll(res.data.TotalCiiScoreList_YeartoDate);
      setCIIAll_Simulation(res.data.TotalCiiScoreList_Simulation);
      setCIILASTYEAR(res.data.CiiScore_LastYear);
      setCIIRATING(res.data.CII_RATING);
      setFOC_YAXIS(res.data.FOC_YAXIS);
      setFOCyAxisMin(0)
      setFOCyAxisMax(res.data.FOC_YAXIS.max)
      setFOCyAxisMaxShow(0)
      setFOCyAxisMaxShow(res.data.FOC_YAXIS.max)
      setVoyageInformationTotal(res.data.VoyageInformationTotal);
      setVoyageInformationUnit(res.data.VoyageInformationUnit);
      setVoyageInformationForDisplay(res.data.VoyageInformationTotal);

      // Loading解除
      setLoading_LowerBody(false)
      setSuccess_LowerBody(true)
    })
      .catch((e: AxiosError<ErrorData>) => {
        reset_all()
        setLoading_LowerBody(false)
        setSuccess_LowerBody(true)
        if (e.response === undefined) {
          console.log("undefinedError: " + e);
          return;
        }
        console.log(e + ", message: " + e.response.data.message)
      });
  }

  // SpeedPlanのSimulationデータ取得
  const getSimulationResultSpeed = (url, value, jwt) => {
    console.log("Simulation Speed Plan");

    if (props.SeachInfo.imoNo !== "" && jwt !== undefined) {
      console.log("insertFocFormualsスタート", value)
      const formData = new FormData();
      Object.keys(value).forEach(key => {
        formData.append(key, value[key]);
      });
      const options = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': jwt
        }
      }
      console.log("url[SimulationSpeedPlan]:", url);
      axios.post(url, value, options).then((res) => {
        console.log("API Response", res.data);

        // 認可チェック
        if (res.data === 401) {
          props.setAuthCheck(true)
          props.setAuthMessage("不正な操作が行われた可能性があります。")
        } else if (res.data === 500) {
          props.setAuthCheck(true)
          props.setAuthMessage("JWTが改ざんされている可能性があります。")
        } else {
          props.setAuthCheck(false)
        }

        setCII(res.data.MonthlyCiiScoreList_YeartoDate);
        setCII_Simulation(res.data.MonthlyCiiScoreList_Simulation);
        setFOC(res.data.FOCList_YeartoDate);
        setFOC_Simulation(res.data.FOCList_Simulation);
        setCIIAll(res.data.TotalCiiScoreList_YeartoDate);
        setCIIAll_Simulation(res.data.TotalCiiScoreList_Simulation);
        setCIILASTYEAR(res.data.CiiScore_LastYear);
        setCIIRATING(res.data.CII_RATING);
        setFOC_YAXIS(res.data.FOC_YAXIS);
        setFOCyAxisMin(0)
        setFOCyAxisMax(res.data.FOC_YAXIS.max)
        setFOCyAxisMaxShow(0)
        setFOCyAxisMaxShow(res.data.FOC_YAXIS.max)
        setVoyageInformationTotal(res.data.VoyageInformationTotal);
        setVoyageInformationUnit(res.data.VoyageInformationUnit);
        setVoyageInformationForDisplay(res.data.VoyageInformationTotal);
  
        if (res.data.SimulationInformationLogSpeedList.length > 0) {
          setSpeedPlan_TimeToEndofYear(res.data.SimulationInformationLogSpeedList[0].time_to_endofyear);
          setSpeedPlan_Distance(res.data.SimulationInformationLogSpeedList[0].distance);
          setSpeedPlan_Foc(res.data.SimulationInformationLogSpeedList[0].foc);
        }

        // Loading解除
        setLoading_LowerBody(false)
        setSuccess_LowerBody(true)

      })
        .catch((e: AxiosError<ErrorData>) => {
          reset_all()
          setLoading_LowerBody(false)
          setSuccess_LowerBody(true)
          if (e.response === undefined) {
            console.log("undefinedError: " + e);
            return;
          }
          console.log(e + ", message: " + e.response.data.message);
        });
    }
  }

  // VoyagePlan/SpeedPlanのタブがクリックされた時に呼び出されるハンドラー関数
  const [activeSimulationTab, setActiveSimulationTab] = React.useState(0);
  const handleSimulationTabClick = (index) => {
    setActiveSimulationTab(index);

    reset_all();

    // imoに入力がある場合
    if (props.SeachInfo.imoNo) {  
      // Loading表示
      setLoading_UpperBody(true)
      setSuccess_UpperBody(false)
      setLoading_LowerBody(true)
      setSuccess_LowerBody(false)

      // API呼び出し   
      if (index === 0) {
        // VoyagePlanの場合、VoyagePlanのSimulation呼び出し
        const url = `${base_url}Init/%23?&user=${userId}&imo=${props.SeachInfo.imoNo}&plan=Voyage`
        getSimulationResultInit(url, jwt);
      }
      else if (index === 1) {
        // SpeedPlanの場合、SpeedPlanのSimulation呼び出し
        const url = `${base_url}Init/%23?&user=${userId}&imo=${props.SeachInfo.imoNo}&plan=Speed`
        getSimulationResultInit(url, jwt);
      }
    }
  };

  // Rateグラフのリセットボタンがクリックされたら
  const [changeClickGraphReSet, setChangeClickGraphReSet] = React.useState<number>(0);
  // const [ClickGraphReSetOn, setClickGraphReSetOn] = React.useState<boolean>(false);
  const onClickGraphReSet = (event: any) => {
    console.log("onClickGraphReSet")
    setChangeClickGraphReSet((prev) => (prev + 1));
    setClickItemGraph({ index: 0, x: null, y: null })
  }

  React.useEffect(() => {
    if (changeClickGraphReSet > 0) {
      changeGraphReSet()
    }
  }, [changeClickGraphReSet])

  // グラフ上の線をクリックされたら
  const [changeClickGraph, setChangeClickGraph] = React.useState<number>(0);
  const [clickItemGraph, setClickItemGraph] = React.useState({ index: 0, x: null, y: null }); // クリックした点の値

  const onClickGraph = (event: any) => { //グラフ上の点をクリック
    console.log("onClickGraph")
    setChangeClickGraph((prev) => (prev + 1));
    setClickItemGraph({ index: event.point.index, x: event.point.x, y: event.point.y });
  }

  const [key, setKey] = React.useState(0);

  // クリックされた棒グラフの集計値をセット
  React.useEffect(() => {
    if (changeClickGraph > 0) {
      const item = clickItemGraph;
      console.log("item", item);

      if (item.x !== null && item.y !== null 
        && CII !== undefined && CIILASTYEAR !== undefined
        && CIIAll !== undefined && FOC !== undefined && CIIRATING !== undefined && FOC_YAXIS !== undefined
        && CII_Simulation !== undefined && FOC_Simulation !== undefined && CIIAll_Simulation !== undefined
      ) {

        // FOC&CII
        const newHcOptions = { ...hcOptions };
        newHcOptions.series[0].data = JSON.parse(JSON.stringify(CII))
        newHcOptions.series[1].data = JSON.parse(JSON.stringify(CII_Simulation))
        newHcOptions.series[2].data = JSON.parse(JSON.stringify(FOC))
        newHcOptions.series[3].data = JSON.parse(JSON.stringify(FOC_Simulation))
        newHcOptions.yAxis[1].max = FOC_YAXIS.max
        newHcOptions.yAxis[1].tickInterval = FOC_YAXIS.tickInterval
        newHcOptions.series[4].data = JSON.parse(JSON.stringify(CIIAll))
        newHcOptions.series[5].data = JSON.parse(JSON.stringify(CIIAll_Simulation))

        // クリックされたグラフの月を取得
        const clickGraphDate = new Date(Number(item.x));
        const clickGraphMonth = clickGraphDate.getMonth(); // 0（1月）〜 11（12月）

        // 実績分のグラフについてクリックされた月の色をセット
        for (let i = 0; i < FOC.length; i++) {
          let FOC_color_a = 'rgb(18, 87, 151, 0.2)'
          let FOC_color_b = 'rgb(19, 101, 178, 0.2)'
          let CII_color_a = 'rgb(221, 135, 57, 0.2)'
          let CII_color_b = 'rgb(225, 149, 80, 0.2)'
          let CIIAll_color_a = 'rgba(255, 191, 131, 0.2)'
          let CIIAll_color_b = 'rgba(255, 133, 20, 0.2)'
          if (i === clickGraphMonth) {
            FOC_color_a = 'rgb(18, 87, 151, 1)'
            FOC_color_b = 'rgb(19, 101, 178, 1)'
            CII_color_a = 'rgb(221, 135, 57, 1)'
            CII_color_b = 'rgb(225, 149, 80, 1)'
            CIIAll_color_a = 'rgba(255, 191, 131, 1)'
            CIIAll_color_b = 'rgba(255, 133, 20, 1)'
          }
          newHcOptions.series[0].data[i] = JSON.parse(JSON.stringify({
            x: CII[i][0],
            y: CII[i][1],
            color: {
              linearGradient: {
                x1: 0, x2: 0, y1: 0, y2: 1
              },
              stops: [
                [0, CII_color_a],
                [1, CII_color_b]
              ]
            },
          }))
          newHcOptions.series[2].data[i] = JSON.parse(JSON.stringify({
            x: FOC[i][0],
            y: FOC[i][1],
            color: {
              linearGradient: {
                x1: 0, x2: 0, y1: 0, y2: 1
              },
              stops: [
                [0, FOC_color_a],
                [1, FOC_color_b]
              ]
            },
          }))
          newHcOptions.series[4].data[i] = JSON.parse(JSON.stringify({
            x: CIIAll[i][0],
            y: CIIAll[i][1],
            lineWidth: 0.5,
            marker: {
              radius: 8,
              fillColor: {
                radialGradient: { cx: 0.5, cy: 0.5, r: 0.5 },
                stops: [
                  [0, CIIAll_color_a],
                  [1, CIIAll_color_b]
                ]
              }
            },
          }))
        }

        // Simulation分のグラフについてクリックされた月の色をセット
        for (let i = 0; i < FOC_Simulation.length; i++) {
          let FOC_color_a = 'rgb(18, 87, 151, 0.2)'
          let FOC_color_b = 'rgb(19, 101, 178, 0.2)'
          let CII_color_a = 'rgb(221, 135, 57, 0.2)'
          let CII_color_b = 'rgb(225, 149, 80, 0.2)'
          let CIIAll_color_a = 'rgba(255, 191, 131, 0.2)'
          let CIIAll_color_b = 'rgba(255, 133, 20, 0.2)'
          if (i === clickGraphMonth - FOC.length) {
            FOC_color_a = 'rgb(18, 87, 151, 1)'
            FOC_color_b = 'rgb(19, 101, 178, 1)'
            CII_color_a = 'rgb(221, 135, 57, 1)'
            CII_color_b = 'rgb(225, 149, 80, 1)'
            CIIAll_color_a = 'rgba(255, 191, 131, 1)'
            CIIAll_color_b = 'rgba(255, 133, 20, 1)'
          }
          newHcOptions.series[1].data[i] = JSON.parse(JSON.stringify({
            x: CII_Simulation[i][0],
            y: CII_Simulation[i][1],
            color: {
              linearGradient: {
                x1: 0, x2: 0, y1: 0, y2: 1
              },
              stops: [
                [0, CII_color_a],
                [1, CII_color_b]
              ]
            },
          }))
          newHcOptions.series[3].data[i] = JSON.parse(JSON.stringify({
            x: FOC_Simulation[i][0],
            y: FOC_Simulation[i][1],
            color: {
              linearGradient: {
                x1: 0, x2: 0, y1: 0, y2: 1
              },
              stops: [
                [0, FOC_color_a],
                [1, FOC_color_b]
              ]
            },
          }))
          newHcOptions.series[5].data[i + 1] = JSON.parse(JSON.stringify({
            x: CIIAll_Simulation[i + 1][0],
            y: CIIAll_Simulation[i + 1][1],
            lineWidth: 0.5,
            marker: {
              radius: 8,
              fillColor: {
                radialGradient: { cx: 0.5, cy: 0.5, r: 0.5 },
                stops: [
                  [0, CIIAll_color_a],
                  [1, CIIAll_color_b]
                ]
              }
            },
          }))
        }

        // CIIALL_Simulationの先頭要素は問答無用で半透明にする。実績分と重なっているので問題なし。
        newHcOptions.series[5].data[0] = JSON.parse(JSON.stringify({
          x: CIIAll_Simulation[0][0],
          y: CIIAll_Simulation[0][1],
          lineWidth: 0.5,
          marker: {
            radius: 8,
            fillColor: {
              radialGradient: { cx: 0.5, cy: 0.5, r: 0.5 },
              stops: [
                [0, 'rgba(255, 191, 131, 0.2)'],
                [1, 'rgba(255, 133, 20, 0.2)']
              ]
            }
          },
        }))

        setHcOptions(newHcOptions);

        // VoyageInformationUnit
        setVoyageInformationForDisplay(voyageInformationUnit[clickGraphMonth]);

      }
    }
    setKey(key + 1);
  }, [changeClickGraph, clickItemGraph]);

  const changeGraphReSet = () => {

    if (CII !== undefined && CIILASTYEAR !== undefined
      && CIIAll !== undefined && FOC !== undefined && CIIRATING !== undefined && FOC_YAXIS !== undefined
      && CII_Simulation !== undefined && FOC_Simulation !== undefined && CIIAll_Simulation !== undefined) {

      // FOC&CII
      const newHcOptionsFOCCII = { ...hcOptions };
      newHcOptionsFOCCII.series[0].data = JSON.parse(JSON.stringify(CII))
      newHcOptionsFOCCII.series[1].data = JSON.parse(JSON.stringify(CII_Simulation))
      newHcOptionsFOCCII.series[2].data = JSON.parse(JSON.stringify(FOC))
      newHcOptionsFOCCII.series[3].data = JSON.parse(JSON.stringify(FOC_Simulation))
      newHcOptionsFOCCII.yAxis[1].max = FOC_YAXIS.max
      newHcOptionsFOCCII.yAxis[1].tickInterval = FOC_YAXIS.tickInterval
      newHcOptionsFOCCII.series[4].data = JSON.parse(JSON.stringify(CIIAll))
      newHcOptionsFOCCII.series[5].data = JSON.parse(JSON.stringify(CIIAll_Simulation))
      setHcOptions(newHcOptionsFOCCII)

      // Voyage Information
      setVoyageInformationForDisplay(voyageInformationTotal);
    }
    setKey(key + 1);
  }

  const [CII, setCII] = React.useState<Array<Array<any>>>();
  const [CII_Simulation, setCII_Simulation] = React.useState<Array<Array<any>>>();
  const [FOC, setFOC] = React.useState<Array<Array<any>>>();
  const [FOC_Simulation, setFOC_Simulation] = React.useState<Array<Array<any>>>();
  const [CIIAll, setCIIAll] = React.useState<Array<Array<any>>>();
  const [CIIAll_Simulation, setCIIAll_Simulation] = React.useState<Array<Array<any>>>();
  const [CIILASTYEAR, setCIILASTYEAR] = React.useState<any>();
  const [CIIRATING, setCIIRATING] = React.useState<Array<any>>();
  const [FOC_YAXIS, setFOC_YAXIS] = React.useState({ tickInterval: 400, max: 2000 });
  const [voyageInformationTotal, setVoyageInformationTotal] = React.useState<any>(); // TotalのVoyageInformation保持用
  const [voyageInformationUnit, setVoyageInformationUnit] = React.useState<any>(); // グラフ単位のVoyageInformation保持用
  const [voyageInformationForDisplay, setVoyageInformationForDisplay] = React.useState<any>(); // 表示用のVoyageInformation
  const [CIINone, setCIINone] = React.useState<any>({ name: "", top: "", left: "" });

  const [openDialogyAxis, setOpenDialogAxis] = React.useState(false);
  const handleClickOpenDialogAxis = () => {
    setOpenDialogAxis(true);
  };
  const [FOCyAxisMin, setFOCyAxisMin] = React.useState<any>(0);
  const [FOCyAxisMax, setFOCyAxisMax] = React.useState<any>(2000);
  const [FOCyAxisMinShow, setFOCyAxisMinShow] = React.useState<any>(0);
  const [FOCyAxisMaxShow, setFOCyAxisMaxShow] = React.useState<any>(2000);
  const handleNoClickOpenDialogAxis = () => {
    setFOCyAxisMinShow(FOCyAxisMin)
    setFOCyAxisMaxShow(FOCyAxisMax)
    setOpenDialogAxis(false);
  };
  const handleClickFOCyAxisMinShow = (event) => {
    if (/^\d*$/.test(event)) { setFOCyAxisMinShow(event); }
    else { alert("Please enter numbers in half-width characters.") }
  }
  const handleClickFOCyAxisMaxShow = (event) => {
    if (/^\d*$/.test(event)) { setFOCyAxisMaxShow(event); }
    else { alert("Please enter numbers in half-width characters.") }
  }
  React.useEffect(() => {
    const newHcOptions = { ...hcOptions };
    newHcOptions.yAxis[1].min = parseFloat(FOCyAxisMin)
    newHcOptions.yAxis[1].max = parseFloat(FOCyAxisMax)
    setHcOptions(newHcOptions)
  }, [FOCyAxisMin, FOCyAxisMax])
  const handleClickSetAxis = () => {
    if (FOCyAxisMin >= FOCyAxisMax) {
      alert("Max must be greater than Min.")
    }
    else if (FOCyAxisMin === "" || FOCyAxisMax === "" || FOCyAxisMin === undefined || FOCyAxisMax === undefined) {
      alert("No spaces allowed. Please enter a value.")
    }
    else {
      setFOCyAxisMin(FOCyAxisMinShow)
      setFOCyAxisMax(FOCyAxisMaxShow)
      setOpenDialogAxis(false);
      setKey(key + 1);
    }
  };

  // FOCCIIOptions--------------------------------------------------------------------
  const FOCCIIOptions: Highcharts.Options = {
    navigation: {
      buttonOptions: {
        enabled: false,
      }
    },
    credits: {
      enabled: false
    },
    chart: {
      height: window.innerHeight * 0.325,
      width: window.innerWidth - 750,
      marginLeft: 120,
      zooming: { type: "xy" },
      alignTicks: false,
      backgroundColor: 'rgba(0, 0, 0, 0)', // 背景色を透明にする
      // plotBackgroundColor: {
      //   linearGradient: { x1: 0, x2: 0, y1: 1, y2: 0 },
      //   stops: [
      //     [0, 'rgba(255, 255, 255, 0.03)'],
      //     [0.8529, 'rgba(255, 255, 255, 0.13)'],
      //     [1, 'rgba(255, 255, 255, 0.15)']
      //   ]
      // },
      events: {
        click: function (event) {
          onClickGraphReSet(event)
        },
      }
    },
    tooltip: {
      enabled: true,
      useHTML: true,
      formatter: function () {

        let formattedY = ""; // point.yを整形
        let seriesColor = ""; // ヘッダーに表示する点の色を設定

        if (this.series.name === 'CII Score(Monthly)') {
          formattedY = (this.y ?? 0).toFixed(2);
          seriesColor = "#FF5800";
        } 
        else if (this.series.name === 'Fuel Oil Consumption') {
          formattedY = formatNumber((this.y ?? 0).toFixed(1));
          seriesColor = "blue";
        }
        else if (this.series.name === 'CII Score') {
          formattedY = (this.y ?? 0).toFixed(2);
          seriesColor = "#FF5800";
        }

        return `<span style="font-size:10px">
                  ${Highcharts.dateFormat("%B\' %Y", Number(this.x))}
                </span>
                <table>
                  <tr>
                    <td style="padding:0">
                      <span style="display:inline-block;width:6px;height:6px;border-radius:50%;background-color:${seriesColor};margin-right:0px;"></span>
                      ${this.series.name}: 
                    </td>
                    <td style="padding:0">
                      &nbsp<b>${formattedY}</b>
                    </td>
                  </tr>`;
      }
    },
    legend: {
      enabled: false,
    },
    title: {
      text: '時系列の折れ線グラフと棒グラフの組み合わせ',
      style: {
        color: 'transparent'
      }
    },
    plotOptions: {
      column: {
        grouping: true,
        pointWidth: 20,
        // groupPadding: 5,
      },
      series: {
        cursor: 'pointer',
        point: {
          events: {
            click: (event) => {
              onClickGraph(event);
            }
          }
        },
      },
    },
    xAxis: [
      {
        title: {
          text: "",
          style: {
            color: 'white'
          }
        },
        type: 'datetime',
        labels: {
          enabled: true,
          style: {
            color: 'rgba(255, 255, 255, 0.70)',
            fontFamily: 'Roboto Condensed',
            fontSize: '13px'
          }
        },
        gridLineWidth: 0
      },
    ],
    yAxis: [
      { // Primary yAxis
        tickInterval: 0.5,
        min: 0,
        max: 1.5,
        title: {
          text: '',
        },
        gridLineWidth: 0,
        gridLineDashStyle: 'Dot',
      },
      { // Secondary yAxis
        tickInterval: 400,
        min: 0,
        max: 2000,
        opposite: true,
        gridLineWidth: 0,
        gridLineDashStyle: 'Dot',
        title: {
          text: 'Fuel Oil Consumption (MT)',
          align: 'middle',
          rotation: 90,
          x: 5,
          // y: 20,
          style: {
            color: 'rgba(255, 255, 255, 0.70)',
            opacity: 0.70,
            fontFamily: "Roboto Condensed",
            fontSize: "13px",
            fontWeight: '400',
            lineHeight: "24px",
            wordWrap: 'break-word'
          },
        },
        labels: {
          // align: 'left',
          x: 10,
          y: 0,
          enabled: true,
          style: {
            color: 'rgba(255, 255, 255, 0.70)',
            opacity: 0.70,
            fontFamily: "Roboto Condensed",
            fontSize: "13px",
            fontWeight: '400',
            lineHeight: "24px",
            wordWrap: 'break-word'
          },
          formatter: function (this: Highcharts.AxisLabelsFormatterContextObject) {
            const test = this.value.valueOf().toString()
            return test;
          }
        },
      },
      { // Third yAxis{
        opposite: false,
        tickInterval: 0.5,
        min: 0,
        max: 1.5,
        gridLineWidth: 0,
        gridLineDashStyle: 'Dot',
        title: {
          text: 'CII Score',
          align: 'middle',
          rotation: -90,
          x: 0,
          // y: -150,
          style: {
            color: 'rgba(255, 255, 255, 0.70)',
            opacity: 0.70,
            fontFamily: "Roboto Condensed",
            fontSize: "13px",
            fontWeight: '400',
            lineHeight: "24px",
            wordWrap: 'break-word'
          },
        },
        labels: {
          x: 20,
          y: 0,
          enabled: true,
          style: {
            color: 'rgba(255, 255, 255, 0.70)',
            opacity: 0.70,
            fontFamily: "Roboto Condensed",
            fontSize: "13px",
            fontWeight: '400',
            lineHeight: "24px",
            wordWrap: 'break-word'
          },
        },
        plotBands: [{}],
        plotLines: [{
          value: 1,
          width: 0,
          zIndex: 3,
          color: 'rgba(194, 247, 255, 1)',
          id: 'myPlotLine' // PlotLineにIDを指定
        }],
      }
    ],
    series: [
      {
        type: 'column',
        name: "CII Score(Monthly)",
        visible: true,
        yAxis: 0,
        data: CII,
        borderWidth: 0,
        // pointWidth: 20,
        color: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, 'rgba(221, 135, 57, 1)'],
            [1, 'rgba(225, 149, 80, 1)']
          ]
        },
      },
      {
        type: 'column',
        name: "CII Score(Monthly)",
        visible: true,
        yAxis: 0,
        data: CII_Simulation,
        borderWidth: 0,
        // pointWidth: 20,
        color: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, 'rgba(221, 135, 57, 0.4)'],
            [1, 'rgba(225, 149, 80, 0.4)']
          ]
        },
      },
      {
        type: 'column',
        name: "Fuel Oil Consumption",
        visible: true,
        yAxis: 1,
        data: FOC,
        borderWidth: 0,
        // pointWidth: 20,
        color: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, 'rgb(18, 87, 151, 1)'],
            [1, 'rgb(19, 101, 178, 1)']
          ]
        },
        // pointPlacement: 1
      },
      {
        type: 'column',
        name: "Fuel Oil Consumption",
        visible: true,
        yAxis: 1,
        data: FOC_Simulation,
        borderWidth: 0,
        // pointWidth: 20,
        color: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, 'rgb(18, 87, 151, 0.4)'],
            [1, 'rgb(19, 101, 255, 0.4)']
          ]
        },
        // pointPlacement: 1
      },
      {
        type: 'line',
        name: "CII Score",
        color: '#FFBF83',
        visible: true,
        yAxis: 2,
        data: CIIAll,
        lineWidth: 0.5,
        marker: {
          radius: 8,
          fillColor: {
            radialGradient: { cx: 0.5, cy: 0.5, r: 0.5 },
            stops: [
              [0.14, '#FFE6CE'],
              [0.86, '#FF800C']
            ]
          }
        },
        // pointPlacement: 'on'
        stickyTracking: false
      },
      {
        type: 'line',
        name: "CII Score",
        color: '#FFBF83',
        visible: true,
        yAxis: 2,
        data: CIIAll_Simulation,
        dashStyle: 'LongDash',
        lineWidth: 0.8,
        marker: {
          symbol: 'circle', // 点の形を丸に指定
          radius: 8,
          fillColor: {
            radialGradient: { cx: 0.5, cy: 0.5, r: 0.5 },
            stops: [
              [0.14, 'rgba(255, 230, 206, 0.8)'],
              [0.86, 'rgba(255, 128, 255, 0.3)']
            ]
          }
        },
        // pointPlacement: 'on'
        stickyTracking: false
      },
    ]
  };
  const chartComponentRef = React.useRef(null);
  const [hcOptions, setHcOptions] = React.useState<any>(FOCCIIOptions);

  // APIから取得データでHcOptionsを更新
  React.useEffect(() => {
    if (CII !== undefined && CIILASTYEAR !== undefined
      && CIIAll !== undefined && FOC !== undefined && CIIRATING !== undefined && FOC_YAXIS !== undefined
    ) {
      // FOC&CII
      const newHcOptions = { ...hcOptions };

      // CII(Monthly)
      newHcOptions.series[0].data = JSON.parse(JSON.stringify(CII))
      // CII(Monthly(Simulation))
      newHcOptions.series[1].data = JSON.parse(JSON.stringify(CII_Simulation))

      // FOC
      newHcOptions.series[2].data = JSON.parse(JSON.stringify(FOC))
      // FOC(Simulation)
      newHcOptions.series[3].data = JSON.parse(JSON.stringify(FOC_Simulation))
      newHcOptions.yAxis[1].max = FOC_YAXIS.max
      newHcOptions.yAxis[1].tickInterval = FOC_YAXIS.tickInterval

      // CII(Total)
      newHcOptions.series[4].data = JSON.parse(JSON.stringify(CIIAll))
      // CII(Total_Simulation)
      newHcOptions.series[5].data = JSON.parse(JSON.stringify(CIIAll_Simulation))
      
      newHcOptions.yAxis[2].plotBands = [
        {
          from: 0,
          to: CIIRATING[0],
          color: 'rgba(0, 144, 206, 0.2)'
        }, {
          from: CIIRATING[0],
          to: CIIRATING[1],
          color: 'rgba(6, 165, 80, 0.3)'
        }, {
          from: CIIRATING[1],
          to: CIIRATING[2],
          color: 'rgba(212, 195, 38, 0.3)'
        }, {
          from: CIIRATING[2],
          to: CIIRATING[3],
          color: 'rgba(255, 103, 103, 0.3)'
        }, {
          from: CIIRATING[3],
          to: 1.5,
          color: 'rgba(255, 47, 47, 0.3)'
        }
      ]

      if (CIILASTYEAR !== "" && CIILASTYEAR !== "hidden") {
        newHcOptions.chart.events = {
          click: function (event) {
            onClickGraphReSet(event)
          },
          render: function () {
            var yAxis = this.yAxis[2];

            // 既存のPlotLineを削除
            yAxis.removePlotLine('myPlotLine');

            // プロットライン追加
            yAxis.addPlotLine({
              value: CIILASTYEAR,
              width: 2,
              zIndex: 3,
              color: 'rgba(194, 247, 255, 1)',
              dashStyle: '5, 5, 5, 5',
              id: 'myPlotLine' // PlotLineにIDを指定
            });
          }
        }
        setCIINone({ name: "", top: "", left: "" })
      } else {
        // プロットライン削除
        newHcOptions.chart.events = {
          click: function (event) {
            onClickGraphReSet(event)
          },
          render: function () {
            var yAxis = this.yAxis[2];

            // 既存のPlotLineを削除
            yAxis.removePlotLine('myPlotLine');
          }
        }
        // if(CIILASTYEAR === ""){
        //   setCIINone({ name: "Data Nothing", top: "120", left: "500" })
        // }
      }
      setHcOptions(newHcOptions)

    }
    setKey(key + 1);
  }, [CII, CIIAll, CIILASTYEAR, FOC, CIIRATING, FOC_YAXIS])

  // 画面表示情報をクリア
  const reset_all = () => {

    // FOC
    setFOC([])
    setFOC_Simulation([]);
    setFOC_YAXIS({ tickInterval: 400, max: 2000 })

    // CII
    setCIIRATING([])

    setCII([])
    setCII_Simulation([]);
    setCIIAll([])
    setCIIAll_Simulation([]);
    setCIILASTYEAR("")

    setLegInfoList([])

    // SpeedPlan入力欄
    setSpeedPlan_TimeToEndofYear("");
    setSpeedPlan_Sailing("");
    setSpeedPlan_Ballast("");
    setSpeedPlan_Log_Speed_Ballast("");
    setSpeedPlan_Log_Speed_Laden("");
    setSpeedPlan_Fuel1("-");
    setSpeedPlan_Fuel2("-");
    setSpeedPlan_Fuel3("-");
    setSpeedPlan_FuelRate1("");
    setSpeedPlan_FuelRate2("");
    setSpeedPlan_FuelRate3("");
    setSpeedPlan_Distance("");
    setSpeedPlan_Foc("");

    setVoyageInformationTotal(undefined);
    setVoyageInformationUnit(undefined);
    setVoyageInformationForDisplay(undefined);

    // グラフの表示非表示の状態リセット
    setCIIScoreFlag(true);
    setCIIMorWFlag(true);
    setFOCFlag(true);
    setCIILastYearFlag(true);
    hichartsVisuble();
  }

  // SpeedPlan用変数定義
  const [SpeedPlan_TimeToEndofYear, setSpeedPlan_TimeToEndofYear] = React.useState<any>();
  const [SpeedPlan_Sailing, setSpeedPlan_Sailing] = React.useState<any>();
  const [SpeedPlan_Ballast, setSpeedPlan_Ballast] = React.useState<any>();
  const [SpeedPlan_Log_Speed_Ballast, setSpeedPlan_Log_Speed_Ballast] = React.useState<any>();
  const [SpeedPlan_Log_Speed_Laden, setSpeedPlan_Log_Speed_Laden] = React.useState<any>();
  const [SpeedPlan_Fuel1, setSpeedPlan_Fuel1] = React.useState<any>("-");
  const [SpeedPlan_Fuel2, setSpeedPlan_Fuel2] = React.useState<any>("-");
  const [SpeedPlan_Fuel3, setSpeedPlan_Fuel3] = React.useState<any>("-");
  const [SpeedPlan_FuelRate1, setSpeedPlan_FuelRate1] = React.useState<any>();
  const [SpeedPlan_FuelRate2, setSpeedPlan_FuelRate2] = React.useState<any>();
  const [SpeedPlan_FuelRate3, setSpeedPlan_FuelRate3] = React.useState<any>();
  const [SpeedPlan_Distance, setSpeedPlan_Distance] = React.useState<any>();
  const [SpeedPlan_Foc, setSpeedPlan_Foc] = React.useState<any>();
  const [SpeedPlan_SimulationEnableFlg, setSpeedPlan_SimulationEnableFlg] = React.useState<boolean>(false);

  // SpeedPlanの入力欄をチェックし、Simulationボタンの活性、非活性を制御する。
  React.useEffect(() => {

    if (/^-?\d+(\.\d+)?$/.test(SpeedPlan_Sailing) && /^-?\d+(\.\d+)?$/.test(SpeedPlan_Ballast) &&
      /^-?\d+(\.\d+)?$/.test(SpeedPlan_Log_Speed_Ballast) && /^-?\d+(\.\d+)?$/.test(SpeedPlan_Log_Speed_Laden)) {
      
      // Fuel、FuelRateのチェック
      let ng_flag = false;
      if (SpeedPlan_Fuel1 !== "-" && (SpeedPlan_FuelRate1 === undefined || SpeedPlan_FuelRate1 === "")){
        ng_flag = true;
      }
      if (SpeedPlan_Fuel1 === "-" && (SpeedPlan_FuelRate1 !== undefined && SpeedPlan_FuelRate1 !== "")){
        ng_flag = true;
      }
      if (SpeedPlan_Fuel2 !== "-" && (SpeedPlan_FuelRate2 === undefined || SpeedPlan_FuelRate2 === "")){
        ng_flag = true;
      }
      if (SpeedPlan_Fuel2 === "-" && (SpeedPlan_FuelRate2 !== undefined && SpeedPlan_FuelRate2 !== "")){
        ng_flag = true;
      }
      if (SpeedPlan_Fuel3 !== "-" && (SpeedPlan_FuelRate3 === undefined || SpeedPlan_FuelRate3 === "")){
        ng_flag = true;
      }
      if (SpeedPlan_Fuel3 === "-" && (SpeedPlan_FuelRate3 !== undefined && SpeedPlan_FuelRate3 !== "")){
        ng_flag = true;
      }

      let ok_flg = false;
      if (SpeedPlan_Fuel1 !== "-" && /^-?\d+(\.\d+)?$/.test(SpeedPlan_FuelRate1)){
        ok_flg = true;
      }
      if (SpeedPlan_Fuel1 !== "-" && /^-?\d+(\.\d+)?$/.test(SpeedPlan_FuelRate1)){
        ok_flg = true;
      }
      if (SpeedPlan_Fuel1 !== "-" && /^-?\d+(\.\d+)?$/.test(SpeedPlan_FuelRate1)){
        ok_flg = true;
      }

      if (ng_flag === true) {
        setSpeedPlan_SimulationEnableFlg(false);
      }
      else if (ok_flg === true) {
        setSpeedPlan_SimulationEnableFlg(true);
      }
    }
    else {
      setSpeedPlan_SimulationEnableFlg(false);
    }

  }, [SpeedPlan_Sailing, SpeedPlan_Ballast, SpeedPlan_Log_Speed_Ballast, SpeedPlan_Log_Speed_Laden, 
    SpeedPlan_Fuel1, SpeedPlan_Fuel2, SpeedPlan_Fuel3, SpeedPlan_FuelRate1, SpeedPlan_FuelRate2, SpeedPlan_FuelRate3
  ]);

  // SpeedPlan入力欄関連--------------------------------------------------------------------
  // Sailing変更
  const handleChangeSailing = (event) => {
    // 入力値が空の場合はそのまま許可
    if (event === '') {
      setSpeedPlan_Sailing('');
      return;
    }
    // 入力値を整数に変換し、条件を満たす場合のみ更新
    if (/^(0|[1-9][0-9]{0,2})$/.test(event) && Number(event) <= 100) {
      setSpeedPlan_Sailing(event);
    }
  }
  // Ballast変更
  const handleChangeBallast = (event) => {
    // 入力値が空の場合はそのまま許可
    if (event === '') {
      setSpeedPlan_Ballast('');
      return;
    }
    // 入力値を整数に変換し、条件を満たす場合のみ更新
    if (/^(0|[1-9][0-9]{0,2})$/.test(event) && Number(event) <= 100) {
      setSpeedPlan_Ballast(event);
    }
  }
  // LogSpeed Ballast変更
  const handleChangeLogSpeedBallast = (event) => {
    if (/^(\d*|\d*\.\d*)$/.test(event)) {
      setSpeedPlan_Log_Speed_Ballast(event);
    }
  }
  // LogSpeed Laden変更
  const handleChangeLogSpeedLaden = (event) => {
    if (/^(\d*|\d*\.\d*)$/.test(event)) {
      setSpeedPlan_Log_Speed_Laden(event);
    }
  }
  // fuel1変更
  const handleChangeFuel1 = (event: SelectChangeEvent) => {
    setSpeedPlan_Fuel1(event.target.value as string);
  }
  // fuel2変更
  const handleChangeFuel2 = (event: SelectChangeEvent) => {
    setSpeedPlan_Fuel2(event.target.value as string);
  }
  // fuel3変更
  const handleChangeFuel3 = (event: SelectChangeEvent) => {
    setSpeedPlan_Fuel3(event.target.value as string);
  }

  // fuelRate1変更
  const handleChangeFuelRate1 = (event) => {
    // 入力値が空の場合はそのまま許可
    if (event === '') {
      setSpeedPlan_FuelRate1('');
      return;
    }
    // 入力値を整数に変換し、条件を満たす場合のみ更新
    if (/^(0|[1-9][0-9]{0,2})$/.test(event) && Number(event) <= 100) {
      setSpeedPlan_FuelRate1(event);
    }
  }
  // fuelRate2変更
  const handleChangeFuelRate2 = (event) => {
    // 入力値が空の場合はそのまま許可
    if (event === '') {
      setSpeedPlan_FuelRate2('');
      return;
    }
    // 入力値を整数に変換し、条件を満たす場合のみ更新
    if (/^(0|[1-9][0-9]{0,2})$/.test(event) && Number(event) <= 100) {
      setSpeedPlan_FuelRate2(event);
    }
  }
  // fuelRate3変更
  const handleChangeFuelRate3 = (event) => {
    // 入力値が空の場合はそのまま許可
    if (event === '') {
      setSpeedPlan_FuelRate3('');
      return;
    }
    // 入力値を整数に変換し、条件を満たす場合のみ更新
    if (/^(0|[1-9][0-9]{0,2})$/.test(event) && Number(event) <= 100) {
      setSpeedPlan_FuelRate3(event);
    }
  }

  // Highchartsグラフの表示ON/OFF
  const [CIIScoreFlag, setCIIScoreFlag] = React.useState(true);
  const [CIIMorWFlag, setCIIMorWFlag] = React.useState(true);
  const [FOCFlag, setFOCFlag] = React.useState(true);
  const handleClickGraphLabel = (number) => {
    const newHcOptions = { ...hcOptions };
      for (var i = 0; i <= 5; i++) {
        if (i === number || i === number + 1) {
          newHcOptions.series[i].visible = !newHcOptions.series[i].visible;
          if( i === 0){
            setCIIMorWFlag(!CIIMorWFlag)
          }else if(i === 2){
            setFOCFlag(!FOCFlag)
          }else if(i === 4){
            setCIIScoreFlag(!CIIScoreFlag)
          }
        }
      }
    setHcOptions(newHcOptions);
  };

  // search時に全てのHighchartsグラフを表示状態にする
  const hichartsVisuble = () => {
    for (var i = 0; i <= 5; i++) {
      const newHcOptions = { ...hcOptions };
      newHcOptions.series[i].visible = true;
    }
  }

  // CII Score (Last Year)の表示ON/OFF
  const [CIILastYearFlag, setCIILastYearFlag] = React.useState(true);
  const [saveCIILastYear, setSaveCIILastYear] = React.useState("");
  const switchCIILastYear = () => {
    if (CIILastYearFlag === true) {             // 表示状態→非表示
      setCIILastYearFlag(false)
      setSaveCIILastYear(CIILASTYEAR)           // 保持しているCII Score (Last Year)を保持しておく
      setCIILASTYEAR("hidden")
    }else{                                      // 非表示状態→表示
      setCIILastYearFlag(true)
      setCIILASTYEAR(saveCIILastYear)
    }
  }

  return (
    <>

      <Box sx={{height: "51.25%"}}>
        <Box sx={{ width: "100%", height: "25px"}}>
        </Box>
        {/* ↓↓ タブ ↓↓ */}
        <Button
          sx={{
              width: "351px",
              height: "31px",
              borderBottomLeftRadius: "0px",
              borderBottomRightRadius: "0px",
              fontSize: "14px",
              fontFamily: "'Roboto', 'Meiryo', sans-serif",
              fontWeight: "700",
              color: `${activeSimulationTab === 0 ? "rgba(255, 255, 255, 0.8)" : "rgba(255, 255, 255, 0.4)"}`,
              textTransform: 'none',
              ":hover": { bgcolor: "rgba(255, 255, 255, 0.6)", color: "rgba(255, 255, 255, 0.8)", },
              marginRight: "5px",
              border: "1px solid #5A5A5A",
              borderBottomWidth: "2px",
              backgroundColor: `${activeSimulationTab === 0 ? "rgba(224, 224, 224, 0.15)" : "rgba(121, 121, 121, 0.1)"}`,
              borderBottomColor: `${activeSimulationTab === 0 ? "#E66300" : "#5A5A5A"}`,                
            }}
          onClick={() => handleSimulationTabClick(0)}
          >
          <Stack direction={"row"}>
            <Box sx={{ textAlign: "center" }}>Simulation Conditions (Based on Voyage Plan)</Box>
          </Stack>
        </Button>
        <Button
          sx={{
              width: "351px",
              height: "31px",
              borderBottomLeftRadius: "0px",
              borderBottomRightRadius: "0px",
              fontSize: "14px",
              fontFamily: "'Roboto', 'Meiryo', sans-serif",
              fontWeight: "bold",
              color: `${activeSimulationTab === 1 ? "rgba(255, 255, 255, 0.8)" : "rgba(255, 255, 255, 0.4)"}`,
              textTransform: 'none',
              ":hover": { bgcolor: "rgba(255, 255, 255, 0.6)", color: "rgba(255, 255, 255, 0.8)", },
              border: "1px solid #5A5A5A",
              borderBottomWidth: "2px",
              backgroundColor: `${activeSimulationTab === 1 ? "rgba(224, 224, 224, 0.15)" : "rgba(121, 121, 121, 0.1)"}`,
              borderBottomColor: `${activeSimulationTab === 1 ? "#E66300" : "#5A5A5A"}`,                 
            }}
          onClick={() => handleSimulationTabClick(1)}
          >
          <Stack direction={"row"}>
            <Box sx={{ textAlign: "center" }}>Simulation Conditions (Based on Speed Plan)</Box>
          </Stack>
        </Button>
        {/* ↑↑ タブ ↑↑ */}

        {/* リスト */}
        {activeSimulationTab === 0 ?
          // Simulation Conditions (Based on Voyage Plan)
          <Box 
            sx={{
              height: "85%",
              borderTop:"1px solid #5A5A5A", 
              borderTopWidth: "1px",
              backgroundColor: "rgba(65, 66, 68, 0.20)"
            }}>
            {/* Edit Conditions */}
            <Stack sx={{ position: "relative", height: "70px"}}>
              {/* 船選択中 かつ FOC Formulasが入力済みの場合、EditConditionsボタンを活性にする。 */}
              {props.SeachInfo.imoNo !== ""  && props.focFormulaBallast.length !== 0 ?
                <Button
                  variant="contained"
                  onClick={handleClickOpenDialogEditConditions}
                  sx={{
                    position: "absolute",
                    top: "24px",
                    left: "23px",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "30px",
                    width: "180px",
                    background: 'rgba(255, 255, 255, 0.15)',
                    borderRadius: 30,
                    border: "1px solid #C8C8C8",
                    color: "#fff",
                    ":hover": {
                      bgcolor: "rgba(255, 255, 255, 0.6)",
                      color: "rgba(255, 255, 255, 0.8)",
                    },
                    textTransform: 'none'
                  }}
                  >
                  <img src={EditConditionsIcon} alt="editCondition" style={{ height: "17px", width: "17px", marginRight: "2px"}} />
                  <Typography align="center" sx={{ fontSize: 14, fontFamily: "'Roboto Bold', 'Meiryo', sans-serif", fontWeight: "700"}}>
                    &nbsp;Edit Conditions
                  </Typography>
                </Button>
                  : 
                  <Button
                    variant="contained"
                    sx={{
                      pointerEvents: "none",
                      position: "absolute",
                      top: "24px",
                      left: "23px",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "30px",
                      width: "180px",
                      background: 'rgba(121, 121, 121, 0.1)',
                      borderRadius: 30,
                      border: "1px solid #C8C8C8",
                      color: "rgba(255, 255, 255, 0.4)",
                      textTransform: 'none'
                    }}
                    >
                    <img src={EditConditionsIcon} alt="editCondition" style={{ height: "17px", width: "17px", marginRight: "2px"}} />
                    <Typography align="center" sx={{ fontSize: 14, fontFamily: "'Roboto Bold', 'Meiryo', sans-serif", fontWeight: "700"}}>
                      &nbsp;Edit Conditions
                    </Typography>
                  </Button>
              }
              
            </Stack>

            {/* Edit Conditions Daialog */}
            <CiiEditConditionsDialog
              openDialogFlg = {openDialogEditConditions}
              setOpenDialogFlg={(updateValue: boolean) => setOpenDialogEditConditions(updateValue)}
              loading = {loading_UpperBody}
              setLoading={(updateValue: boolean) => setLoading_UpperBody(updateValue)}
              success = {success_UpperBody}
              setSuccess={(updateValue: boolean) => setSuccess_UpperBody(updateValue)}
              fuelOileList={fuelOileList}
              parentsLegInfoList = {legInfoList}
              setParentsLegInfoList = {(updateValue: any) => setLegInfoList(updateValue)}
              onClickSaveFlg = {onClickSaveFlg}
              setOnClickSaveFlg={(updateValue: boolean) => setOnClickSaveFlg(updateValue)}
              SeachInfo = {props.SeachInfo}
              setSeachInfo = {(updateValue: any) => props.setSeachInfo(updateValue)}
            />         
            
            <Box className='cii-simulation-list-wrapper'>
              {/* LEG List */}
              <table className='simulation-list'>
                <thead>
                  <tr>
                    <th className='leg-no'>
                      <span>LEG No.</span>
                    </th>
                    <th className='departure'>
                      <span>Departure</span>
                    </th>
                    <th className='padding1'>
                      <span></span>
                    </th>
                    <th className='arrival'>
                      <span>Arrival</span>
                    </th>
                    <th className='total-time'>
                      <span>Total Time (h)</span>
                    </th>
                    <th className='distance'>
                      <span>Distance (NM)</span>
                    </th>
                    <th className='padding1'>
                      <span></span>
                    </th>
                    <th className='fuel'>
                      <span>Fuel</span>
                    </th>
                    <th className='displacement'>
                      <span>Displacement</span>
                    </th>
                    <th className='log-speed'>
                      <span>Log Speed (knot)</span>
                    </th>
                    <th className='foc'>
                      <span>FOC (MT)</span>
                    </th>
                    <th className='padding2'>
                      <span></span>
                    </th>
                  </tr>
                </thead>
                {success_UpperBody ?
                  <tbody>
                    {legInfoList.length !== 0 ?
                      <>
                        {
                          legInfoList.map((element, index) => {
                            return (
                              <tr>
                                <td className='leg-no'>
                                  <span className="inner-text" title={`E${element.leg_no}`}>
                                    E{element.leg_no}
                                  </span>
                                </td>
                                <td className='departure'>
                                  <span className="inner-text" title={`${element.departure_port} ${element.departure_time}`}>
                                    {element.departure_port}<br/>{element.departure_time}
                                  </span>
                                </td>
                                <td className='padding1'>
                                  {/* ▶▶ */}
                                  <Box sx={{ width: "100%" , height: "35px"}}>
                                    <img src={RightArrow} alt="right" style={{ height: "15px", width: "15px", marginLeft: "0px", marginTop: "10px"}} />
                                  </Box>
                                </td>
                                <td className='arrival'>
                                  <span className="inner-text" title={`${element.arrival_port} ${element.arrival_time}`}>
                                    {element.arrival_port}<br/>{element.arrival_time}
                                  </span>
                                </td>
                                <td className='total-time'>
                                  <span className="inner-text" title={`${element.total_time}`}>
                                    {formatNumber(element.total_time)}
                                  </span>
                                </td>
                                <td className='distance'>
                                  <span className="inner-text" title={`${element.distance}`}>
                                    {formatNumber(element.distance)}
                                  </span>
                                </td>
                                <td className='padding1'>
                                  <span></span>
                                </td>
                                <td className='fuel'>
                                  {element.fuel.map((item, index) => (
                                    <>
                                      <span key={index} className="inner-text" title={`${item.fuel_type} (${item.fuel_rate}%)`}> 
                                        {item.fuel_type} ({item.fuel_rate}%)
                                      </span>
                                      <br />
                                    </>
                                  ))}
                                </td>
                                <td className='displacement'>
                                  <span className="inner-text" title={`${element.dispracement}`}>
                                    {element.dispracement}
                                  </span>
                                </td>
                                <td className='log-speed'>
                                  <span className="inner-text" title={`${element.log_speed}`}>
                                    {element.log_speed}
                                  </span>
                                </td>
                                <td className='foc'>
                                  <span className="inner-text" title={`${element.foc}`}>
                                    {formatNumber(element.foc)}
                                  </span>
                                </td>
                                <td className='padding2'>
                                  <span></span>
                                </td>
                              </tr>
                            )
                          })
                        }
                      </>
                      :
                      ""
                    }
                  </tbody>
                  :
                  <Box>
                    {loading_UpperBody && (
                      <CircularProgress
                        size={68}
                        sx={{
                          color: "grey",
                          position: 'absolute',
                          top: 220,
                          left: 700,
                          zIndex: 1,
                        }}
                      />
                    )}
                  </Box>
                }  
              </table>
              
              {/* Simulation Button */}
              <Stack sx={{ position: "relative" }}>
                <Button
                  variant="contained"
                  onClick={handleClickSimulationVoyage}
                  sx={{
                    // この二つで中央揃えにできる。
                    display: "block",
                    margin: "auto",
                    
                    pointerEvents: `${legInfoList.length !== 0 && props.focFormulaBallast.length !== 0 ? "aute" : "none"}`,
                    marginTop: "17px",
                    width: 200,
                    background: `${legInfoList.length !== 0 && props.focFormulaBallast.length !== 0 ? "#E66300" : "rgba(230, 99, 0, 0.4)"}`,
                    borderRadius: 30,
                    color: `${legInfoList.length !== 0 && props.focFormulaBallast.length !== 0 ? "#fff" : "rgba(255, 255, 255, 0.4)"}`,
                    ":hover": {
                      bgcolor: "#FFFFFF",
                      color: "#40475D",
                    },
                    textTransform: 'none'
                  }}
                >
                  <Typography align="center" sx={{ fontSize: 16, fontFamily: "'Roboto', 'Meiryo', sans-serif", fontWeight: "bold" }}>Simulate</Typography>
                </Button>
              </Stack>
            </Box>
          </Box>
          :
          <Box 
            sx={{
              height: "85%",
              borderTop:"1px solid #5A5A5A", 
              borderTopWidth: "1px",
              backgroundColor: "rgba(65, 66, 68, 0.20)"
            }}>
            <Box sx={{height: "30px"}}></Box>
            
            <Box className='list-wrapper'>

              {/* Simulation Conditions (Based on Speed Plan) header */}
              <Stack direction={"row"} sx={{ height: "50px",}}>
                <Box className="cii-simulation-speed-plan-header" sx={{width: "20px"}}>
                </Box>
                <Box className="cii-simulation-speed-plan-header" sx={{width: "100px", paddingLeft: "10px"}}>
                  <Stack>Time to</Stack>
                  <Stack>End of Year</Stack>
                </Box>
                <Box className="cii-simulation-speed-plan-header" sx={{width: "140px"}}>
                  <Box style={{ width: "100%", height: "20px", background: 'rgba(83, 84, 89, 0.50)', }}>
                    <Typography align="center" sx={{ marginTop: "2px", color: 'rgba(255, 255, 255, 0.50)', fontSize: 14, fontFamily: 'Roboto', fontWeight: 'bold', wordWrap: 'break-word' }}>
                      Service Rate
                    </Typography>
                  </Box>
                  <Stack direction={"row"}>
                    <Stack sx={{width: "60%"}}>Saling</Stack>
                    <Stack sx={{width: "40%"}}>Port</Stack>
                  </Stack>    
                </Box>
                <Box className="cii-simulation-speed-plan-header" sx={{width: "30px"}}></Box>
                <Box className="cii-simulation-speed-plan-header" sx={{width: "160px"}}>
                  <Box style={{ width: "100%", height: "20px", background: 'rgba(83, 84, 89, 0.50)', }}>
                    <Typography align="center" sx={{ marginTop: "2px", color: 'rgba(255, 255, 255, 0.50)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
                      Displacement Rate
                    </Typography>
                  </Box>
                  <Stack direction={"row"}>
                    <Stack sx={{width: "60%"}}>Ballast</Stack>
                    <Stack sx={{width: "40%"}}>Laden</Stack>
                  </Stack>    
                </Box>
                <Box className="cii-simulation-speed-plan-header" sx={{width: "30px"}}></Box>
                <Box className="cii-simulation-speed-plan-header" sx={{width: "200px"}}>
                  <Box style={{ width: "100%", height: "20px", background: 'rgba(83, 84, 89, 0.50)', }}>
                    <Typography align="center" sx={{ marginTop: "2px", color: 'rgba(255, 255, 255, 0.50)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
                      Average Log Speed
                    </Typography>
                  </Box>
                  <Stack direction={"row"}>
                    <Stack sx={{width: "50%"}}>Ballast</Stack>
                    <Stack sx={{width: "50%"}}>Laden</Stack>
                  </Stack>    
                </Box>
                <Box className="cii-simulation-speed-plan-header" sx={{width: "30px"}}></Box>
                <Box className="cii-simulation-speed-plan-header" sx={{width: "190px"}}>
                  <Stack sx={{marginTop: "22px"}}>Fuel</Stack>
                </Box>
                <Box className="cii-simulation-speed-plan-header" sx={{width: "100px"}}>
                  <Stack sx={{marginTop: "22px"}}>Fuel Rate</Stack>
                </Box>
                <Box className="cii-simulation-speed-plan-header" sx={{width: "100px"}}>
                  <Stack sx={{marginTop: "22px"}}>Distance (NM)</Stack>
                </Box>
                <Box className="cii-simulation-speed-plan-header" sx={{width: "29px"}}></Box>
                <Box className="cii-simulation-speed-plan-header" sx={{width: "85px"}}>
                  <Stack sx={{marginTop: "22px"}}>FOC (MT)</Stack>
                </Box>
              </Stack>
              
              {/* 点線 */}
              <Stack direction={"row"}>
                <Box sx={{ width: "20px", }}></Box>
                <Box sx={{ width: "calc(100% - 40px)", border:"1px dotted #5A5A5A", }}></Box>
                <Box sx={{ width: "20px", }}></Box>
              </Stack>

              {/* Simulation Conditions (Based on Speed Plan) data */}
              <Stack direction={"row"} sx={{ height: "214px",}}>


                {success_UpperBody ?
                  <Stack direction={"row"} sx={{ height: "214px",}}>
                    <Box sx={{width: "20px"}}>
                    </Box>
                    <Box className="cii-simulation-speed-plan-data-time" sx={{width: "100px", display: "flex"}}>
                      <Stack sx={{marginLeft: "20px", marginTop: "10px"}}>
                        {SpeedPlan_TimeToEndofYear !== undefined && SpeedPlan_TimeToEndofYear !== "" ? formatNumber(SpeedPlan_TimeToEndofYear) : "-"}
                      </Stack>
                      <Stack sx={{marginLeft: "6px", marginTop: "10px", fontSize: "14px", fontWeight: "400", fontFamily: "Roboto Condensed"}}>h</Stack>
                    </Box>
                    <Box sx={{width: "140px"}}>
                      <Stack direction={"row"}>
                        <Stack sx={{width: "60%"}}>
                          <Stack direction={"row"}>
                            <Input
                              value={SpeedPlan_Sailing} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeSailing(event.target.value) }}
                              type='tel'
                              sx={{
                                marginTop: "5px",
                                width: "50px", fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                "& .MuiInput-input": { height: "25px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                ":hover:after": { borderBottom: "0px solid green", },
                                ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                ":after": { borderBottom: "2px solid white", },
                                ":before": { borderBottom: "2px solid #A4A7B1", },
                              }} />
                            <Typography sx={{marginTop: "20px", marginLeft: "6px", fontFamily: 'Roboto Condensed', fontWeight: '400', wordWrap: 'break-word', color: "rgba(255, 255, 255, 0.50)", fontSize: "14px"}}>%</Typography>
                          </Stack>
                        </Stack>
                        <Stack sx={{width: "40%"}}>
                          <Stack direction={"row"}>
                            <Stack sx={{marginTop: "10px", fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word', color: "rgba(255, 255, 255, 0.50)", fontSize: "15px"}}>
                              {SpeedPlan_Sailing !== undefined && SpeedPlan_Sailing !== "" ? 100 - SpeedPlan_Sailing : "-"}
                            </Stack>
                            <Stack sx={{marginTop: "10px", fontFamily: 'Roboto Condensed', fontWeight: '400', wordWrap: 'break-word', color: "rgba(255, 255, 255, 0.50)", fontSize: "14px"}}>&nbsp;%</Stack>
                          </Stack>
                        </Stack>
                      </Stack>                      
                    </Box>
                    <Box sx={{width: "30px"}}></Box>
                    <Box sx={{width: "160px"}}>
                      <Stack direction={"row"}>
                        <Stack sx={{width: "60%"}}>
                          <Stack direction={"row"}>
                            <Input
                              value={SpeedPlan_Ballast} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeBallast(event.target.value) }}
                              type='tel'
                              sx={{
                                marginTop: "5px",
                                width: "50px", fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                "& .MuiInput-input": { height: "25px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                ":hover:after": { borderBottom: "0px solid green", },
                                ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                ":after": { borderBottom: "2px solid white", },
                                ":before": { borderBottom: "2px solid #A4A7B1", },
                              }} />
                            <Typography sx={{marginTop: "20px", marginLeft: "6px", fontFamily: 'Roboto Condensed', fontWeight: '400', wordWrap: 'break-word', color: "rgba(255, 255, 255, 0.50)", fontSize: "14px"}}>%</Typography>
                          </Stack>
                        </Stack>
                        <Stack sx={{width: "40%"}}>
                          <Stack direction={"row"}>
                            <Stack sx={{marginTop: "10px", fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word', color: "rgba(255, 255, 255, 0.50)", fontSize: "15px"}}>
                              {SpeedPlan_Ballast !== undefined && SpeedPlan_Ballast !== "" ? 100 - SpeedPlan_Ballast : "-"}
                            </Stack>
                            <Stack sx={{marginTop: "10px", fontFamily: 'Roboto Condensed', fontWeight: '400', wordWrap: 'break-word', color: "rgba(255, 255, 255, 0.50)", fontSize: "14px"}}>&nbsp;%</Stack>
                          </Stack>
                        </Stack>
                      </Stack> 
                    </Box>
                    <Box sx={{width: "30px"}}></Box>
                    <Box sx={{width: "200px"}}>
                      <Stack direction={"row"}>
                        <Stack sx={{width: "50%"}}>
                          <Stack direction={"row"}>
                            <Input
                              value={SpeedPlan_Log_Speed_Ballast} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeLogSpeedBallast(event.target.value) }}
                              type='tel'
                              sx={{
                                marginTop: "5px",
                                width: "60px", fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                "& .MuiInput-input": { height: "25px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                ":hover:after": { borderBottom: "0px solid green", },
                                ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                ":after": { borderBottom: "2px solid white", },
                                ":before": { borderBottom: "2px solid #A4A7B1", },
                              }} />
                            <Typography sx={{marginTop: "20px", marginLeft: "6px", fontFamily: 'Roboto Condensed', fontWeight: '400', wordWrap: 'break-word', color: "rgba(255, 255, 255, 0.50)", fontSize: "14px"}}>knot</Typography>
                          </Stack>
                        </Stack>
                        <Stack sx={{width: "50%"}}>
                          <Stack direction={"row"}>
                            <Input
                              value={SpeedPlan_Log_Speed_Laden} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeLogSpeedLaden(event.target.value) }}
                              type='tel'
                              sx={{
                                marginTop: "5px",
                                width: "60px", fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                "& .MuiInput-input": { height: "25px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                ":hover:after": { borderBottom: "0px solid green", },
                                ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                ":after": { borderBottom: "2px solid white", },
                                ":before": { borderBottom: "2px solid #A4A7B1", },
                              }} />
                            <Typography sx={{marginTop: "20px", marginLeft: "6px", fontFamily: 'Roboto Condensed', fontWeight: '400', wordWrap: 'break-word', color: "rgba(255, 255, 255, 0.50)", fontSize: "14px"}}>knot</Typography>
                          </Stack>                        
                        </Stack>
                      </Stack> 
                    </Box>
                    <Box sx={{width: "30px"}}></Box>
                    <Box sx={{width: "190px"}}>                     
                      <Box sx={{height: "4px"}}></Box>
                      {/* Fuelプルダウン1 */}
                      <Box>
                        <ThemeProvider theme={SelectTheme}>
                          <FormControl>
                            <Select
                              IconComponent={() =>
                                <div style={{ position: "absolute", top: "20px", left: "150px" }}>
                                  <InputAdornment position="start" disablePointerEvents>
                                    <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.7)", borderRight: "solid 2px rgb(255, 255, 255, 0.7)", width: "8px", height: "8px", transform: "rotate(135deg)" }}></Box>
                                  </InputAdornment>
                                </div>
                              }
                              MenuProps={{
                                MenuListProps: {
                                  disablePadding: true,
                                },
                                // style: { zIndex: 100000, }
                              }}
                              sx={{
                                width: 180,
                                height: 40,
                                color: "white",
                                borderRadius: 30,
                                ":hover": { backgroundColor: "#7a86a0" }
                              }}
                              input={<BootstrapInput />}
                              onChange={handleChangeFuel1}
                              value={SpeedPlan_Fuel1}
                            >
                              <MenuItem key={"hyphen"} value={"-"}>{"-"}</MenuItem>
                              {fuelOileList.map((fuel) => {
                                return (
                                  <MenuItem key={fuel} value={fuel}>{fuel}</MenuItem>
                                )
                              }
                              )}
                            </Select>
                          </FormControl>
                        </ThemeProvider>
                      </Box>
                      <Box sx={{height: "4px"}}></Box>
                      {/* Fuelプルダウン2 */}
                      <Box>
                        <ThemeProvider theme={SelectTheme}>
                          <FormControl>
                            <Select
                              IconComponent={() =>
                                <div style={{ position: "absolute", top: "20px", left: "150px" }}>
                                  <InputAdornment position="start" disablePointerEvents>
                                    <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.7)", borderRight: "solid 2px rgb(255, 255, 255, 0.7)", width: "8px", height: "8px", transform: "rotate(135deg)" }}></Box>
                                  </InputAdornment>
                                </div>
                              }
                              MenuProps={{
                                MenuListProps: {
                                  disablePadding: true,
                                },
                                // style: { zIndex: 100000, }
                              }}
                              sx={{
                                width: 180,
                                height: 40,
                                color: "white",
                                borderRadius: 30,
                                ":hover": { backgroundColor: "#7a86a0" }
                              }}
                              input={<BootstrapInput />}
                              onChange={handleChangeFuel2}
                              value={SpeedPlan_Fuel2}
                            >
                              <MenuItem key={"hyphen"} value={"-"}>{"-"}</MenuItem>
                              {fuelOileList.map((fuel) => {
                                return (
                                  <MenuItem key={fuel} value={fuel}>{fuel}</MenuItem>
                                )
                              }
                              )}
                            </Select>
                          </FormControl>
                        </ThemeProvider>
                      </Box>
                      <Box sx={{height: "4px"}}></Box>
                      {/* Fuelプルダウン3 */}
                      <Box>
                        <ThemeProvider theme={SelectTheme}>
                          <FormControl>
                            <Select
                              IconComponent={() =>
                                <div style={{ position: "absolute", top: "20px", left: "150px" }}>
                                  <InputAdornment position="start" disablePointerEvents>
                                    <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.7)", borderRight: "solid 2px rgb(255, 255, 255, 0.7)", width: "8px", height: "8px", transform: "rotate(135deg)" }}></Box>
                                  </InputAdornment>
                                </div>
                              }
                              MenuProps={{
                                MenuListProps: {
                                  disablePadding: true,
                                },
                                // style: { zIndex: 100000, }
                              }}
                              sx={{
                                width: 180,
                                height: 40,
                                color: "white",
                                borderRadius: 30,
                                ":hover": { backgroundColor: "#7a86a0" }
                              }}
                              input={<BootstrapInput />}
                              onChange={handleChangeFuel3}
                              value={SpeedPlan_Fuel3}
                            >
                              <MenuItem key={"hyphen"} value={"-"}>{"-"}</MenuItem>
                              {fuelOileList.map((fuel) => {
                                return (
                                  <MenuItem key={fuel} value={fuel}>{fuel}</MenuItem>
                                )
                              }
                              )}
                            </Select>
                          </FormControl>
                        </ThemeProvider>
                      </Box>
                    </Box>
                    <Box sx={{width: "100px"}}>
                      {/* FuelRate1 */}
                      <Stack direction={"row"}>
                        <Input
                          value={SpeedPlan_FuelRate1} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeFuelRate1(event.target.value) }}
                          type='tel'
                          sx={{
                            marginTop: "5px",
                            width: "60px", fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                            "& .MuiInput-input": { height: "25px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                            ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                            ":hover:after": { borderBottom: "0px solid green", },
                            ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                            ":after": { borderBottom: "2px solid white", },
                            ":before": { borderBottom: "2px solid #A4A7B1", },
                          }} />
                        <Typography sx={{marginTop: "20px", marginLeft: "6px", fontFamily: 'Roboto Condensed', fontWeight: '400', wordWrap: 'break-word', color: "rgba(255, 255, 255, 0.50)", fontSize: "14px"}}>%</Typography>
                      </Stack>
                      {/* FuelRate2 */}
                      <Stack direction={"row"}>
                        <Input
                          value={SpeedPlan_FuelRate2} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeFuelRate2(event.target.value) }}
                          type='tel'
                          sx={{
                            marginTop: "10px",
                            width: "60px", fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                            "& .MuiInput-input": { height: "25px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                            ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                            ":hover:after": { borderBottom: "0px solid green", },
                            ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                            ":after": { borderBottom: "2px solid white", },
                            ":before": { borderBottom: "2px solid #A4A7B1", },
                          }} />
                        <Typography sx={{marginTop: "20px", marginLeft: "6px", fontFamily: 'Roboto Condensed', fontWeight: '400', wordWrap: 'break-word', color: "rgba(255, 255, 255, 0.50)", fontSize: "14px"}}>%</Typography>
                      </Stack> 
                      {/* FuelRate3 */}
                      <Stack direction={"row"}>
                        <Input
                          value={SpeedPlan_FuelRate3} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeFuelRate3(event.target.value) }}
                          type='tel'
                          sx={{
                            marginTop: "10px",
                            width: "60px", fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                            "& .MuiInput-input": { height: "25px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                            ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                            ":hover:after": { borderBottom: "0px solid green", },
                            ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                            ":after": { borderBottom: "2px solid white", },
                            ":before": { borderBottom: "2px solid #A4A7B1", },
                          }} />
                        <Typography sx={{marginTop: "20px", marginLeft: "6px", fontFamily: 'Roboto Condensed', fontWeight: '400', wordWrap: 'break-word', color: "rgba(255, 255, 255, 0.50)", fontSize: "14px"}}>%</Typography>
                      </Stack> 
                    </Box>
                    {/* Distance */}
                    <Box sx={{width: "100px"}}>
                      <Stack direction={"row"} justifyItems={"end"} sx={{ position: "relative"}}>
                        <Stack sx={{marginTop: "10px", fontWeight: '400', position: "absolute", right: "3px", color: "rgba(255, 255, 255, 0.50)", fontSize: "15px"}}>
                          {SpeedPlan_Distance !== undefined && SpeedPlan_Distance !== "" ? formatNumber(SpeedPlan_Distance) : "-"}
                        </Stack>
                      </Stack>
                    </Box>
                    <Box sx={{width: "29px"}}></Box>
                    {/* FOC */}
                    <Box sx={{width: "85px"}}>
                      <Stack direction={"row"} justifyItems={"end"} sx={{ position: "relative"}}>
                        <Stack sx={{marginTop: "10px", fontWeight: '400', position: "absolute", right: "20px", color: "rgba(255, 255, 255, 0.50)", fontSize: "15px"}}>
                          {SpeedPlan_Foc !== undefined && SpeedPlan_Foc !== "" ? formatNumber(SpeedPlan_Foc) : "-"}
                        </Stack>
                      </Stack>
                    </Box>
                  </Stack>
                  :
                  <Box>
                    {loading_UpperBody && (
                      <CircularProgress
                        size={68}
                        sx={{
                          color: "grey",
                          position: 'absolute',
                          top: 220,
                          left: 700,
                          zIndex: 1,
                        }}
                      />
                    )}
                  </Box>
                }
              </Stack>
              
              {/* Simulation Button */}
              <Stack sx={{ position: "relative" }}>
                <Button
                  variant="contained"
                  onClick={handleClickSimulationSpeed}
                  sx={{
                    // この二つで中央揃えにできる。
                    display: "block",
                    margin: "auto",

                    pointerEvents: `${SpeedPlan_SimulationEnableFlg === true && props.focFormulaBallast.length !== 0 ? "aute" : "none"}`,
                    marginTop: "10px",
                    width: 200,
                    background: `${SpeedPlan_SimulationEnableFlg === true && props.focFormulaBallast.length !== 0 ? "#E66300" : "rgba(230, 99, 0, 0.4)"}`,
                    borderRadius: 30,
                    color: `${SpeedPlan_SimulationEnableFlg === true && props.focFormulaBallast.length !== 0 ? "#fff" : "rgba(255, 255, 255, 0.4)"}`,
                    ":hover": {
                      bgcolor: "#FFFFFF",
                      color: "#40475D",
                    },
                    textTransform: 'none'
                  }}
                >
                  <Typography align="center" sx={{ fontSize: 16, fontFamily: "'Roboto', 'Meiryo', sans-serif", fontWeight: "bold" }}>Simulate</Typography>
                </Button>
              </Stack>
            </Box>
          </Box>               
        }
      </Box>
      {/* ↑↑ リストエリア ↑↑ */}
      
      <Box sx={{ width: "100%", height: "10px"}}>
      </Box>

      {/* ↓↓ グラフエリア ↓↓ */}
      <Box sx={{height: "46%", backgroundColor: "rgba(65, 66, 68, 0.20)"}}>
        <Stack sx={{height: "45px"}}>
          <Typography sx={{marginTop: "10px", marginLeft: "10px", color: "white", fontSize: 15, fontFamily: "'Roboto', 'Meiryo', sans-serif", fontWeight: "bold"}}>Simulation Result</Typography>
        </Stack>
        <Box sx={{display: "flex" , height: "85%"}}>
          {/* グラフ */}
          <Box sx={{position:"relative", width: "calc(100% - 350px)", height: "100%"}}>

            {/* Y軸設定ダイアログ */}
            <Box
              sx={{
                position: "absolute",
                top: "10px",
                left: "30px",
                width: "20px",
                height: "20px",
                background: "rgb(255,255,255,0.3)",
                cursor: "pointer",
                borderRadius: "50%",
                border: "2px solid rgb(255,255,255,0.7)",
                zIndex: 10,

              }}
              onClick={handleClickOpenDialogAxis}
              >
              <SettingsIcon sx={{ position: "absolute", top: "1px", left: "1px", width: "14px", height: "14px", color: "rgb(255,255,255,0.7)" }} />
            </Box>

            <Dialog
                open={openDialogyAxis}
                sx={{
                  "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      width: "100%",
                      maxWidth: "500px",
                      height: "100%",
                      maxHeight: "250px",
                    },
                  },
                }}
              >
                <DialogTitle
                  sx={{
                    bgcolor: "#474C53",
                    height: "50px",
                    color: "white",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}>
                  Axis Setting
                  <IconButton
                    aria-label="close"
                    onClick={handleNoClickOpenDialogAxis}
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                      color: "rgba(255, 255, 255, 0.5)",
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent
                  sx={{
                    background: "linear-gradient(180deg, #0E1B25 0%, #17232D 100%)",
                  }}>

                  <Box sx={{ position: "relative" }}>
                    <Box sx={{ position: "absolute", top: "60px", left: "20px", height: "1px", width: "400px", borderRadius: '0px ', border: "1px dotted #A4A7B1" }}></Box>
                    <Typography align="center"
                      sx={{
                        position: "absolute", top: "30px", left: "40px",
                        color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word'
                      }}>
                      Channel Name
                    </Typography>
                    <Typography align="center"
                      sx={{
                        position: "absolute", top: "30px", left: "240px",
                        color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word'
                      }}>
                      Min
                    </Typography>
                    <Typography align="center"
                      sx={{
                        position: "absolute", top: "30px", left: "350px",
                        color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word'
                      }}>
                      Max
                    </Typography>
                  </Box>
                  <Box sx={{ position: "relative" }}>
                    <Stack direction={"row"}>
                      <Typography align="left"
                        sx={{
                          position: "absolute", top: "90px", left: "70px",
                          color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word'
                        }}>
                        FOC
                      </Typography>
                      <FormControl>
                        <Input
                          value={FOCyAxisMinShow} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleClickFOCyAxisMinShow(event.target.value) }}
                          type='tel'
                          sx={{
                            position: "absolute", top: "80px", left: "200px", width: "100px", fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                            "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                            ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                            ":hover:after": { borderBottom: "0px solid green", },
                            ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                            ":after": { borderBottom: "2px solid white", },
                            ":before": { borderBottom: "2px solid #A4A7B1", },
                          }} />
                      </FormControl>
                      <Box sx={{ position: "absolute", top: "100px", left: "300px", height: "1px", width: "20px", borderRadius: '0px ', border: "1px solid #A4A7B1" }}></Box>
                      <FormControl>
                        <Input
                          value={FOCyAxisMaxShow} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleClickFOCyAxisMaxShow(event.target.value) }}
                          type='tel'
                          sx={{
                            position: "absolute", top: "80px", left: "320px", width: "100px", fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                            "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                            ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                            ":hover:after": { borderBottom: "0px solid green", },
                            ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                            ":after": { borderBottom: "2px solid white", },
                            ":before": { borderBottom: "2px solid #A4A7B1", },
                          }} />
                      </FormControl>
                    </Stack>
                  </Box>
                </DialogContent>
                <DialogActions sx={{
                  height: "50px",
                  bgcolor: "#474C53"
                }}>
                  <Button
                    sx={{
                      marginLeft: "0px",
                      marginRight: "-8px",
                      backgroundColor: "rgba(255, 255, 255, 0.5)",
                      width: "127px",
                      height: "50px",
                      padding: "0",
                      fontSize: "18px",
                      fontFamily: "'Roboto', 'Meiryo', sans-serif",
                      fontWeight: "bold",
                      color: "white",
                      borderRadius: "0px",
                      ":hover": {
                        bgcolor: "#FFFFFF",
                        color: "#40475D",
                      },
                      textTransform: 'none'
                    }}
                    onClick={handleNoClickOpenDialogAxis}
                  >
                    Cancel
                  </Button>
                  <Button
                    sx={{
                      marginLeft: "0px",
                      marginRight: "-8px",
                      backgroundColor: "#e66300",
                      width: "127px",
                      height: "50px",
                      padding: "0",
                      fontSize: "18px",
                      fontFamily: "'Roboto', 'Meiryo', sans-serif",
                      fontWeight: "bold",
                      color: "white",
                      borderRadius: "0px",
                      ":hover": {
                        bgcolor: "#FFFFFF",
                        color: "#40475D",
                      },
                      textTransform: 'none'
                    }}
                    onClick={handleClickSetAxis}
                  >
                    Save
                  </Button>
                </DialogActions>
            </Dialog>
            {/* ↑ タイトル ↑ */}

            <Box sx={{ position: "relative", height: "100%" }}>
              {success_LowerBody ?
                <>
                  {/* ↓ Highchartsグラフ ↓ */}
                  <Stack sx={{ position: "relative", top: "-30px", left: "-28px", height: "95%", width: "100%" }}>
                    <div onBlur={onClickGraphReSet}>
                      <HighchartsReact
                        key={key}
                        highcharts={Highcharts}
                        options={hcOptions}
                        ref={chartComponentRef}
                        allowChartUpdate={true}
                        updateArgs={[true, true, true]}
                      />
                    </div>
                  </Stack>
                  <Stack sx={{ position: "absolute", top: `${CIINone.top}px`, left: `${CIINone.left}px` }}>
                    <Typography align="left" sx={{ color: 'white', opacity: 0.50, fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                      {CIINone.name}
                    </Typography>
                  </Stack>
                  {/* ↑ Highchartsグラフ ↑ */}

                  {/* ↓ 凡例 ↓ */}
                  <Stack sx={{ position: "relative", width: "100%", marginTop: "25px" , bottom: "40px"}}>
                    <Grid container alignItems={"center"} justifyContent={"center"} >
                      {/* 左余白 */}
                      <Grid item xs={0.2}>
                      </Grid>
                      {/* CII Score (Total) */}
                      <Grid className="cii-simulation-pointer-area" container alignItems={"center"} justifyContent={"start"} item xs={1.45} onClick={() =>handleClickGraphLabel(4)} >
                        <Grid item xs={1}></Grid>
                        <Grid item xs={3}>
                          <Box sx={{ position: "relative" }} >
                            <Box sx={{ position: "absolute", top: "0px", left: "0px", width: '8px', height: '1px',
                              border: (CIIScoreFlag ? '1px #FF8514 solid' : '1px rgba(255, 133, 20, 0.4) solid')
                            }}></Box>
                            <Box sx={{ 
                              position: "absolute", top: "-5px", left: "8px", width: '10px', height: '10px', 
                              background: (CIIScoreFlag ? 'radial-gradient(rgba(255, 191, 131, 1) 0%, rgba(255, 133, 20, 1) 100%)' : 'radial-gradient(rgba(255, 191, 131, 0.4) 0%, rgba(255, 133, 20, 0.4) 100%)') ,
                              borderRadius: "5px" 
                            }}></Box>
                            <Box sx={{ position: "absolute", top: "0px", left: "18px", width: '8px', height: '1px', 
                              border: (CIIScoreFlag ? '1px #FF8514 solid' : '1px rgba(255, 133, 20, 0.4) solid')
                            }}></Box>
                          </Box>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography align="center" sx={{ color: (CIIScoreFlag ? 'white': "rgba(255,255,255,0.4)"), width: "100%", opacity: 0.70, fontSize: "min(0.7vw,14px)", fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                            CII Score (Total)
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={0.25}></Grid>
                      {/* CII Score (Monthly) */}
                      <Grid className="cii-simulation-pointer-area" container alignItems={"center"} justifyContent={"center"} item xs={1.4} onClick={() =>handleClickGraphLabel(0)} >
                        <Grid item xs={0.3}></Grid>
                        <Grid item xs={1.5} >
                          <Box style={{ width: '15px', height: '20px', background: (CIIMorWFlag ? 'linear-gradient(180deg, #DD8739 0%, #E19550 100%)' : 'linear-gradient(180deg, rgba(221, 135, 57, 0.4) 0%, rgba(225, 149, 80, 0.4) 40%)') }} />
                        </Grid>
                        <Grid item xs={10.2} >
                          <Typography align="center" sx={{ color: (CIIMorWFlag ? 'white' : "rgba(255,255,255,0.4)"), width: "100%", opacity: 0.70, fontSize: "min(0.7vw,13px)", fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                            CII Score (Monthly)
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={0.25}></Grid>
                      {/* CII Score (Last Year) */}
                      <Grid className="cii-emission-board-pointer-area" container alignItems={"center"} justifyContent={"center"} item xs={1.68} onClick={() => switchCIILastYear()} >
                        <Grid item xs={0.3} ></Grid>
                        <Grid item xs={2.2} >
                          <Box sx={{ position: "relative" }} >
                            <Box sx={{ position: "absolute", top: "-3px", left: "0px", width: '6px', height: '6px', bgcolor: (CIILastYearFlag ? "rgba(194, 247, 255, 1)" : "rgba(194, 247, 255, 0.4)"), borderRadius: "50%" }}></Box>
                            <Box sx={{ position: "absolute", top: "0px", left: "6px", width: '5px', height: '0.4px', bgcolor: (CIILastYearFlag ? "rgba(194, 247, 255, 1)" : "rgba(194, 247, 255, 0.4)"), }}></Box>
                            <Box sx={{ position: "absolute", top: "0px", left: "11px", width: '3px', height: '1px', bgcolor: "transparent" }}></Box>
                            <Box sx={{ position: "absolute", top: "0px", left: "14px", width: '5px', height: '0.4px', bgcolor: (CIILastYearFlag ? "rgba(194, 247, 255, 1)" : "rgba(194, 247, 255, 0.4)"), }}></Box>
                            <Box sx={{ position: "absolute", top: "0px", left: "19px", width: '3px', height: '1px', bgcolor: "transparent" }}></Box>
                            <Box sx={{ position: "absolute", top: "0px", left: "22px", width: '5px', height: '0.4px', bgcolor: (CIILastYearFlag ? "rgba(194, 247, 255, 1)" : "rgba(194, 247, 255, 0.4)") }}></Box>
                            <Box sx={{ position: "absolute", top: "-3px", left: "27px", width: '6px', height: '6px', bgcolor: (CIILastYearFlag ? "rgba(194, 247, 255, 1)" : "rgba(194, 247, 255, 0.4)") , borderRadius: "50%" }}></Box>
                          </Box>
                        </Grid>
                        <Grid item xs={9.5} >
                          <Typography align="center" sx={{ color: 'white', width: "100%", opacity: 0.70, fontSize: "min(0.7vw,13px)", fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                            CII Score ({lastYear})
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={0.25}></Grid>
                      {/* Fuel Oil Consumption */}
                      <Grid className="cii-simulation-pointer-area" container alignItems={"center"} justifyContent={"center"} item xs={1.52} onClick={() =>handleClickGraphLabel(2)} >
                        <Grid item xs={0.3}></Grid>
                        <Grid item xs={1.5}>
                          <Box style={{ width: '15px', height: '20px', background: (FOCFlag ? 'linear-gradient(180deg, #1164B1 0%, #1478D5 100%)' : 'linear-gradient(180deg, rgba(17, 100, 177, 0.4) 0%, rgba(20, 120, 213, 0.4) 100%)')}} />
                        </Grid>
                        <Grid item xs={10.2} >
                          <Typography align="center" sx={{ color: (FOCFlag ? 'white' : 'rgba(255, 255, 255, 0.4)'), width: "100%", opacity: 0.70, fontSize: "min(0.7vw,13px)", fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                            Fuel Oil Consumption
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={0.25}></Grid>
                      {/* CIIスコア */}
                      <Grid item xs={0.4}>
                        <Stack alignItems={"center"} justifyContent={"center"} sx={{ width: "26px", height: "26px", background: "rgba(0, 144, 206, 0.4)" }}>
                          <Typography align="center" sx={{ color: 'white', width: "100%", opacity: 0.70, fontSize: 15, fontFamily: 'Roboto Condensed', fontWeight: 'bold', wordWrap: 'break-word', paddingTop: "2px", }}>
                            A
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={0.4}>
                        <Stack alignItems={"center"} justifyContent={"center"} sx={{ width: "26px", height: "26px", background: "rgba(6, 165, 80, 0.4)" }}>
                        <Typography align="center" sx={{ color: 'white', width: "100%", opacity: 0.70, fontSize: 15, fontFamily: 'Roboto Condensed', fontWeight: 'bold', wordWrap: 'break-word', paddingTop: "2px", }}>
                            B
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={0.4}>
                        <Stack alignItems={"center"} justifyContent={"center"} sx={{ width: "26px", height: "26px", background: "rgba(212, 195, 38, 0.4)" }}>
                        <Typography align="center" sx={{ color: 'white', width: "100%", opacity: 0.70, fontSize: 15, fontFamily: 'Roboto Condensed', fontWeight: 'bold', wordWrap: 'break-word', paddingTop: "2px", }}>
                            C
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={0.4}>
                        <Stack alignItems={"center"} justifyContent={"center"} sx={{ width: "26px", height: "26px", background: "rgba(255, 103, 103, 0.45)" }}>
                        <Typography align="center" sx={{ color: 'white', width: "100%", opacity: 0.70, fontSize: 15, fontFamily: 'Roboto Condensed', fontWeight: 'bold', wordWrap: 'break-word', paddingTop: "2px", }}>
                            D
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={0.4} >
                        <Stack alignItems={"center"} justifyContent={"center"} sx={{ width: "26px", height: "26px", background: "rgba(255, 47, 47, 0.45)" }}>
                        <Typography align="center" sx={{ color: 'white', width: "100%", opacity: 0.70, fontSize: 15, fontFamily: 'Roboto Condensed', fontWeight: 'bold', wordWrap: 'break-word', paddingTop: "2px", }}>
                            E
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Stack>
                  {/* ↑ 凡例 ↑ */}
                </>
                :
                <Box>
                  {loading_LowerBody && (
                    <CircularProgress
                      size={68}
                      sx={{
                        color: "grey",
                        position: 'absolute',
                        top: "150px",
                        left: "500px",
                        zIndex: 1,
                      }}
                    />
                  )}
                </Box>
              }
            </Box>


          </Box>
          {/* 数値エリア */}
          <Box sx={{width: "350px", height: "100%"}}>
            <Box sx={{height: "5px"}}></Box>
            <Grid className='cii-simulation-result-titole' container sx={{width: "100%"}}>
              <Grid item xs={2.8}>CII Score</Grid>
              <Grid item xs={9.2} className='cii-score'>
                {voyageInformationForDisplay !== undefined && voyageInformationForDisplay !== null ? voyageInformationForDisplay.CiiScore : "-"}
              </Grid>
            </Grid>
            <Box sx={{height: "10%"}}></Box>
            <Grid className='cii-simulation-result-titole' container sx={{width: "100%"}}>
              <Grid item xs={12}>Total</Grid>
            </Grid>
            <Grid container sx={{width: "100%"}}>
              <Grid item xs={1}></Grid>
              <Grid item xs={4} className='cii-simulation-result-titole'>FOC</Grid>
              <Grid item xs={2.25} className='cii-simulation-result-param' sx={{textAlign: "right"}}>
                {voyageInformationForDisplay !== undefined && voyageInformationForDisplay !== null ? formatNumber(voyageInformationForDisplay.total_foc) : "-"}
              </Grid>
              <Grid item xs={3.75} className='cii-simulation-result-unit' >
                MT
              </Grid>
            </Grid>
            <Grid container sx={{width: "100%"}}>
              <Grid item xs={1}></Grid>
              <Grid item xs={4} className='cii-simulation-result-titole'>CO2 Emissions</Grid>
              <Grid item xs={2.25} className='cii-simulation-result-param' sx={{textAlign: "right"}}>
                {voyageInformationForDisplay !== undefined && voyageInformationForDisplay !== null ? formatNumber(voyageInformationForDisplay.total_co2_emissions) : "-"}
              </Grid>
              <Grid item xs={3.75} className='cii-simulation-result-unit' >
                MT
              </Grid>
            </Grid>
            <Grid container sx={{width: "100%"}}>
              <Grid item xs={1}></Grid>
              <Grid item xs={4} className='cii-simulation-result-titole'>Distance</Grid>
              <Grid item xs={2.25} className='cii-simulation-result-param' sx={{textAlign: "right"}}>
                {voyageInformationForDisplay !== undefined && voyageInformationForDisplay !== null ? formatNumber(voyageInformationForDisplay.total_distance) : "-"}
              </Grid>
              <Grid item xs={3.75} className='cii-simulation-result-unit' >
                NM
              </Grid>
            </Grid>
            <Box sx={{height: "10%"}}></Box>
            <Grid className='cii-simulation-result-titole' container sx={{width: "100%"}}>
              <Grid item xs={12}>Average</Grid>
            </Grid>
            <Grid container sx={{width: "100%"}}>
              <Grid item xs={1}></Grid>
              <Grid item xs={4} className='cii-simulation-result-titole'>LOG Speed</Grid>
              <Grid item xs={2.25} className='cii-simulation-result-param' sx={{textAlign: "right"}}>
                {voyageInformationForDisplay !== undefined && voyageInformationForDisplay !== null ? formatNumber(voyageInformationForDisplay.avg_log_speed) : "-"}
              </Grid>
              <Grid item xs={3.75} className='cii-simulation-result-unit' >
                knot
              </Grid>
            </Grid>
            <Grid container sx={{width: "100%"}}>
              <Grid item xs={1}></Grid>
              <Grid item xs={4} className='cii-simulation-result-titole'>Displacement</Grid>
              <Grid item xs={2.25} className='cii-simulation-result-param' sx={{textAlign: "right"}}>
                {voyageInformationForDisplay !== undefined && voyageInformationForDisplay !== null ? formatNumber(voyageInformationForDisplay.avg_displacement) : "-"}
              </Grid>
              <Grid item xs={3.75} className='cii-simulation-result-unit' >
                ton
              </Grid>
            </Grid>  
          </Box>
          
        </Box>
      {/* ↑↑ グラフエリア ↑↑ */}
      </Box>
    </>
  );
} 

export default CiiSimulation;