
import * as React from 'react';

import axios, { AxiosError } from "axios";

import './CiiEmissionBoard.css'; // スタイルのために追加

// MUI @mui/material/
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DateIcon_svg from '../../../assets/images/date.svg';

// MUI @mui/icons-material/
import SettingsIcon from '@mui/icons-material/Settings';

// Highcharts
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';


// Axios Error
interface ErrorData {
  message: string;
  status: number;
  error: string;
}


// 親コンポーネントから持ち回る値
export type Props = {
  unit: String;
  vesselMaster: any;
  changeVesselFlg: boolean;
  setChangeVesselFlg: (value: boolean) => void;
  setVesselMaster: (value: any) => void;
  setAuthCheck: (value: boolean) => void;
  setAuthMessage: (value: any) => void;

  SeachInfo: any;
  setSeachInfo: (value: any) => void;
}

function formatNumber(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// Cii EmissionBoard Component--------------------------------------------------------------------
export const CiiEmissionBoard: React.FC<Props> = props => {

  let initFlg = true;
  const [jwt, setJwt] = React.useState<string>();

  const [SeachInfo_from, setSeachInfo_from] = React.useState<string>(''); // 検索期間From
  const [SeachInfo_to, setSeachInfo_to] = React.useState<string>(''); // 検索期間To

  const [lastYear, setLastYear] = React.useState<string>('');

  React.useEffect(() => {

    // 前年を取得
    setLastYear(String(new Date().getFullYear() - 1));

    setSeachInfo_from(props.SeachInfo.from);
    setSeachInfo_to(props.SeachInfo.to);

    // API呼び出し
    // 初回のみ、必要項目をpayloadから取得
    let payloadJwt = ""
    const pay_json = localStorage.getItem("payload")
    if (pay_json !== null) {
      const pay = JSON.parse(pay_json)
      payloadJwt = pay["jwt"];
      setJwt(pay["jwt"]);
    }
    
    if (initFlg) {

      if (props.SeachInfo.imoNo) {
        let tmp_unit = "";

        if (props.unit !== undefined && props.unit !== "") {
          tmp_unit = String(props.unit);
        }
        else {
          tmp_unit = "Monthly"
        }

        // API呼び出し
        const url = `${base_url}${props.SeachInfo.imoNo}?Timestamp_from=${props.SeachInfo.from}&Timestamp_to=${props.SeachInfo.to}&Unit=${tmp_unit}`
        getNoonReport(url, payloadJwt);

      }
      else {
        // Loading解除
        setLoading(false);
        setSuccess(true);
      }

      initFlg = false;
    }     
  }, []);

  // VesselName変更
  React.useEffect(() => {
   
    let tmp_unit = "";

    if (props.unit !== undefined && props.unit !== "") {
      tmp_unit = String(props.unit);
    }
    else {
      tmp_unit = "Monthly"
    }

    // 親コンポーネントからchangeVesselFlgがtrueで飛んできた場合、検索処理実行
    if (props.changeVesselFlg === true) {
      // Loading表示
      setLoading(true);
      setSuccess(false);

      // グラフの表示/非表示状態をリセット     
      setCIIScoreFlag(true);
      setCIIMorWFlag(true);
      setFOCFlag(true);
      setCIILastYearFlag(true);
      hichartsVisuble();

      const url = `${base_url}${props.SeachInfo.imoNo}?Timestamp_from=${props.SeachInfo.from}&Timestamp_to=${props.SeachInfo.to}&Unit=${tmp_unit}`     
      getNoonReport(url, jwt);

      // clickSearchFlgをfalseに更新
      props.setChangeVesselFlg(false);
    }
  }, [props.changeVesselFlg]);

  // DataRange変更
  React.useEffect(() => {

    let tmpFrom = props.SeachInfo.from;
    let tmpTo = props.SeachInfo.to;

    if (tmpFrom !== SeachInfo_from || tmpTo !== SeachInfo_to) {
      setSeachInfo_from(tmpFrom);
      setSeachInfo_to(tmpTo);

      if (props.SeachInfo.imoNo) {
        let tmp_unit = "";

        if (props.unit) {
          tmp_unit = String(props.unit);
        }
        else {
          tmp_unit = "Monthly"
        }
        
        // Loading表示
        setLoading(true);
        setSuccess(false);

        // グラフの表示/非表示状態をリセット     
        setCIIScoreFlag(true);
        setCIIMorWFlag(true);
        setFOCFlag(true);
        setCIILastYearFlag(true);
        hichartsVisuble();

        const url = `${base_url}${props.SeachInfo.imoNo}?Timestamp_from=${props.SeachInfo.from}&Timestamp_to=${props.SeachInfo.to}&Unit=${tmp_unit}`     
        getNoonReport(url, jwt);
      }

    }
  }, [props.SeachInfo.from, props.SeachInfo.to]);

  // Unit変更
  React.useEffect(() => {

    if (props.SeachInfo.imoNo && props.unit) {
      // Loading表示
      setLoading(true);
      setSuccess(false);

      // グラフの表示/非表示状態をリセット     
      setCIIScoreFlag(true);
      setCIIMorWFlag(true);
      setFOCFlag(true);
      setCIILastYearFlag(true);
      hichartsVisuble();

      const url = `${base_url}${props.SeachInfo.imoNo}?Timestamp_from=${props.SeachInfo.from}&Timestamp_to=${props.SeachInfo.to}&Unit=${props.unit}`     
      getNoonReport(url, jwt);
    }
  }, [props.unit]);

  // 検索フォーム----------------------------------------------------------------------------------------------------------------------------
  const [base_url, setBase_url] = React.useState(`${process.env.REACT_APP_API_URL}/EmissionBoard/`);

  // DataSet
  const [LOGSpeedRate, setLOGSpeedRate] = React.useState<Array<any>>();
  const [LOGSpeedRateUnit, setLOGSpeedRateUnit] = React.useState<Array<any>>();
  const [LOGSpeedRate_Accumulation, setLOGSpeedRate_Accumulation] = React.useState<Array<any>>();
  const [LOGSpeedRateUnit_Accumulation, setLOGSpeedRateUnit_Accumulation] = React.useState<Array<any>>();
  const [LOGSpeedRateNone, setLOGSpeedRateNone] = React.useState<any>({ name: "", top: "", left: "", yname: "", ytop: "", yleft: "" });
  const [MELoadRate, setMELoadRate] = React.useState<Array<any>>();
  const [MELoadRateUnit, setMELoadRateUnit] = React.useState<Array<any>>();
  const [MELoadRate_Accumulation, setMELoadRate_Accumulation] = React.useState<Array<any>>();
  const [MELoadRateUnit_Accumulation, setMELoadRateUnit_Accumulation] = React.useState<Array<any>>();
  const [MELoadRateNone, setMELoadRateNone] = React.useState<any>({ name: "", top: "", left: "", yname: "", ytop: "", yleft: "" });

  // const [Displacement_Categories, setDisplacement_Categories] = React.useState<Array<any>>();
  const [DisplacementRate, setDisplacementRate] = React.useState<Array<any>>();
  const [DisplacementRateUnit, setDisplacementRateUnit] = React.useState<Array<any>>();
  const [DisplacementRate_Accumulation, setDisplacementRate_Accumulation] = React.useState<Array<any>>();
  const [DisplacementRateUnit_Accumulation, setDisplacementRateUnit_Accumulation] = React.useState<Array<any>>();

  const [BeaufortRate, setBeaufortRate] = React.useState<Array<any>>();
  const [BeaufortRateUnit, setBeaufortRateUnit] = React.useState<Array<any>>();
  const [BeaufortRate_Accumulation, setBeaufortRate_Accumulation] = React.useState<Array<any>>();
  const [BeaufortRateUnit_Accumulation, setBeaufortRateUnit_Accumulation] = React.useState<Array<any>>();
  const [BeaufortRateNone, setBeaufortRateNone] = React.useState<any>({ name: "", top: "", left: "", yname: "", ytop: "", yleft: "" });

  // VoyageInformation
  const voyageInfoInit = {
    "From": null,
    "To": null,
    "CII_Score": null,
    "CII_value": null,
    "Total_FOC": null,
    "ME_FOC": null,
    "Total_Distance": null,
    "Total_CO2_Emissions": null,
    "Avg_Wind_Speed": null,
    "Avg_LOG_Speed": null,
    "Avg_LOAD": null,
    "Avg_RPM": null,
    "Avg_Displacement": null,
    "Avg_Beaufort": null,
  }
  const [VoyageInformationUnitShow, setVoyageInformationUnitShow] = React.useState<any | null>(voyageInfoInit);
  // const [VoyageInformationShow, setVoyageInformationShow] = React.useState<any | null>(voyageInfoInit);
  const [VoyageInformation, setVoyageInformation] = React.useState<any | null>();
  const [VoyageInformationUnit, setVoyageInformationUnit] = React.useState<any | null>();

  // Graph----------------------------------------------------------------------------------------------------------------------------
  // FOCCII--------------------------------------------------------------------
  const [FOC, setFOC] = React.useState<Array<Array<any>>>();
  const [FOC_YAXIS, setFOC_YAXIS] = React.useState({ tickInterval: 400, max: 2000 });
  const [CIIRATING, setCIIRATING] = React.useState<Array<any>>();
  const [CIILASTYEAR, setCIILASTYEAR] = React.useState<any>();
  const [CII, setCII] = React.useState<Array<Array<any>>>();
  const [CIISCORE, setCIISCORE] = React.useState<Array<Array<any>>>();
  const [CIIAll, setCIIAll] = React.useState<Array<Array<any>>>();
  const [CIIAllSCORE, setCIIAllSCORE] = React.useState<Array<Array<any>>>();
  const [CIISCOREShowColor, setCIISCOREShowColor] = React.useState<any>({ color: ["rgba(255, 255, 255, 0.05)", "rgba(255, 255, 255, 0.05)", "rgba(255, 255, 255, 0.05)", "rgba(255, 255, 255, 0.05)", "rgba(255, 255, 255, 0.05)"] });
  const [CIINone, setCIINone] = React.useState<any>({ name: "", top: "", left: "" });

  // yAxis
  const [FOCyAxisMin, setFOCyAxisMin] = React.useState<any>(0);
  const [FOCyAxisMax, setFOCyAxisMax] = React.useState<any>(2000);
  const [FOCyAxisMinShow, setFOCyAxisMinShow] = React.useState<any>(0);
  const [FOCyAxisMaxShow, setFOCyAxisMaxShow] = React.useState<any>(2000);
  // const [tickInterval, setFOCtickInterval] = React.useState<any>(400);
  const [openDialogyAxis, setOpenDialogAxis] = React.useState(false);
  const handleClickOpenDialogAxis = () => {
    setOpenDialogAxis(true);
  };
  const handleNoClickOpenDialogAxis = () => {
    setFOCyAxisMinShow(FOCyAxisMin)
    setFOCyAxisMaxShow(FOCyAxisMax)
    setOpenDialogAxis(false);
  };
  const handleClickSetAxis = () => {
    if (FOCyAxisMin >= FOCyAxisMax) {
      alert("Max must be greater than Min.")
    }
    else if (FOCyAxisMin === "" || FOCyAxisMax === "" || FOCyAxisMin === undefined || FOCyAxisMax === undefined) {
      alert("No spaces allowed. Please enter a value.")
    }
    else {
      setFOCyAxisMin(FOCyAxisMinShow)
      setFOCyAxisMax(FOCyAxisMaxShow)
      setOpenDialogAxis(false);
      setKey(key + 1);
    }
  };
  const handleClickFOCyAxisMinShow = (event) => {
    if (/^\d*$/.test(event)) { setFOCyAxisMinShow(event); }
    else { alert("Please enter numbers in half-width characters.") }
  }
  const handleClickFOCyAxisMaxShow = (event) => {
    if (/^\d*$/.test(event)) { setFOCyAxisMaxShow(event); }
    else { alert("Please enter numbers in half-width characters.") }
  }
  React.useEffect(() => {
    const newHcOptions = { ...hcOptions };
    newHcOptions.yAxis[1].min = parseFloat(FOCyAxisMin)
    newHcOptions.yAxis[1].max = parseFloat(FOCyAxisMax)
    setHcOptions(newHcOptions)
  }, [FOCyAxisMin, FOCyAxisMax])
  // FOCCIIOptions--------------------------------------------------------------------
  const FOCCIIOptions: Highcharts.Options = {
    navigation: {
      buttonOptions: {
        enabled: false,
      }
    },
    credits: {
      enabled: false
    },
    chart: {
      marginTop: window.innerHeight * 0.06,
      height: window.innerHeight * 0.52,
      zooming: { type: "xy" },
      alignTicks: false,
      backgroundColor: 'rgba(0, 0, 0, 0)', // 背景色を透明にする
      // plotBackgroundColor: {
      //   linearGradient: { x1: 0, x2: 0, y1: 1, y2: 0 },
      //   stops: [
      //     [0, 'rgba(255, 255, 255, 0.03)'],
      //     [0.8529, 'rgba(255, 255, 255, 0.13)'],
      //     [1, 'rgba(255, 255, 255, 0.15)']
      //   ]
      // },
      events: {
        click: function (event) {
          onClickGraphReSet(event)
        },
      }
    },
    legend: {
      enabled: false,
    },
    title: {
      text: '時系列の折れ線グラフと棒グラフの組み合わせ',
      style: {
        color: 'transparent'
      }
    },
    plotOptions: {
      column: {
        grouping: true,
        // groupPadding: 5,
      },
      series: {
        cursor: 'pointer',
        point: {
          events: {
            click: (event) => {
              onClickGraph(event);
            }
          }
        },
      },
    },
    xAxis: [
      {
        title: {
          text: "",
          style: {
            color: 'white'
          }
        },
        type: 'datetime',
        labels: {
          enabled: true,
          style: {
            color: 'rgba(255, 255, 255, 0.70)',
            fontFamily: 'Roboto Condensed',
            fontSize: '13px'
          }
        },
        gridLineWidth: 0
      },
    ],
    yAxis: [
      { // Primary yAxis
        tickInterval: 0.5,
        min: 0,
        max: 1.5,
        title: {
          text: '',
        },
        gridLineWidth: 0,
        gridLineDashStyle: 'Dot',
      },
      { // Secondary yAxis
        tickInterval: 400,
        min: 0,
        max: 2000,
        opposite: true,
        gridLineWidth: 0,
        gridLineDashStyle: 'Dot',
        title: {
          text: 'Fuel Oil Consumption (MT)',
          // align: 'low',
          rotation: 90,
          x: 5,
          // y: -50,
          style: {
            color: 'rgba(255, 255, 255, 0.70)',
            opacity: 0.70,
            fontSize: "13px",
            fontWeight: '400',
            lineHeight: "24px",
            wordWrap: 'break-word'
          },
        },
        labels: {
          // align: 'left',
          x: 10,
          y: 0,
          enabled: true,
          style: {
            color: 'rgba(255, 255, 255, 0.70)',
            opacity: 0.70,
            fontSize: "13px",
            fontWeight: '400',
            lineHeight: "24px",
            wordWrap: 'break-word'
          },
          formatter: function (this: Highcharts.AxisLabelsFormatterContextObject) {
            const test = this.value.valueOf().toString()
            return test;
          }
        },
      },
      { // Third yAxis{
        opposite: false,
        tickInterval: 0.5,
        min: 0,
        max: 1.5,
        gridLineWidth: 0,
        gridLineDashStyle: 'Dot',
        title: {
          text: 'CII Score',
          align: 'low',
          rotation: -90,
          x: 0,
          y: -150,
          style: {
            color: 'rgba(255, 255, 255, 0.70)',
            opacity: 0.70,
            fontSize: "13px",
            fontWeight: '400',
            lineHeight: "24px",
            wordWrap: 'break-word'
          },
        },
        labels: {
          x: 20,
          y: 0,
          enabled: true,
          style: {
            color: 'rgba(255, 255, 255, 0.70)',
            opacity: 0.70,
            fontSize: "13px",
            fontWeight: '400',
            lineHeight: "24px",
            wordWrap: 'break-word'
          },
        },
        plotBands: [{}],
        plotLines: [{
          value: 1,
          width: 0,
          zIndex: 3,
          color: 'rgba(194, 247, 255, 1)',
          id: 'myPlotLine' // PlotLineにIDを指定
        }],
      }
    ],
    series: [
      {
        type: 'column',
        name: "CII Score(Monthly)",
        visible: true,
        yAxis: 0,
        data: CII,
        borderWidth: 0,
        // pointWidth: 20,
        color: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, 'rgba(221, 135, 57, 1)'],
            [1, 'rgba(225, 149, 80, 1)']
          ]
        },
      },
      {
        type: 'column',
        name: "Fuel Oil Consumption",
        visible: true,
        yAxis: 1,
        data: FOC,
        borderWidth: 0,
        // pointWidth: 20,
        color: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, 'rgb(18, 87, 151, 1)'],
            [1, 'rgb(19, 101, 178, 1)']
          ]
        },
        // pointPlacement: 1
      },
      {
        type: 'line',
        name: "CII Score",
        color: '#FFBF83',
        visible: true,
        yAxis: 2,
        data: CIIAll,
        lineWidth: 0.5,
        marker: {
          radius: 8,
          fillColor: {
            radialGradient: { cx: 0.5, cy: 0.5, r: 0.5 },
            stops: [
              [0.14, '#FFE6CE'],
              [0.86, '#FF800C']
            ]
          }
        },
        // pointPlacement: 'on'
        stickyTracking: false
      },
    ]
  };
  const chartComponentRef = React.useRef(null);
  const [hcOptions, setHcOptions] = React.useState<any>(FOCCIIOptions);

  // logSpeedOptions--------------------------------------------------------------------
  const logSpeedOptions: Highcharts.Options = {
    navigation: {
      buttonOptions: {
        enabled: false,
      }
    },
    credits: {
      enabled: false
    },
    chart: {
      height: 200,
      alignTicks: false,
      backgroundColor: 'rgba(0, 0, 0, 0)', // 背景色を透明にする
      // plotBackgroundColor: {
      //   linearGradient: { x1: 0, x2: 0, y1: 1, y2: 0 },
      //   stops: [
      //     [0, 'rgba(255, 255, 255, 0.03)'],
      //     [0.8529, 'rgba(255, 255, 255, 0.13)'],
      //     [1, 'rgba(255, 255, 255, 0.15)']
      //   ]
      // },
    },
    title: {
      text: ''
    },
    subtitle: {
      text: ''
    },
    xAxis: {
      crosshair: true,
      // title: {
      //   text: '(knot)',
      //   align: 'low',
      //   // rotation: -90,
      //   x: 290,
      //   y: -10,
      //   style: {
      //     color: 'rgba(255, 255, 255, 0.70)',
      //     opacity: 0.70,
      //     fontSize: "13px",
      //     fontWeight: '400',
      //     lineHeight: "24px",
      //     wordWrap: 'break-word'
      //   },
      // },
      labels: {
        step: 2, // 2つのラベルごとに2つ表示
        rotation: 0, // X軸ラベルを水平に表示
        enabled: true,
        style: {
          color: 'rgba(255, 255, 255, 0.70)',
          opacity: 0.70,
          fontSize: "13px",
          fontWeight: '400',
          lineHeight: "24px",
          wordWrap: 'break-word'
        },
      }
    },
    yAxis: {
      title: {
        text: ''
      },
      labels: {
        enabled: true,
        style: {
          color: 'rgba(255, 255, 255, 0.70)',
          opacity: 0.70,
          fontSize: "13px",
          fontWeight: '400',
          lineHeight: "24px",
          wordWrap: 'break-word'
        }
      },
      opposite: false,
      gridLineWidth: 0.5,
      gridLineDashStyle: 'Dot',
    },
    // tooltip: {
    //   enabled: true,
    //   headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    //   pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
    //     '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
    //   shared: true,
    //   useHTML: true
    // },
    tooltip: {
      enabled: true,
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      style: {
        color: '#000000', // 文字色
        fontSize: '14px', // フォントサイズ
        fontFamily: 'Roboto' // フォントファミリー
      },
      formatter: function () {
        if (this.series.name === 'partial') {
          return `${this.y?.toFixed(1)} %`;
        } else if (this.series.name === 'total') {
          return `${this.y?.toFixed(1)} %`;
        }
      }
    },
    plotOptions: {
      column: {
        pointPadding: 0,
        borderWidth: 0.1,
        groupPadding: 0,
        shadow: false,
        minPointLength: 0,
      },
      line: {
        lineWidth: 1
      },
    },
    legend: {
      enabled: false,
    },
    series: [{
        type: 'column',
        data: LOGSpeedRate,
        name: 'partial'
      },
      {
        type: 'line',
        data: LOGSpeedRate_Accumulation,
        name: 'total',
        color: "rgba(176, 176, 37, 1)",
        marker: {
          enabled: false
        },
        stickyTracking: false
    }]
  };

  // MELoadOptions--------------------------------------------------------------------
  const MELoadOptions: Highcharts.Options = {
    navigation: {
      buttonOptions: {
        enabled: false,
      }
    },
    credits: {
      enabled: false
    },
    chart: {
      height: 200,
      alignTicks: false,
      backgroundColor: 'rgba(0, 0, 0, 0)', // 背景色を透明にする
      // plotBackgroundColor: {
      //   linearGradient: { x1: 0, x2: 0, y1: 1, y2: 0 },
      //   stops: [
      //     [0, 'rgba(255, 255, 255, 0.03)'],
      //     [0.8529, 'rgba(255, 255, 255, 0.13)'],
      //     [1, 'rgba(255, 255, 255, 0.15)']
      //   ]
      // },
    },
    title: {
      text: ''
    },
    subtitle: {
      text: ''
    },
    xAxis: {
      crosshair: true,
      labels: {
        step: 2, // 2つのラベルごとに2つ表示
        rotation: 0, // X軸ラベルを水平に表示
        enabled: true,
        style: {
          color: 'rgba(255, 255, 255, 0.70)',
          opacity: 0.70,
          fontSize: "13px",
          fontWeight: '400',
          lineHeight: "24px",
          wordWrap: 'break-word'
        },
      }
    },
    yAxis: {
      title: {
        text: ''
      },
      labels: {
        enabled: true,
        style: {
          color: 'rgba(255, 255, 255, 0.70)',
          opacity: 0.70,
          fontSize: "13px",
          fontWeight: '400',
          lineHeight: "24px",
          wordWrap: 'break-word'
        }
      },
      opposite: false,
      gridLineWidth: 0.5,
      gridLineDashStyle: 'Dot',
    },
    // tooltip: {
    //   enabled: false,
    //   headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    //   pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
    //     '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
    //   footerFormat: '</table>',
    //   shared: true,
    //   useHTML: true
    // },
    tooltip: {
      enabled: true,
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      style: {
        color: '#000000', // 文字色
        fontSize: '14px', // フォントサイズ
        fontFamily: 'Roboto' // フォントファミリー
      },
      formatter: function () {
        if (this.series.name === 'partial') {
          return `${this.y?.toFixed(1)} %`;
        } else if (this.series.name === 'total') {
          return `${this.y?.toFixed(1)} %`;
        }
      }
    },
    plotOptions: {
      column: {
        pointPadding: 0,
        borderWidth: 0.1,
        groupPadding: 0,
        shadow: false,
        minPointLength: 0,
      },
      line: {
        lineWidth: 1
      },
    },
    legend: {
      enabled: false,
    },
    series: [{
      type: 'column',
      data: MELoadRate,
      name: 'partial'
    },
    {
      type: 'line',
      data: MELoadRate_Accumulation,
      name: 'total',
      color: "rgba(166, 65, 206, 1)",
      marker: {
        enabled: false
      },
      stickyTracking: false
    }]
  };

  
  const [hcOptionsLogSpeed, setHcOptionsLogSpeed] = React.useState<any>(logSpeedOptions);
  const [hcOptionsMELoad, setHcOptionsMELoad] = React.useState<any>(MELoadOptions);

  const BeaufortOptions: Highcharts.Options = {
    navigation: {
      buttonOptions: {
        enabled: false,
      }
    },
    credits: {
      enabled: false
    },
    chart: {
      height: 200,
      alignTicks: false,
      backgroundColor: 'rgba(0, 0, 0, 0)',
      // plotBackgroundColor: {
      //   linearGradient: { x1: 0, x2: 0, y1: 1, y2: 0 },
      //   stops: [
      //     [0, 'rgba(255, 255, 255, 0.03)'],
      //     [0.8529, 'rgba(255, 255, 255, 0.13)'],
      //     [1, 'rgba(255, 255, 255, 0.15)']
      //   ]
      // },
    },
    title: {
      text: ''
    },
    subtitle: {
      text: ''
    },
    xAxis: {
      crosshair: true,
      labels: {
        step: 1, // 1つのラベルごとに1つ表示
        rotation: 0, // X軸ラベルを水平に表示
        enabled: true,
        style: {
          color: 'rgba(255, 255, 255, 0.70)',
          opacity: 0.70,
          fontSize: "13px",
          fontWeight: '400',
          lineHeight: "24px",
          wordWrap: 'break-word'
        },
      }
    },
    yAxis: {
      title: {
        text: ''
      },
      labels: {
        enabled: true,
        style: {
          color: 'rgba(255, 255, 255, 0.70)',
          opacity: 0.70,
          fontSize: "13px",
          fontWeight: '400',
          lineHeight: "24px",
          wordWrap: 'break-word'
        }
      },
      opposite: false,
      gridLineWidth: 0.5,
      gridLineDashStyle: 'Dot',
    },
    // tooltip: {
    //   enabled: false,
    //   headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    //   pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
    //     '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
    //   footerFormat: '</table>',
    //   shared: true,
    //   useHTML: true
    // },
    tooltip: {
      enabled: true,
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      style: {
        color: '#000000', // 文字色
        fontSize: '14px', // フォントサイズ
        fontFamily: 'Roboto' // フォントファミリー
      },
      formatter: function () {
        if (this.series.name === 'partial') {
          return `${this.y?.toFixed(1)} %`;
        } else if (this.series.name === 'total') {
          return `${this.y?.toFixed(1)} %`;
        }
      }
    },
    plotOptions: {
      column: {
        pointPadding: 0,
        borderWidth: 0.1,
        groupPadding: 0,
        shadow: false,
        minPointLength: 0,
      },
      line: {
        lineWidth: 1
      },
    },
    legend: {
      enabled: false,
    },
    series: [{
      type: 'column',
      name: 'partial',
      data: BeaufortRate,
    },
    {
      type: 'line',
      data: BeaufortRate_Accumulation,
      name: 'total',
      color: "rgba(47, 228, 196, 1)",
      marker: {
        enabled: false
      },
      stickyTracking: false
    }]
  };
  const [hcOptionsBeaufort, setHcOptionsBeaufort] = React.useState<any>(BeaufortOptions);

  const DisplacementOptions = {
    navigation: {
      buttonOptions: {
        enabled: false,
      }
    },
    credits: {
      enabled: false
    },
    chart: {
      type: 'pie',
      marginTop: 5,
      height: 200,
      alignTicks: false,
      backgroundColor: 'rgba(0, 0, 0, 0)',
      // plotBackgroundColor: {
      //   linearGradient: { x1: 0, x2: 0, y1: 1, y2: 0 },
      //   stops: [
      //     [0, 'rgba(255, 255, 255, 0.03)'],
      //     [0.8529, 'rgba(255, 255, 255, 0.13)'],
      //     [1, 'rgba(255, 255, 255, 0.15)']
      //   ]
      // },
      borderWidth: 0
    },
    title: {
      text: ''
    },
    subtitle: {
      text: ''
    },
    tooltip: {
      enabled: false,
      headerFormat: '<span style="font-size:10px">Displacement</span><table>',
      pointFormat: '<tr>'
        + '<p><td style="color:{series.color};padding:0">DataRange: </td>'
        + '<td style="padding:0"><b>{point.x:.0f} MT</br></td></p>'
        + '</tr>'
        + '<tr>'
        + '<p><td style="color:{series.color};padding:0">Rate: </td>'
        + '<td style="padding:0"><b>{point.y:.1f} %</br></td></p>'
        + '</tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true
    },
    plotOptions: {
      pie: {
        size: '100%',
        innerSize: '70%',
        borderRadius: "0%",
        borderWidth: 0,
        colorByPoint: true,
        dataLabels: {
          enabled: false
        },
        point: {
          events: {}
        }
      }
    },
    series: [{
      name: '',
      data: []
    }],
  };
  const [hcOptionsDisplacement, setHcOptionsDisplacement] = React.useState<any>(DisplacementOptions)
  // const DisplacementchartComponentRef = React.useRef(null);
  const DisplacementchartComponentRef = React.useRef<HighchartsReact.RefObject>(null);


  const handleClickSearch = () => {
    setLoading(true)
    setSuccess(false)
    setCIIScoreFlag(true)
    setCIIMorWFlag(true)
    setFOCFlag(true)
    setCIILastYearFlag(true)
    hichartsVisuble()
    getNoonReport(`${base_url}${props.SeachInfo.imoNo}?Timestamp_from=${props.SeachInfo.from}&Timestamp_to=${props.SeachInfo.to}&Unit=${props.unit}`, jwt);
  };
  // Loading--------------------------------------------------------------------
  const [loading, setLoading] = React.useState(true);
  const [success, setSuccess] = React.useState(false);

  // Seach実行
  const getNoonReport = (url, jwt) => {

    if (jwt !== undefined) {
      const options = {
        headers: {
          'Authorization': jwt,
        }
      }
      console.log("URL", url);
      axios.get(url, options).then((res) => {

        console.log("axios", res);

        // 認可チェック
        if (res.data === 401) {
          props.setAuthCheck(true)
          props.setAuthMessage("不正な操作が行われた可能性があります。")
        } else if (res.data === 500) {
          props.setAuthCheck(true)
          props.setAuthMessage("JWTが改ざんされている可能性があります。")
        } else {
          props.setAuthCheck(false)
        }

        // LOG Speed Rate
        setLOGSpeedRate(res.data.datas.LOGSpeedRate)
        setLOGSpeedRateUnit(res.data.datas.LOGSpeedRateUnit)
        setLOGSpeedRate_Accumulation(res.data.datas.LOGSpeedRate_Accumulation)
        setLOGSpeedRateUnit_Accumulation(res.data.datas.LOGSpeedRateUnit_Accumulation)

        // ME Load Rate
        setMELoadRate(res.data.datas.MELoadRate)
        setMELoadRateUnit(res.data.datas.MELoadRateUnit)
        setMELoadRate_Accumulation(res.data.datas.MELoadRate_Accumulation)
        setMELoadRateUnit_Accumulation(res.data.datas.MELoadRateUnit_Accumulation)

        // Displacement Rate
        // setDisplacement_Categories(res.data.datas.Displacement_Categories)
        setDisplacementRate(res.data.datas.DisplacementRate)
        setDisplacementRateUnit(res.data.datas.DisplacementRateUnit)
        setDisplacementRate_Accumulation(res.data.datas.DisplacementRate_Accumulation)
        setDisplacementRateUnit_Accumulation(res.data.datas.DisplacementRateUnit_Accumulation)

        // Beaufort Rate
        setBeaufortRate(res.data.datas.BeaufortRate)
        setBeaufortRateUnit(res.data.datas.BeaufortRateUnit)
        setBeaufortRate_Accumulation(res.data.datas.BeaufortRate_Accumulation)
        setBeaufortRateUnit_Accumulation(res.data.datas.BeaufortRateUnit_Accumulation)

        // FOC
        setFOC(res.data.datas.FOC)
        setFOC_YAXIS(res.data.datas.FOC_YAXIS)
        setFOCyAxisMin(0)
        setFOCyAxisMax(res.data.datas.FOC_YAXIS.max)
        setFOCyAxisMaxShow(0)
        setFOCyAxisMaxShow(res.data.datas.FOC_YAXIS.max)

        // CII
        setCIIRATING(res.data.datas.CIIRATING)
        setCIILASTYEAR(res.data.datas.CIILASTYEAR)
        setCII(res.data.datas.CII)
        setCIISCORE(res.data.datas.CIISCORE)
        setCIIAll(res.data.datas.CIIAll)
        setCIIAllSCORE(res.data.datas.CIIAllSCORE)

        // Voyage Information
        setVoyageInformation(res.data.datas.VoyageInformation)
        setVoyageInformationUnit(res.data.datas.VoyageInformationUnit)

        // VesselMaster
        props.setVesselMaster(res.data.datas.VESSELMASTER)

        // クリックインデックスをリセット
        // setClickItemGraph({ index: 0, x: null, y: null })
        // setClickGraphReSetOn(true)

        // Loading解除
        setLoading(false)
        setSuccess(true)
      })
        .catch((e: AxiosError<ErrorData>) => {
          reset_all()
          setLoading(false)
          setSuccess(true)
          if (e.response === undefined) {
            console.log("undefinedError: " + e);
            return;
          }
          console.log(e + ", message: " + e.response.data.message)
        });
    }
  }

  const reset_all = () => {
    // LOG Speed Rate
    setLOGSpeedRate([])
    setLOGSpeedRateUnit([])
    setLOGSpeedRate_Accumulation([])
    setLOGSpeedRateUnit_Accumulation([])

    // Displacement Rate
    // setDisplacement_Categories([])
    setDisplacementRate([])
    setDisplacementRateUnit([])
    setDisplacementRate_Accumulation([])
    setDisplacementRateUnit_Accumulation([])

    // Beaufort Rate
    setBeaufortRate([])
    setBeaufortRateUnit([])
    setBeaufortRate_Accumulation([])
    setBeaufortRateUnit_Accumulation([])

    // FOC
    setFOC([])
    setFOC_YAXIS({ tickInterval: 400, max: 2000 })

    // CII
    setCIIRATING([])
    setCIILASTYEAR("")
    setCII([])
    setCIISCORE([])
    setCIIAll([])
    setCIIAllSCORE([])

    // Voyage Information
    setVoyageInformation([])
    setVoyageInformationUnit([])

    // VesselMaster
    props.setVesselMaster("")

  }

  // APIから取得データHcOptionsを更新
  React.useEffect(() => {
    if (LOGSpeedRate !== undefined && LOGSpeedRate_Accumulation !== undefined
      && DisplacementRate !== undefined && DisplacementRate_Accumulation !== undefined
      && BeaufortRate !== undefined && BeaufortRate_Accumulation !== undefined
      && MELoadRate !== undefined && MELoadRate_Accumulation !== undefined
      && CII !== undefined && CIISCORE !== undefined && CIIRATING !== undefined && CIILASTYEAR !== undefined
      && CIIAll !== undefined && CIIAllSCORE !== undefined
      && FOC !== undefined && FOC_YAXIS !== undefined
    ) {

      // FOC&CII
      const newHcOptions = { ...hcOptions };

      // CII(Unit)
      newHcOptions.series[0].data = JSON.parse(JSON.stringify(CII))

      // FOC
      newHcOptions.series[1].data = JSON.parse(JSON.stringify(FOC))
      newHcOptions.yAxis[1].max = FOC_YAXIS.max
      newHcOptions.yAxis[1].tickInterval = FOC_YAXIS.tickInterval

      // CII(Total)
      newHcOptions.series[2].data = JSON.parse(JSON.stringify(CIIAll))
      newHcOptions.yAxis[2].plotBands = [
        {
          from: 0,
          to: CIIRATING[0],
          color: 'rgba(0, 144, 206, 0.2)'
        }, {
          from: CIIRATING[0],
          to: CIIRATING[1],
          color: 'rgba(6, 165, 80, 0.3)'
        }, {
          from: CIIRATING[1],
          to: CIIRATING[2],
          color: 'rgba(212, 195, 38, 0.3)'
        }, {
          from: CIIRATING[2],
          to: CIIRATING[3],
          color: 'rgba(255, 103, 103, 0.3)'
        }, {
          from: CIIRATING[3],
          to: 1.5,
          color: 'rgba(255, 47, 47, 0.3)'
        }
      ]

      if (CIILASTYEAR !== "" && CIILASTYEAR !== "hidden") {
        newHcOptions.chart.events = {
          click: function (event) {
            onClickGraphReSet(event)
          },
          render: function () {
            var yAxis = this.yAxis[2];

            // 既存のPlotLineを削除
            yAxis.removePlotLine('myPlotLine');

            // プロットライン追加
            yAxis.addPlotLine({
              value: CIILASTYEAR,
              width: 2,
              zIndex: 3,
              color: 'rgba(194, 247, 255, 1)',
              dashStyle: '5, 5, 5, 5',
              id: 'myPlotLine' // PlotLineにIDを指定
            });
          }
        }
        setCIINone({ name: "", top: "", left: "" })
      } else {
        // プロットライン削除
        newHcOptions.chart.events = {
          click: function (event) {
            onClickGraphReSet(event)
          },
          render: function () {
            var yAxis = this.yAxis[2];

            // 既存のPlotLineを削除
            yAxis.removePlotLine('myPlotLine');
          }
        }
        if(CIILASTYEAR === ""){
          setCIINone({ name: "No Data", top: "220", left: "500" })
        }
      }

      newHcOptions.tooltip = {
        enabled: true,
        useHTML: true,
        formatter: function () {

          let headerPrefixStr = ""; // ヘッダー文字列

          if (props.unit === 'Monthly') {
            headerPrefixStr = Highcharts.dateFormat("%B\' %Y", Number(this.x));
          }
          else {
            headerPrefixStr = Highcharts.dateFormat("%e %B", Number(this.x));
          }
  
          let formattedY = ""; // point.yを整形
          let seriesColor = ""; // ヘッダーに表示する点の色を設定
  
          if (this.series.name === 'CII Score(Monthly)') {
            formattedY = (this.y ?? 0).toFixed(2);
            seriesColor = "#FF5800";
          } 
          else if (this.series.name === 'Fuel Oil Consumption') {
            formattedY = formatNumber((this.y ?? 0).toFixed(1));
            seriesColor = "blue";
          }
          else if (this.series.name === 'CII Score') {
            formattedY = (this.y ?? 0).toFixed(2);
            seriesColor = "#FF5800";
          }
  
          return `<span style="font-size:10px">
                    ${headerPrefixStr}
                  </span>
                  <table>
                    <tr>
                      <td style="padding:0">
                        <span style="display:inline-block;width:6px;height:6px;border-radius:50%;background-color:${seriesColor};margin-right:0px;"></span>
                        ${this.series.name}: 
                      </td>
                      <td style="padding:0">
                        &nbsp<b>${formattedY}</b>
                      </td>
                    </tr>`;
        }
      }

      setHcOptions(newHcOptions)

      // LOGSpeedRate
      const newHcOptionsLogSpeed = { ...hcOptionsLogSpeed };
      if (LOGSpeedRate.length) {
        newHcOptionsLogSpeed.xAxis.categories = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '', '(knot)']
        newHcOptionsLogSpeed.yAxis.min = 0
        newHcOptionsLogSpeed.yAxis.max = 100
        newHcOptionsLogSpeed.yAxis.tickInterval = 25
        newHcOptionsLogSpeed.series[0].data = JSON.parse(JSON.stringify(LOGSpeedRate[LOGSpeedRate.length - 1]))
        newHcOptionsLogSpeed.series[1].data = JSON.parse(JSON.stringify(LOGSpeedRate_Accumulation[LOGSpeedRate_Accumulation.length - 1]))
        setLOGSpeedRateNone({ xname: "", xtop: "", xleft: "", yname: "(%)", ytop: "0", yleft: "8" })
      } else {
        newHcOptionsLogSpeed.xAxis.categories = []
        newHcOptionsLogSpeed.yAxis.min = ""
        newHcOptionsLogSpeed.yAxis.max = ""
        newHcOptionsLogSpeed.yAxis.tickInterval = ""
        newHcOptionsLogSpeed.series[0].data = []
        newHcOptionsLogSpeed.series[1].data = []
        setLOGSpeedRateNone({ xname: "No Data", xtop: "110", xleft: "140", yname: "", ytop: "", yleft: "" })
      }
      setHcOptionsLogSpeed(newHcOptionsLogSpeed)

      // MELoadRate
      const newHcOptionsMELoad = { ...hcOptionsMELoad };
      if (MELoadRate.length) {
        newHcOptionsMELoad.xAxis.categories = ['0', '5', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90', '95', '100', '', '(%)']
        newHcOptionsMELoad.yAxis.min = 0
        newHcOptionsMELoad.yAxis.max = 100
        newHcOptionsMELoad.yAxis.tickInterval = 25
        newHcOptionsMELoad.series[0].data = JSON.parse(JSON.stringify(MELoadRate[MELoadRate.length - 1]))
        newHcOptionsMELoad.series[1].data = JSON.parse(JSON.stringify(MELoadRate_Accumulation[MELoadRate_Accumulation.length - 1]))
        setMELoadRateNone({ xname: "", xtop: "", xleft: "", yname: "(%)", ytop: "0", yleft: "8" })
      } else {
        newHcOptionsMELoad.xAxis.categories = []
        newHcOptionsMELoad.yAxis.min = ""
        newHcOptionsMELoad.yAxis.max = ""
        newHcOptionsMELoad.yAxis.tickInterval = ""
        newHcOptionsMELoad.series[0].data = []
        newHcOptionsMELoad.series[1].data = []
        setMELoadRateNone({ xname: "No Data", xtop: "110", xleft: "140", yname: "", ytop: "", yleft: "" })
      }
      setHcOptionsMELoad(newHcOptionsMELoad)

      // Beaufort
      const newHcOptionsBeaufort = { ...hcOptionsBeaufort };
      if (BeaufortRate.length) {
        newHcOptionsBeaufort.xAxis.categories = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '', '(BF)']
        newHcOptionsBeaufort.yAxis.min = 0
        newHcOptionsBeaufort.yAxis.max = 100
        newHcOptionsBeaufort.yAxis.tickInterval = 25
        newHcOptionsBeaufort.series[0].data = JSON.parse(JSON.stringify(BeaufortRate[BeaufortRate.length - 1]))
        newHcOptionsBeaufort.series[1].data = JSON.parse(JSON.stringify(BeaufortRate_Accumulation[BeaufortRate_Accumulation.length - 1]))
        setBeaufortRateNone({ xname: "", xtop: "", xleft: "", yname: "(%)", ytop: "0", yleft: "8" })
      } else {
        newHcOptionsBeaufort.xAxis.categories = []
        newHcOptionsBeaufort.yAxis.min = ""
        newHcOptionsBeaufort.yAxis.max = ""
        newHcOptionsBeaufort.yAxis.tickInterval = ""
        newHcOptionsBeaufort.series[0].data = []
        newHcOptionsBeaufort.series[1].data = []
        setBeaufortRateNone({ xname: "No Data", xtop: "110", xleft: "140", yname: "", ytop: "", yleft: "" })
      }
      setHcOptionsBeaufort(newHcOptionsBeaufort)

      // DisplacementRate
      const newHcOptionsDisplacement = { ...hcOptionsDisplacement };

      if (DisplacementRate.length) {
        newHcOptionsDisplacement.series[0].data = JSON.parse(JSON.stringify(DisplacementRate[DisplacementRate.length - 1]))
        newHcOptionsDisplacement.title = {
          text:
            `<div style="color: rgba(255,255,255,0.6); font-size: 14px; font-weight: lighter; font-family: Roboto Condensed, Arial, sans-serif; margin-top:70px;">Ballast</div>
             <div style="color: rgba(255,255,255,0.6); font-size: 14px; font-weight: lighter; font-family: Roboto Condensed, Arial, sans-serif; text-align: center;"><span style="font-size: 15px; color: white;">${DisplacementRate[DisplacementRate.length - 1][0].y.toFixed(1)}</span>&nbsp;%</div>`,
          useHTML: true,
        }
        newHcOptionsDisplacement.chart.events = {
          load() {
            const chart = this;
            chart.series[0].points.forEach(point => {
              point.onMouseOver = function () {
                console.log("this", this)
                let name
                if (this.index === 0) {
                  name = "Ballast"
                } else if (this.index === 1) {
                  name = "Laden"
                }
                chart.update({
                  title: {
                    text:
                      `<div style="color: rgba(255,255,255,0.6); font-size: 14px; font-weight: lighter; font-family: Roboto Condensed, Arial, sans-serif; margin-top:70px;">${name}</div>
                      <div style="color: rgba(255,255,255,0.6); font-size: 14px; font-weight: lighter; font-family: Roboto Condensed, Arial, sans-serif; text-align: center;"><span style="font-size: 15px; color: white;">${this.y.toFixed(1)}</span>&nbsp;%</div>`,
                    useHTML: true
                  },
                  align: 'center',
                  verticalAlign: 'middle',
                  y: 0 // タイトルの垂直位置を中央に設定
                });
              };
            });
          }
        }
      } else {
        newHcOptionsDisplacement.series[0].data = JSON.parse(JSON.stringify([]))
        newHcOptionsDisplacement.title = {
          text:
            `<div style="color: rgba(255,255,255,0.5); font-size: 13px; font-weight: lighter; font-family: Roboto Condensed; margin-top:60px;">No Data</div>`,
          useHTML: true,
        }
      }
      setHcOptionsDisplacement(newHcOptionsDisplacement)

      // Voyage Information
      let voyageInfo = voyageInfoInit
      setCIISCOREShowColor({ color: ["rgba(255, 255, 255, 0.05)", "rgba(255, 255, 255, 0.05)", "rgba(255, 255, 255, 0.05)", "rgba(255, 255, 255, 0.05)", "rgba(255, 255, 255, 0.05)"] })
      if (VoyageInformation.length) {
        voyageInfo = VoyageInformation[VoyageInformation.length - 1]
        const CII_color = voyageInfo.CII_Score
        if (CII_color === "A") {
          setCIISCOREShowColor({ color: ["rgba(0, 144, 206, 0.4)", "rgba(255, 255, 255, 0.05)", "rgba(255, 255, 255, 0.05)", "rgba(255, 255, 255, 0.05)", "rgba(255, 255, 255, 0.05)"] })
        } else if (CII_color === "B") {
          setCIISCOREShowColor({ color: ["rgba(6, 165, 80, 0.4)", "rgba(6, 165, 80, 0.4)", "rgba(255, 255, 255, 0.05)", "rgba(255, 255, 255, 0.05)", "rgba(255, 255, 255, 0.05)"] })
        } else if (CII_color === "C") {
          setCIISCOREShowColor({ color: ["rgba(212, 195, 38, 0.4)", "rgba(212, 195, 38, 0.4)", "rgba(212, 195, 38, 0.4)", "rgba(255, 255, 255, 0.05)", "rgba(255, 255, 255, 0.05)"] })
        } else if (CII_color === "D") {
          setCIISCOREShowColor({ color: ["rgba(255, 103, 103, 0.45)", "rgba(255, 103, 103, 0.45)", "rgba(255, 103, 103, 0.45)", "rgba(255, 103, 103, 0.45)", "rgba(255, 255, 255, 0.05)"] })
        } else if (CII_color === "E") {
          setCIISCOREShowColor({ color: ["rgba(255, 47, 47, 0.45)", "rgba(255, 47, 47, 0.45)", "rgba(255, 47, 47, 0.45)", "rgba(255, 47, 47, 0.45)", "rgba(255, 47, 47, 0.45)"] })
        }
      }
      setVoyageInformationUnitShow(voyageInfo)
      // setVoyageInformationShow(voyageInfo)
    }
    setKey(key + 1);
  }, [LOGSpeedRate, LOGSpeedRate_Accumulation, DisplacementRate, DisplacementRate_Accumulation, BeaufortRate, BeaufortRate_Accumulation,
    MELoadRate, MELoadRate_Accumulation, CII, CIISCORE, CIIAll, CIIAllSCORE, CIILASTYEAR, FOC, FOC_YAXIS
  ])

  // グラフ上の線をクリックされたら
  const [changeClickGraph, setChangeClickGraph] = React.useState<number>(0);
  const [clickItemGraph, setClickItemGraph] = React.useState({ index: 0, x: null, y: null }); // クリックした点の値

  const onClickGraph = (event: any) => { //グラフ上の点をクリック
    console.log("onClickGraph")
    setChangeClickGraph((prev) => (prev + 1));
    setClickItemGraph({ index: event.point.index, x: event.point.x, y: event.point.y });
  }


  const [key, setKey] = React.useState(0);
  // クリックされた棒グラフの集計値をセット
  React.useEffect(() => {
    if (changeClickGraph > 0) {
      const item = clickItemGraph;
      console.log("item", item)

      if (LOGSpeedRateUnit !== undefined && LOGSpeedRateUnit_Accumulation !== undefined
        && DisplacementRateUnit !== undefined && DisplacementRateUnit_Accumulation !== undefined
        && BeaufortRateUnit !== undefined && BeaufortRateUnit_Accumulation !== undefined
        && MELoadRateUnit !== undefined && MELoadRateUnit_Accumulation !== undefined
        && FOC !== undefined && CII !== undefined && CIIAll !== undefined
      ) {

        // FOC&CII
        const newHcOptions = { ...hcOptions };
        newHcOptions.series[0].data = JSON.parse(JSON.stringify(CII))
        newHcOptions.series[1].data = JSON.parse(JSON.stringify(FOC))
        newHcOptions.series[2].data = JSON.parse(JSON.stringify(CIIAll))

        // クリックされた月の色をセット
        for (let i = 0; i < FOC.length; i++) {
          let FOC_color_a = 'rgb(18, 87, 151, 0.2)'
          let FOC_color_b = 'rgb(19, 101, 178, 0.2)'
          let CII_color_a = 'rgb(221, 135, 57, 0.2)'
          let CII_color_b = 'rgb(225, 149, 80, 0.2)'
          let CIIAll_color_a = 'rgba(255, 191, 131, 0.2)'
          let CIIAll_color_b = 'rgba(255, 133, 20, 0.2)'
          if (i === item.index) {
            FOC_color_a = 'rgb(18, 87, 151, 1)'
            FOC_color_b = 'rgb(19, 101, 178, 1)'
            CII_color_a = 'rgb(221, 135, 57, 1)'
            CII_color_b = 'rgb(225, 149, 80, 1)'
            CIIAll_color_a = 'rgba(255, 191, 131, 1)'
            CIIAll_color_b = 'rgba(255, 133, 20, 1)'
          }
          newHcOptions.series[0].data[i] = JSON.parse(JSON.stringify({
            x: CII[i][0],
            y: CII[i][1],
            color: {
              linearGradient: {
                x1: 0, x2: 0, y1: 0, y2: 1
              },
              stops: [
                [0, CII_color_a],
                [1, CII_color_b]
              ]
            },
          }))
          newHcOptions.series[1].data[i] = JSON.parse(JSON.stringify({
            x: FOC[i][0],
            y: FOC[i][1],
            color: {
              linearGradient: {
                x1: 0, x2: 0, y1: 0, y2: 1
              },
              stops: [
                [0, FOC_color_a],
                [1, FOC_color_b]
              ]
            },
          }))
          newHcOptions.series[2].data[i] = JSON.parse(JSON.stringify({
            x: CIIAll[i][0],
            y: CIIAll[i][1],
            lineWidth: 0.5,
            marker: {
              radius: 8,
              fillColor: {
                radialGradient: { cx: 0.5, cy: 0.5, r: 0.5 },
                stops: [
                  [0, CIIAll_color_a],
                  [1, CIIAll_color_b]
                ]
              }
            },
          }))
        }

        setHcOptions(newHcOptions)

        // LOGSpeedRate
        const newHcOptionsLogSpeed = { ...hcOptionsLogSpeed };
        if (LOGSpeedRateUnit[item.index] !== undefined && LOGSpeedRateUnit[item.index].length) {
          newHcOptionsLogSpeed.xAxis.categories = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '', '(knot)']
          newHcOptionsLogSpeed.yAxis.min = 0
          newHcOptionsLogSpeed.yAxis.max = 100
          newHcOptionsLogSpeed.yAxis.tickInterval = 25
          newHcOptionsLogSpeed.series[0].data = JSON.parse(JSON.stringify(LOGSpeedRateUnit[item.index]))
          newHcOptionsLogSpeed.series[1].data = JSON.parse(JSON.stringify(LOGSpeedRateUnit_Accumulation[item.index]))
          setLOGSpeedRateNone({ xname: "", xtop: "", xleft: "", yname: "(%)", ytop: "0", yleft: "8" })
        } else {
          newHcOptionsLogSpeed.xAxis.categories = []
          newHcOptionsLogSpeed.yAxis.min = ""
          newHcOptionsLogSpeed.yAxis.max = ""
          newHcOptionsLogSpeed.yAxis.tickInterval = ""
          newHcOptionsLogSpeed.series[0].data = []
          newHcOptionsLogSpeed.series[1].data = []
          setLOGSpeedRateNone({ xname: "No Data", xtop: "110", xleft: "140", yname: "", ytop: "", yleft: "" })
        }
        setHcOptionsLogSpeed(newHcOptionsLogSpeed)

        // MELoadRate
        const newHcOptionsMELoad = { ...hcOptionsMELoad };
        if (MELoadRateUnit[item.index] !== undefined && MELoadRateUnit[item.index].length) {
          newHcOptionsMELoad.xAxis.categories = ['0', '5', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90', '95', '100', '', '(%)']
          newHcOptionsMELoad.yAxis.min = 0
          newHcOptionsMELoad.yAxis.max = 100
          newHcOptionsMELoad.yAxis.tickInterval = 25
          newHcOptionsMELoad.series[0].data = JSON.parse(JSON.stringify(MELoadRateUnit[item.index]))
          newHcOptionsMELoad.series[1].data = JSON.parse(JSON.stringify(MELoadRateUnit_Accumulation[item.index]))
          setMELoadRateNone({ xname: "", xtop: "", xleft: "", yname: "(%)", ytop: "0", yleft: "8" })
        } else {
          newHcOptionsMELoad.xAxis.categories = []
          newHcOptionsMELoad.yAxis.min = ""
          newHcOptionsMELoad.yAxis.max = ""
          newHcOptionsMELoad.yAxis.tickInterval = ""
          newHcOptionsMELoad.series[0].data = []
          newHcOptionsMELoad.series[1].data = []
          setMELoadRateNone({ xname: "No Data", xtop: "110", xleft: "140", yname: "", ytop: "", yleft: "" })
        }
        setHcOptionsMELoad(newHcOptionsMELoad)

        // Beaufort
        const newHcOptionsBeaufort = { ...hcOptionsBeaufort };
        if (BeaufortRateUnit[item.index] !== undefined && BeaufortRateUnit[item.index].length) {
          newHcOptionsBeaufort.xAxis.categories = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '', '(BF)']
          newHcOptionsBeaufort.yAxis.min = 0
          newHcOptionsBeaufort.yAxis.max = 100
          newHcOptionsBeaufort.yAxis.tickInterval = 25
          newHcOptionsBeaufort.series[0].data = JSON.parse(JSON.stringify(BeaufortRateUnit[item.index]))
          newHcOptionsBeaufort.series[1].data = JSON.parse(JSON.stringify(BeaufortRateUnit_Accumulation[item.index]))
          setBeaufortRateNone({ xname: "", xtop: "", xleft: "", yname: "(%)", ytop: "0", yleft: "8" })
        } else {
          newHcOptionsBeaufort.xAxis.categories = []
          newHcOptionsBeaufort.yAxis.min = ""
          newHcOptionsBeaufort.yAxis.max = ""
          newHcOptionsBeaufort.yAxis.tickInterval = ""
          newHcOptionsBeaufort.series[0].data = []
          newHcOptionsBeaufort.series[1].data = []
          setBeaufortRateNone({ xname: "No Data", xtop: "110", xleft: "140", yname: "", ytop: "", yleft: "" })
        }
        setHcOptionsBeaufort(newHcOptionsBeaufort)

        // DisplacementRate
        const newHcOptionsDisplacement = { ...hcOptionsDisplacement };


        if (DisplacementRateUnit[item.index] !== undefined && DisplacementRateUnit[item.index].length) {
          newHcOptionsDisplacement.series[0].data = JSON.parse(JSON.stringify(DisplacementRateUnit[item.index]))
          newHcOptionsDisplacement.title = {
            text:
              `<div style="color: rgba(255,255,255,0.6); font-size: 14px; font-weight: lighter; font-family: Roboto Condensed, Arial, sans-serif; margin-top:70px;">Ballast</div>
              <div style="color: rgba(255,255,255,0.6); font-size: 14px; font-weight: lighter; font-family: Roboto Condensed, Arial, sans-serif; text-align: center;"><span style="font-size: 15px; color: white;">${DisplacementRateUnit[item.index][0].y.toFixed(1)}</span>&nbsp;%</div>`,
 
            useHTML: true,
          }
          newHcOptionsDisplacement.chart.events = {
            load() {
              const chart = this;
              chart.series[0].points.forEach(point => {
                point.onMouseOver = function () {
                  console.log("this", this)
                  let name
                  if (this.index === 0) {
                    name = "Ballast"
                  } else if (this.index === 1) {
                    name = "Laden"
                  }
                  chart.update({
                    title: {
                      text:
                        `<div style="color: rgba(255,255,255,0.6); font-size: 14px; font-weight: lighter; font-family: Roboto Condensed, Arial, sans-serif; margin-top:70px;">${name}</div>
                        <div style="color: rgba(255,255,255,0.6); font-size: 14px; font-weight: lighter; font-family: Roboto Condensed, Arial, sans-serif; text-align: center;"><span style="font-size: 15px; color: white;">${this.y.toFixed(1)}</span>&nbsp;%</div>`,
  
                      useHTML: true
                    },
                  });
                };
              });
            }
          }
        } else {
          newHcOptionsDisplacement.series[0].data = JSON.parse(JSON.stringify([]))
          newHcOptionsDisplacement.title = {
            text:
              `<div style="color: rgba(255,255,255,0.5); font-size: 13px; font-weight: lighter; font-family: Roboto Condensed; margin-top:90px;">No Data</div>`,
            useHTML: true,
          }
        }
        setHcOptionsDisplacement(newHcOptionsDisplacement)

        // VoyageInformationUnit
        setCIISCOREShowColor({ color: ["rgba(255, 255, 255, 0.05)", "rgba(255, 255, 255, 0.05)", "rgba(255, 255, 255, 0.05)", "rgba(255, 255, 255, 0.05)", "rgba(255, 255, 255, 0.05)"] })
        let voyageInfoUnit = voyageInfoInit
        if (VoyageInformationUnit.length) {
          voyageInfoUnit = {
            "From": VoyageInformationUnit[item.index].From,
            "To": VoyageInformationUnit[item.index].To,
            "CII_Score": VoyageInformationUnit[item.index].CII_Score,
            "CII_value": VoyageInformationUnit[item.index].CII_value,
            "Total_FOC": VoyageInformationUnit[item.index].Total_FOC,
            "ME_FOC": VoyageInformationUnit[item.index].ME_FOC,
            "Total_Distance": VoyageInformationUnit[item.index].Total_Distance,
            "Total_CO2_Emissions": VoyageInformationUnit[item.index].Total_CO2_Emissions,
            "Avg_Wind_Speed": VoyageInformationUnit[item.index].Avg_Wind_Speed,
            "Avg_LOG_Speed": VoyageInformationUnit[item.index].Avg_LOG_Speed,
            "Avg_LOAD": VoyageInformationUnit[item.index].Avg_LOAD,
            "Avg_RPM": VoyageInformationUnit[item.index].Avg_RPM,
            "Avg_Displacement": VoyageInformationUnit[item.index].Avg_Displacement,
            "Avg_Beaufort": VoyageInformationUnit[item.index].Avg_Beaufort,
          }
          const CII_color = voyageInfoUnit.CII_Score
          if (CII_color === "A") {
            setCIISCOREShowColor({ color: ["rgba(0, 144, 206, 0.4)", "rgba(255, 255, 255, 0.05)", "rgba(255, 255, 255, 0.05)", "rgba(255, 255, 255, 0.05)", "rgba(255, 255, 255, 0.05)"] })
          } else if (CII_color === "B") {
            setCIISCOREShowColor({ color: ["rgba(6, 165, 80, 0.4)", "rgba(6, 165, 80, 0.4)", "rgba(255, 255, 255, 0.05)", "rgba(255, 255, 255, 0.05)", "rgba(255, 255, 255, 0.05)"] })
          } else if (CII_color === "C") {
            setCIISCOREShowColor({ color: ["rgba(212, 195, 38, 0.4)", "rgba(212, 195, 38, 0.4)", "rgba(212, 195, 38, 0.4)", "rgba(255, 255, 255, 0.05)", "rgba(255, 255, 255, 0.05)"] })
          } else if (CII_color === "D") {
            setCIISCOREShowColor({ color: ["rgba(255, 103, 103, 0.45)", "rgba(255, 103, 103, 0.45)", "rgba(255, 103, 103, 0.45)", "rgba(255, 103, 103, 0.45)", "rgba(255, 255, 255, 0.05)"] })
          } else if (CII_color === "E") {
            setCIISCOREShowColor({ color: ["rgba(255, 47, 47, 0.45)", "rgba(255, 47, 47, 0.45)", "rgba(255, 47, 47, 0.45)", "rgba(255, 47, 47, 0.45)", "rgba(255, 47, 47, 0.45)"] })
          }
        }
        setVoyageInformationUnitShow(voyageInfoUnit)

        // // VoyageInformation
        // let voyageInfo = voyageInfoInit
        // if (VoyageInformation.length) {
        //   voyageInfo = {
        //     "From": VoyageInformation[item.index].From,
        //     "To": VoyageInformation[item.index].To,
        //     "CII_Score": VoyageInformation[item.index].CII_Score,
        //     "CII_value": VoyageInformation[item.index].CII_value,
        //     "Total_FOC": VoyageInformation[item.index].Total_FOC,
        //     "ME_FOC": VoyageInformation[item.index].ME_FOC,
        //     "Total_Distance": VoyageInformation[item.index].Total_Distance,
        //     "Total_CO2_Emissions": VoyageInformation[item.index].Total_CO2_Emissions,
        //     "Avg_Wind_Speed": VoyageInformation[item.index].Avg_Wind_Speed,
        //     "Avg_LOG_Speed": VoyageInformation[item.index].Avg_LOG_Speed,
        //     "Avg_LOAD": VoyageInformation[item.index].Avg_LOAD,
        //     "Avg_RPM": VoyageInformation[item.index].Avg_RPM,
        //     "Avg_Displacement": VoyageInformation[item.index].Avg_Displacement,
        //     "Avg_Beaufort": VoyageInformation[item.index].Avg_Beaufort,
        //   }
        // }
        // setVoyageInformationShow(voyageInfo)
      }
    }
    setKey(key + 1);
  }, [changeClickGraph, clickItemGraph]);


  // Rateグラフのリセットボタンがクリックされたら
  const [changeClickGraphReSet, setChangeClickGraphReSet] = React.useState<number>(0);
  // const [ClickGraphReSetOn, setClickGraphReSetOn] = React.useState<boolean>(false);
  const onClickGraphReSet = (event: any) => {
    console.log("onClickGraphReSet")
    setChangeClickGraphReSet((prev) => (prev + 1));
    setClickItemGraph({ index: 0, x: null, y: null })
  }

  React.useEffect(() => {
    if (changeClickGraphReSet > 0) {
      changeGraphReSet()
    }
  }, [changeClickGraphReSet])

  const changeGraphReSet = () => {

    if (LOGSpeedRate !== undefined && LOGSpeedRate_Accumulation !== undefined
      && MELoadRate !== undefined && MELoadRate_Accumulation !== undefined
      && DisplacementRate !== undefined && DisplacementRate_Accumulation !== undefined
      && BeaufortRate !== undefined && BeaufortRate_Accumulation !== undefined) {

      // FOC&CII
      const newHcOptionsFOCCII = { ...hcOptions };
      newHcOptionsFOCCII.series[0].data = JSON.parse(JSON.stringify(CII))
      newHcOptionsFOCCII.series[1].data = JSON.parse(JSON.stringify(FOC))
      newHcOptionsFOCCII.series[2].data = JSON.parse(JSON.stringify(CIIAll))
      setHcOptions(newHcOptionsFOCCII)

      // LOGSpeedRate
      const newHcOptionsLogSpeed = { ...hcOptionsLogSpeed };
      if (LOGSpeedRate.length) {
        newHcOptionsLogSpeed.xAxis.categories = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '', '(knot)']
        newHcOptionsLogSpeed.yAxis.min = 0
        newHcOptionsLogSpeed.yAxis.max = 100
        newHcOptionsLogSpeed.yAxis.tickInterval = 25
        newHcOptionsLogSpeed.series[0].data = JSON.parse(JSON.stringify(LOGSpeedRate[LOGSpeedRate.length - 1]))
        newHcOptionsLogSpeed.series[1].data = JSON.parse(JSON.stringify(LOGSpeedRate_Accumulation[LOGSpeedRate_Accumulation.length - 1]))
        setLOGSpeedRateNone({ xname: "", xtop: "", xleft: "", yname: "(%)", ytop: "0", yleft: "8" })
      } else {
        newHcOptionsLogSpeed.xAxis.categories = []
        newHcOptionsLogSpeed.yAxis.min = ""
        newHcOptionsLogSpeed.yAxis.max = ""
        newHcOptionsLogSpeed.yAxis.tickInterval = ""
        newHcOptionsLogSpeed.series[0].data = []
        newHcOptionsLogSpeed.series[1].data = []
        setLOGSpeedRateNone({ xname: "No Data", xtop: "110", xleft: "140", yname: "", ytop: "", yleft: "" })
      }
      setHcOptionsLogSpeed(newHcOptionsLogSpeed)

      // MELoadRate
      const newHcOptionsMELoad = { ...hcOptionsMELoad };
      if (MELoadRate.length) {
        newHcOptionsMELoad.xAxis.categories = ['0', '5', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90', '95', '100', '', '(%)']
        newHcOptionsMELoad.yAxis.min = 0
        newHcOptionsMELoad.yAxis.max = 100
        newHcOptionsMELoad.yAxis.tickInterval = 25
        newHcOptionsMELoad.series[0].data = JSON.parse(JSON.stringify(MELoadRate[MELoadRate.length - 1]))
        newHcOptionsMELoad.series[1].data = JSON.parse(JSON.stringify(MELoadRate_Accumulation[MELoadRate_Accumulation.length - 1]))
        setMELoadRateNone({ xname: "", xtop: "", xleft: "", yname: "(%)", ytop: "0", yleft: "8" })
      } else {
        newHcOptionsMELoad.xAxis.categories = []
        newHcOptionsMELoad.yAxis.min = ""
        newHcOptionsMELoad.yAxis.max = ""
        newHcOptionsMELoad.yAxis.tickInterval = ""
        newHcOptionsMELoad.series[0].data = []
        newHcOptionsMELoad.series[1].data = []
        setMELoadRateNone({ xname: "No Data", xtop: "110", xleft: "140", yname: "", ytop: "", yleft: "" })
      }
      setHcOptionsMELoad(newHcOptionsMELoad)

      // Beaufort
      const newHcOptionsBeaufort = { ...hcOptionsBeaufort };
      if (BeaufortRate.length) {
        newHcOptionsBeaufort.xAxis.categories = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '', '(BF)']
        newHcOptionsBeaufort.yAxis.min = 0
        newHcOptionsBeaufort.yAxis.max = 100
        newHcOptionsBeaufort.yAxis.tickInterval = 25
        newHcOptionsBeaufort.series[0].data = JSON.parse(JSON.stringify(BeaufortRate[BeaufortRate.length - 1]))
        newHcOptionsBeaufort.series[1].data = JSON.parse(JSON.stringify(BeaufortRate_Accumulation[BeaufortRate_Accumulation.length - 1]))
        setBeaufortRateNone({ xname: "", xtop: "", xleft: "", yname: "(%)", ytop: "0", yleft: "8" })
      } else {
        newHcOptionsBeaufort.xAxis.categories = []
        newHcOptionsBeaufort.yAxis.min = ""
        newHcOptionsBeaufort.yAxis.max = ""
        newHcOptionsBeaufort.yAxis.tickInterval = ""
        newHcOptionsBeaufort.series[0].data = []
        newHcOptionsBeaufort.series[1].data = []
        setBeaufortRateNone({ xname: "No Data", xtop: "110", xleft: "140", yname: "", ytop: "", yleft: "" })
      }
      setHcOptionsBeaufort(newHcOptionsBeaufort)

      // DisplacementRate
      const newHcOptionsDisplacement = { ...hcOptionsDisplacement };
      if (DisplacementRate.length) {
        newHcOptionsDisplacement.series[0].data = JSON.parse(JSON.stringify(DisplacementRate[DisplacementRate.length - 1]))
        newHcOptionsDisplacement.title = {
          text:
            `<div style="color: rgba(255,255,255,0.6); font-size: 14px; font-weight: lighter; font-family: Roboto Condensed, Arial, sans-serif; margin-top:70px;">Ballast</div>
             <div style="color: rgba(255,255,255,0.6); font-size: 14px; font-weight: lighter; font-family: Roboto Condensed, Arial, sans-serif; text-align: center;"><span style="font-size: 15px; color: white;">${DisplacementRate[DisplacementRate.length - 1][0].y.toFixed(1)}</span>&nbsp;%</div>`,
            useHTML: true,
        }
        newHcOptionsDisplacement.chart.events = {
          load() {
            const chart = this;
            chart.series[0].points.forEach(point => {
              point.onMouseOver = function () {
                console.log("this", this)
                let name
                if (this.index === 0) {
                  name = "Ballast"
                } else if (this.index === 1) {
                  name = "Laden"
                }
                chart.update({
                  title: {
                    text:
                      `<div style="color: rgba(255,255,255,0.6); font-size: 14px; font-weight: lighter; font-family: Roboto Condensed, Arial, sans-serif; margin-top:70px;">${name}</div>
                      <div style="color: rgba(255,255,255,0.6); font-size: 14px; font-weight: lighter; font-family: Roboto Condensed, Arial, sans-serif; text-align: center;"><span style="font-size: 15px; color: white;">${this.y.toFixed(1)}</span>&nbsp;%</div>`,
                      useHTML: true
                  },
                  align: 'center',
                  verticalAlign: 'middle',
                  y: 0 // タイトルの垂直位置を中央に設定
                });
              };
            });
          }
        }
      } else {
        newHcOptionsDisplacement.series[0].data = JSON.parse(JSON.stringify([]))
        newHcOptionsDisplacement.title = {
          text:
            `<div style="color: rgba(255,255,255,0.5); font-size: 13px; font-weight: lighter; font-family: Roboto Condensed; margin-top:90px;">No Data</div>`,
          useHTML: true,
        }
      }
      setHcOptionsDisplacement(newHcOptionsDisplacement)

      // Voyage Information
      let voyageInfo = voyageInfoInit
      if (VoyageInformation.length) {
        voyageInfo = VoyageInformation[VoyageInformation.length - 1]
        const CII_color = voyageInfo.CII_Score
        if (CII_color === "A") {
          setCIISCOREShowColor({ color: ["rgba(0, 144, 206, 0.4)", "rgba(255, 255, 255, 0.05)", "rgba(255, 255, 255, 0.05)", "rgba(255, 255, 255, 0.05)", "rgba(255, 255, 255, 0.05)"] })
        } else if (CII_color === "B") {
          setCIISCOREShowColor({ color: ["rgba(6, 165, 80, 0.4)", "rgba(6, 165, 80, 0.4)", "rgba(255, 255, 255, 0.05)", "rgba(255, 255, 255, 0.05)", "rgba(255, 255, 255, 0.05)"] })
        } else if (CII_color === "C") {
          setCIISCOREShowColor({ color: ["rgba(212, 195, 38, 0.4)", "rgba(212, 195, 38, 0.4)", "rgba(212, 195, 38, 0.4)", "rgba(255, 255, 255, 0.05)", "rgba(255, 255, 255, 0.05)"] })
        } else if (CII_color === "D") {
          setCIISCOREShowColor({ color: ["rgba(255, 103, 103, 0.45)", "rgba(255, 103, 103, 0.45)", "rgba(255, 103, 103, 0.45)", "rgba(255, 103, 103, 0.45)", "rgba(255, 255, 255, 0.05)"] })
        } else if (CII_color === "E") {
          setCIISCOREShowColor({ color: ["rgba(255, 47, 47, 0.45)", "rgba(255, 47, 47, 0.45)", "rgba(255, 47, 47, 0.45)", "rgba(255, 47, 47, 0.45)", "rgba(255, 47, 47, 0.45)"] })
        }
      }
      setVoyageInformationUnitShow(voyageInfo)
      // setVoyageInformationShow(voyageInfo)
    }
    setKey(key + 1);
  }

  // useEffectチェック
  React.useEffect(() => {
    // console.log("hcOptions", hcOptions)
    // console.log("hcOptionsBeaufort", hcOptionsBeaufort)
    // console.log("hcOptionsDisplacement", hcOptionsDisplacement)
    // console.log("hcOptionsDisplacementplotOptions.pie.point.events.mouseOver", hcOptionsDisplacement.plotOptions.pie.point.events.mouseOver)
  }, [hcOptions])


  // サイドバー展開--------------------------------------------------------------------
  // 現在のアクティブなタブを管理するための状態
  const [SeachTab, setSeachTab] = React.useState<boolean>(true);
  const [VesselInformationTab, setVesselInformationTab] = React.useState<boolean>(true);

  // タブがクリックされた時に呼び出されるハンドラー関数
  const handleTabClick = (index) => {
    console.log("handleTabClick", index)
    if (index === 1) {
      setSeachTab(!SeachTab)
    }
    else if (index === 2) {
      setVesselInformationTab(!VesselInformationTab)
    }
  };

  // Highchartsグラフの表示ON/OFF
  const [CIIScoreFlag, setCIIScoreFlag] = React.useState(true);
  const [CIIMorWFlag, setCIIMorWFlag] = React.useState(true);
  const [FOCFlag, setFOCFlag] = React.useState(true);
  const handleClickGraphLabel = (number) => {
    const newHcOptions = { ...hcOptions };
      for (var i = 0; i <= 2; i++) {
        if (i === number) {
          newHcOptions.series[i].visible = !newHcOptions.series[i].visible;
          if( i === 0){
            setCIIMorWFlag(!CIIMorWFlag)
          }else if(i === 1){
            setFOCFlag(!FOCFlag)
          }else if(i === 2){
            setCIIScoreFlag(!CIIScoreFlag)
          }
        }
      }
    setHcOptions(newHcOptions);
  };

  // search時に全てのHighchartsグラフを表示状態にする
  const hichartsVisuble = () => {
    for (var i = 0; i <= 2; i++) {
      const newHcOptions = { ...hcOptions };
      newHcOptions.series[i].visible = true;
    }
  }

  // CII Score (Last Year)の表示ON/OFF
  const [CIILastYearFlag, setCIILastYearFlag] = React.useState(true);
  const [saveCIILastYear, setSaveCIILastYear] = React.useState("");
  const switchCIILastYear = () => {
    if (CIILastYearFlag === true) {             // 表示状態→非表示
      setCIILastYearFlag(false)
      setSaveCIILastYear(CIILASTYEAR)           // 保持しているCII Score (Last Year)を保持しておく
      setCIILASTYEAR("hidden")
    }else{                                      // 非表示状態→表示
      setCIILastYearFlag(true)
      setCIILASTYEAR(saveCIILastYear)
    }
  }

  return (
    <>
      <Box sx={{ height: "2%" }}></Box>
      <Box sx={{ height: "98.5%" }}>


          <Grid container spacing={2} sx={{height: "100%"}}>

            {/* ↓ メイン左側 ↓ */}
            <Grid item xs={8.75} sx={{height: "100%"}}>

              {/* ↓ FOC & CII Scoreエリア ↓ */}
              <Stack
                direction='column'
                spacing={0}
                sx={{
                  background: 'linear-gradient(180deg, rgba(45.82, 46.41, 47.81, 0.20) 0%, rgba(65.37, 66.21, 68.21, 0.20) 20%)',
                  height: "69%",
                  width: "100%",
                }}>

                {/* ↓ タイトル ↓ */}
                <Box sx={{ position: "relative", height: "40px" }}>
                  <Typography sx={{ position: "absolute", top: "10px", left: "10px", color: 'white', fontSize: "15px", fontFamily: "'Roboto'", fontWeight: 'bold', wordWrap: 'break-word' }}>
                    CII Score & FOC
                  </Typography>

                  {/* Y軸設定ダイアログ */}
                  <Box
                    sx={{
                      position: "absolute",
                      top: "40px",
                      left: "10px",
                      width: "20px",
                      height: "20px",
                      background: "rgb(255,255,255,0.3)",
                      cursor: "pointer",
                      borderRadius: "50%",
                      border: "2px solid rgb(255,255,255,0.7)",
                      zIndex: 10,

                    }}
                    onClick={handleClickOpenDialogAxis}
                  >
                    <SettingsIcon sx={{ position: "absolute", top: "1px", left: "1px", width: "14px", height: "14px", color: "rgb(255,255,255,0.7)" }} />
                  </Box>

                  <Dialog
                    open={openDialogyAxis}
                    sx={{
                      "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                          width: "100%",
                          maxWidth: "500px",
                          height: "100%",
                          maxHeight: "250px",
                        },
                      },
                    }}
                  >
                    <DialogTitle
                      sx={{
                        bgcolor: "#474C53",
                        height: "50px",
                        color: "white",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}>
                      Axis Setting
                      <IconButton
                        aria-label="close"
                        onClick={handleNoClickOpenDialogAxis}
                        sx={{
                          position: 'absolute',
                          right: 8,
                          top: 8,
                          color: "rgba(255, 255, 255, 0.5)",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </DialogTitle>
                    <DialogContent
                      sx={{
                        background: "linear-gradient(180deg, #0E1B25 0%, #17232D 100%)",
                      }}>

                      <Box sx={{ position: "relative" }}>
                        <Box sx={{ position: "absolute", top: "60px", left: "20px", height: "1px", width: "400px", borderRadius: '0px ', border: "1px dotted #A4A7B1" }}></Box>
                        <Typography align="center"
                          sx={{
                            position: "absolute", top: "30px", left: "40px",
                            color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word'
                          }}>
                          Channel Name
                        </Typography>
                        <Typography align="center"
                          sx={{
                            position: "absolute", top: "30px", left: "240px",
                            color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word'
                          }}>
                          Min
                        </Typography>
                        <Typography align="center"
                          sx={{
                            position: "absolute", top: "30px", left: "350px",
                            color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word'
                          }}>
                          Max
                        </Typography>
                      </Box>
                      <Box sx={{ position: "relative" }}>
                        <Stack direction={"row"}>
                          <Typography align="left"
                            sx={{
                              position: "absolute", top: "90px", left: "70px",
                              color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word'
                            }}>
                            FOC
                          </Typography>
                          <FormControl>
                            <Input
                              value={FOCyAxisMinShow} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleClickFOCyAxisMinShow(event.target.value) }}
                              type='tel'
                              sx={{
                                position: "absolute", top: "80px", left: "200px", width: "100px", fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                ":hover:after": { borderBottom: "0px solid green", },
                                ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                ":after": { borderBottom: "2px solid white", },
                                ":before": { borderBottom: "2px solid #A4A7B1", },
                              }} />
                          </FormControl>
                          <Box sx={{ position: "absolute", top: "100px", left: "300px", height: "1px", width: "20px", borderRadius: '0px ', border: "1px solid #A4A7B1" }}></Box>
                          <FormControl>
                            <Input
                              value={FOCyAxisMaxShow} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleClickFOCyAxisMaxShow(event.target.value) }}
                              type='tel'
                              sx={{
                                position: "absolute", top: "80px", left: "320px", width: "100px", fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                ":hover:after": { borderBottom: "0px solid green", },
                                ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                ":after": { borderBottom: "2px solid white", },
                                ":before": { borderBottom: "2px solid #A4A7B1", },
                              }} />
                          </FormControl>
                        </Stack>
                      </Box>
                    </DialogContent>
                    <DialogActions sx={{
                      height: "50px",
                      bgcolor: "#474C53"
                    }}>
                      <Button
                        sx={{
                          marginLeft: "0px",
                          marginRight: "-8px",
                          backgroundColor: "rgba(255, 255, 255, 0.5)",
                          width: "127px",
                          height: "50px",
                          padding: "0",
                          fontSize: "18px",
                          fontFamily: "'Roboto Bold', 'Meiryo', sans-serif",
                          fontWeight: "bold",
                          color: "white",
                          borderRadius: "0px",
                          ":hover": {
                            bgcolor: "#FFFFFF",
                            color: "#40475D",
                          },
                          textTransform: 'none'
                        }}
                        onClick={handleNoClickOpenDialogAxis}
                      >
                        Cancel
                      </Button>
                      <Button
                        sx={{
                          marginLeft: "0px",
                          marginRight: "-8px",
                          backgroundColor: "#e66300",
                          width: "127px",
                          height: "50px",
                          padding: "0",
                          fontSize: "18px",
                          fontFamily: "'Roboto Bold', 'Meiryo', sans-serif",
                          fontWeight: "bold",
                          color: "white",
                          borderRadius: "0px",
                          ":hover": {
                            bgcolor: "#FFFFFF",
                            color: "#40475D",
                          },
                          textTransform: 'none'
                        }}
                        onClick={handleClickSetAxis}
                      >
                        Save
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Box>
                {/* ↑ タイトル ↑ */}

                <Box sx={{ position: "relative", height: "100%" }}>
                  {success ?
                    <>
                      {/* ↓ Highchartsグラフ ↓ */}
                      <Stack sx={{ position: "relative", top: "0px", left: "0px", height: "95%", width: "100%" }}>
                        <div onBlur={onClickGraphReSet}>
                          <HighchartsReact
                            key={key}
                            highcharts={Highcharts}
                            options={hcOptions}
                            ref={chartComponentRef}
                            allowChartUpdate={true}
                            updateArgs={[true, true, true]}
                          />
                        </div>
                      </Stack>
                      <Stack sx={{ position: "absolute", top: `${CIINone.top}px`, left: `${CIINone.left}px` }}>
                        <Typography align="left" sx={{ color: 'white', opacity: 0.50, fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                          {CIINone.name}
                        </Typography>
                      </Stack>
                      {/* ↑ Highchartsグラフ ↑ */}

                      {/* ↓ 凡例 ↓ */}
                      <Stack sx={{ position: "relative", width: "100%", marginTop: "16px" , bottom: "25px"}}>
                        <Grid container alignItems={"center"} justifyContent={"center"} >
                          {/* 左余白 */}
                          <Grid item xs={0}>
                          </Grid>
                          {/* CII Score (Total) */}
                          <Grid className="cii-emission-board-pointer-area" container alignItems={"center"} justifyContent={"start"} item xs={1.05} onClick={() =>handleClickGraphLabel(2)}>
                            <Grid item xs={0.5}></Grid>
                            <Grid item xs={3.5}>
                              <Box sx={{ position: "relative" }} >
                                <Box sx={{ position: "absolute", top: "0px", left: "0px", width: '8px', height: '1px',
                                  border: (CIIScoreFlag ? '1px #FF8514 solid' : '1px rgba(255, 133, 20, 0.4) solid')
                                }}></Box>
                                <Box sx={{ 
                                  position: "absolute", top: "-5px", left: "8px", width: '10px', height: '10px', 
                                  background: (CIIScoreFlag ? 'radial-gradient(rgba(255, 191, 131, 1) 0%, rgba(255, 133, 20, 1) 100%)' : 'radial-gradient(rgba(255, 191, 131, 0.4) 0%, rgba(255, 133, 20, 0.4) 100%)'),
                                  borderRadius: "5px" 
                                }}></Box>
                                <Box sx={{ position: "absolute", top: "0px", left: "18px", width: '8px', height: '1px', 
                                  border: (CIIScoreFlag ? '1px #FF8514 solid' : '1px rgba(255, 133, 20, 0.4) solid')
                                }}></Box>
                              </Box>
                            </Grid>
                            <Grid item xs={8}>
                              <Typography align="center" sx={{ color: (CIIScoreFlag ? 'white': "rgba(255,255,255,0.4)"), width: "100%", opacity: 0.70, fontSize: "min(0.7vw,14px)", fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                CII Score
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid item xs={0.25}></Grid>
                          {/* CII Score (Last Year) */}
                          <Grid className="cii-emission-board-pointer-area" container alignItems={"center"} justifyContent={"center"} item xs={1.88} onClick={() => switchCIILastYear()} >
                            <Grid item xs={0.3} ></Grid>
                            <Grid item xs={2.2} >
                              <Box sx={{ position: "relative" }} >
                                <Box sx={{ position: "absolute", top: "-3px", left: "0px", width: '6px', height: '6px', bgcolor: (CIILastYearFlag ? "rgba(194, 247, 255, 1)" : "rgba(194, 247, 255, 0.4)"), borderRadius: "50%" }}></Box>
                                <Box sx={{ position: "absolute", top: "0px", left: "6px", width: '5px', height: '0.4px', bgcolor: (CIILastYearFlag ? "rgba(194, 247, 255, 1)" : "rgba(194, 247, 255, 0.4)"), }}></Box>
                                <Box sx={{ position: "absolute", top: "0px", left: "11px", width: '3px', height: '1px', bgcolor: "transparent" }}></Box>
                                <Box sx={{ position: "absolute", top: "0px", left: "14px", width: '5px', height: '0.4px', bgcolor: (CIILastYearFlag ? "rgba(194, 247, 255, 1)" : "rgba(194, 247, 255, 0.4)"), }}></Box>
                                <Box sx={{ position: "absolute", top: "0px", left: "19px", width: '3px', height: '1px', bgcolor: "transparent" }}></Box>
                                <Box sx={{ position: "absolute", top: "0px", left: "22px", width: '5px', height: '0.4px', bgcolor: (CIILastYearFlag ? "rgba(194, 247, 255, 1)" : "rgba(194, 247, 255, 0.4)"), }}></Box>
                                <Box sx={{ position: "absolute", top: "-3px", left: "27px", width: '6px', height: '6px', bgcolor: (CIILastYearFlag ? "rgba(194, 247, 255, 1)" : "rgba(194, 247, 255, 0.4)"), borderRadius: "50%" }}></Box>
                              </Box>
                            </Grid>
                            <Grid item xs={9.5} >
                              <Typography align="center" sx={{ color: (CIILastYearFlag ? 'white': "rgba(255,255,255,0.4)"), width: "100%", opacity: 0.70, fontSize: "min(0.7vw,13px)", fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                CII Score ({lastYear})
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid item xs={0.25}></Grid>
                          {/* CII Score (Monthly or Weekly) */}
                          <Grid className="cii-emission-board-pointer-area" container alignItems={"center"} justifyContent={"center"} item xs={1.6} onClick={() =>handleClickGraphLabel(0)} >
                            <Grid item xs={0.3}></Grid>
                            <Grid item xs={1.5} >
                              <Box style={{ width: '15px', height: '20px', background: (CIIMorWFlag ? 'linear-gradient(180deg, #DD8739 0%, #E19550 100%)' : 'linear-gradient(180deg, rgba(221, 135, 57, 0.4) 0%, rgba(225, 149, 80, 0.4) 40%)') }} />
                            </Grid>
                            <Grid item xs={10.2} >
                              <Typography align="center" sx={{ color: (CIIMorWFlag ? 'white' : "rgba(255,255,255,0.4)"), width: "100%", opacity: 0.70, fontSize: "min(0.7vw,13px)", fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                CII Score ({props.unit})
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid item xs={0.25}></Grid>
                          {/* Fuel Oil Consumption */}
                          <Grid className="cii-emission-board-pointer-area" container alignItems={"center"} justifyContent={"center"} item xs={1.72} onClick={() =>handleClickGraphLabel(1)} >
                            <Grid item xs={0.3}></Grid>
                            <Grid item xs={1.5}>
                              <Box style={{ width: '15px', height: '20px', background: (FOCFlag ? 'linear-gradient(180deg, #1164B1 0%, #1478D5 100%)' : 'linear-gradient(180deg, rgba(17, 100, 177, 0.4) 0%, rgba(20, 120, 213, 0.4) 100%)') }} />
                            </Grid>
                            <Grid item xs={10.2} >
                              <Typography align="center" sx={{ color: (FOCFlag ? 'white' : 'rgba(255, 255, 255, 0.4)'), width: "100%", opacity: 0.70, fontSize: "min(0.7vw,13px)", fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                Fuel Oil Consumption
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid item xs={0.25}></Grid>
                          {/* CIIスコア */}
                          <Grid item xs={0.5}>
                            <Stack alignItems={"center"} justifyContent={"center"} sx={{ width: "26px", height: "26px", background: "rgba(0, 144, 206, 0.4)" }}>
                              <Typography align="center" sx={{ color: 'white', width: "100%", opacity: 0.70, fontSize: 15, fontFamily: 'Roboto Condensed', fontWeight: 'bold', wordWrap: 'break-word', paddingTop: "2px",   }}>
                                A
                              </Typography>
                            </Stack>
                          </Grid>
                          <Grid item xs={0.5}>
                            <Stack alignItems={"center"} justifyContent={"center"} sx={{ width: "26px", height: "26px", background: "rgba(6, 165, 80, 0.4)" }}>
                              <Typography align="center" sx={{ color: 'white', width: "100%", opacity: 0.70, fontSize: 15, fontFamily: 'Roboto Condensed', fontWeight: 'bold', wordWrap: 'break-word', paddingTop: "2px",   }}>
                                B
                              </Typography>
                            </Stack>
                          </Grid>
                          <Grid item xs={0.5}>
                            <Stack alignItems={"center"} justifyContent={"center"} sx={{ width: "26px", height: "26px", background: "rgba(212, 195, 38, 0.4)" }}>
                              <Typography align="center" sx={{ color: 'white', width: "100%", opacity: 0.70, fontSize: 15, fontFamily: 'Roboto Condensed', fontWeight: 'bold', wordWrap: 'break-word', paddingTop: "2px",   }}>
                                C
                              </Typography>
                            </Stack>
                          </Grid>
                          <Grid item xs={0.5}>
                            <Stack alignItems={"center"} justifyContent={"center"} sx={{ width: "26px", height: "26px", background: "rgba(255, 103, 103, 0.45)" }}>
                              <Typography align="center" sx={{ color: 'white', width: "100%", opacity: 0.70, fontSize: 15, fontFamily: 'Roboto Condensed', fontWeight: 'bold', wordWrap: 'break-word', paddingTop: "2px",  }}>
                                D
                              </Typography>
                            </Stack>
                          </Grid>
                          <Grid item xs={0.5} >
                            <Stack alignItems={"center"} justifyContent={"center"} sx={{ width: "26px", height: "26px", background: "rgba(255, 47, 47, 0.45)" }}>
                              <Typography align="center" sx={{ color: 'white', width: "100%", opacity: 0.70, fontSize: 15, fontFamily: 'Roboto Condensed', fontWeight: 'bold', wordWrap: 'break-word', paddingTop: "2px",   }}>
                                E
                              </Typography>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Stack>
                      {/* ↑ 凡例 ↑ */}
                    </>
                    :
                    <Box>
                      {loading && (
                        <CircularProgress
                          size={68}
                          sx={{
                            color: "grey",
                            position: 'absolute',
                            top: "150px",
                            left: "500px",
                            zIndex: 1,
                          }}
                        />
                      )}
                    </Box>
                  }
                </Box>
              </Stack>
              {/* ↑ FOC & CII Scoreエリア ↑ */}


              {/* ↓ Voyage Information ↓ */}
              <Stack
                direction='column'
                spacing={0}
                sx={{
                  position: "relative",
                  background: 'linear-gradient(180deg, rgba(45.82, 46.41, 47.81, 0.20) 0%, rgba(65.37, 66.21, 68.21, 0.20) 20%)',
                  marginTop: "18px",
                  // height: "295px",
                  height: "28.75%",
                  width: "100%",
                }}>

                {/* ↓ タイトル ↓ */}
                {/* <Box sx={{ position: "relative", top: "0px", left: "0px", width: "100%", height: "50px" }}> */}
                <Box sx={{ width: "100%", height: "15%" }}>
                  {/* <Typography sx={{ position: "absolute", top: "10px", left: "10px", color: 'white', fontSize: "14px", fontFamily: "'Roboto Bold'", fontWeight: 'bold', wordWrap: 'break-word' }}> */}
                  <Typography sx={{ marginLeft: "10px", marginTop: "10px", color: 'white', fontSize: "15px", fontFamily: "Roboto", fontWeight: 'bold', wordWrap: 'break-word' }}>
                    Voyage Information
                  </Typography>
                </Box>
                {/* ↑ タイトル ↑ */}

                <Box sx={{ position: "relative", top: "0px", left: "0px", width: "100%", height: "85%" }}>
                  {success ?
                    <>
                      {/* ↓ Point Information ↓ */}
                      <Stack sx={{ position: "relative", top: "0px", left: "0px", width: "100%", height: "100%" }}>

                        {/* ↓ header ↓ */}

                        {/* utc date */}
                        <Stack sx={{ position: "relative", top: "0px", left: "0px", height: "10px" }} ></Stack>
                        <Stack sx={{ position: "relative", top: "0px", left: "0px", height: "30px" }} >
                          <Grid container direction="row" alignItems={"center"}>
                            <Grid item xs={0.4}>
                            </Grid>
                            <Grid item xs={0.3} >
                              <Stack sx={{}} alignItems={"center"} justifyContent={"center"}>
                                <img src={DateIcon_svg} alt="iconLike" style={{ height: "20px", width: "20px", color: 'white' }} />
                              </Stack>
                            </Grid>
                            <Grid item xs={11.3}>
                              <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 1)', fontSize: "14px", fontFamily: "'Roboto Regular'", wordWrap: 'break-word' }}>
                                {VoyageInformationUnitShow.From !== undefined && VoyageInformationUnitShow.From !== null ? VoyageInformationUnitShow.From + " - " + VoyageInformationUnitShow.To : ""}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Stack>
                        {/* ↑ header ↑ */}


                        {/* ↓ Body ↓ */}
                        <Stack sx={{ height: "70%" }} >

                          {/* Voyage Information */}
                          <Grid container sx={{ height: "8%" }} ></Grid>
                          <Grid container direction="row" >

                            {/* 区切り */}
                            <Grid item xs={1} sx={{ height: "100%" }}>
                              <Stack sx={{ height: "100%", width: "100%" }} alignItems={"center"} justifyContent={"center"}>
                                <Box sx={{ height: "100px", width: "0px", background: "linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))" }}></Box>
                              </Stack>
                            </Grid>

                            {/* CII Score */}
                            <Grid item xs={2} sx={{ height: "100%" }}>
                              <Stack direction="column" sx={{ height: "100%" }} >
                                {/* Title */}
                                <Grid container sx={{ height: "30px" }}>
                                  <Grid item xs={12} >
                                    <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.50)', fontSize: "14px", fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
                                      CII Score
                                    </Typography>
                                  </Grid>
                                </Grid>
                                {/* CII Score */}
                                <Grid container sx={{ height: "30px", marginLeft: "10%"  }}>
                                  <Grid item xs={12} >
                                    <Stack sx={{ position: "relative", top: "0px", left: "0px" }}>
                                      {/* <Stack sx={{ position: "absolute", top: "10px", left: "8%" }}> */}
                                      <Stack sx={{ position: "absolute", top: "10px", left: "0px" }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="136" height="67" viewBox="0 0 136 67" fill="none">
                                          <path d="M26.6485 42.6457L11.4478 31.5999C4.89608 41.8257 0.788417 53.9437 0 66.9999H18.9772C19.6789 58.004 22.4068 49.7178 26.6485 42.6457Z" fill={CIISCOREShowColor.color[0]} />
                                          <path d="M49.8473 22.2576L44.0761 4.49458C32.2183 9.15413 21.9295 17.2039 14.266 27.5321L29.4036 38.5306C34.7255 31.3087 41.766 25.6636 49.8552 22.2655L49.8473 22.2576Z" fill={CIISCOREShowColor.color[1]} />
                                          <path d="M67.7876 18.6461C72.392 18.6461 76.8465 19.3241 81.0725 20.5856L86.8358 2.85407C80.7965 0.993402 74.4025 0 67.7955 0C61.1886 0 54.7945 1.00129 48.7552 2.85407L54.5186 20.5856C58.7445 19.3241 63.1991 18.6461 67.8034 18.6461H67.7876Z" fill={CIISCOREShowColor.color[2]} />
                                          <path d="M106.186 38.522L121.323 27.5236C113.66 17.1954 103.371 9.14562 91.5132 4.48608L85.742 22.2491C93.8311 25.6472 100.872 31.2923 106.194 38.5142L106.186 38.522Z" fill={CIISCOREShowColor.color[3]} />
                                          <path d="M116.61 66.9999H135.587C134.799 53.9358 130.691 41.8257 124.14 31.5999L108.939 42.6457C113.188 49.7257 115.908 58.0119 116.61 66.9999Z" fill={CIISCOREShowColor.color[4]} />
                                        </svg>
                                      </Stack>
                                      {/* <Stack sx={{ position: "absolute", top: "40px", left: "55%" }}> */}
                                      <Stack sx={{ position: "absolute", top: "40px", left: "60px" }}>
                                        <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 1)', fontSize: "16px", fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
                                          {VoyageInformationUnitShow.CII_Score}
                                        </Typography>
                                      </Stack>
                                      {/* <Stack sx={{ position: "absolute", top: "60px", left: "40%", width: "55px" }}> */}
                                      <Stack sx={{ position: "absolute", top: "60px", left: "40px", width: "55px" }}>
                                        <Typography align="center" sx={{ color: 'rgba(255, 255, 255, 0.50)', fontSize: "14px", fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word' }}>
                                          {VoyageInformationUnitShow.CII_value === 0 ? "" : VoyageInformationUnitShow.CII_value}
                                        </Typography>
                                      </Stack>
                                    </Stack>
                                  </Grid>
                                </Grid>
                              </Stack>
                            </Grid>

                            {/* 区切り */}
                            <Grid item xs={1} sx={{ height: "100%" }}>
                              <Stack sx={{ height: "100%", width: "100%" }} alignItems={"center"} justifyContent={"center"}>
                                <Box sx={{ height: "100px", width: "1px", background: "linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))" }}></Box>
                              </Stack>
                            </Grid>

                            {/* Total */}
                            <Grid item xs={3} sx={{ height: "100%" }}>
                              <Stack direction="column" sx={{ height: "100%" }} >
                                {/* Title */}
                                <Grid container sx={{ height: "30px" }}>
                                  <Grid item xs={12} >
                                    <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.50)', fontSize: "14px", fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
                                      Total
                                    </Typography>
                                  </Grid>
                                </Grid>
                                {/* FOC */}
                                <Grid container sx={{ height: "30px" }}>
                                  <Grid item xs={1}>
                                  </Grid>
                                  <Grid item xs={5.5}>
                                    <Typography sx={{ color: 'rgba(255, 255, 255, 0.50)', fontSize: "min(0.8vw,14px)", fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
                                      FOC
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={3}>
                                    <Typography align="right" sx={{ color: 'rgba(255, 255, 255, 1)', fontSize: "min(0.8vw,14px)", fontFamily: "'Roboto Regular'", wordWrap: 'break-word' }}>
                                      { VoyageInformationUnitShow && VoyageInformationUnitShow.Total_FOC !== null ? formatNumber(parseFloat(VoyageInformationUnitShow.Total_FOC)) : "-"}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={0.2}>
                                  </Grid>
                                  <Grid item xs={1.8}>
                                    <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.50)', fontSize: "min(0.8vw,14px)", fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
                                      MT
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={0.5}>
                                  </Grid>
                                </Grid>
                                {/* CO2 Emissions */}
                                <Grid container sx={{ height: "30px" }}>
                                  <Grid item xs={1}>
                                  </Grid>
                                  <Grid item xs={5.5}>
                                    <Typography sx={{ color: 'rgba(255, 255, 255, 0.50)', fontSize: "min(0.8vw,14px)", fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
                                      CO2 Emissions
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={3}>
                                    <Typography align="right" sx={{ color: 'rgba(255, 255, 255, 1)', fontSize: "min(0.8vw,14px)", fontFamily: "'Roboto Regular'", wordWrap: 'break-word' }}>
                                      { VoyageInformationUnitShow && VoyageInformationUnitShow.Total_CO2_Emissions !== null ? formatNumber(parseFloat(VoyageInformationUnitShow.Total_CO2_Emissions)) : "-"}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={0.2}>
                                  </Grid>
                                  <Grid item xs={1.8}>
                                    <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.50)', fontSize: "min(0.8vw,14px)", fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
                                      MT
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={0.5}>
                                  </Grid>
                                </Grid>
                                {/* Distance */}
                                <Grid container sx={{ height: "30px" }}>
                                  <Grid item xs={1}>
                                  </Grid>
                                  <Grid item xs={5.5}>
                                    <Typography sx={{ color: 'rgba(255, 255, 255, 0.50)', fontSize: "min(0.8vw,14px)", fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
                                      Distance
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={3}>
                                    <Typography align="right" sx={{ color: 'rgba(255, 255, 255, 1)', fontSize: "min(0.8vw,14px)", fontFamily: "'Roboto Regular'", wordWrap: 'break-word' }}>
                                      { VoyageInformationUnitShow && VoyageInformationUnitShow.Total_Distance !== null ? formatNumber(parseFloat(VoyageInformationUnitShow.Total_Distance)) : "-"}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={0.2}>
                                  </Grid>
                                  <Grid item xs={1.8}>
                                    <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.50)', fontSize: "min(0.8vw,14px)", fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
                                      NM
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={0.5}>
                                  </Grid>
                                </Grid>
                              </Stack>
                            </Grid>

                            {/* 区切り */}
                            <Grid item xs={1} sx={{ height: "100%" }}>
                              <Stack sx={{ height: "100%", width: "100%" }} alignItems={"center"} justifyContent={"center"}>
                                <Box sx={{ height: "100px", width: "1px", background: "linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))" }}></Box>
                              </Stack>
                            </Grid>

                            {/* Avg. */}
                            <Grid item xs={3.5} sx={{ height: "100%" }}>
                              <Stack direction="column" sx={{ height: "100%" }} >
                                {/* Title */}
                                <Grid container sx={{ height: "30px" }}>
                                  <Grid item xs={12} >
                                    <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.50)', fontSize: "14px", fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
                                      Average
                                    </Typography>
                                  </Grid>
                                </Grid>
                                {/* Log Speed */}
                                <Grid container sx={{ height: "30px" }}>
                                  <Grid item xs={1}>
                                  </Grid>
                                  <Grid item xs={4.5}>
                                    <Typography sx={{ color: 'rgba(255, 255, 255, 0.50)', fontSize: "min(0.8vw,14px)", fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
                                      Log Speed
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={3.5}>
                                    <Typography align="right" sx={{ color: 'rgba(255, 255, 255, 1)', fontSize: "min(0.8vw,14px)", fontFamily: "'Roboto Regular'", wordWrap: 'break-word' }}>
                                      { 
                                        VoyageInformationUnitShow && VoyageInformationUnitShow.Avg_LOG_Speed !== null ? 
                                          formatNumber(
                                            Number.isInteger(VoyageInformationUnitShow.Avg_LOG_Speed) ? 
                                              `${VoyageInformationUnitShow.Avg_LOG_Speed}.0` 
                                            : VoyageInformationUnitShow.Avg_LOG_Speed.toString()
                                          ) 
                                        : "-"
                                      }
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={0.2}>
                                  </Grid>
                                  <Grid item xs={1.5}>
                                    <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.50)', fontSize: "min(0.8vw,14px)", fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
                                      knot
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={1.3}>
                                  </Grid>
                                </Grid>
                                {/* Beaufort */}
                                <Grid container sx={{ height: "30px" }}>
                                  <Grid item xs={1}>
                                  </Grid>
                                  <Grid item xs={4.5}>
                                    <Typography sx={{ color: 'rgba(255, 255, 255, 0.50)', fontSize: "min(0.8vw,14px)", fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
                                      Beaufort
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={3.5}>
                                    <Typography align="right" sx={{ color: 'rgba(255, 255, 255, 1)', fontSize: "min(0.8vw,14px)", fontFamily: "'Roboto Regular'", wordWrap: 'break-word' }}>
                                      { 
                                        VoyageInformationUnitShow && VoyageInformationUnitShow.Avg_Beaufort !== null ? 
                                          formatNumber(
                                            Number.isInteger(VoyageInformationUnitShow.Avg_Beaufort) ? 
                                              `${VoyageInformationUnitShow.Avg_Beaufort}.0` 
                                            : VoyageInformationUnitShow.Avg_Beaufort.toString()
                                          ) 
                                        : "-"
                                      }
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={0.2}>
                                  </Grid>
                                  <Grid item xs={1.5}>
                                    <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.50)', fontSize: "min(0.8vw,14px)", fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
                                      BF
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={1.3}>
                                  </Grid>
                                </Grid>
                                {/* Displacement */}
                                <Grid container sx={{ height: "30px" }}>
                                  <Grid item xs={1}>
                                  </Grid>
                                  <Grid item xs={4.5}>
                                    <Typography sx={{ color: 'rgba(255, 255, 255, 0.50)', fontSize: "min(0.8vw,14px)", fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
                                      Displacement
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={3.5}>
                                    <Typography align="right" sx={{ color: 'rgba(255, 255, 255, 1)', fontSize: "min(0.8vw,14px)", fontFamily: "'Roboto Regular'", wordWrap: 'break-word' }}>
                                      { VoyageInformationUnitShow && VoyageInformationUnitShow.Avg_Displacement !== null ? formatNumber(parseFloat(VoyageInformationUnitShow.Avg_Displacement)) : "-"}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={0.2}>
                                  </Grid>
                                  <Grid item xs={1.5}>
                                    <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.50)', fontSize: "min(0.8vw,14px)", fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
                                      ton
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={1.3}>
                                  </Grid>
                                </Grid>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Stack>
                      </Stack>
                      {/* ↑ Body ↑ */}
                    </>
                    :
                    <Box>
                      {loading && (
                        <CircularProgress
                          size={68}
                          sx={{
                            color: "grey",
                            position: 'absolute',
                            top: "100px",
                            left: "500px",
                            zIndex: 1,
                          }}
                        />
                      )}
                    </Box>
                  }
                </Box>
              </Stack >
              {/* ↑ Voyage Information ↑ */}

            </Grid >
            {/* ↑ メイン左側 ↑ */}

            {/* メイン右側 */}
            <Grid item xs={3.25} sx={{height: "100%"}}>

              <Stack
                direction={"column"}
                sx={{
                  height: "100%",
                  maxHeight: "calc(100vh - 80px)",
                  // maxWidth: "200px",
                  overflowX: 'hidden',
                  overflowY: 'scroll',
                  bgcolor: 'linear-gradient(180deg, rgba(45.82, 46.41, 47.81, 0.20) 0%, rgba(65.37, 66.21, 68.21, 0.20) 20%)',

                  '&::-webkit-scrollbar': {
                    width: "6px",
                    height: "6px",
                  },
                  '&::-webkit-scrollbar-track': {
                    borderRadius: "10px",
                    background: "rgba(255, 255, 255, 0.5)",
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: "#fff",
                    borderRadius: "10px",
                  },
                  '&::-webkit-scrollbar-thumb:hover': {
                    backgroundColor: '#555',
                  },
                  "&::-webkit-scrollbar-corner": {
                    display: "none",
                  }
                }}
              >

                {/* ↓ LOG Speed Rate ↓ */}
                <Stack
                  direction='column'
                  sx={{
                    background: 'linear-gradient(180deg, rgba(45.82, 46.41, 47.81, 0.20) 0%, rgba(65.37, 66.21, 68.21, 0.20) 100%)',
                    minHeight: "252px",
                    maxHheight: "252px",
                    marginBottom: "18px"
                  }}>


                  {/* ↓ タイトル ↓ */}
                  <Box sx={{ position: "relative", height: "40px" }}>
                    <Typography sx={{ position: "absolute", top: "10px", left: "10px", color: 'white', fontSize: "15px", fontFamily: "'Roboto'", fontWeight: 'bold', wordWrap: 'break-word' }}>
                      LOG Speed Rate
                    </Typography>
                  </Box>
                  {/* ↑ タイトル ↑ */}


                  <Box sx={{ position: "relative" }}>
                    {success ?
                      <>
                        <Stack direction='column' sx={{ position: "absolute", top: "16px", left: "0px", height: "220px", width: "99%" }} >
                          <HighchartsReact
                            key={key}
                            highcharts={Highcharts}
                            options={hcOptionsLogSpeed}
                            ref={chartComponentRef}
                            allowChartUpdate={true}
                            updateArgs={[true, true, true]}
                          />
                        </Stack>
                        <Stack sx={{ position: "absolute", top: `${LOGSpeedRateNone.xtop}px`, left: `${LOGSpeedRateNone.xleft}px` }}>
                          <Typography align="left" sx={{ color: 'white', opacity: 0.50, fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                            {LOGSpeedRateNone.xname}
                          </Typography>
                        </Stack>
                        <Stack sx={{ position: "absolute", top: `${LOGSpeedRateNone.ytop}px`, left: `${LOGSpeedRateNone.yleft}px` }}>
                          <Typography align="left" sx={{ color: 'white', opacity: 0.50, fontSize: 13, fontFamily: 'Roboto', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                            {LOGSpeedRateNone.yname}
                          </Typography>
                        </Stack>
                      </>
                      :
                      <Box>
                        {loading && (
                          <CircularProgress
                            size={68}
                            sx={{
                              color: "grey",
                              position: 'absolute',
                              top: "30px",
                              left: "150px",
                              zIndex: 1,
                            }}
                          />
                        )}
                      </Box>
                    }
                  </Box>
                </Stack>
                {/* ↑ LOG Speed Rate ↑ */}

                {/* ↓ Beaufort Rate ↓ */}
                <Stack
                  direction='column'
                  spacing={0}
                  sx={{
                    background: 'linear-gradient(180deg, rgba(45.82, 46.41, 47.81, 0.20) 0%, rgba(65.37, 66.21, 68.21, 0.20) 20%)',
                    minHeight: "252px",
                    maxHeight: "252px",
                    marginBottom: "18px"
                  }}>

                  {/* ↓ タイトル ↓ */}
                  <Box sx={{ position: "relative", height: "40px" }}>
                    <Typography sx={{ position: "absolute", top: "10px", left: "10px", color: 'white', fontSize: "15px", fontFamily: "'Roboto'", fontWeight: 'bold', wordWrap: 'break-word' }}>
                      Beaufort Rate
                    </Typography>
                  </Box>
                  {/* ↑ タイトル ↑ */}

                  <Box sx={{ position: "relative" }}>
                    {success ?
                      <>
                        <Stack direction='column' sx={{ position: "absolute", top: "16px", left: "0px", height: "220px", width: "99%" }} >
                          <HighchartsReact
                            key={key}
                            highcharts={Highcharts}
                            options={hcOptionsBeaufort}
                            ref={chartComponentRef}
                            allowChartUpdate={true}
                            updateArgs={[true, true, true]}
                          />
                        </Stack>
                        <Stack sx={{ position: "absolute", top: `${BeaufortRateNone.xtop}px`, left: `${BeaufortRateNone.xleft}px` }}>
                          <Typography align="left" sx={{ color: 'white', opacity: 0.50, fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                            {BeaufortRateNone.xname}
                          </Typography>
                        </Stack>
                        <Stack sx={{ position: "absolute", top: `${BeaufortRateNone.ytop}px`, left: `${BeaufortRateNone.yleft}px` }}>
                          <Typography align="left" sx={{ color: 'white', opacity: 0.50, fontSize: 13, fontFamily: 'Roboto', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                            {BeaufortRateNone.yname}
                          </Typography>
                        </Stack>
                      </>
                      :
                      <Box>
                        {loading && (
                          <CircularProgress
                            size={68}
                            sx={{
                              color: "grey",
                              position: 'absolute',
                              top: "50px",
                              left: "150px",
                              zIndex: 1,
                            }}
                          />
                        )}
                      </Box>
                    }
                  </Box>
                </Stack>
                {/* ↑ Beaufort Rate ↑ */}

                {/* ↓ Displacement Rate ↓ */}
                <Stack
                  direction='column'
                  spacing={0}
                  sx={{
                    background: 'linear-gradient(180deg, rgba(45.82, 46.41, 47.81, 0.20) 0%, rgba(65.37, 66.21, 68.21, 0.20) 20%)',
                    minHeight: "231px",
                    maxHeight: "231px",
                    marginBottom: "18px"
                    // height: "190px",
                  }}>

                  {/* ↓ タイトル ↓ */}
                  <Box sx={{ position: "relative", height: "40px", width: "200px" }}>
                    <Typography sx={{ position: "absolute", top: "10px", left: "10px", color: 'white', fontSize: "15px", fontFamily: "'Roboto'", fontWeight: 'bold', wordWrap: 'break-word' }}>
                      Displacement Rate
                    </Typography>
                  </Box>

                  <Box sx={{ position: "relative" }}>
                    {/* ↑ タイトル ↑ */}
                    {success ?
                      <>
                        <Stack direction='column' sx={{ position: "absolute", top: "0px", left: "35px", height: "250px", width: "95%", }} >
                          <Grid item xs={10} alignItems={"center"}>
                            <HighchartsReact
                              key={key}
                              highcharts={Highcharts}
                              options={hcOptionsDisplacement}
                              ref={DisplacementchartComponentRef}
                              allowChartUpdate={true}
                              updateArgs={[true, true, true]}
                            />
                          </Grid>
                        </Stack>
                      </>
                      :
                      <Box>
                        {loading && (
                          <CircularProgress
                            size={68}
                            sx={{
                              color: "grey",
                              position: 'absolute',
                              top: "80px",
                              left: "150px",
                              zIndex: 1,
                            }}
                          />
                        )}
                      </Box>
                    }
                  </Box>
                </Stack>
                {/* ↑ Displacement Rate ↑ */}

                {/* ↓ M/E Load Rate ↓ */}
                <Stack
                  direction='column'
                  spacing={0}
                  sx={{
                    background: 'linear-gradient(180deg, rgba(45.82, 46.41, 47.81, 0.20) 0%, rgba(65.37, 66.21, 68.21, 0.20) 20%)',
                    minHeight: "252px",
                    maxHheight: "252px",
                  }}>


                  {/* ↓ タイトル ↓ */}
                  <Box sx={{ position: "relative", height: "40px" }}>
                    <Typography sx={{ position: "absolute", top: "10px", left: "10px", color: 'white', fontSize: "14px", fontFamily: "'Roboto'", fontWeight: 'bold', wordWrap: 'break-word' }}>
                      M/E Load Rate
                    </Typography>
                  </Box>
                  {/* ↑ タイトル ↑ */}


                  <Box sx={{ position: "relative" }}>
                    {success ?
                      <>
                        <Stack direction='column' sx={{ position: "absolute", top: "16px", left: "0px", height: "220px", width: "99%" }} >
                          <HighchartsReact
                            key={key}
                            highcharts={Highcharts}
                            options={hcOptionsMELoad}
                            ref={chartComponentRef}
                            allowChartUpdate={true}
                            updateArgs={[true, true, true]}
                          />
                        </Stack>
                        <Stack sx={{ position: "absolute", top: `${MELoadRateNone.xtop}px`, left: `${MELoadRateNone.xleft}px` }}>
                          <Typography align="left" sx={{ color: 'white', opacity: 0.50, fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                            {MELoadRateNone.xname}
                          </Typography>
                        </Stack>
                        <Stack sx={{ position: "absolute", top: `${MELoadRateNone.ytop}px`, left: `${MELoadRateNone.yleft}px` }}>
                          <Typography align="left" sx={{ color: 'white', opacity: 0.50, fontSize: 13, fontFamily: 'Roboto', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                            {MELoadRateNone.yname}
                          </Typography>
                        </Stack>
                      </>
                      :
                      <Box>
                        {loading && (
                          <CircularProgress
                            size={68}
                            sx={{
                              color: "grey",
                              position: 'absolute',
                              top: "30px",
                              left: "150px",
                              zIndex: 1,
                            }}
                          />
                        )}
                      </Box>
                    }
                  </Box>
                </Stack>
                {/* ↑ M/E Load Rate ↑ */}

              </Stack>


            </Grid>

          </Grid >


      </Box>
    </>
  );
} 

export default CiiEmissionBoard;