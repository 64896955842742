
// 昇順に並び替え
export const commonSortAsc = (item: string, seq: number, rows: any, activeSortNo:{ item: string, seq: Number, sort: string }, defSortItemName: string) => {

  console.log("asc sort processing.")
  
  let returnActiveSortNo = { item: '', seq: 0, sort: '' };

  const newListData = [...rows];
  if (activeSortNo.item === item && activeSortNo.sort === 'asc') { // ソート解除（初期状態の順番に戻すためにソート）
    newListData.sort((a, b) => {
      if (a[defSortItemName] > b[defSortItemName]) {
        return 1;
      } else {
        return -1;
      }
    })
    returnActiveSortNo = { item: '', seq: 0, sort: 'asc' };
  } else {
    if (seq === 1) { //文字列
      newListData.sort((a, b) => {
        if (a[item] > b[item]) {
          return 1;
        } else {
          return -1;
        }
      })
    } else { //数値
      newListData.sort((a, b) => {
        return a[item] - b[item];
      })
    }
    returnActiveSortNo = { item, seq, sort: 'asc' };
  }

  return { newRows: newListData, newActiveSortNo: returnActiveSortNo };
}

// 降順に並び替え
export const commonSortDesc = (item: string, seq: number, rows: any, activeSortNo:{ item: string, seq: Number, sort: string }, defSortItemName: string) => {

  console.log("desc sort processing.")

  let returnActiveSortNo = { item: '', seq: 0, sort: '' };

  const newListData = [...rows];
  if (activeSortNo.item === item && activeSortNo.sort === 'desc') { // ソート解除（初期状態の順番に戻すためにソート）
    newListData.sort((a, b) => {
      if (a[defSortItemName] > b[defSortItemName]) {
        return 1;
      } else {
        return -1;
      }
    })
    returnActiveSortNo = { item: '', seq: 0, sort: 'desc' };
  } else {
    if (seq === 1) { //文字列
      newListData.sort((a, b) => {
        if (a[item] < b[item]) {
          return 1;
        } else {
          return -1;
        }
      })
    } else { //数値
      newListData.sort((a, b) => {
        return b[item] - a[item];
      })
    }
    returnActiveSortNo = { item, seq, sort: 'desc' };
  }
  
  return { newRows: newListData, newActiveSortNo: returnActiveSortNo };
}