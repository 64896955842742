
import * as React from 'react';

import axios, { AxiosError } from "axios";

import './PoolingGroupSettingDialog.css'; // スタイルのために追加
import { commonSortAsc, commonSortDesc } from "../../../components/CommonSort/CommonSort";

// MUI @mui/material/
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';

// MUI @mui/icons-material/

// Dialog
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

// Select Date
import Select, { SelectChangeEvent } from '@mui/material/Select';

// アイコン
import CommonIconPlus from '../../../components/components/CommonIconPlus';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import { BootstrapInput3 } from '../../../components/styled/styled';
import MenuItem from '@mui/material/MenuItem';
import { SelectTheme } from '../../../components/theme/SelectTheme';
import VesselList from '../../DataViewer/DataViewer';

// Axios Error
interface ErrorData {
  message: string;
  status: number;
  error: string;
}

// 親コンポーネントから持ち回る値
export type Props = {
  openDialogFlg: boolean;
  setOpenDialogFlg: (value : boolean) => void;
  onClickSaveFlg: boolean;
  setOnClickSaveFlg: (value : boolean) => void;
  selectedYear: string;
  ytdGroupList: any;
  eoyGroupList: any;
  ytdAllVesselsList: any;
  setYtdAllVesselsList: (value: any) => void;
  eoyAllVesselsList: any;
  setEoyAllVesselsList: (value: any) => void;
  setPoolingGroupMojiList: (value: any) => void;
  setAuthCheck: (value: boolean) => void;
  setAuthMessage: (value: any) => void;

  SeachInfo: any;
  setSeachInfo: (value: any) => void;
}

function formatNumber(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// Pooling Group Setting Dialog Component--------------------------------------------------------------------
export const PoolingGroupSettingDialog: React.FC<Props> = props => {

  const [jwt, setJwt] = React.useState<string>();
  const [userId, setUserId] = React.useState<string>();

  // URL
  const [base_url, setBase_url] = React.useState(`${process.env.REACT_APP_API_URL}/EuEtsFuelEu/SupportTool/CalcTotal`);
  // Saveボタン活性フラグ
  const [enableSaveFlg, setEnableSaveFlg] = React.useState(false);

  // 初回のみ実行する処理
  React.useEffect(() => {

    // Groupプルダウンの文字列生成--------------
    let mojiList = ["-", "A"]; // 初期値として-、Aを設定

    // ytdGroupListの各要素で持っているgroup_nameを追加していく
    props.ytdGroupList.forEach(function(element) {
      if (element.group_name !== "A") {
        mojiList.push(element.group_name);
      }
    });
    // 生成したものを設定
    setPoolingGroupMojiList(mojiList);

    let payloadUserId = ""
    let payloadJwt = ""
    const pay_json = localStorage.getItem("payload")
    if (pay_json !== null) {
      const pay = JSON.parse(pay_json)
      payloadUserId = pay["user_id"];
      payloadJwt = pay["jwt"];
      setUserId(payloadUserId);
      setJwt(payloadJwt);
    }

  }, []);

  // Loading--------------------------------------------------------------------
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(true);

  // Cancel、×ボタンで閉じる
  const handleNoClickOpenDialog = () => {

    // Saveボタン活性フラグ初期化
    setEnableSaveFlg(false);

    // どちらのリストを使うか判定
    let dateObj = new Date();
    let thisYear = dateObj.getFullYear();
    let tmpGroupList: any = [];
    if (props.selectedYear === String(thisYear)) {
      setVesselList(props.ytdAllVesselsList);
      setCountPoolingGroupList(props.ytdGroupList.length);
      tmpGroupList = props.ytdGroupList;
    }
    else {
      setVesselList(props.eoyAllVesselsList);
      setCountPoolingGroupList(props.eoyGroupList.length);
      tmpGroupList = props.eoyGroupList;
    }

    if (tmpGroupList !== undefined && tmpGroupList.length !== 0) {
      setPoolingGroupList(tmpGroupList);
    }

    // Groupプルダウンの文字列生成--------------
    let mojiList = ["-", "A"]; // 初期値として-、Aを設定

    // ytdGroupListの各要素で持っているgroup_nameを追加していく
    tmpGroupList.forEach(function(element) {
      if (element.group_name !== "A") {
        mojiList.push(element.group_name);
      }
    });
    // 生成したものを設定
    setPoolingGroupMojiList(mojiList);

    // ソートの状態をリセット
    setActiveSortNo({ item: '', seq: 0, sort: '' });

    // ダイアログ閉じる
    props.setOpenDialogFlg(false);
  };

  // Saveボタン押下
  const handleClickSavePoolingGroup = () => {
    // Saveボタン活性フラグ初期化
    setEnableSaveFlg(false);
    // 親のVesselListを上書き
    props.setYtdAllVesselsList(vesselList);
    // 親のVesselListを上書き
    props.setEoyAllVesselsList(vesselList);
    // Saveボタンが押されてことを親に通知
    props.setOnClickSaveFlg(true);
    // PoolingGroupの文字リストを親に渡す。
    props.setPoolingGroupMojiList(poolingGroupMojiList);
    // ソートの状態をリセット
    setActiveSortNo({ item: '', seq: 0, sort: '' });
    // ダイアログ閉じる
    props.setOpenDialogFlg(false);
  };

  // ソート機能--------------------------------------------------------------------
  type ActiveSortNo = {
    item: string;
    seq: number;
    sort: string;
  }

  const [activeSortNo, setActiveSortNo] = React.useState<ActiveSortNo>({ item: '', seq: 0, sort: '' }); // seq: 文字列→1, 数値→2
  const sortAsc = (item: string, seq: number) => { //昇順に並び替え
    const { newRows, newActiveSortNo } = commonSortAsc(item, seq, vesselList, activeSortNo, "vessel_name");
    setVesselList(newRows);
    setActiveSortNo(newActiveSortNo);
  }
  const sortDesc = (item: string, seq: number) => { //降順に並び替え
    const { newRows, newActiveSortNo } = commonSortDesc(item, seq, vesselList, activeSortNo, "vessel_name");
    setVesselList(newRows);
    setActiveSortNo(newActiveSortNo);
  }
  // --------------------------------------------------------------------

  // PoolingGroup情報を保持するリスト
  const poolingGroup = {
    "group_name"     : "A",
    "total_cb"       : 0,
    "total_cb_cost"  : 0,
    "GroupedVesselList" : []
  }

  // Groupプルダウン用文字リスト
  const [poolingGroupMojiList, setPoolingGroupMojiList] = React.useState(["-","A"]);

  const [poolingGroupList, setPoolingGroupList] = React.useState([poolingGroup]);
  const [countPoolingGroupList, setCountPoolingGroupList] = React.useState(1);

  // PoolingGroup追加
  const handleAddPoolingGroup = () => {

    if (countPoolingGroupList <= 26) {
      // グループ名はA～Zの26個まで対応する。

      // グループ名作成
      let mojiCode = 65 + countPoolingGroupList; // 65はA、そこから個数分足して、該当のグループコードを出している。
      let groupMoji = String.fromCodePoint(mojiCode); // 文字コードを文字列に変換

      setCountPoolingGroupList(countPoolingGroupList + 1) 

      poolingGroupList.push({
        "group_name"      : groupMoji,
        "total_cb"        : 0,
        "total_cb_cost"   : 0,
        "GroupedVesselList" : []
      })

      // Groupプルダウン用文字リストにも、Group文字を追加
      let tempPoolingGroupMojiList = poolingGroupMojiList;
      tempPoolingGroupMojiList.push(groupMoji)
      setPoolingGroupMojiList(tempPoolingGroupMojiList);

      // Saveボタン活性フラグOn
      setEnableSaveFlg(true);
    }
  }

  // PoolingGroup削除
  const handleDeletePoolingGroup = (key: number) => {

    setCountPoolingGroupList(countPoolingGroupList - 1) 

    // 一時保存用のリスト定義
    let tempPoolingGroupList = poolingGroupList;
    tempPoolingGroupList = [];
   
    poolingGroupList.forEach(function(element, index) {
      
      if (index === key) {
        // キーがインデックスと一致する場合、削除対象のためtempPoolingGroupListには追加しない。
    
        // VesselListのうち、所属Groupが削除したGroupの場合、ハイフン（所属グループなし）に更新する。
        let deleteMojiCode = 65 + index; // 65はA、そこから個数分足して、該当のグループコードを出している。
        let deleteGroupMoji = String.fromCodePoint(deleteMojiCode); // 文字コードを文字列に変換
        
        vesselList.forEach(function(element, index) {

          // Groupが削除したGroupと一致している場合
          if (deleteGroupMoji === element.group) {

            // Groupをハイフンに変更
            const newVessel = {
              "imo"             : element.imo,
              "vessel_name"     : element.vessel_name,
              "operator"        : element.operator,
              "year_to_date"    : element.year_to_date,
              "last_year"       : element.last_year,
              "total"           : element.total,
              "penalty_factor"  : element.penalty_factor,
              "group"           : "-",
            }

            // 新しい要素で上書き
            setVesselList(prevVesselList => prevVesselList.map((item, i) => i === index ? newVessel : item)); 
          }          
        });
      }
      else {

        if (index > key) {
          // 削除したGroupの後ろにGroupがある場合、Group名を詰めて振りなおし。
          let mojiCode = 65 + index - 1; // 65はA、そこから個数分足して、該当のグループコードを出している。
          let groupMoji = String.fromCodePoint(mojiCode); // 文字コードを文字列に変換

          // 新しいGroup名を設定
          element.group_name = groupMoji;

          // 詰める前の文字を取得
          let oldMojiCode = 65 + index;
          let oldGroupMoji = String.fromCodePoint(oldMojiCode)

          vesselList.forEach(function(element, index) {

            // 詰める前のGroupと一致している場合、一つ前の文字にGroupを変更
            if (oldGroupMoji === element.group) {
  
              // Groupをハイフンに変更
              const newVessel = {
                "imo"             : element.imo,
                "vessel_name"     : element.vessel_name,
                "operator"        : element.operator,
                "year_to_date"    : element.year_to_date,
                "last_year"       : element.last_year,
                "total"           : element.total,
                "penalty_factor"  : element.penalty_factor,
                "group"           : groupMoji,
              }
  
              // 新しい要素で上書き
              setVesselList(prevVesselList => prevVesselList.map((item, i) => i === index ? newVessel : item)); 
            }          
          });
        }

        // 新しいGroup名の要素をリストに追加
        tempPoolingGroupList.push(element);
      }
    });

    // 一時保存用のリストで上書き
    setPoolingGroupList(tempPoolingGroupList); 

    // Groupプルダウン用文字リストの末尾要素を削除
    let tempPoolingGroupMojiList = poolingGroupMojiList;
    tempPoolingGroupMojiList.pop()
    setPoolingGroupMojiList(tempPoolingGroupMojiList);

    // Saveボタン活性フラグOn
    setEnableSaveFlg(true);
  }

  // テスト用の仮のVesselリスト
  const vessel1 = {
    "imo"     : "",
    "vessel_name"     : "ACX CRYSTAL",
    "operator"        :  "NYK",
    "year_to_date"    : "0",
    "last_year"       : "0",
    "total"           : "10",
    "penalty_factor"  : 1.0,
    "group"           : "A",
  }
  const [vesselList, setVesselList] = React.useState([vessel1]);

  // Pooling GroupモーダルOpen時の処理
  React.useEffect(() => {
    if (props.openDialogFlg === true) {

      // どちらのリストを使うか判定
      let dateObj = new Date();
      let thisYear = dateObj.getFullYear();
      let tmpGroupList: any = [];
      if (props.selectedYear === String(thisYear)) {
        setVesselList(props.ytdAllVesselsList);
        setCountPoolingGroupList(props.ytdGroupList.length);
          const subList = props.ytdGroupList.slice();
          tmpGroupList = subList
      }
      else {
        setVesselList(props.eoyAllVesselsList);
        setCountPoolingGroupList(props.eoyGroupList.length);
        const subList = props.eoyGroupList.slice();
        tmpGroupList = subList;
      }

      if (tmpGroupList !== undefined && tmpGroupList.length !== 0) {
        setPoolingGroupList(tmpGroupList);
      }

      // Groupプルダウンの文字列生成--------------
      let mojiList = ["-", "A"]; // 初期値として-、Aを設定

      // ytdGroupListの各要素で持っているgroup_nameを追加していく
      tmpGroupList.forEach(function(element) {
        if (element.group_name !== "A") {
          mojiList.push(element.group_name);
        }
      });
      // 生成したものを設定
      setPoolingGroupMojiList(mojiList);
    } 
  }, [props.openDialogFlg]);

  // VesselListのGroup変更
  const onSelectChangeGroup = (selectValue: string, key: number) => {
    
    let tempOldGroup: string;
    let tempCB: string;

    vesselList.forEach(function(element, index) {
      
      if (index === key) {
        // キーがインデックスと一致する場合

        // totalの再計算用に値を保持
        tempOldGroup = element.group;
        tempCB = element.total;

        const newVessel = {
          "imo"             : element.imo,
          "vessel_name"     : element.vessel_name,
          "operator"        : element.operator,
          "year_to_date"    : element.year_to_date,
          "last_year"       : element.last_year,
          "total"           : element.total,
          "penalty_factor"  : element.penalty_factor,
          "group"           : selectValue,
        }

        // 新しい要素で上書き
        setVesselList(prevVesselList => prevVesselList.map((item, i) => i === index ? newVessel : item)); 
        
        // 変更したVesselとVesselNameが一致する要素も、同じグループに変更する。
        vesselList.forEach(function(element1, index1) {

          if (element.vessel_name === element1.vessel_name) {
            
            const newVessel1 = {
              "imo"             : element.imo,
              "vessel_name"     : element1.vessel_name,
              "operator"        : element1.operator,
              "year_to_date"    : element1.year_to_date,
              "last_year"       : element1.last_year,
              "total"           : element1.total,
              "penalty_factor"  : element1.penalty_factor,
              "group"           : selectValue,
            }                        
            
            // 新しい要素で上書き
            setVesselList(prevVesselList => prevVesselList.map((item, i) => i === index1 ? newVessel1 : item));
          }
        });
      }
    });

    // Saveボタン活性フラグOn
    setEnableSaveFlg(true);
    // 再計算フラグOn
    setRecalculationFlag(true);
  }

  // VesselListが更新されたとき
  const[recalculationFlag, setRecalculationFlag] = React.useState(false);
  React.useEffect(() => {

    if (recalculationFlag) {
      // 全グループの合計値を再計算する。
      calcPooligGroupTotalValue();
      setRecalculationFlag(false);
    }

  }, [recalculationFlag])

  const calcPooligGroupTotalValue = () => {
        
    // 合計値計算用API呼び出し
    // インプット作成
    let inputValueArray: string[][] = []
    poolingGroupList.forEach(function(element1, index) {
      
      let groupName = element1.group_name;
      let tempImoArray: string[] = [];
      vesselList.forEach(function(element2, index) {
        if (groupName === element2.group && !tempImoArray.includes(element2.imo)) {
          tempImoArray.push(element2.imo);
        }
      });
      inputValueArray.push(tempImoArray) 
    });

    let result = inputValueArray
      .map(innerArray => `(${innerArray.join('-')})`) // 内側の配列をフォーマット
      .join(','); // 全体をカンマで結合

    // API呼び出し
    const url = `${base_url}/%23?&user=${userId}&data=${result}&year=${props.selectedYear}`   
    calcPoolingGroupTotal(url, jwt);
  }

  // PoolingGoupごとの合計値再計算API呼び出し
  const calcPoolingGroupTotal = (url, jwt) => {
    console.log("calcPoolingGroupTotal");

    const options = {
      headers: {
        'Authorization': jwt,
      }
    }
    console.log("URL", url);
    axios.get(url, options).then((res) => {

      console.log("axios", res);

      // 認可チェック
      if (res.data === 401) {
        props.setAuthCheck(true)
        props.setAuthMessage("不正な操作が行われた可能性があります。")
      } else if (res.data === 500) {
        props.setAuthCheck(true)
        props.setAuthMessage("JWTが改ざんされている可能性があります。")
      } else {
        props.setAuthCheck(false)
      }

      let tmpList: any[] = []
      res.data.group_total_list.forEach(function(element1, index) {
        
        let newValue = {
          "group_name": poolingGroupList[index].group_name,
          "total_cb": element1.total_cb,
          "total_cb_cost": element1.total_cb_cost,
          "GroupedVesselList": []
        }

        tmpList.push(newValue)

      });

      // PoolingGroupListを更新
      setPoolingGroupList(tmpList)     

    })
      .catch((e: AxiosError<ErrorData>) => {
        // reset_all()
        setLoading(false)
        setSuccess(true)
          if (e.response === undefined) {
          console.log("undefinedError: " + e);
          return;
        }
        console.log(e + ", message: " + e.response.data.message)
      });
  }

  return (
    <>

      {/* Pooling Group Setting Daialog */}
      <Dialog
        open={props.openDialogFlg}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "84%",
              maxWidth: "1600px",
              height: "89%",
              maxHeight: "850px",
            },
          },
        }}
        >
        <DialogTitle
          sx={{
            bgcolor: "#474C53",
            height: "60px",
            // width: "1383",
            color: "white",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontWeight: "bold"
          }}>
            Pooling Group Setting
          <IconButton
            aria-label="close"
            onClick={handleNoClickOpenDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: "rgba(255, 255, 255, 0.5)",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            background: "linear-gradient(180deg, #0E1B25 0%, #17232D 100%)",
          }}>

          <Box sx={{ height: "100%", width: "100%", overflowX: "auto"
            , 
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: "6px",
              height: "6px",
            },
            '&::-webkit-scrollbar-track': {
              borderRadius: "10px",
              background: "rgba(255, 255, 255, 0.5)",
            },
            '&::-webkit-scrollbar-thumb': {
              background: "#fff",
              borderRadius: "10px",
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#555',
            },
            "&::-webkit-scrollbar-corner": {
              display: "none",
              }
            }}>
            
            <Stack direction={"row"}>
              {/* List部 */}
              <Box sx={{ width: "950px", height: "700px" }}>
                <Box sx={{ height: "55px", width: "100%"}}></Box>
                <Box sx={{ height: "623px", width: "917px", marginLeft: "20px"}}>
                  <Box style={{ width: "100%", height: "27px", position: "relative"}}>
                    <Box style={{ width: "318px", height: "25px", left: "343px", position: 'absolute', background: 'rgba(83, 84, 89, 0.50)', }}>
                      <Typography align="center" sx={{ marginTop: "2px", color: 'rgba(255, 255, 255, 0.50)', fontSize: 14, fontFamily: 'Roboto', fontWeight: 'bold', wordWrap: 'break-word' }}>
                        Compliance Balance (TonCO2eq)
                      </Typography>
                    </Box>
                  </Box>
                  <div className="pgsd-vessel-list-table-wrapper">
                    <table className='pgsd-vessel-list-table'>
                      <thead>
                        <tr>
                          <th className="pgsd-vessel-list-vesselName">
                            <div className='common-sort-list-header-left'>
                              <div>
                                <span>Vessel Name</span>
                              </div>
                              <div className="common-sort-container">
                                <div className="common-sort-icon">
                                  <div className={(activeSortNo.item === "vessel_name" && activeSortNo.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAsc('vessel_name', 1)}></div>
                                  <div className={(activeSortNo.item === "vessel_name" && activeSortNo.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDesc('vessel_name', 1)}></div>
                                </div>
                              </div>
                            </div>
                          </th>
                          <th className="pgsd-vessel-list-operator">
                            <div className='common-sort-list-header-left'>
                              <div>
                                <span>Operator</span>
                              </div>
                              <div className="common-sort-container">
                                <div className="common-sort-icon">
                                  <div className={(activeSortNo.item === "operator" && activeSortNo.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAsc('operator', 1)}></div>
                                  <div className={(activeSortNo.item === "operator" && activeSortNo.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDesc('operator', 1)}></div>
                                </div>
                              </div>
                            </div>
                          </th>
                          <th className="pgsd-vessel-list-year-to-date">
                            <div className='common-sort-list-header-right'>
                              <div>
                                <span>Year to Date</span>
                              </div>
                              <div className="common-sort-container">
                                <div className="common-sort-icon">
                                  <div className={(activeSortNo.item === "year_to_date" && activeSortNo.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAsc('year_to_date', 2)}></div>
                                  <div className={(activeSortNo.item === "year_to_date" && activeSortNo.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDesc('year_to_date', 2)}></div>
                                </div>
                              </div>
                            </div>
                          </th>
                          <th className="pgsd-vessel-list-last-year">
                            <div className='common-sort-list-header-right'>
                              <div>
                                <span>Last Year</span>
                              </div>
                              <div className="common-sort-container">
                                <div className="common-sort-icon">
                                  <div className={(activeSortNo.item === "last_year" && activeSortNo.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAsc('last_year', 2)}></div>
                                  <div className={(activeSortNo.item === "last_year" && activeSortNo.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDesc('last_year', 2)}></div>
                                </div>
                              </div>
                            </div>
                          </th>
                          <th className="pgsd-vessel-list-total">
                            <div className='common-sort-list-header-right'>
                              <div>
                                <span>Total</span>
                              </div>
                              <div className="common-sort-container">
                                <div className="common-sort-icon">
                                  <div className={(activeSortNo.item === "total" && activeSortNo.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAsc('total', 2)}></div>
                                  <div className={(activeSortNo.item === "total" && activeSortNo.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDesc('total', 2)}></div>
                                </div>
                              </div>
                            </div>
                          </th>
                          <th className="pgsd-vessel-list-penalty-factor">
                            <div className='common-sort-list-header-right'>
                              <div style={{ paddingRight: "20px" }}>
                                <span>Penalty<br/>Factor</span>
                              </div>
                              <div className="common-sort-container" style={{ position: "absolute", top: "10px", left: "85px" }}>
                                <div className="common-sort-icon">
                                  <div className={(activeSortNo.item === "penalty_factor" && activeSortNo.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAsc('penalty_factor', 2)}></div>
                                  <div className={(activeSortNo.item === "penalty_factor" && activeSortNo.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDesc('penalty_factor', 2)}></div>
                                </div>
                              </div>
                            </div>
                          </th>
                          <th className="pgsd-vessel-list-group">
                            <div className='common-sort-list-header-center'>
                              <div>
                                <span>Group</span>
                              </div>
                              <div className="common-sort-container">
                                <div className="common-sort-icon">
                                  <div className={(activeSortNo.item === "group" && activeSortNo.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAsc('group', 1)}></div>
                                  <div className={(activeSortNo.item === "group" && activeSortNo.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDesc('group', 1)}></div>
                                </div>
                              </div>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          vesselList.map((element, index) => {
                            return (
                              <tr>
                                <td className='pgsd-vessel-list-vesselName'>
                                  <span className="inner-text" title={`${element.vessel_name}`}>
                                    {element.vessel_name}
                                  </span>
                                </td>
                                <td className='pgsd-vessel-list-operator'>
                                  <span className="inner-text" title={`${element.operator}`}>
                                    {element.operator}
                                  </span>
                                </td>
                                <td className='pgsd-vessel-list-year-to-date'>
                                  <span className="inner-text" title={`${element.year_to_date}`}>
                                    {element.year_to_date}
                                  </span>
                                </td>
                                <td className='pgsd-vessel-list-last-year'>
                                  <span className="inner-text" title={`${element.last_year}`}>
                                    {element.last_year}
                                  </span>
                                </td>
                                <td className='pgsd-vessel-list-total'>
                                  <span className="inner-text" title={`${element.total}`}>
                                    {element.total}
                                  </span>
                                </td>
                                <td className='pgsd-vessel-list-penalty-factor'>
                                  <span className="inner-text" title={`${element.penalty_factor}`}>
                                    {element.penalty_factor === 1 ? element.penalty_factor.toFixed(1) : element.penalty_factor}
                                  </span>
                                </td>
                                <td className='pgsd-vessel-list-group'>
                                  <ThemeProvider theme={SelectTheme}>
                                    <FormControl>
                                      <Select
                                        IconComponent={() =>
                                          <div style={{ position: "absolute", top: "14px", left: "55px" }}>
                                            <InputAdornment position="start" disablePointerEvents>
                                              <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.7)", borderRight: "solid 2px rgb(255, 255, 255, 0.7)", width: "8px", height: "8px", transform: "rotate(135deg)" }}></Box>
                                            </InputAdornment>
                                          </div>
                                        }
                                        MenuProps={{
                                          MenuListProps: {
                                            disablePadding: true,

                                          },
                                          style: { zIndex: 100000, }
                                        }}
                                        sx={{
                                          width: 75,
                                          height: 32,
                                          color: "white",
                                          borderRadius: 30,
                                          ":hover": { backgroundColor: "#7a86a0" }
                                        }}
                                        input={<BootstrapInput3 />}
                                        onChange={(event: SelectChangeEvent) => { onSelectChangeGroup(event.target.value, index) }}
                                        value={element.group}
                                      >
                                        {poolingGroupMojiList.map((gropName) => {
                                          return (
                                            <MenuItem key={gropName} value={gropName}>{gropName}</MenuItem>
                                          )
                                        })}
                                      </Select>
                                    </FormControl>
                                  </ThemeProvider>
                                </td> 
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                </Box>
              </Box>
              {/* Plooling Group部 */}
              <Box sx={{ width: "580px", height: "700px", }}>
                <Box 
                  sx={{ 
                    width: "535px", height: "634px", marginTop: "64px", marginLeft: "auto", overflowY: "auto",
                  
                    '&::-webkit-scrollbar': {
                      width: "6px",
                      height: "6px",
                    },
                    '&::-webkit-scrollbar-track': {
                      borderRadius: "10px",
                      background: "rgba(255, 255, 255, 0.5)",
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: "#fff",
                      borderRadius: "10px",
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                      backgroundColor: '#555',
                    },
                    "&::-webkit-scrollbar-corner": {
                      display: "none",
                    }                 
                   
                  }}>
                  
                  {/* Pooling Group */}
                  {poolingGroupList.map((item, index) => {
                    return (
                      <Box>
                        <Box sx={{ border:"1px dotted rgba(169, 169, 169, 0.7)", height: "99px", width: "525px",
                          backgroundColor: "rgba(169, 169, 169, 0.05)"
                          }}>
                          
                          {/* ×ボタン ※「Pooling Group A」以外の時に表示する*/}
                          <Box sx={{ position: "relative", height: "1px" }}>
                            {item.group_name !== "A" ?
                              <IconButton
                                aria-label="close"
                                
                                onClick={() => handleDeletePoolingGroup(index)}
                                sx={{
                                  position: "absolute",
                                  top: "-2px",
                                  right: "2px",
                                  color: "rgba(255, 255, 255, 0.5)",
                                }}
                              >
                                <CloseIcon />
                              </IconButton>
                              :
                              <></>
                            }
                          </Box>

                          {/* Group名 */}
                          <Box sx={{ height: "46px", width: "100%",}}>
                            <Box sx={{ color: "white", fontSize: "14px", fontWeight: "bold",
                              paddingTop: "15px", paddingLeft: "15px"
                            }}>
                              Pooling Group {item.group_name}
                            </Box>
                          </Box>

                          {/* 値 */}
                          <Box sx={{ height: "53px", width: "100%" }}>
                            <Stack direction={"row"}
                              sx={{ height: "30px", width: "486px", backgroundColor: "rgba(114, 115, 117, 0.15)", marginLeft: "15px" }}>
                              <Box sx={{ width: "19px" }}></Box>
                              {/* Total CB */}
                              <Box sx={{ 
                                width: "199px", color: "rgba(255, 255, 255, 0.5)", fontSize: "14px", fontWeight: "bold",
                                display: "flex", alignItems: "center"}}>
                                <Box sx={{width:"75px"}}>Total CB :</Box>
                                <Box sx={{width:"100px", color: "white", fontSize: "15px", fontWeight: "400"}}>{item.total_cb}</Box>  
                              </Box>
                              {/* Total CB Cost */}
                              <Box sx={{ 
                                width: "250px", color: "rgba(255, 255, 255, 0.5)", fontSize: "14px", fontWeight: "bold",
                                display: "flex", alignItems: "center"}}>
                                <Box sx={{width:"107px"}}>Total CB Cost :</Box>
                                <Box sx={{display: "flex", width:"120px", }}>
                                  <Box sx={{ color: "white", fontSize: "15px", fontWeight: "400", }}>
                                    {formatNumber(item.total_cb_cost)}
                                  </Box>
                                  <Box sx={{ color: "rgba(255, 255, 255, 0.5)", fontSize: "16px", fontWeight: "400", marginLeft: "5px" }}>€</Box>
                                </Box>
                              </Box>   
                            </Stack>
                          </Box>
                        </Box>
                        <Box sx={{height: "35px"}}></Box>
                      </Box>
                    )
                  })}

                  {/* プラスボタン */}
                  <Stack sx={{ position: "relative", width: "100%", height: "10px" }} alignItems={'center'} >                         
                    <IconButton className='plusButton'
                      sx ={{ height : "18px", padding: 0, borderRadius: 30, position: "absolute", bottom: "-2px" }}
                      onClick={() => handleAddPoolingGroup()}
                      >
                      <CommonIconPlus />                                            
                    </IconButton>
                  </Stack>

                </Box>
              </Box>
            </Stack>

            
            
            
          </Box>


        </DialogContent>
        <DialogActions sx={{
          height: "60px",
          bgcolor: "#474C53"
          }}>
          <Button
            sx={{
              marginLeft: "0px",
              marginRight: "-8px",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              width: "300px",
              height: "60px",
              padding: "0",
              fontSize: "18px",
              fontFamily: "'Roboto', 'Meiryo', sans-serif",
              fontWeight: "bold",
              color: "white",
              borderRadius: "0px",
              ":hover": {
                bgcolor: "#FFFFFF",
                color: "#40475D",
              },
              textTransform: 'none'
            }}
            onClick={handleNoClickOpenDialog}
          >
            Cancel
          </Button>
          <Button
            sx={{
              marginLeft: "0px",
              marginRight: "-8px",
              backgroundColor : enableSaveFlg ? "#e66300" : "rgba(230, 99, 0, 0.15)",
              width: "300px",
              height: "60px",
              padding: "0",
              fontSize: "18px",
              fontFamily: "'Roboto', 'Meiryo', sans-serif",
              fontWeight: "bold",
              color: "white",
              borderRadius: "0px",
              ":hover": {
                bgcolor: "#FFFFFF",
                color: "#40475D",
              },
              textTransform: 'none'
            }}
            onClick={handleClickSavePoolingGroup}
            disabled={enableSaveFlg ? false : true}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
} 

export default PoolingGroupSettingDialog;