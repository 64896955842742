// 日付の形式チェック
export const checkDateType = (date: string) => {

    if (date === undefined) { 
      return true;
    }
    const month = date.substring(5, 7);
    const checkDate = new Date(date);
    if (!isNaN(checkDate.getDate())) {
      if (checkDate.getMonth() + 1 === Number(month)) {
        return true;
      }
    }
    return false;
};