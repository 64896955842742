
import * as React from 'react';

import axios, { AxiosError } from "axios";

import './CiiList.css'; // スタイルのために追加
import { commonSortAsc, commonSortDesc } from "../../../components/CommonSort/CommonSort";

// MUI @mui/material/
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';
import { BootstrapInput2 } from '../../../components/styled/styled';
import MenuItem from '@mui/material/MenuItem';
import { SelectTheme } from '../../../components/theme/SelectTheme';
import Input from '@mui/material/Input';
import Problem_icon_svg from '../../../assets/images/problem_icon.svg';

// MUI @mui/icons-material/
// @mui/icons-material/
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import HighchartsReact from 'highcharts-react-official';

// Highcharts
import * as Highcharts from 'highcharts';

interface payload {
  role: string | null
  user_id: string | null
  group_id: string | null
  company_id: string | null
  gid: string | null
  gidList: Array<string>
  imoList: Array<string>
  jwt: string | null
}

// Axios Error
interface ErrorData {
  message: string;
  status: number;
  error: string;
}

// 親コンポーネントから持ち回る値
export type Props = {
  activeTab: any;
  setActiveTab: (value: any) => void;
  unit: any;
  setUnit: (value: any) => void;
  openDrwer: any;
  setOpenDrwer: (value: any) => void;
  tabMarginSize: any;
  setTabMarginSize: (value: any) => void;
  tabSize: any;
  setTabSize: (value: any) => void;
  vesselName: any;
  setVesselName: (value: any) => void;
  changeGroupFlg: boolean;
  setChangeGroupFlg: (value: boolean) => void;

  SeachInfo: any;
  setSeachInfo: (value: any) => void;
}

const ciiSize = "16px"

function formatNumber(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// Cii List Component--------------------------------------------------------------------
export const CiiList: React.FC<Props> = props => {

  const [yearMap, setYearMap] = React.useState<any>([]);
  const [todayYear, setTodayYear] = React.useState<String>("");
  let initFlg = true;

  const [role, setRole] = React.useState<string>();
  const [jwt, setJwt] = React.useState<string>();
  const [userId, setUserId] = React.useState<string>();

  // 初回のみ実行する処理
  React.useEffect(() => {

    // Yearプルダウンに表示するmap生成--------------
    let dateObj = new Date();
    let todayYear = dateObj.getFullYear();
    let yearList = ["2024"];

    setTodayYear(String(todayYear));

    // 画面初期表示用にセット
    setSelectedYear(String(todayYear));

    // Yearが2024になるまで、Mapに年を追加（2024年が最古）
    let tmpYear = todayYear;
    while (2024 != tmpYear) {
      yearList.unshift(String(tmpYear))
      tmpYear = tmpYear - 1;
    }
    setYearMap(yearList);

    // Yearプルダウンの初期値として、今年の日付をセット
    // setSelectedYear(String(todayYear))

    // API呼び出し
    // 初回のみ、必要項目をpayloadから取得
    let payloadUserId = ""
    let payloadJwt = ""
    let payloadRole = ""
    const pay_json = localStorage.getItem("payload")
    if (pay_json !== null) {
      const pay = JSON.parse(pay_json)
      payloadUserId = pay["user_id"];
      payloadJwt = pay["jwt"];
      payloadRole = pay["role"];
      setUserId(pay["user_id"]);
      setJwt(pay["jwt"]);
      setRole(pay["role"]);
    }
 
    if (initFlg) {
    
      let url = "";
      
      // gidが空ではない場合（他画面からの遷移を想定）
      if (props.SeachInfo.gid !== "") {
        url = `${base_url}%23?&user=${payloadUserId}&group=${props.SeachInfo.gid}&year=${String(todayYear)}`;
      }
      // 上記以外の場合（ブラウザ更新による初期表示を想定）
      else {
        url = `${base_url}%23?&user=${payloadUserId}&init=true&year=${String(todayYear)}`;
      }
    
      getCiiVesselList(url, payloadJwt, payloadRole);
      initFlg = false;
    }

  }, []);

  // Loading--------------------------------------------------------------------
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(true);

  // EmissionBoardに遷移--------------------------------------------------------------------
  const handleClickCiiEmissionBoard = (index, imoNo, from, to, unit, vesselName) => {
    props.setSeachInfo({ imoNo: imoNo, from: from, to: to, gid: props.SeachInfo.gid, gidList: props.SeachInfo.gidList, imoList: props.SeachInfo.imoList })
    console.log("props.SeachInfo:" + from);
    props.setUnit(unit);
    props.setActiveTab(1);
    props.setOpenDrwer(true);
    props.setTabMarginSize(0.025);
    props.setTabSize(3.98);
    props.setVesselName(vesselName);
  };

  // Simulationに遷移--------------------------------------------------------------------
  const handleClickCiiSimulation = (index, imoNo, from, to, vesselName) => {
    props.setSeachInfo({ imoNo: imoNo, from: from, to: to, gid: props.SeachInfo.gid, gidList: props.SeachInfo.gidList, imoList: props.SeachInfo.imoList })
    console.log("props.SeachInfo:" + from);
    props.setActiveTab(2);
    props.setOpenDrwer(true);
    props.setTabMarginSize(0.025);
    props.setTabSize(3.98);
    props.setVesselName(vesselName);
  };

  // Group--------------------------------------------------------------------
  const selectGroup = (event: SelectChangeEvent) => {

    let val = event.target.value as string;
    let gid = "";

    if (val === "ALL") {
      gid = "admin";
    }
    else if (val === "My Fleet") {
      gid = "Favorite";
    }
    else {
      gid = val;
    }

      // ソートの状態をリセット
      setActiveSortNo({ item: '', seq: 0, sort: '' });

    props.setSeachInfo({ 
      imoNo: ``, from: props.SeachInfo.from, to: props.SeachInfo.to, 
      gid: `${gid}`, gidList: props.SeachInfo.gidList, 
      imoList: props.SeachInfo.imoList })

    props.setChangeGroupFlg(true);

    onClickGroup()
  };
  const selectGroupAction = (gid) => {
    setLoading(true)
    setSuccess(false)
    const url = `${base_url}%23?&user=${userId}&group=${gid}&year=${selectedYear}`
    getCiiVesselList(url, jwt, role)
  }

  const [changeClickGroup, setChangeClickGroup] = React.useState<number>(0);

  const onClickGroup = () => {
    setChangeClickGroup((prev) => (prev + 1));
  }
  React.useEffect(() => {
    if (changeClickGroup > 0) {
      selectGroupAction(props.SeachInfo.gid)
    }
  }, [changeClickGroup])

  // Year--------------------------------------------------------------------
  const [selectedYear, setSelectedYear] = React.useState<string>("");
  const selectYear = (event: SelectChangeEvent) => {

    // ソートの状態をリセット
    setActiveSortNo({ item: '', seq: 0, sort: '' });

    setSelectedYear(event.target.value)
    onClickYear()
  };
  const selectYearAction = (year) => {
    setLoading(true)
    setSuccess(false)
    const url = `${base_url}%23?&user=${userId}&group=${props.SeachInfo.gid}&year=${year}`
    getCiiVesselList(url, jwt, role)
  }

  const [changeClickYear, setChangeClickYear] = React.useState<number>(0);

  const onClickYear = () => {
    setChangeClickYear((prev) => (prev + 1));
  }
  React.useEffect(() => {
    if (changeClickYear > 0) {
      selectYearAction(selectedYear)
    }
  }, [changeClickYear])

  // URL
  const [base_url, setBase_url] = React.useState(`${process.env.REACT_APP_API_URL}/CiiAnalysis/List/`);

  // CiiVesselList取得データ
  const [rows, setRows] = React.useState<any>([])
  // CiiVesselList取得データ（元データ保持用）
  const [rowsDefoult, setRowsDefoult] = React.useState<any>([])

  // CiiVesselList取得--------------------------------------------------------------------
  const getCiiVesselList = (url, jwt, role) => {
    console.log("url:", url);
    console.log("role:", role);
    
    // Loading表示
    setLoading(true);
    setSuccess(false);
    
    const options = {
      headers: {
        'Authorization': jwt,
      }
    }
    axios.get(url, options).then((res) => {
      console.log("axios[CiiVesselList]", res)

      props.setSeachInfo({ imoNo: `${props.SeachInfo.imoNo}`, from: props.SeachInfo.from, to: props.SeachInfo.to, gid: res.data.datas.gid, gidList: res.data.datas.gidList, imoList: res.data.datas.imoList })

      if (role !== undefined) {
        localStorage.clear()
        let Payload: payload
        Payload = {
          role: role,
          user_id: res.data.datas.user_id,
          group_id: res.data.datas.group_id,
          company_id: res.data.datas.company_id,
          gid: res.data.datas.gid,
          gidList: res.data.datas.gidList,
          imoList: res.data.datas.imoList,
          jwt: jwt
        }
        localStorage.setItem("payload", JSON.stringify(Payload))
      }
      setRows(res.data.datas.rows)
  
      // 元データ保持用のオブジェクトにもセット
      setRowsDefoult(res.data.datas.rows)

      // 画面上部 Total関連計算メソッド呼び出し
      calcCiiRatio(res.data.datas.rows)

      // Loading解除
      setLoading(false)
      setSuccess(true)
    })
      .catch((e: AxiosError<ErrorData>) => {
        setLoading(false)
        setSuccess(true)
        if (e.response === undefined) {
          console.log("undefinedError: " + e);
          return;
        }
        console.log(e + ", message: " + e.response.data.message);
      });
  }

  type ActiveSortNo = {
    item: string;
    seq: number;
    sort: string;
  }

  // ソート機能--------------------------------------------------------------------
  const [activeSortNo, setActiveSortNo] = React.useState<ActiveSortNo>({ item: '', seq: 0, sort: '' }); // seq: 文字列→1, 数値→2
  const sortAsc = (item: string, seq: number) => { //昇順に並び替え
    const { newRows, newActiveSortNo } = commonSortAsc(item, seq, rows, activeSortNo, "VesselName");
    setRows(newRows);
    setActiveSortNo(newActiveSortNo);
  }
  const sortDesc = (item: string, seq: number) => { //降順に並び替え
    const { newRows, newActiveSortNo } = commonSortDesc(item, seq, rows, activeSortNo, "VesselName");
    setRows(newRows);
    setActiveSortNo(newActiveSortNo);
  }

  // VesselListのフィルタ処理
  const handleFilterVesselList = (value) => {

    // 予備品名フィルタ値
    let filterValVesselName = String(value).toUpperCase();

    // 一覧（元データ）を取得
    let tableData = rowsDefoult.concat();

    // フィルタに入力ありの場合は処理
    let filterTableData = tableData;
    if (filterValVesselName !== "") {
      // フィルタ処理
      filterTableData = [];
      for (const rowData of tableData) {
        // フィルタ対象のテーブル項目値を取得
        const colDataPartsName = String(rowData.VesselName).toUpperCase();

        // フィルタ入力値がVesselNameに一致するかチェック
        let pushFlgVesselName = false;

        if (filterValVesselName === "") {
          pushFlgVesselName = true;
        } else if (colDataPartsName.indexOf(filterValVesselName) > -1) {
          pushFlgVesselName = true;
        }

        // 一致があった場合は追加
        if (pushFlgVesselName) {
          filterTableData.push(rowData);
        }
      }
    }
    // フィルタした一覧を画面表示
    setRows(filterTableData);
  }

  // TotalVessels
  const[totalDataList, setTotalDataList] = React.useState<number[][]>([[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0]])
  const[totalVesselCountList, setTotalVesselCountList] = React.useState<number[]>([])

  // Total欄の値が変わった時に実行する処理
  React.useEffect(() => {
    // 画面上部 Totalのグラフ設定メソッド呼び出し
    setHichartsData();
  }, [totalDataList]);

  // 画面上部のTotal関連の計算
  const calcCiiRatio = (rows) => {

    let l4w_ciiCount_A = 0;
    let l4w_ciiCount_B = 0;
    let l4w_ciiCount_C = 0;
    let l4w_ciiCount_D = 0;
    let l4w_ciiCount_E = 0;
    let ytd_ciiCount_A = 0;
    let ytd_ciiCount_B = 0;
    let ytd_ciiCount_C = 0;
    let ytd_ciiCount_D = 0;
    let ytd_ciiCount_E = 0;
    let eoy_ciiCount_A = 0;
    let eoy_ciiCount_B = 0;
    let eoy_ciiCount_C = 0;
    let eoy_ciiCount_D = 0;
    let eoy_ciiCount_E = 0;
    let ly_ciiCount_A = 0;
    let ly_ciiCount_B = 0;
    let ly_ciiCount_C = 0;
    let ly_ciiCount_D = 0;
    let ly_ciiCount_E = 0;

    // 取得データ件数分繰り返し、集計期間ごとのCIIスコアごとの件数を算出
    for (const rowData of rows) {

      // Last 4 Weeks
      if (rowData.l4w_cii_score === "A") {
        l4w_ciiCount_A += 1;
      }
      else if (rowData.l4w_cii_score === "B") {
        l4w_ciiCount_B += 1;
      }
      else if (rowData.l4w_cii_score === "C") {
        l4w_ciiCount_C += 1;
      }
      else if (rowData.l4w_cii_score === "D") {
        l4w_ciiCount_D += 1;
      }
      else if (rowData.l4w_cii_score === "E") {
        l4w_ciiCount_E += 1;
      }

      // Year to Date
      if (rowData.ytd_cii_score === "A") {
        ytd_ciiCount_A += 1;
      }
      else if (rowData.ytd_cii_score === "B") {
        ytd_ciiCount_B += 1;
      }
      else if (rowData.ytd_cii_score === "C") {
        ytd_ciiCount_C += 1;
      }
      else if (rowData.ytd_cii_score === "D") {
        ytd_ciiCount_D += 1;
      }
      else if (rowData.ytd_cii_score === "E") {
        ytd_ciiCount_E += 1;
      }
    
      // End of Year
      if (rowData.eoy_cii_score === "A") {
        eoy_ciiCount_A += 1;
      }
      else if (rowData.eoy_cii_score === "B") {
        eoy_ciiCount_B += 1;
      }
      else if (rowData.eoy_cii_score === "C") {
        eoy_ciiCount_C += 1;
      }
      else if (rowData.eoy_cii_score === "D") {
        eoy_ciiCount_D += 1;
      }
      else if (rowData.eoy_cii_score === "E") {
        eoy_ciiCount_E += 1;
      }

      // Last Year
      if (rowData.ly_cii_score === "A") {
        ly_ciiCount_A += 1;
      }
      else if (rowData.ly_cii_score === "B") {
        ly_ciiCount_B += 1;
      }
      else if (rowData.ly_cii_score === "C") {
        ly_ciiCount_C += 1;
      }
      else if (rowData.ly_cii_score === "D") {
        ly_ciiCount_D += 1;
      }
      else if (rowData.ly_cii_score === "E") {
        ly_ciiCount_E += 1;
      }
    }

    // 集計期間ごとの合計値算出
    let tmpTotalVesselCount_l4w = l4w_ciiCount_A + l4w_ciiCount_B + l4w_ciiCount_C + l4w_ciiCount_D + l4w_ciiCount_E;
    let tmpTotalVesselCount_ytd = ytd_ciiCount_A + ytd_ciiCount_B + ytd_ciiCount_C + ytd_ciiCount_D + ytd_ciiCount_E;
    let tmpTotalVesselCount_eoy = eoy_ciiCount_A + eoy_ciiCount_B + eoy_ciiCount_C + eoy_ciiCount_D + eoy_ciiCount_E;
    let tmpTotalVesselCount_ly = ly_ciiCount_A + ly_ciiCount_B + ly_ciiCount_C + ly_ciiCount_D + ly_ciiCount_E;
    
    // 集計期間ごとかつCiiスコアごとの割合算出
    let l4w_ciiRatio_A = tmpTotalVesselCount_l4w > 0 ? Math.round(l4w_ciiCount_A / tmpTotalVesselCount_l4w * 100) : 0;
    let l4w_ciiRatio_B = tmpTotalVesselCount_l4w > 0 ? Math.round(l4w_ciiCount_B / tmpTotalVesselCount_l4w * 100) : 0;
    let l4w_ciiRatio_C = tmpTotalVesselCount_l4w > 0 ? Math.round(l4w_ciiCount_C / tmpTotalVesselCount_l4w * 100) : 0;
    let l4w_ciiRatio_D = tmpTotalVesselCount_l4w > 0 ? Math.round(l4w_ciiCount_D / tmpTotalVesselCount_l4w * 100) : 0;
    let l4w_ciiRatio_E = tmpTotalVesselCount_l4w > 0 ? Math.round(l4w_ciiCount_E / tmpTotalVesselCount_l4w * 100) : 0;
    let ytd_ciiRatio_A = tmpTotalVesselCount_ytd > 0 ? Math.round(ytd_ciiCount_A / tmpTotalVesselCount_ytd * 100) : 0;
    let ytd_ciiRatio_B = tmpTotalVesselCount_ytd > 0 ? Math.round(ytd_ciiCount_B / tmpTotalVesselCount_ytd * 100) : 0;
    let ytd_ciiRatio_C = tmpTotalVesselCount_ytd > 0 ? Math.round(ytd_ciiCount_C / tmpTotalVesselCount_ytd * 100) : 0;
    let ytd_ciiRatio_D = tmpTotalVesselCount_ytd > 0 ? Math.round(ytd_ciiCount_D / tmpTotalVesselCount_ytd * 100) : 0;
    let ytd_ciiRatio_E = tmpTotalVesselCount_ytd > 0 ? Math.round(ytd_ciiCount_E / tmpTotalVesselCount_ytd * 100) : 0;
    let eoy_ciiRatio_A = tmpTotalVesselCount_eoy > 0 ? Math.round(eoy_ciiCount_A / tmpTotalVesselCount_eoy * 100) : 0;
    let eoy_ciiRatio_B = tmpTotalVesselCount_eoy > 0 ? Math.round(eoy_ciiCount_B / tmpTotalVesselCount_eoy * 100) : 0;
    let eoy_ciiRatio_C = tmpTotalVesselCount_eoy > 0 ? Math.round(eoy_ciiCount_C / tmpTotalVesselCount_eoy * 100) : 0;
    let eoy_ciiRatio_D = tmpTotalVesselCount_eoy > 0 ? Math.round(eoy_ciiCount_D / tmpTotalVesselCount_eoy * 100) : 0;
    let eoy_ciiRatio_E = tmpTotalVesselCount_eoy > 0 ? Math.round(eoy_ciiCount_E / tmpTotalVesselCount_eoy * 100) : 0;
    let ly_ciiRatio_A = tmpTotalVesselCount_ly > 0 ? Math.round(ly_ciiCount_A / tmpTotalVesselCount_ly * 100) : 0;
    let ly_ciiRatio_B = tmpTotalVesselCount_ly > 0 ? Math.round(ly_ciiCount_B / tmpTotalVesselCount_ly * 100) : 0;
    let ly_ciiRatio_C = tmpTotalVesselCount_ly > 0 ? Math.round(ly_ciiCount_C / tmpTotalVesselCount_ly * 100) : 0;
    let ly_ciiRatio_D = tmpTotalVesselCount_ly > 0 ? Math.round(ly_ciiCount_D / tmpTotalVesselCount_ly * 100) : 0;
    let ly_ciiRatio_E = tmpTotalVesselCount_ly > 0 ? Math.round(ly_ciiCount_E / tmpTotalVesselCount_ly * 100) : 0;

    // 結果をリストにセット
    let resultCountList = [tmpTotalVesselCount_l4w, tmpTotalVesselCount_ytd, tmpTotalVesselCount_eoy, tmpTotalVesselCount_ly]
    let resultList = [
      [l4w_ciiRatio_A, l4w_ciiCount_A], [l4w_ciiRatio_B, l4w_ciiCount_B], [l4w_ciiRatio_C, l4w_ciiCount_C], [l4w_ciiRatio_D, l4w_ciiCount_D], [l4w_ciiRatio_E, l4w_ciiCount_E],
      [ytd_ciiRatio_A, ytd_ciiCount_A], [ytd_ciiRatio_B, ytd_ciiCount_B], [ytd_ciiRatio_C, ytd_ciiCount_C], [ytd_ciiRatio_D, ytd_ciiCount_D], [ytd_ciiRatio_E, ytd_ciiCount_E],
      [eoy_ciiRatio_A, eoy_ciiCount_A], [eoy_ciiRatio_B, eoy_ciiCount_B], [eoy_ciiRatio_C, eoy_ciiCount_C], [eoy_ciiRatio_D, eoy_ciiCount_D], [eoy_ciiRatio_E, eoy_ciiCount_E],
      [ly_ciiRatio_A, ly_ciiCount_A], [ly_ciiRatio_B, ly_ciiCount_B], [ly_ciiRatio_C, ly_ciiCount_C], [ly_ciiRatio_D, ly_ciiCount_D], [ly_ciiRatio_E, ly_ciiCount_E]
    ]
    
    setTotalVesselCountList(resultCountList)
    setTotalDataList(resultList);
    
  }

  // CiiScoreの円グラフのHighchatsオプション
  const [key, setKey] = React.useState(0);
  const CiiRateOptions = {
    navigation: {
      buttonOptions: {
        enabled: false,
      }
    },
    credits: {
      enabled: false
    },
    chart: {
      type: 'pie',
      marginTop: 15,
      marginLeft: 35,
      height: 160,
      alignTicks: false,
      backgroundColor: 'rgba(0, 0, 0, 0)',
      borderWidth: 0,
    },
    title: {
      text: 'Score</br>Rate',
      align: 'center',
                  verticalAlign: 'middle',
                  x: 10,
                  y: 15,
                  style: {
                    fontSize: '14px',       // フォントサイズ
                    color: 'rgba(255, 255, 255, 0.5)',       // フォントカラー
                    fontWeight: '400', 
                    fontFamily: 'Roboto Condensed, Arial, sans-serif' // フォントファミリ
                  }
    },
    subtitle: {
      text: ''
    },
    tooltip: {
      enabled: false,
      headerFormat: '<span style="font-size:10px">Displacement</span><table>',
      pointFormat: '<tr>'
        + '<p><td style="color:{series.color};padding:0">DataRange: </td>'
        + '<td style="padding:0"><b>{point.x:.0f} MT</br></td></p>'
        + '</tr>'
        + '<tr>'
        + '<p><td style="color:{series.color};padding:0">Rate: </td>'
        + '<td style="padding:0"><b>{point.y:.1f} %</br></td></p>'
        + '</tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true
    },
    plotOptions: {
      pie: {
        size: '85%',
        innerSize: '75%',
        borderRadius: "0%",
        borderWidth: 0,
        colorByPoint: true,
        dataLabels: {
          enabled: false
        },
        point: {
          events: {}
        }
      }
    },
    series: [{
      name: '',
      data: []
    }],
  };
  const [hcOptionsCiiRate_l4w, setHcOptionsCiiRate_l4w] = React.useState<any>(CiiRateOptions)
  const [hcOptionsCiiRate_ytd, setHcOptionsCiiRate_ytd] = React.useState<any>(CiiRateOptions)
  const [hcOptionsCiiRate_eoy, setHcOptionsCiiRate_eoy] = React.useState<any>(CiiRateOptions)
  const [hcOptionsCiiRate_ly, setHcOptionsCiiRate_ly] = React.useState<any>(CiiRateOptions)

  const setHichartsData = () => {

    const newHcOptionsCiiRate_l4w = { ...hcOptionsCiiRate_l4w };
    const newHcOptionsCiiRate_ytd = { ...hcOptionsCiiRate_ytd };
    const newHcOptionsCiiRate_eoy = { ...hcOptionsCiiRate_eoy };
    const newHcOptionsCiiRate_ly = { ...hcOptionsCiiRate_ly };

    // Last 4 Weeks
    newHcOptionsCiiRate_l4w.series = {
      data: [
        { name: 'A', y: totalDataList[0][0], color: 'rgba(0, 144, 206, 0.5)' }, // CiiScore A
        { name: 'B', y: totalDataList[1][0], color: 'rgba(6, 165, 80, 0.5)' }, // CiiScore B
        { name: 'C', y: totalDataList[2][0], color: 'rgba(212, 195, 38, 0.5)' }, // CiiScore C
        { name: 'D', y: totalDataList[3][0], color: 'rgba(255, 103, 103, 0.55)' }, // CiiScore D
        { name: 'E', y: totalDataList[4][0], color: 'rgba(255, 47, 47, 0.55)' } // CiiScore E
      ]
    }
    setHcOptionsCiiRate_l4w(newHcOptionsCiiRate_l4w);

    // Year to Date
    newHcOptionsCiiRate_ytd.series = {
      data: [
        { name: 'A', y: totalDataList[5][0], color: 'rgba(0, 144, 206, 0.5)' }, // CiiScore A
        { name: 'B', y: totalDataList[6][0], color: 'rgba(6, 165, 80, 0.5)' }, // CiiScore B
        { name: 'C', y: totalDataList[7][0], color: 'rgba(212, 195, 38, 0.5)' }, // CiiScore C
        { name: 'D', y: totalDataList[8][0], color: 'rgba(255, 103, 103, 0.55)' }, // CiiScore D
        { name: 'E', y: totalDataList[9][0], color: 'rgba(255, 47, 47, 0.55)' } // CiiScore E
      ]
    }
    setHcOptionsCiiRate_ytd(newHcOptionsCiiRate_ytd);
  
    // End of Year
    newHcOptionsCiiRate_eoy.series = {
      data: [
        { name: 'A', y: totalDataList[10][0], color: 'rgba(0, 144, 206, 0.5)' }, // CiiScore A
        { name: 'B', y: totalDataList[11][0], color: 'rgba(6, 165, 80, 0.5)' }, // CiiScore B
        { name: 'C', y: totalDataList[12][0], color: 'rgba(212, 195, 38, 0.5)' }, // CiiScore C
        { name: 'D', y: totalDataList[13][0], color: 'rgba(255, 103, 103, 0.55)' }, // CiiScore D
        { name: 'E', y: totalDataList[14][0], color: 'rgba(255, 47, 47, 0.55)' } // CiiScore E
      ]
    }   
    setHcOptionsCiiRate_eoy(newHcOptionsCiiRate_eoy);
  
    // Last Year
    newHcOptionsCiiRate_ly.series = {
      data: [
        { name: 'A', y: totalDataList[15][0], color: 'rgba(0, 144, 206, 0.5)' }, // CiiScore A
        { name: 'B', y: totalDataList[16][0], color: 'rgba(6, 165, 80, 0.5)' }, // CiiScore B
        { name: 'C', y: totalDataList[17][0], color: 'rgba(212, 195, 38, 0.5)' }, // CiiScore C
        { name: 'D', y: totalDataList[18][0], color: 'rgba(255, 103, 103, 0.55)' }, // CiiScore D
        { name: 'E', y: totalDataList[19][0], color: 'rgba(255, 47, 47, 0.55)' } // CiiScore E
      ]
    }
    setHcOptionsCiiRate_ly(newHcOptionsCiiRate_ly);
  }

  return (
    <>
      <Box sx={{width: "97.5%", height: "98.75%", marginLeft: "1.2%"}}>
        {/* 条件プルダウン */}
        <Box sx={{ height: "82px"}}>
          <Box sx={{height: "32px"}}></Box>
          <Stack direction="row" sx={{ height: "100%", }} >
            
            {/* Group */}
            <Typography align='left' sx={{ marginTop: "7px", marginRight: "10px", color: 'white', fontSize: 14, fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
              Group
            </Typography>
            <ThemeProvider theme={SelectTheme}>
              <FormControl>
                <Select
                  IconComponent={() =>
                    <div style={{ position: "absolute", top: "15px", left: "105px" }}>
                      <InputAdornment position="start" disablePointerEvents>
                        <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.7)", borderRight: "solid 2px rgb(255, 255, 255, 0.7)", width: "8px", height: "8px", transform: "rotate(135deg)" }}></Box>
                      </InputAdornment>
                    </div>
                  }
                  MenuProps={{
                    MenuListProps: {
                      disablePadding: true,

                    },
                    style: { zIndex: 100000, }
                  }}
                  sx={{
                    width: 128,
                    height: 32,
                    color: "white",
                    borderRadius: 30,
                    ":hover": { backgroundColor: "#7a86a0" }
                  }}
                  input={<BootstrapInput2 />}
                  onChange={selectGroup}
                  value={props.SeachInfo.gid === "admin" ? "ALL": props.SeachInfo.gid === "Favorite" ? "My Fleet": props.SeachInfo.gid}
                >
                  {props.SeachInfo.gidList.map((gid) => {
                    return (
                      <MenuItem 
                        key={gid === "admin" ? "ALL": gid === "Favorite" ? "My Fleet": gid} 
                        value={gid === "admin" ? "ALL": gid === "Favorite" ? "My Fleet": gid}
                        >
                          {gid === "admin" ? "ALL": gid === "Favorite" ? "My Fleet": gid}
                      </MenuItem>
                    )
                  }
                  )}
                </Select>
              </FormControl>
            </ThemeProvider>
            <Stack sx={{ width: "56px" }}></Stack>

            {/* Year */}
            <Typography align='left' sx={{ marginTop: "7px", marginRight: "10px", color: 'white', fontSize: 14, fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
              Year
            </Typography>
            <ThemeProvider theme={SelectTheme}>
              <FormControl>
                <Select
                  IconComponent={() =>
                    <div style={{ position: "absolute", top: "15px", left: "105px" }}>
                      <InputAdornment position="start" disablePointerEvents>
                        <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.7)", borderRight: "solid 2px rgb(255, 255, 255, 0.7)", width: "8px", height: "8px", transform: "rotate(135deg)" }}></Box>
                      </InputAdornment>
                    </div>
                  }
                  MenuProps={{
                    MenuListProps: {
                      disablePadding: true,

                    },
                    style: { zIndex: 100000, }
                  }}
                  sx={{
                    width: 128,
                    height: 32,
                    color: "white",
                    borderRadius: 30,
                    ":hover": { backgroundColor: "#7a86a0" }
                  }}
                  input={<BootstrapInput2 />}
                  onChange={selectYear}
                  value={selectedYear}
                >
                  {yearMap.map((year) => {
                    return (
                      <MenuItem key={year} value={year}>{year}</MenuItem>
                    )
                  }
                  )}
                </Select>
              </FormControl>
            </ThemeProvider>
          </Stack>
        </Box>

        {/* Total */}
        <Stack direction="row" sx={{ height: "215px" }}>

          {/* Last 4 Weeks */}
          <Box className="cii-total-area" sx={{ filter: `brightness(${selectedYear === todayYear ? "100%" : "70%"})` }}>
            <Stack direction={"row"} sx={{ height: "36px", width: "100%" }}>
              <Box className="cii-total-area-titole-1" >
                Last 4 Weeks
              </Box>
              <Box className="cii-total-area-total-vessels">
                Total Vessels : 
              </Box>
              <Box className="cii-total-area-total-vessels-value">
                {selectedYear === todayYear ? totalVesselCountList[0] : "-" }
              </Box>
            </Stack>
            <Stack direction={"row"}>
              {/* グラフエリア */}
              <Box sx={{ height: "161px", width: "44%" }}>
                <Grid item xs={10} alignItems={"center"}>
                  <HighchartsReact
                    key={key}
                    highcharts={Highcharts}
                    options={hcOptionsCiiRate_l4w}
                    // ref={DisplacementchartComponentRef}
                    allowChartUpdate={true}
                    updateArgs={[true, true, true]}
                  />
                </Grid>
              </Box>
              {/* グラフの凡例、内訳エリア */}
              <Box className="cii-total-area-total-graph-legend-wrapper" >
                <Box className="cii-total-area-total-graph-legend-margin" ></Box>
                <Box className="cii-total-area-total-graph-legend" >
                  <Stack direction={"row"} sx={{ height: "100%" }}>
                    <Box className="legend">
                      <Box className="legend-A"></Box>
                    </Box>
                    <Box className="legend-name">
                      A
                    </Box>
                    <Box className="legend-colon">
                      :
                    </Box>
                    <Box className="legend-percent-value">
                      {selectedYear === todayYear ? totalDataList[0] ? totalDataList[0][0] : 0 : "-" }
                    </Box>
                    <Box className="legend-percent-unit">
                      %
                    </Box>
                    <Stack className='horizontal-bar' >
                      <Box className="bar"></Box>
                    </Stack>
                    <Box className="legend-vessel-count-value">
                      {selectedYear === todayYear ? totalDataList[0] ? totalDataList[0][1] : 0 : "-" }
                    </Box>
                    <Box className="legend-vessel-count">
                      vessels
                    </Box>
                  </Stack>
                </Box>
                <Box className="cii-total-area-total-graph-legend" >
                  <Stack direction={"row"} sx={{ height: "100%" }}>
                    <Box className="legend">
                      <Box className="legend-B"></Box>
                    </Box>
                    <Box className="legend-name">
                      B
                    </Box>
                    <Box className="legend-colon">
                      :
                    </Box>
                    <Box className="legend-percent-value">
                      {selectedYear === todayYear ? totalDataList[1] ? totalDataList[1][0] : 0 : "-" }
                    </Box>
                    <Box className="legend-percent-unit">
                      %
                    </Box>
                    <Stack className='horizontal-bar' >
                      <Box className="bar"></Box>
                    </Stack>
                    <Box className="legend-vessel-count-value">
                      {selectedYear === todayYear ? totalDataList[1] ? totalDataList[1][1] : 0 : "-" }
                    </Box>
                    <Box className="legend-vessel-count">
                      vessels
                    </Box>
                  </Stack>
                </Box>
                <Box className="cii-total-area-total-graph-legend" >
                  <Stack direction={"row"} sx={{ height: "100%" }}>
                    <Box className="legend">
                      <Box className="legend-C"></Box>
                    </Box>
                    <Box className="legend-name">
                      C
                    </Box>
                    <Box className="legend-colon">
                      :
                    </Box>
                    <Box className="legend-percent-value">
                      {selectedYear === todayYear ? totalDataList[2] ? totalDataList[2][0] : 0 : "-" }
                    </Box>
                    <Box className="legend-percent-unit">
                      %
                    </Box>
                    <Stack className='horizontal-bar' >
                      <Box className="bar"></Box>
                    </Stack>
                    <Box className="legend-vessel-count-value">
                      {selectedYear === todayYear ? totalDataList[2] ? totalDataList[2][1] : 0 : "-" }
                    </Box>
                    <Box className="legend-vessel-count">
                      vessels
                    </Box>
                  </Stack>
                </Box>
                <Box className="cii-total-area-total-graph-legend" >
                  <Stack direction={"row"} sx={{ height: "100%" }}>
                    <Box className="legend">
                      <Box className="legend-D"></Box>
                    </Box>
                    <Box className="legend-name">
                      D
                    </Box>
                    <Box className="legend-colon">
                      :
                    </Box>
                    <Box className="legend-percent-value">
                      {selectedYear === todayYear ? totalDataList[3] ? totalDataList[3][0] : 0 : "-" }
                    </Box>
                    <Box className="legend-percent-unit">
                      %
                    </Box>
                    <Stack className='horizontal-bar' >
                      <Box className="bar"></Box>
                    </Stack>
                    <Box className="legend-vessel-count-value">
                      {selectedYear === todayYear ? totalDataList[3] ? totalDataList[3][1] : 0 : "-" }
                    </Box>
                    <Box className="legend-vessel-count">
                      vessels
                    </Box>
                  </Stack>
                </Box>
                <Box className="cii-total-area-total-graph-legend" >
                  <Stack direction={"row"} sx={{ height: "100%" }}>
                    <Box className="legend">
                      <Box className="legend-E"></Box>
                    </Box>
                    <Box className="legend-name">
                      E
                    </Box>
                    <Box className="legend-colon">
                      :
                    </Box>
                    <Box className="legend-percent-value">
                      {selectedYear === todayYear ? totalDataList[4] ? totalDataList[4][0] : 0 : "-" }
                    </Box>
                    <Box className="legend-percent-unit">
                      %
                    </Box>
                    <Stack className='horizontal-bar' >
                      <Box className="bar"></Box>
                    </Stack>
                    <Box className="legend-vessel-count-value">
                      {selectedYear === todayYear ? totalDataList[4] ? totalDataList[4][1] : 0 : "-" }
                    </Box>
                    <Box className="legend-vessel-count">
                      vessels
                    </Box>
                  </Stack>
                </Box>
              </Box>
            </Stack>
          </Box>
          <Box className="cii-total-area-margin"></Box>
          {/* Year to Date */}
          <Box className="cii-total-area" sx={{ filter: `brightness(${selectedYear === todayYear ? "100%" : "70%"})` }}>
            <Stack direction={"row"} sx={{height: "36px", width: "100%" }}>
              <Box className="cii-total-area-titole-2" >
                Year to Date
              </Box>
              <Box className="cii-total-area-total-vessels">
                Total Vessels : 
              </Box>
              <Box className="cii-total-area-total-vessels-value">
                {selectedYear === todayYear ? totalVesselCountList[1] : "-" }
              </Box>
            </Stack>
            <Stack direction={"row"}>
              {/* グラフエリア */}
              <Box sx={{ height: "161px", width: "44%" }}>
                <Grid item xs={10} alignItems={"center"}>
                  <HighchartsReact
                    key={key}
                    highcharts={Highcharts}
                    options={hcOptionsCiiRate_ytd}
                    // ref={DisplacementchartComponentRef}
                    allowChartUpdate={true}
                    updateArgs={[true, true, true]}
                  />
                </Grid>
              </Box>
              {/* グラフの凡例、内訳エリア */}
              <Box className="cii-total-area-total-graph-legend-wrapper" >
                <Box className="cii-total-area-total-graph-legend-margin" ></Box>
                <Box className="cii-total-area-total-graph-legend" >
                  <Stack direction={"row"} sx={{ height: "100%" }}>
                    <Box className="legend">
                      <Box className="legend-A"></Box>
                    </Box>
                    <Box className="legend-name">
                      A
                    </Box>
                    <Box className="legend-colon">
                      :
                    </Box>
                    <Box className="legend-percent-value">
                      {selectedYear === todayYear ? totalDataList[5] ? totalDataList[5][0] : 0 : "-" }
                    </Box>
                    <Box className="legend-percent-unit">
                      %
                    </Box>
                    <Stack className='horizontal-bar' >
                      <Box className="bar"></Box>
                    </Stack>
                    <Box className="legend-vessel-count-value">
                      {selectedYear === todayYear ? totalDataList[5] ? totalDataList[5][1] : 0 : "-" }
                    </Box>
                    <Box className="legend-vessel-count">
                      vessels
                    </Box>
                  </Stack>
                </Box>
                <Box className="cii-total-area-total-graph-legend" >
                  <Stack direction={"row"} sx={{ height: "100%" }}>
                    <Box className="legend">
                      <Box className="legend-B"></Box>
                    </Box>
                    <Box className="legend-name">
                      B
                    </Box>
                    <Box className="legend-colon">
                      :
                    </Box>
                    <Box className="legend-percent-value">
                      {selectedYear === todayYear ? totalDataList[6] ? totalDataList[6][0] : 0 : "-" }
                    </Box>
                    <Box className="legend-percent-unit">
                      %
                    </Box>
                    <Stack className='horizontal-bar' >
                      <Box className="bar"></Box>
                    </Stack>
                    <Box className="legend-vessel-count-value">
                      {selectedYear === todayYear ? totalDataList[6] ? totalDataList[6][1] : 0 : "-" }
                    </Box>
                    <Box className="legend-vessel-count">
                      vessels
                    </Box>
                  </Stack>
                </Box>
                <Box className="cii-total-area-total-graph-legend" >
                  <Stack direction={"row"} sx={{ height: "100%" }}>
                    <Box className="legend">
                      <Box className="legend-C"></Box>
                    </Box>
                    <Box className="legend-name">
                      C
                    </Box>
                    <Box className="legend-colon">
                      :
                    </Box>
                    <Box className="legend-percent-value">
                      {selectedYear === todayYear ? totalDataList[7] ? totalDataList[7][0] : 0 : "-" }
                    </Box>
                    <Box className="legend-percent-unit">
                      %
                    </Box>
                    <Stack className='horizontal-bar' >
                      <Box className="bar"></Box>
                    </Stack>
                    <Box className="legend-vessel-count-value">
                      {selectedYear === todayYear ? totalDataList[7] ? totalDataList[7][1] : 0 : "-" }
                    </Box>
                    <Box className="legend-vessel-count">
                      vessels
                    </Box>
                  </Stack>
                </Box>
                <Box className="cii-total-area-total-graph-legend" >
                  <Stack direction={"row"} sx={{ height: "100%" }}>
                    <Box className="legend">
                      <Box className="legend-D"></Box>
                    </Box>
                    <Box className="legend-name">
                      D
                    </Box>
                    <Box className="legend-colon">
                      :
                    </Box>
                    <Box className="legend-percent-value">
                      {selectedYear === todayYear ? totalDataList[8] ? totalDataList[8][0] : 0 : "-" }
                    </Box>
                    <Box className="legend-percent-unit">
                      %
                    </Box>
                    <Stack className='horizontal-bar' >
                      <Box className="bar"></Box>
                    </Stack>
                    <Box className="legend-vessel-count-value">
                      {selectedYear === todayYear ? totalDataList[8] ? totalDataList[8][1] : 0 : "-" }
                    </Box>
                    <Box className="legend-vessel-count">
                      vessels
                    </Box>
                  </Stack>
                </Box>
                <Box className="cii-total-area-total-graph-legend" >
                  <Stack direction={"row"} sx={{ height: "100%" }}>
                    <Box className="legend">
                      <Box className="legend-E"></Box>
                    </Box>
                    <Box className="legend-name">
                      E
                    </Box>
                    <Box className="legend-colon">
                      :
                    </Box>
                    <Box className="legend-percent-value">
                      {selectedYear === todayYear ? totalDataList[9] ? totalDataList[9][0] : 0 : "-" }
                    </Box>
                    <Box className="legend-percent-unit">
                      %
                    </Box>
                    <Stack className='horizontal-bar' >
                      <Box className="bar"></Box>
                    </Stack>
                    <Box className="legend-vessel-count-value">
                      {selectedYear === todayYear ? totalDataList[9] ? totalDataList[9][1] : 0 : "-" }
                    </Box>
                    <Box className="legend-vessel-count">
                      vessels
                    </Box>
                  </Stack>
                </Box>
              </Box>
            </Stack>
          </Box>
          <Box className="cii-total-area-margin"></Box>
          {/* End of Year */}
          <Box className="cii-total-area">
            <Stack direction={"row"} sx={{ height: "36px", width: "100%" }}>
              <Box className="cii-total-area-titole-3" >
                {selectedYear === todayYear ? "End of Year ( Estimated )" : "End of Year" }
              </Box>
              <Box className="cii-total-area-total-vessels">
                Total Vessels : 
              </Box>
              <Box className="cii-total-area-total-vessels-value">
                {totalVesselCountList[2]}
              </Box>
            </Stack>
            <Stack direction={"row"}>
              {/* グラフエリア */}
              <Box sx={{ height: "161px", width: "44%" }}>
                <Grid item xs={10} alignItems={"center"}>
                  <HighchartsReact
                    key={key}
                    highcharts={Highcharts}
                    options={hcOptionsCiiRate_eoy}
                    // ref={DisplacementchartComponentRef}
                    allowChartUpdate={true}
                    updateArgs={[true, true, true]}
                  />
                </Grid>
              </Box>
              {/* グラフの凡例、内訳エリア */}
              <Box className="cii-total-area-total-graph-legend-wrapper" >
                <Box className="cii-total-area-total-graph-legend-margin" ></Box>
                <Box className="cii-total-area-total-graph-legend" >
                  <Stack direction={"row"} sx={{ height: "100%" }}>
                    <Box className="legend">
                      <Box className="legend-A"></Box>
                    </Box>
                    <Box className="legend-name">
                      A
                    </Box>
                    <Box className="legend-colon">
                      :
                    </Box>
                    <Box className="legend-percent-value">
                      {totalDataList[10] ? totalDataList[10][0] : 0}
                    </Box>
                    <Box className="legend-percent-unit">
                      %
                    </Box>
                    <Stack className='horizontal-bar' >
                      <Box className="bar"></Box>
                    </Stack>
                    <Box className="legend-vessel-count-value">
                      {totalDataList[10] ? totalDataList[10][1] : 0}
                    </Box>
                    <Box className="legend-vessel-count">
                      vessels
                    </Box>
                  </Stack>
                </Box>
                <Box className="cii-total-area-total-graph-legend" >
                  <Stack direction={"row"} sx={{ height: "100%" }}>
                    <Box className="legend">
                      <Box className="legend-B"></Box>
                    </Box>
                    <Box className="legend-name">
                      B
                    </Box>
                    <Box className="legend-colon">
                      :
                    </Box>
                    <Box className="legend-percent-value">
                      {totalDataList[11] ? totalDataList[11][0] : 0}
                    </Box>
                    <Box className="legend-percent-unit">
                      %
                    </Box>
                    <Stack className='horizontal-bar' >
                      <Box className="bar"></Box>
                    </Stack>
                    <Box className="legend-vessel-count-value">                     
                      {totalDataList[11] ? totalDataList[11][1] : 0}
                    </Box>
                    <Box className="legend-vessel-count">
                      vessels
                    </Box>
                  </Stack>
                </Box>
                <Box className="cii-total-area-total-graph-legend" >
                  <Stack direction={"row"} sx={{ height: "100%" }}>
                    <Box className="legend">
                      <Box className="legend-C"></Box>
                    </Box>
                    <Box className="legend-name">
                      C
                    </Box>
                    <Box className="legend-colon">
                      :
                    </Box>
                    <Box className="legend-percent-value">
                      {totalDataList[12] ? totalDataList[12][0] : 0}
                    </Box>
                    <Box className="legend-percent-unit">
                      %
                    </Box>
                    <Stack className='horizontal-bar' >
                      <Box className="bar"></Box>
                    </Stack>
                    <Box className="legend-vessel-count-value">
                      {totalDataList[12] ? totalDataList[12][1] : 0}
                    </Box>
                    <Box className="legend-vessel-count">
                      vessels
                    </Box>
                  </Stack>
                </Box>
                <Box className="cii-total-area-total-graph-legend" >
                  <Stack direction={"row"} sx={{ height: "100%" }}>
                    <Box className="legend">
                      <Box className="legend-D"></Box>
                    </Box>
                    <Box className="legend-name">
                      D
                    </Box>
                    <Box className="legend-colon">
                      :
                    </Box>
                    <Box className="legend-percent-value">
                      {totalDataList[13] ? totalDataList[13][0] : 0}
                    </Box>
                    <Box className="legend-percent-unit">
                      %
                    </Box>
                    <Stack className='horizontal-bar' >
                      <Box className="bar"></Box>
                    </Stack>
                    <Box className="legend-vessel-count-value">
                      {totalDataList[13] ? totalDataList[13][1] : 0}
                    </Box>
                    <Box className="legend-vessel-count">
                      vessels
                    </Box>
                  </Stack>
                </Box>
                <Box className="cii-total-area-total-graph-legend" >
                  <Stack direction={"row"} sx={{ height: "100%" }}>
                    <Box className="legend">
                      <Box className="legend-E"></Box>
                    </Box>
                    <Box className="legend-name">
                      E
                    </Box>
                    <Box className="legend-colon">
                      :
                    </Box>
                    <Box className="legend-percent-value">
                      {totalDataList[14] ? totalDataList[14][0] : 0}
                    </Box>
                    <Box className="legend-percent-unit">
                      %
                    </Box>
                    <Stack className='horizontal-bar' >
                      <Box className="bar"></Box>
                    </Stack>
                    <Box className="legend-vessel-count-value">
                      {totalDataList[14] ? totalDataList[14][1] : 0}
                    </Box>
                    <Box className="legend-vessel-count">
                      vessels
                    </Box>
                  </Stack>
                </Box>
              </Box>
            </Stack>
          </Box>
          <Box className="cii-total-area-margin"></Box>
          {/* Last Year */}
          <Box className="cii-total-area">
            <Stack direction={"row"} sx={{ height: "36px", width: "100%" }}>
              <Box className="cii-total-area-titole-4" >
                Last Year
              </Box>
              <Box className="cii-total-area-total-vessels">
                Total Vessels : 
              </Box>
              <Box className="cii-total-area-total-vessels-value">
                {totalVesselCountList[3]}
              </Box>
            </Stack>
            <Stack direction={"row"}>
              {/* グラフエリア */}
              <Box sx={{ height: "161px", width: "44%" }}>
                <Grid item xs={10} alignItems={"center"}>
                  <HighchartsReact
                    key={key}
                    highcharts={Highcharts}
                    options={hcOptionsCiiRate_ly}
                    // ref={DisplacementchartComponentRef}
                    allowChartUpdate={true}
                    updateArgs={[true, true, true]}
                  />
                </Grid>
              </Box>
              {/* グラフの凡例、内訳エリア */}
              <Box className="cii-total-area-total-graph-legend-wrapper" >
                <Box className="cii-total-area-total-graph-legend-margin" ></Box>
                <Box className="cii-total-area-total-graph-legend" >
                  <Stack direction={"row"} sx={{ height: "100%" }}>
                    <Box className="legend">
                      <Box className="legend-A"></Box>
                    </Box>
                    <Box className="legend-name">
                      A
                    </Box>
                    <Box className="legend-colon">
                      :
                    </Box>
                    <Box className="legend-percent-value">
                      {totalDataList[15] ? totalDataList[15][0] : 0}
                    </Box>
                    <Box className="legend-percent-unit">
                      %
                    </Box>
                    <Stack className='horizontal-bar' >
                      <Box className="bar"></Box>
                    </Stack>
                    <Box className="legend-vessel-count-value">
                      {totalDataList[15] ? totalDataList[15][1] : 0}
                    </Box>
                    <Box className="legend-vessel-count">
                      vessels
                    </Box>
                  </Stack>
                </Box>
                <Box className="cii-total-area-total-graph-legend" >
                  <Stack direction={"row"} sx={{ height: "100%" }}>
                    <Box className="legend">
                      <Box className="legend-B"></Box>
                    </Box>
                    <Box className="legend-name">
                      B
                    </Box>
                    <Box className="legend-colon">
                      :
                    </Box>
                    <Box className="legend-percent-value">
                      {totalDataList[16] ? totalDataList[16][0] : 0}
                    </Box>
                    <Box className="legend-percent-unit">
                      %
                    </Box>
                    <Stack className='horizontal-bar' >
                      <Box className="bar"></Box>
                    </Stack>
                    <Box className="legend-vessel-count-value">
                      {totalDataList[16] ? totalDataList[16][1] : 0}
                    </Box>
                    <Box className="legend-vessel-count">
                      vessels
                    </Box>
                  </Stack>
                </Box>
                <Box className="cii-total-area-total-graph-legend" >
                  <Stack direction={"row"} sx={{ height: "100%" }}>
                    <Box className="legend">
                      <Box className="legend-C"></Box>
                    </Box>
                    <Box className="legend-name">
                      C
                    </Box>
                    <Box className="legend-colon">
                      :
                    </Box>
                    <Box className="legend-percent-value">
                      {totalDataList[17] ? totalDataList[17][0] : 0}
                    </Box>
                    <Box className="legend-percent-unit">
                      %
                    </Box>
                    <Stack className='horizontal-bar' >
                      <Box className="bar"></Box>
                    </Stack>
                    <Box className="legend-vessel-count-value">
                      {totalDataList[17] ? totalDataList[17][1] : 0}
                    </Box>
                    <Box className="legend-vessel-count">
                      vessels
                    </Box>
                  </Stack>
                </Box>
                <Box className="cii-total-area-total-graph-legend" >
                  <Stack direction={"row"} sx={{ height: "100%" }}>
                    <Box className="legend">
                      <Box className="legend-D"></Box>
                    </Box>
                    <Box className="legend-name">
                      D
                    </Box>
                    <Box className="legend-colon">
                      :
                    </Box>
                    <Box className="legend-percent-value">
                      {totalDataList[18] ? totalDataList[18][0] : 0}
                    </Box>
                    <Box className="legend-percent-unit">
                      %
                    </Box>
                    <Stack className='horizontal-bar' >
                      <Box className="bar"></Box>
                    </Stack>
                    <Box className="legend-vessel-count-value">
                      {totalDataList[18] ? totalDataList[18][1] : 0}
                    </Box>
                    <Box className="legend-vessel-count">
                      vessels
                    </Box>
                  </Stack>
                </Box>
                <Box className="cii-total-area-total-graph-legend" >
                  <Stack direction={"row"} sx={{ height: "100%" }}>
                    <Box className="legend">
                      <Box className="legend-E"></Box>
                    </Box>
                    <Box className="legend-name">
                      E
                    </Box>
                    <Box className="legend-colon">
                      :
                    </Box>
                    <Box className="legend-percent-value">
                      {totalDataList[19] ? totalDataList[19][0] : 0}
                    </Box>
                    <Box className="legend-percent-unit">
                      %
                    </Box>
                    <Stack className='horizontal-bar' >
                      <Box className="bar"></Box>
                    </Stack>
                    <Box className="legend-vessel-count-value">
                      {totalDataList[19] ? totalDataList[19][1] : 0}
                    </Box>
                    <Box className="legend-vessel-count">
                      vessels
                    </Box>
                  </Stack>
                </Box>
              </Box>
            </Stack>
          </Box>
        </Stack>
        
        {/* List */}
        <Box className="cii-list-area">
          {/* VesselName絞り込みテキストボックス */}
          <Box sx={{ position: "relative", height: "80px" }}>
            <Stack direction="row" sx={{ height: "60px", marginLeft: "2.6%", position: "relative"}} alignItems={"center"} justifyContent={"flex-start"}>
              <SearchIcon sx={{ marginRight: "6px", width: "20px", height: "20px", color: "white", 
                  position:"absolute", top: "32px", left:"5px", zIndex:10000
                }} />
              <FormControl>
                <Input id="vesselNameFilter" type="email"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleFilterVesselList(event.target.value) }}
                  placeholder="Vessel Name" autoComplete="off"
                  sx={{  marginTop: "25px",
                    fontFamily: 'Roboto', fontWeight: '500', border: '0px grey solid', color: "white", fontSize: "15px", 
                    paddingLeft:"30px",background: "rgba(255, 255, 255, 0.15)",
                    "& .MuiInput-input": { height: "23px", width: "122px", borderRadius: '0px', border: '0px white solid', textAlign: "left", },
                    ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                    ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                    ":after": { borderBottom: "2px solid white", },
                    ":before": { borderBottom: "2px solid #A4A7B1", }, fontStyle:"italic"
                  }}/>
              </FormControl>
            </Stack>
            {/* Last 4 Weeks */}
            <Box style={{ width: "18.75%", height: "25px", left: "13.75%", top: "53px", position: 'absolute', background: 'rgba(83, 84, 89, 0.50)', }}>
              <Typography align="center" sx={{ marginTop: "2px", color: 'rgba(255, 255, 255, 0.50)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
                Last 4 Weeks
              </Typography>
            </Box>
            {/* Year to Date */}
            <Box style={{ width: "18.75%", height: "25px", left: "35.5%", top: "53px", position: 'absolute', background: 'rgba(83, 84, 89, 0.50)', }}>
              <Typography align="center" sx={{ marginTop: "2px", color: 'rgba(255, 255, 255, 0.50)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
                Year to Date
              </Typography>
            </Box>
            {/* End of Year ( Estimated ) */}
            <Box style={{ width: "18.75%", height: "25px", left: "57.25%", top: "53px", position: 'absolute', background: 'rgba(83, 84, 89, 0.50)', }}>
              <Typography align="center" sx={{ marginTop: "2px", color: 'rgba(255, 255, 255, 0.50)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
                {selectedYear === todayYear ? "End of Year ( Estimated )" : "End of Year" }
              </Typography>
            </Box>
            {/* Last Year */}
            <Box style={{ width: "18.75%", height: "25px", left: "79%", top: "53px", position: 'absolute', background: 'rgba(83, 84, 89, 0.50)', }}>
              <Typography align="center" sx={{ marginTop: "2px", color: 'rgba(255, 255, 255, 0.50)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
                Last Year
              </Typography>
            </Box>
          </Box>
         
          <div className='cii-list-area-wrapper'>
            <table className="cii-vessel-list-table">
              <thead>
                <tr>
                  <th className="padding1">
                    <span></span>
                  </th>
                  <th className="vesselName">
                    <div className='common-sort-list-header-left'>
                      <div>
                        <span>Vessel Name</span>
                      </div>
                      <div className="common-sort-container">
                        <div className="common-sort-icon">
                          <div className={(activeSortNo.item === "VesselName" && activeSortNo.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAsc('VesselName', 1)}></div>
                          <div className={(activeSortNo.item === "VesselName" && activeSortNo.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDesc('VesselName', 1)}></div>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th className="l4w-distance">
                    <div className='common-sort-list-header-right'>
                      <div>
                        <span>Distance (NM)</span>
                      </div>
                      <div className="common-sort-container">
                        <div className="common-sort-icon">
                          <div className={(activeSortNo.item === "l4w_distance" && activeSortNo.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAsc('l4w_distance', 2)}></div>
                          <div className={(activeSortNo.item === "l4w_distance" && activeSortNo.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDesc('l4w_distance', 2)}></div>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th className="l4w-foc">
                    <div className='common-sort-list-header-right'>
                      <div>
                        <span>FOC (MT)</span>
                      </div>
                      <div className="common-sort-container">
                        <div className="common-sort-icon">
                          <div className={(activeSortNo.item === "l4w_foc" && activeSortNo.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAsc('l4w_foc', 2)}></div>
                          <div className={(activeSortNo.item === "l4w_foc" && activeSortNo.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDesc('l4w_foc', 2)}></div>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th className="l4w-cii-score">
                    <div className='common-sort-list-header-right'>
                      <div>
                        <span>CII Score</span>
                      </div>
                      <div className="common-sort-container">
                        <div className="common-sort-icon">
                          <div className={(activeSortNo.item === "l4w_cii_score" && activeSortNo.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAsc('l4w_cii_score', 1)}></div>
                          <div className={(activeSortNo.item === "l4w_cii_score" && activeSortNo.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDesc('l4w_cii_score', 1)}></div>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th className="ytd-distance">
                    <div className='common-sort-list-header-right'>
                      <div>
                        <span>Distance (NM)</span>
                      </div>
                      <div className="common-sort-container">
                        <div className="common-sort-icon">
                          <div className={(activeSortNo.item === "ytd_distance" && activeSortNo.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAsc('ytd_distance', 2)}></div>
                          <div className={(activeSortNo.item === "ytd_distance" && activeSortNo.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDesc('ytd_distance', 2)}></div>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th className="ytd-foc">
                    <div className='common-sort-list-header-right'>
                      <div>
                        <span>FOC (MT)</span>
                      </div>
                      <div className="common-sort-container">
                        <div className="common-sort-icon">
                          <div className={(activeSortNo.item === "ytd_foc" && activeSortNo.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAsc('ytd_foc', 2)}></div>
                          <div className={(activeSortNo.item === "ytd_foc" && activeSortNo.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDesc('ytd_foc', 2)}></div>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th className="ytd-cii-score">
                    <div className='common-sort-list-header-right'>
                      <div>
                        <span>CII Score</span>
                      </div>
                      <div className="common-sort-container">
                        <div className="common-sort-icon">
                          <div className={(activeSortNo.item === "ytd_cii_score" && activeSortNo.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAsc('ytd_cii_score', 1)}></div>
                          <div className={(activeSortNo.item === "ytd_cii_score" && activeSortNo.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDesc('ytd_cii_score', 1)}></div>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th className="eoy-distance">
                    <div className='common-sort-list-header-right'>
                      <div>
                        <span>Distance (NM)</span>
                      </div>
                      <div className="common-sort-container">
                        <div className="common-sort-icon">
                          <div className={(activeSortNo.item === "eoy_distance" && activeSortNo.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAsc('eoy_distance', 2)}></div>
                          <div className={(activeSortNo.item === "eoy_distance" && activeSortNo.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDesc('eoy_distance', 2)}></div>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th className="eoy-foc">
                    <div className='common-sort-list-header-right'>
                      <div>
                        <span>FOC (MT)</span>
                      </div>
                      <div className="common-sort-container">
                        <div className="common-sort-icon">
                          <div className={(activeSortNo.item === "eoy_foc" && activeSortNo.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAsc('eoy_foc', 2)}></div>
                          <div className={(activeSortNo.item === "eoy_foc" && activeSortNo.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDesc('eoy_foc', 2)}></div>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th className="eoy-cii-score">
                    <div className='common-sort-list-header-right'>
                      <div>
                        <span>CII Score</span>
                      </div>
                      <div className="common-sort-container">
                        <div className="common-sort-icon">
                          <div className={(activeSortNo.item === "eoy_cii_score" && activeSortNo.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAsc('eoy_cii_score', 1)}></div>
                          <div className={(activeSortNo.item === "eoy_cii_score" && activeSortNo.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDesc('eoy_cii_score', 1)}></div>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th className="ly-distance">
                    <div className='common-sort-list-header-right'>
                      <div>
                        <span>Distance (NM)</span>
                      </div>
                      <div className="common-sort-container">
                        <div className="common-sort-icon">
                          <div className={(activeSortNo.item === "ly_distance" && activeSortNo.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAsc('ly_distance', 2)}></div>
                          <div className={(activeSortNo.item === "ly_distance" && activeSortNo.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDesc('ly_distance', 2)}></div>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th className="ly-foc">
                    <div className='common-sort-list-header-right'>
                      <div>
                        <span>FOC (MT)</span>
                      </div>
                      <div className="common-sort-container">
                        <div className="common-sort-icon">
                          <div className={(activeSortNo.item === "ly_foc" && activeSortNo.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAsc('ly_foc', 2)}></div>
                          <div className={(activeSortNo.item === "ly_foc" && activeSortNo.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDesc('ly_foc', 2)}></div>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th className="ly-cii-score">
                    <div className='common-sort-list-header-right'>
                      <div>
                        <span>CII Score</span>
                      </div>
                      <div className="common-sort-container">
                        <div className="common-sort-icon">
                          <div className={(activeSortNo.item === "ly_cii_score" && activeSortNo.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAsc('ly_cii_score', 1)}></div>
                          <div className={(activeSortNo.item === "ly_cii_score" && activeSortNo.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDesc('ly_cii_score', 1)}></div>
                        </div>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>

              {success ?
                <tbody>
                  {rows.length !== 0 ?
                    <>
                      {
                          rows.map((element, index) => {
                            return (
                              <tr>
                                <td className="padding1">
                                  <span></span>
                                </td>
                                <td className="VesselName">
                                  <span className="inner-text" title={`${element.VesselName}`}>
                                    {element.VesselName}
                                  </span>
                                </td>
                                <td className="l4w-distance">
                                  {selectedYear === todayYear ? 
                                    <Stack direction={"row"} sx={{ width: "100%", height: "100%", }}>
                                      <Button
                                        onClick={() => handleClickCiiEmissionBoard(index, element.imo, element.l4w_from, element.l4w_to, "Weekly", element.VesselName)}
                                        sx={{
                                          width: "100%",
                                          height: "100%",
                                          marginLeft: "auto",
                                          marginRight: "0px",
                                          padding: "0px",
                                          ":hover": {
                                            bgcolor: "rgba(255, 255, 255, 0.5)"
                                          }
                                        }}
                                        disabled={element.l4w_cii_score === "" ? true : false}
                                      >
                                        <span className="inner-text cii-list-text-right" title={`${element.l4w_distance}`}>
                                          {selectedYear === todayYear ? formatNumber(element.l4w_distance) : "-" }
                                        </span>
                                      </Button>
                                    </Stack>
                                  : <span>-</span> }
                                </td>
                                <td className="l4w-foc">
                                  {selectedYear === todayYear ? 
                                    <Stack direction={"row"} sx={{ width: "100%", height: "100%", }}>
                                      <Button
                                        onClick={() => handleClickCiiEmissionBoard(index, element.imo, element.l4w_from, element.l4w_to, "Weekly", element.VesselName)}
                                        sx={{
                                          width: "100%",
                                          height: "100%",
                                          marginLeft: "auto",
                                          marginRight: "0px",
                                          padding: "0px",
                                          ":hover": {
                                            bgcolor: "rgba(255, 255, 255, 0.5)"
                                          }
                                        }}
                                        disabled={element.l4w_cii_score === "" ? true : false}
                                      >
                                        <span className="inner-text cii-list-text-right" title={`${element.l4w_foc}`}>
                                          {selectedYear === todayYear ? formatNumber(element.l4w_foc) : "-" }
                                        </span>
                                      </Button>
                                    </Stack>
                                  : <span>-</span> }
                                </td>
                                <td className="l4w-cii-score">
                                  {selectedYear === todayYear ? 
                                    <Stack direction={"row"} sx={{ width: "100%", height: "100%", }}>
                                      <Button
                                        onClick={() => handleClickCiiEmissionBoard(index, element.imo, element.l4w_from, element.l4w_to, "Weekly", element.VesselName)}
                                        sx={{
                                          width: "68px",
                                          height: "100%",
                                          marginLeft: "auto",
                                          marginRight: "5px",
                                          ":hover": {
                                            bgcolor: "rgba(255, 255, 255, 0.5)"
                                          }
                                        }}
                                        disabled={element.l4w_cii_score === "" ? true : false}
                                      >
                                        {
                                          element.l4w_cii_score === "A" ?
                                            <>
                                              <Stack sx={{ width: "26px", height: "26px", bgcolor: "rgba(0, 144, 206, 0.4)" }}>
                                                <Typography sx={{ marginTop: "2px", color: '#ffffff', fontSize: ciiSize, fontWeight: "bold", }}>
                                                  <span className="inner-text" title={`${element.l4w_cii_score}`}>{element.l4w_cii_score}</span>
                                                </Typography>
                                              </Stack>
                                            </>
                                            :
                                            <>
                                              {
                                                element.l4w_cii_score === "B" ?
                                                  <>
                                                    <Stack sx={{ width: "26px", height: "26px", bgcolor: "rgba(6, 165, 80, 0.4)" }}>
                                                      <Typography sx={{ marginTop: "2px", color: '#ffffff', fontSize: ciiSize, fontWeight: "bold", }}>
                                                        <span className="inner-text" title={`${element.l4w_cii_score}`}>{element.l4w_cii_score}</span>
                                                      </Typography>
                                                    </Stack>
                                                  </>
                                                  :
                                                  <>
                                                    {
                                                      element.l4w_cii_score === "C" ?
                                                        <>
                                                          <Stack sx={{ width: "26px", height: "26px", bgcolor: "rgba(212, 195, 38, 0.4)" }}>
                                                            <Typography sx={{ marginTop: "2px", color: '#ffffff', fontSize: ciiSize, fontWeight: "bold", }}>
                                                              <span className="inner-text" title={`${element.l4w_cii_score}`}>{element.l4w_cii_score}</span>
                                                            </Typography>
                                                          </Stack>
                                                        </>
                                                        :
                                                        <>
                                                          {
                                                            element.l4w_cii_score === "D" ?
                                                              <>
                                                                <Stack sx={{ position: "relative", width: "26px", height: "26px", bgcolor: "rgba(255, 103, 103, 0.45)" }}>
                                                                  <Typography sx={{ marginTop: "2px", color: '#ffffff', fontSize: ciiSize, fontWeight: "bold", }}>
                                                                    <span className="inner-text" title={`${element.l4w_cii_score}`}>{element.l4w_cii_score}</span>
                                                                  </Typography>
                                                                  <Stack sx={{ position: "absolute", top: "14px", left: "18px", bgcolor: "transparent" }} alignItems={"center"}>
                                                                    <img src={Problem_icon_svg} alt="iconLike" style={{ height: "13px", width: "16px" }} />
                                                                  </Stack>
                                                                </Stack>
                                                              </>
                                                              :
                                                              <>
                                                                {
                                                                  element.l4w_cii_score === "E" ?
                                                                    <>
                                                                      <Stack sx={{ position: "relative", width: "26px", height: "26px", bgcolor: "rgba(255, 47, 47, 0.45)" }}>
                                                                        <Typography sx={{ marginTop: "2px", color: '#ffffff', fontSize: ciiSize, fontWeight: "bold", }}>
                                                                          <span className="inner-text" title={`${element.l4w_cii_score}`}>{element.l4w_cii_score}</span>
                                                                        </Typography>
                                                                        <Stack sx={{ position: "absolute", top: "14px", left: "18px", bgcolor: "transparent" }} alignItems={"center"}>
                                                                          <img src={Problem_icon_svg} alt="iconLike" style={{ height: "13px", width: "16px" }} />
                                                                        </Stack>
                                                                      </Stack>
                                                                    </>
                                                                    :
                                                                    <>
                                                                      {
                                                                        element.l4w_cii_score === "" ?
                                                                          <Typography sx={{ color: 'rgba(255, 255, 255, 0.6)', fontSize: "12px", textTransform: 'none' }}>
                                                                            No Data
                                                                          </Typography>
                                                                          :
                                                                          ""
                                                                      }
                                                                    </>
                                                                }
                                                              </>
                                                          }
                                                        </>
                                                    }
                                                  </>
                                              }
                                            </>
                                        }
                                      </Button>
                                    </Stack>
                                  : <span>-</span> }
                                </td>
                                <td className="ytd-distance">
                                  {selectedYear === todayYear ? 
                                    <Stack direction={"row"} sx={{ width: "100%", height: "100%", }}>
                                      <Button
                                        onClick={() => handleClickCiiEmissionBoard(index, element.imo, element.ytd_from, element.ytd_to, "Monthly", element.VesselName)}
                                        sx={{
                                          width: "100%",
                                          height: "100%",
                                          marginLeft: "auto",
                                          marginRight: "0px",
                                          padding: "0px",
                                          ":hover": {
                                            bgcolor: "rgba(255, 255, 255, 0.5)"
                                          }
                                        }}
                                        disabled={element.ytd_cii_score === "" ? true : false}
                                      >
                                        <span className="inner-text cii-list-text-right" title={`${element.ytd_distance}`}>
                                          {selectedYear === todayYear ? formatNumber(element.ytd_distance) : "-" }
                                        </span>
                                      </Button>
                                    </Stack>
                                  : <span>-</span> }
                                </td>
                                <td className="ytd-foc">
                                  {selectedYear === todayYear ? 
                                    <Stack direction={"row"} sx={{ width: "100%", height: "100%", }}>
                                      <Button
                                        onClick={() => handleClickCiiEmissionBoard(index, element.imo, element.ytd_from, element.ytd_to, "Monthly", element.VesselName)}
                                        sx={{
                                          width: "100%",
                                          height: "100%",
                                          marginLeft: "auto",
                                          marginRight: "0px",
                                          padding: "0px",
                                          ":hover": {
                                            bgcolor: "rgba(255, 255, 255, 0.5)"
                                          }
                                        }}
                                        disabled={element.ytd_cii_score === "" ? true : false}
                                      >
                                        <span className="inner-text cii-list-text-right" title={`${element.ytd_foc}`}>
                                          {selectedYear === todayYear ? formatNumber(element.ytd_foc) : "-" }
                                        </span>
                                      </Button>
                                    </Stack>
                                  : <span>-</span> }
                                </td>
                                <td className="ytd-cii-score">
                                  {selectedYear === todayYear ? 
                                    <Stack direction={"row"} sx={{ width: "100%", height: "100%", }}>
                                      <Button
                                        onClick={() => handleClickCiiEmissionBoard(index, element.imo, element.ytd_from, element.ytd_to, "Monthly", element.VesselName)}
                                        sx={{
                                          width: "68px",
                                          height: "100%",
                                          marginLeft: "auto",
                                          marginRight: "5px",
                                          ":hover": {
                                            bgcolor: "rgba(255, 255, 255, 0.5)"
                                          }
                                        }}
                                        disabled={element.ytd_cii_score === "" ? true : false}
                                      >
                                        {
                                          element.ytd_cii_score === "A" ?
                                            <>
                                              <Stack sx={{ width: "26px", height: "26px", bgcolor: "rgba(0, 144, 206, 0.4)" }}>
                                                <Typography sx={{ marginTop: "2px", color: '#ffffff', fontSize: ciiSize, fontWeight: "bold", }}>
                                                  <span className="inner-text" title={`${element.ytd_cii_score}`}>{element.ytd_cii_score}</span>
                                                </Typography>
                                              </Stack>
                                            </>
                                            :
                                            <>
                                              {
                                                element.ytd_cii_score === "B" ?
                                                  <>
                                                    <Stack sx={{ width: "26px", height: "26px", bgcolor: "rgba(6, 165, 80, 0.4)" }}>
                                                      <Typography sx={{ marginTop: "2px", color: '#ffffff', fontSize: ciiSize, fontWeight: "bold", }}>
                                                        <span className="inner-text" title={`${element.ytd_cii_score}`}>{element.ytd_cii_score}</span>
                                                      </Typography>
                                                    </Stack>
                                                  </>
                                                  :
                                                  <>
                                                    {
                                                      element.ytd_cii_score === "C" ?
                                                        <>
                                                          <Stack sx={{ width: "26px", height: "26px", bgcolor: "rgba(212, 195, 38, 0.4)" }}>
                                                            <Typography sx={{ marginTop: "2px", color: '#ffffff', fontSize: ciiSize, fontWeight: "bold", }}>
                                                              <span className="inner-text" title={`${element.ytd_cii_score}`}>{element.ytd_cii_score}</span>
                                                            </Typography>
                                                          </Stack>
                                                        </>
                                                        :
                                                        <>
                                                          {
                                                            element.ytd_cii_score === "D" ?
                                                              <>
                                                                <Stack sx={{ position: "relative", width: "26px", height: "26px", bgcolor: "rgba(255, 103, 103, 0.45)" }}>
                                                                  <Typography sx={{ marginTop: "2px", color: '#ffffff', fontSize: ciiSize, fontWeight: "bold", }}>
                                                                    <span className="inner-text" title={`${element.ytd_cii_score}`}>{element.ytd_cii_score}</span>
                                                                  </Typography>
                                                                  <Stack sx={{ position: "absolute", top: "14px", left: "18px", bgcolor: "transparent" }} alignItems={"center"}>
                                                                    <img src={Problem_icon_svg} alt="iconLike" style={{ height: "13px", width: "16px" }} />
                                                                  </Stack>
                                                                </Stack>
                                                              </>
                                                              :
                                                              <>
                                                                {
                                                                  element.ytd_cii_score === "E" ?
                                                                    <>
                                                                      <Stack sx={{ position: "relative", width: "26px", height: "26px", bgcolor: "rgba(255, 47, 47, 0.45)" }}>
                                                                        <Typography sx={{ marginTop: "2px", color: '#ffffff', fontSize: ciiSize, fontWeight: "bold", }}>
                                                                          <span className="inner-text" title={`${element.ytd_cii_score}`}>{element.ytd_cii_score}</span>
                                                                        </Typography>
                                                                        <Stack sx={{ position: "absolute", top: "14px", left: "18px", bgcolor: "transparent" }} alignItems={"center"}>
                                                                          <img src={Problem_icon_svg} alt="iconLike" style={{ height: "13px", width: "16px" }} />
                                                                        </Stack>
                                                                      </Stack>
                                                                    </>
                                                                    :
                                                                    <>
                                                                      {
                                                                        element.ytd_cii_score === "" ?
                                                                          <Typography sx={{ color: 'rgba(255, 255, 255, 0.6)', fontSize: "12px", textTransform: 'none' }}>
                                                                            No Data
                                                                          </Typography>
                                                                          :
                                                                          ""
                                                                      }
                                                                    </>
                                                                }
                                                              </>
                                                          }
                                                        </>
                                                    }
                                                  </>
                                              }
                                            </>
                                        }
                                      </Button>
                                    </Stack>
                                  : <span>-</span> }
                                </td>
                                <td className="eoy-distance">
                                  <Stack direction={"row"} sx={{ width: "100%", height: "100%", }}>
                                    <Button
                                      onClick={() => selectedYear === todayYear ? handleClickCiiSimulation(index, element.imo, element.oneMonth_from, element.oneMonth_to, element.VesselName) : handleClickCiiEmissionBoard(index, element.imo, element.eoy_from, element.eoy_to, "Monthly", element.VesselName) }
                                      sx={{
                                        width: "100%",
                                        height: "100%",
                                        marginLeft: "auto",
                                        marginRight: "0px",
                                        padding: "0px",
                                        ":hover": {
                                          bgcolor: "rgba(255, 255, 255, 0.5)"
                                        }
                                      }}
                                      disabled={element.eoy_cii_score === "" ? true : false}
                                    >
                                      <span className="inner-text cii-list-text-right" title={`${element.eoy_distance}`}>
                                        {formatNumber(element.eoy_distance)}
                                      </span>
                                    </Button>
                                  </Stack>
                                </td>
                                <td className="eoy-foc">
                                  <Stack direction={"row"} sx={{ width: "100%", height: "100%", }}>
                                    <Button
                                      onClick={() => selectedYear === todayYear ? handleClickCiiSimulation(index, element.imo, element.oneMonth_from, element.oneMonth_to, element.VesselName) : handleClickCiiEmissionBoard(index, element.imo, element.eoy_from, element.eoy_to, "Monthly", element.VesselName) }
                                      sx={{
                                        width: "100%",
                                        height: "100%",
                                        marginLeft: "auto",
                                        marginRight: "0px",
                                        padding: "0px",
                                        ":hover": {
                                          bgcolor: "rgba(255, 255, 255, 0.5)"
                                        }
                                      }}
                                      disabled={element.eoy_cii_score === "" ? true : false}
                                    >
                                      <span className="inner-text cii-list-text-right" title={`${element.eoy_foc}`}>
                                        {formatNumber(element.eoy_foc)}
                                      </span>
                                    </Button>
                                  </Stack>
                                </td>
                                <td className="eoy-cii-score">
                                  <Stack direction={"row"} sx={{ width: "100%", height: "100%", }}>
                                    <Button
                                      onClick={() => selectedYear === todayYear ? handleClickCiiSimulation(index, element.imo, element.oneMonth_from, element.oneMonth_to, element.VesselName) : handleClickCiiEmissionBoard(index, element.imo, element.eoy_from, element.eoy_to, "Monthly", element.VesselName) }
                                      sx={{
                                        width: "68px",
                                        height: "100%",
                                        marginLeft: "auto",
                                        marginRight: "5px",
                                        ":hover": {
                                          bgcolor: "rgba(255, 255, 255, 0.5)"
                                        }
                                      }}
                                      disabled={element.eoy_cii_score === "" ? true : false}
                                    >
                                      {
                                        element.eoy_cii_score === "A" ?
                                          <>
                                            <Stack sx={{ width: "26px", height: "26px", bgcolor: "rgba(0, 144, 206, 0.4)" }}>
                                              <Typography sx={{ marginTop: "2px", color: '#ffffff', fontSize: ciiSize, fontWeight: "bold", }}>
                                                <span className="inner-text" title={`${element.eoy_cii_score}`}>{element.eoy_cii_score}</span>
                                              </Typography>
                                            </Stack>
                                          </>
                                          :
                                          <>
                                            {
                                              element.eoy_cii_score === "B" ?
                                                <>
                                                  <Stack sx={{ width: "26px", height: "26px", bgcolor: "rgba(6, 165, 80, 0.4)" }}>
                                                    <Typography sx={{ marginTop: "2px", color: '#ffffff', fontSize: ciiSize, fontWeight: "bold", }}>
                                                      <span className="inner-text" title={`${element.eoy_cii_score}`}>{element.eoy_cii_score}</span>
                                                    </Typography>
                                                  </Stack>
                                                </>
                                                :
                                                <>
                                                  {
                                                    element.eoy_cii_score === "C" ?
                                                      <>
                                                        <Stack sx={{ width: "26px", height: "26px", bgcolor: "rgba(212, 195, 38, 0.4)" }}>
                                                          <Typography sx={{ marginTop: "2px", color: '#ffffff', fontSize: ciiSize, fontWeight: "bold", }}>
                                                            <span className="inner-text" title={`${element.eoy_cii_score}`}>{element.eoy_cii_score}</span>
                                                          </Typography>
                                                        </Stack>
                                                      </>
                                                      :
                                                      <>
                                                        {
                                                          element.eoy_cii_score === "D" ?
                                                            <>
                                                              <Stack sx={{ position: "relative", width: "26px", height: "26px", bgcolor: "rgba(255, 103, 103, 0.45)" }}>
                                                                <Typography sx={{ marginTop: "2px", color: '#ffffff', fontSize: ciiSize, fontWeight: "bold", }}>
                                                                  <span className="inner-text" title={`${element.eoy_cii_score}`}>{element.eoy_cii_score}</span>
                                                                </Typography>
                                                                <Stack sx={{ position: "absolute", top: "14px", left: "18px", bgcolor: "transparent" }} alignItems={"center"}>
                                                                  <img src={Problem_icon_svg} alt="iconLike" style={{ height: "13px", width: "16px" }} />
                                                                </Stack>
                                                              </Stack>
                                                            </>
                                                            :
                                                            <>
                                                              {
                                                                element.eoy_cii_score === "E" ?
                                                                  <>
                                                                    <Stack sx={{ position: "relative", width: "26px", height: "26px", bgcolor: "rgba(255, 47, 47, 0.45)" }}>
                                                                      <Typography sx={{ marginTop: "2px", color: '#ffffff', fontSize: ciiSize, fontWeight: "bold", }}>
                                                                        <span className="inner-text" title={`${element.eoy_cii_score}`}>{element.eoy_cii_score}</span>
                                                                      </Typography>
                                                                      <Stack sx={{ position: "absolute", top: "14px", left: "18px", bgcolor: "transparent" }} alignItems={"center"}>
                                                                        <img src={Problem_icon_svg} alt="iconLike" style={{ height: "13px", width: "16px" }} />
                                                                      </Stack>
                                                                    </Stack>
                                                                  </>
                                                                  :
                                                                  <>
                                                                    {
                                                                      element.eoy_cii_score === "" ?
                                                                        <Typography sx={{ color: 'rgba(255, 255, 255, 0.6)', fontSize: "12px", textTransform: 'none' }}>
                                                                          No Data
                                                                        </Typography>
                                                                        :
                                                                        ""
                                                                    }
                                                                  </>
                                                              }
                                                            </>
                                                        }
                                                      </>
                                                  }
                                                </>
                                            }
                                          </>
                                      }
                                    </Button>
                                  </Stack>
                                </td>
                                <td className="ly-distance">
                                  <Stack direction={"row"} sx={{ width: "100%", height: "100%", }}>
                                    <Button
                                      onClick={() => handleClickCiiEmissionBoard(index, element.imo, element.ly_from, element.ly_to, "Monthly", element.VesselName)}
                                      sx={{
                                        width: "100%",
                                        height: "100%",
                                        marginLeft: "auto",
                                        marginRight: "0px",
                                        padding: "0px",
                                        ":hover": {
                                          bgcolor: "rgba(255, 255, 255, 0.5)"
                                        }
                                      }}
                                      disabled={element.ly_cii_score === "" ? true : false}
                                    >
                                      <span className="inner-text cii-list-text-right" title={`${element.ly_distance}`}>
                                        {formatNumber(element.ly_distance)}
                                      </span>
                                    </Button>
                                  </Stack>
                                </td>
                                <td className="ly-foc">
                                  <Stack direction={"row"} sx={{ width: "100%", height: "100%", }}>
                                    <Button
                                      onClick={() => handleClickCiiEmissionBoard(index, element.imo, element.ly_from, element.ly_to, "Monthly", element.VesselName)}
                                      sx={{
                                        width: "100%",
                                        height: "100%",
                                        marginLeft: "auto",
                                        marginRight: "0px",
                                        padding: "0px",
                                        ":hover": {
                                          bgcolor: "rgba(255, 255, 255, 0.5)"
                                        }
                                      }}
                                      disabled={element.ly_cii_score === "" ? true : false}
                                    >
                                      <span className="inner-text cii-list-text-right" title={`${element.ly_foc}`}>
                                        {formatNumber(element.ly_foc)}
                                      </span>
                                    </Button>
                                  </Stack>
                                </td>
                                <td className="ly-cii-score">
                                  <Stack direction={"row"} sx={{ width: "100%", height: "100%", }}>
                                    <Button
                                      onClick={() => handleClickCiiEmissionBoard(index, element.imo, element.ly_from, element.ly_to, "Monthly", element.VesselName)}
                                      sx={{
                                        width: "68px",
                                        height: "100%",
                                        marginLeft: "auto",
                                        marginRight: "5px",
                                        ":hover": {
                                          bgcolor: "rgba(255, 255, 255, 0.5)"
                                        }
                                      }}
                                      disabled={element.ly_cii_score === "" ? true : false}
                                    >
                                      {
                                        element.ly_cii_score === "A" ?
                                          <>
                                            <Stack sx={{ width: "26px", height: "26px", bgcolor: "rgba(0, 144, 206, 0.4)" }}>
                                              <Typography sx={{ marginTop: "2px", color: '#ffffff', fontSize: ciiSize, fontWeight: "bold", }}>
                                                <span className="inner-text" title={`${element.ly_cii_score}`}>{element.ly_cii_score}</span>
                                              </Typography>
                                            </Stack>
                                          </>
                                          :
                                          <>
                                            {
                                              element.ly_cii_score === "B" ?
                                                <>
                                                  <Stack sx={{ width: "26px", height: "26px", bgcolor: "rgba(6, 165, 80, 0.4)" }}>
                                                    <Typography sx={{ marginTop: "2px", color: '#ffffff', fontSize: ciiSize, fontWeight: "bold", }}>
                                                      <span className="inner-text" title={`${element.ly_cii_score}`}>{element.ly_cii_score}</span>
                                                    </Typography>
                                                  </Stack>
                                                </>
                                                :
                                                <>
                                                  {
                                                    element.ly_cii_score === "C" ?
                                                      <>
                                                        <Stack sx={{ width: "26px", height: "26px", bgcolor: "rgba(212, 195, 38, 0.4)" }}>
                                                          <Typography sx={{ marginTop: "2px", color: '#ffffff', fontSize: ciiSize, fontWeight: "bold", }}>
                                                            <span className="inner-text" title={`${element.ly_cii_score}`}>{element.ly_cii_score}</span>
                                                          </Typography>
                                                        </Stack>
                                                      </>
                                                      :
                                                      <>
                                                        {
                                                          element.ly_cii_score === "D" ?
                                                            <>
                                                              <Stack sx={{ position: "relative", width: "26px", height: "26px", bgcolor: "rgba(255, 103, 103, 0.45)" }}>
                                                                <Typography sx={{ marginTop: "2px", color: '#ffffff', fontSize: ciiSize, fontWeight: "bold", }}>
                                                                  <span className="inner-text" title={`${element.ly_cii_score}`}>{element.ly_cii_score}</span>
                                                                </Typography>
                                                              </Stack>
                                                            </>
                                                            :
                                                            <>
                                                              {
                                                                element.ly_cii_score === "E" ?
                                                                  <>
                                                                    <Stack sx={{ position: "relative", width: "26px", height: "26px", bgcolor: "rgba(255, 47, 47, 0.45)" }}>
                                                                      <Typography sx={{ marginTop: "2px", color: '#ffffff', fontSize: ciiSize, fontWeight: "bold", }}>
                                                                        <span className="inner-text" title={`${element.ly_cii_score}`}>{element.ly_cii_score}</span>
                                                                      </Typography>
                                                                    </Stack>
                                                                  </>
                                                                  :
                                                                  <>
                                                                    {
                                                                      element.ly_cii_score === "" ?
                                                                        <Typography sx={{ color: 'rgba(255, 255, 255, 0.6)', fontSize: "12px", textTransform: 'none' }}>
                                                                          No Data
                                                                        </Typography>
                                                                        :
                                                                        ""
                                                                    }
                                                                  </>
                                                              }
                                                            </>
                                                        }
                                                      </>
                                                  }
                                                </>
                                            }
                                          </>
                                      }
                                    </Button>
                                  </Stack>
                                </td>
                              </tr>
                            )
                          })
                        }
                      
                    </>
                    :
                    ""
                  }
                </tbody>
                :
                <Box>
                  {loading && (
                    <CircularProgress
                      size={68}
                      sx={{
                        color: "grey",
                        position: 'absolute',
                        top: 500,
                        left: 910,
                        zIndex: 1,
                      }}
                    />
                  )}
                </Box>
              }                   
            </table>
          </div>
        </Box>
      </Box>
    </>
  );
} 

export default CiiList;