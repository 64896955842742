
import * as React from 'react';

import axios, { AxiosError } from "axios";

import './SimulationSideBar.css'; // スタイルのために追加
import { SearchContext } from '../../App';
import { SelectVesseName } from '../../components/components/SelectVesseNameComponent';

// MUI @mui/material/
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

// アイコン
import EditConditionIcon from '../../assets/images/simulation_edit_condition.svg';

// Dialog
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';

// Axios Error
interface ErrorData {
  message: string;
  status: number;
  error: string;
}

function formatNumber(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// 親コンポーネントから持ち回る値
export type Props = {
  vesselName: string;
  vesselOpen: boolean;
  currentFocusVessel: string;    // 矢印キー(↑↓)でドロップダウン船名変更時のフォーカスしている船
  setVesseName: (value: string) => void;
  setVesselOpen: (value: boolean) => void;
  setCurrentFocusVessel: (value : string) => void;
  selectImo: (value: string) => void;

  vesselMaster: any;
  focFormulaBallast: any[];
  setFocFormulaBallast: (value: any[]) => void;
  focFormulaLaden: any[];
  setFocFormulaLaden: (value: any[]) => void;
  focFormulaAux: any;
  setFocFormulaAux: (value: any) => void;

  SeachInfo: any;
  setSeachInfo: (value: any) => void;
}

export const SimulationSideBar: React.FC<Props> = props => {

  // userId有効期限チェック----------------------------------------------------------------------------------------------------------------------------
  const [message, setMessage] = React.useState<string>();
  const [userCheck, setUserCheck] = React.useState(false);
  let init = true

  const [user_id, setUser_id] = React.useState<string>();
  const [group_id, setGroup_id] = React.useState<string>();
  const [company_id, setCompany_id] = React.useState<string>();
  const [gid, setGid] = React.useState<string>();
  const [gidList, setGidList] = React.useState<string>();
  const [imoList, setImoList] = React.useState<string>();
  const [jwt, setJwt] = React.useState<string>();
  const [role, setRole] = React.useState<string>();

  React.useEffect(() => {
    if (init === true) {

      // localStrage取得----------------------------------------------------------------------------------------------------------------------------
      let jwt_ls
      let gid_ls
      if (localStorage.getItem("payload")) {
        const pay_json = localStorage.getItem("payload")
        if (pay_json !== null) {
          const pay = JSON.parse(pay_json)
          jwt_ls = pay["jwt"]
          gid_ls = pay["user_id"]
          setUser_id(pay["user_id"])
          setGroup_id(pay["group_id"])
          setCompany_id(pay["company_id"])
          setGid(pay["gid"])
          setGidList(pay["gidList"])
          setImoList(pay["imoList"])
          setJwt(jwt_ls)
          setRole(pay["role"])
        }
      }
    }

    init = false
  }, []);

  // FOC Formulasに表示する式
  const [BallastFormulaDispFlg, setBallastFormulaDispFlg] = React.useState<boolean>(false);
  const [LadenFormulaDispFlg, setLadenFormulaDispFlg] = React.useState<boolean>(false);
  const [AuxiliaryEquipmentValDispFlg, setAuxiliaryEquipmentValDispFlg] = React.useState<boolean>(false);

  // SelectVesselNameコンポーネント用
  const [vesselOpen, setVesselOpen] = React.useState<boolean>(false);
  const [currentFocusVessel, setCurrentFocusVessel] = React.useState<string>(''); // 矢印キー(↑↓)でドロップダウン船名変更時のフォーカスしている船
  const [vesselName, setVesselName] = React.useState<string>(''); // 表示船名

  // コンテクスト取得----------------------------------------------------------------------------------------------------------------------------
  const SeachInfo: any = React.useContext(SearchContext);

  // 検索フォーム----------------------------------------------------------------------------------------------------------------------------
  const [base_url, setBase_url] = React.useState(`${process.env.REACT_APP_API_URL}/FocFormulas/%23/`);

  // Select Imo----------------------------------------------------------------------------------------------------------------------------
  // const [Imo, setImo] = React.useState<string>("");
  // const selectImo = (imoNo: string) => {
  //   props.selectImo(imoNo)
  // };

  // Loading--------------------------------------------------------------------
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(true);

  // Imo変更イベント監視
  React.useEffect(() => {
    setLoading(true)
    setSuccess(false)
  }, [props.vesselName]);

  // Vessel Information欄の表示制御
  React.useEffect(() => {
    setLoading(false)
    setSuccess(true)
  }, [props.vesselMaster]);

  // 時系列データ取得--------------------------------------------------------------------
  const [authCheck, setAuthCheck] = React.useState(false);
  const [authMessage, setAuthMessage] = React.useState("");

  // サイドバー展開--------------------------------------------------------------------
  // 現在のアクティブなタブを管理するための状態
  const [VesselInformationTab, setVesselInformationTab] = React.useState<boolean>(true);

  // サイドバーがクリックされた時に呼び出されるハンドラー関数
  const handleSideBarClick = (index) => {
    console.log("handleSideBarClick", index)
    if (index === 1) {
      setVesselInformationTab(!VesselInformationTab)
    }
  };

  // ダイアログ関連--------------------------------------------------------------------
  const [MeBallast1Value, setMeBallast1Value] = React.useState<any>("");
  const [MeBallast2Value, setMeBallast2Value] = React.useState<any>("");
  const [MeBallast3Value, setMeBallast3Value] = React.useState<any>("");
  const [MeLaden1Value, setMeLaden1Value] = React.useState<any>("");
  const [MeLaden2Value, setMeLaden2Value] = React.useState<any>("");
  const [MeLaden3Value, setMeLaden3Value] = React.useState<any>("");
  const [AuxiliaryEquipmentValue, setAuxiliaryEquipmentValue] = React.useState<any>("");
  const [dispMeBallast1Value, setDispMeBallast1Value] = React.useState<any>("");
  const [dispMeBallast2Value, setDispMeBallast2Value] = React.useState<any>("");
  const [dispMeBallast3Value, setDispMeBallast3Value] = React.useState<any>("");
  const [dispMeLaden1Value, setDispMeLaden1Value] = React.useState<any>("");
  const [dispMeLaden2Value, setDispMeLaden2Value] = React.useState<any>("");
  const [dispMeLaden3Value, setDispMeLaden3Value] = React.useState<any>("");
  const [dispAuxiliaryEquipmentValue, setDispAuxiliaryEquipmentValue] = React.useState<any>("");

  const [allInputedflg, setAllInputedflg] = React.useState<boolean>(false);

  // 船変更時のFOCFormulas表示更新
  React.useEffect(() => {

    if (props.focFormulaBallast.length !== 0) {
      // 元データ設定
      setMeBallast1Value(props.focFormulaBallast[0]);
      setMeBallast2Value(props.focFormulaBallast[1]);
      setMeBallast3Value(props.focFormulaBallast[2]);
      setMeLaden1Value(props.focFormulaLaden[0]);
      setMeLaden2Value(props.focFormulaLaden[1]);
      setMeLaden3Value(props.focFormulaLaden[2]);
      setAuxiliaryEquipmentValue(props.focFormulaAux);

      // ダイアログ表示用データ設定
      setDispMeBallast1Value(props.focFormulaBallast[0]);
      setDispMeBallast2Value(props.focFormulaBallast[1]);
      setDispMeBallast3Value(props.focFormulaBallast[2]);
      setDispMeLaden1Value(props.focFormulaLaden[0]);
      setDispMeLaden2Value(props.focFormulaLaden[1]);
      setDispMeLaden3Value(props.focFormulaLaden[2]);
      setDispAuxiliaryEquipmentValue(props.focFormulaAux);

      // 表示フラグをオン
      setBallastFormulaDispFlg(true);
      setLadenFormulaDispFlg(true);
      setAuxiliaryEquipmentValDispFlg(true);
    }
    else {
      // 元データ初期化
      setMeBallast1Value("");
      setMeBallast2Value("");
      setMeBallast3Value("");
      setMeLaden1Value("");
      setMeLaden2Value("");
      setMeLaden3Value("");
      setAuxiliaryEquipmentValue("");
      
      // ダイアログ表示用データ初期化
      setDispMeBallast1Value("");
      setDispMeBallast2Value("");
      setDispMeBallast3Value("");
      setDispMeLaden1Value("");
      setDispMeLaden2Value("");
      setDispMeLaden3Value("");
      setDispAuxiliaryEquipmentValue("");

      // 表示フラグをオフ
      setBallastFormulaDispFlg(false);
      setLadenFormulaDispFlg(false);
      setAuxiliaryEquipmentValDispFlg(false);
    }
    
  
  }, [props.focFormulaBallast, props.focFormulaLaden, props.focFormulaAux]);

  // FOCFormulaダイアログ
  React.useEffect(() => {

    // テキストボックスの値がすべて整数、または小数かを判定
    if (/^-?\d+(\.\d+)?$/.test(dispMeBallast1Value) && /^-?\d+(\.\d+)?$/.test(dispMeBallast2Value) && /^-?\d+(\.\d+)?$/.test(dispMeBallast3Value) &&
          /^-?\d+(\.\d+)?$/.test(dispMeLaden1Value) && /^-?\d+(\.\d+)?$/.test(dispMeLaden2Value) && /^-?\d+(\.\d+)?$/.test(dispMeLaden3Value) &&
          /^-?\d+(\.\d+)?$/.test(dispAuxiliaryEquipmentValue)) {
    
      setAllInputedflg(true);
    }
    else {
      setAllInputedflg(false);
    }
  
  }, [dispMeBallast1Value, dispMeBallast2Value, dispMeBallast3Value, dispMeLaden1Value, dispMeLaden2Value, dispMeLaden3Value, dispAuxiliaryEquipmentValue]);

  const [openDialogyFOCFormulas, setOpenDialogFOCFormulas] = React.useState(false);
  const handleClickOpenDialogFOCFormulas = () => {
    setOpenDialogFOCFormulas(true);
  };

  // FOC FormulasダイアログのCanselボタン押下イベント
  const handleNoClickOpenDialogFOCFormulas = () => {
    
    // 表示用の値を戻す。
    setDispMeBallast1Value(MeBallast1Value);
    setDispMeBallast2Value(MeBallast2Value);
    setDispMeBallast3Value(MeBallast3Value);
    setDispMeLaden1Value(MeLaden1Value);
    setDispMeLaden2Value(MeLaden2Value);
    setDispMeLaden3Value(MeLaden3Value);
    setDispAuxiliaryEquipmentValue(AuxiliaryEquipmentValue);

    // Dialog閉じる。
    setOpenDialogFOCFormulas(false);
  };
  
  // テキストボックスの入力チェック
  const handleClickMeBallast1Value = (event) => {
    if (/^(\d*|\d*\.\d*)$/.test(event)) { setDispMeBallast1Value(event); }
    else { alert("Please enter numbers in half-width characters.") }
  }
  const handleClickMeBallast2Value = (event) => {
    if (/^(\d*|\d*\.\d*)$/.test(event)) { setDispMeBallast2Value(event); }
    else { alert("Please enter numbers in half-width characters.") }
  }
  const handleClickMeBallast3Value = (event) => {
    if (/^(\d*|\d*\.\d*)$/.test(event)) { setDispMeBallast3Value(event); }
    else { alert("Please enter numbers in half-width characters.") }
  }
  const handleClickMeLaden1Value = (event) => {
    if (/^(\d*|\d*\.\d*)$/.test(event)) { setDispMeLaden1Value(event); }
    else { alert("Please enter numbers in half-width characters.") }
  }
  const handleClickMeLaden2Value = (event) => {
    if (/^(\d*|\d*\.\d*)$/.test(event)) { setDispMeLaden2Value(event); }
    else { alert("Please enter numbers in half-width characters.") }
  }
  const handleClickMeLaden3Value = (event) => {
    if (/^(\d*|\d*\.\d*)$/.test(event)) { setDispMeLaden3Value(event); }
    else { alert("Please enter numbers in half-width characters.") }
  }
  const handleClickAuxiliaryEquipmentValue = (event) => {
    if (/^(\d*|\d*\.\d*)$/.test(event)) { setDispAuxiliaryEquipmentValue(event); }
    else { alert("Please enter numbers in half-width characters.") }
  }

  // FOC FormulasダイアログのSaveボタン押下イベント
  const handleClickSaveFocFormulasDialg = () => {

    console.log("dispMeBallast1Value:" + dispMeBallast1Value);
 
    // 表示用の値で元データを上書き
    setMeBallast1Value(dispMeBallast1Value);
    setMeBallast2Value(dispMeBallast2Value);
    setMeBallast3Value(dispMeBallast3Value);
    setMeLaden1Value(dispMeLaden1Value);
    setMeLaden2Value(dispMeLaden2Value);
    setMeLaden3Value(dispMeLaden3Value);
    setAuxiliaryEquipmentValue(dispAuxiliaryEquipmentValue);

    // 登録用データ作成
    const value = {
      // FOC Formulas
      "imo": props.SeachInfo.imoNo,
      "me_ballast": dispMeBallast1Value + "," + dispMeBallast2Value + "," + dispMeBallast3Value,
      "me_laden": dispMeLaden1Value + "," + dispMeLaden2Value + "," + dispMeLaden3Value,
      "auxiliary_equipment": dispAuxiliaryEquipmentValue,
    }
 
    // 登録
    insertFocFormulas(value);

    // 画面表示の式更新
    setBallastFormulaDispFlg(true);
    setLadenFormulaDispFlg(true);
    setAuxiliaryEquipmentValDispFlg(true);

    // ダイアログ閉じる。
    setOpenDialogFOCFormulas(false);
  };

  // FOC Formulas登録
  const insertFocFormulas = (value) => {
    console.log("insertFocFormuals")

    if (props.SeachInfo.imoNo !== "" && jwt !== undefined) {
      console.log("insertFocFormualsスタート", value)
      const formData = new FormData();
      Object.keys(value).forEach(key => {
        formData.append(key, value[key]);
      });
      const options = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': jwt
        }
      }
      const url = `${base_url}`
      console.log("url[insertFocFormulas]:", url);
      axios.post(url, formData, options).then((res) => {
        console.log("API Response", res.data);

        // 認可チェック
        if (res.data === 401) {
          setAuthCheck(true)
          setAuthMessage("不正な操作が行われた可能性があります。")
        } else if (res.data === 500) {
          setAuthCheck(true)
          setAuthMessage("JWTが改ざんされている可能性があります。")
        } else {
          setAuthCheck(false)
        }

        // 値セット用に分割
        let tmpBalastFormulaValue = res.data.me_ballast;
        let tmpBalastFormulaValueList = tmpBalastFormulaValue.split(",");
        let tmpLadenFormulaValue = res.data.me_laden;
        let tmpLadenFormulaValueList = tmpLadenFormulaValue.split(",");
        
        // 元データを上書き
        setMeBallast1Value(tmpBalastFormulaValueList[0]);
        setMeBallast2Value(tmpBalastFormulaValueList[1]);
        setMeBallast3Value(tmpBalastFormulaValueList[2]);
        setMeLaden1Value(tmpLadenFormulaValueList[0]);
        setMeLaden2Value(tmpLadenFormulaValueList[1]);
        setMeLaden3Value(tmpLadenFormulaValueList[2]);
        setAuxiliaryEquipmentValue(res.data.auxiliary_equipment);

        // 親に返す。
        props.setFocFormulaBallast(tmpBalastFormulaValueList);
        props.setFocFormulaLaden(tmpLadenFormulaValueList);
        props.setFocFormulaAux(res.data.auxiliary_equipment);
      })
        .catch((e: AxiosError<ErrorData>) => {
          if (e.response === undefined) {
            console.log("undefinedError: " + e);
            return;
          }
          console.log(e + ", message: " + e.response.data.message);
        });
    }
  }

  return (
    <>
      <Box sx={{ marginTop: "64px",
        overflowY: 'auto',

        '&::-webkit-scrollbar': {
          width: "6px",
          height: "6px",
        },
        '&::-webkit-scrollbar-track': {
          borderRadius: "10px",
          background: "rgba(255, 255, 255, 0.5)",
        },
        '&::-webkit-scrollbar-thumb': {
          background: "#fff",
          borderRadius: "10px",
        },
        '&::-webkit-scrollbar-thumb:hover': {
          backgroundColor: '#555',
        },
        "&::-webkit-scrollbar-corner": {
          display: "none",
        } }}>

        {/* ↓ Space ↓ */}
        <Stack alignItems="center" sx={{ position: "relative", height: "20px" }}>
        </Stack>
        {/* ↑ Space ↑ */}

        {/* ↓ Vessel Name & FOC Formulas ↓ */}
        <Stack alignItems="left" sx={{ position: "relative" }}>
          <Stack sx={{ position: "relative", height: "370px", width: `calc(100% - 22px)` }}>
            {/* Vessel Name */}
            <Stack alignItems="center" sx={{ position: "relative", top: "10px", left: "22px", height: "110px" }}>
              <Typography
                align="left"
                sx={{
                  position: "absolute",
                  top: "0px",
                  left: "20px",
                  color: 'white',
                  fontSize: "14px",
                  fontFamily: "'Roboto'",
                  fontWeight: '700',
                  lineHeight: "24px",
                  wordWrap: 'break-word',
                }}>
                Vessel Name
              </Typography>
              <SelectVesseName
                vesselName = {props.vesselName}
                vesselOpen = {props.vesselOpen}
                currentFocusVessel = {props.currentFocusVessel}
                imoNo = {props.SeachInfo.imoNo}
                imoList = {props.SeachInfo.imoList}
                simulationFlg = {true}
                setVesseName = {(updateValue: string) => props.setVesseName(updateValue)}
                setVesselOpen = {(updateValue: boolean) => props.setVesselOpen(updateValue)}
                setCurrentFocusVessel = {(updateValue: string) => props.setCurrentFocusVessel(updateValue)}
                selectImo = {(updateValue: string) => props.selectImo(updateValue)}
              />
            </Stack>
            <Stack alignItems="center" sx={{ position: "relative", top: "0px", left: "22px", height: "35px" }}>
              <Typography
                align="left"
                sx={{
                  position: "absolute",
                  top: "0px",
                  left: "20px",
                  color: 'white',
                  fontSize: "14px",
                  fontFamily: "'Roboto'",
                  fontWeight: '700',
                  lineHeight: "24px",
                  wordWrap: 'break-word',
                }}>
                FOC Formulas
              </Typography>
              {props.SeachInfo.imoNo !== "" ?
                <img className="pointer-area" src={EditConditionIcon} alt="editCondition" onClick={handleClickOpenDialogFOCFormulas} 
                  style={{ height: "17px", width: "17px", marginRight: "72px"}} />
                  : 
                  <img src={EditConditionIcon} alt="editCondition" 
                    style={{ height: "17px", width: "17px", marginRight: "72px", filter: "brightness(0.4)"}} />
              }
              {/* FOC Formulas Editor Daialog */}
              <Dialog
                open={openDialogyFOCFormulas}
                sx={{
                  "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      width: "600px",
                      maxWidth: "600px",
                      height: "530px",
                      maxHeight: "530px",
                    },
                  },
                }}
                >
                  <DialogTitle
                    sx={{
                      bgcolor: "#474C53",
                      height: "60px",
                      width: "600px",
                      color: "white",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontFamily: "'Roboto', 'Meiryo', sans-serif",
                      fontSize: "18px",
                      fontWeight: "700"
                    }}>
                    FOC Formulas Editor
                    <IconButton
                      aria-label="close"
                      onClick={handleNoClickOpenDialogFOCFormulas}
                      sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: "rgba(255, 255, 255, 0.5)",
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent
                    sx={{
                      background: "linear-gradient(180deg, #0E1B25 0%, #17232D 100%)",
                    }}>
                    
                    {/* M/E (Ballast) [MT/day] */}
                    <Box sx={{ position: "relative", height: "80px",}}>
                      <Typography align="center"
                        sx={{
                          position: "absolute", top: "50px", left: "40px",
                          color: 'rgba(255, 255, 255)', fontSize: 18, fontFamily: 'Roboto', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word'
                        }}>
                        M/E (Ballast) [MT/day]
                      </Typography>
                    </Box>
                    <Box sx={{ position: "relative", height: "60px", }}>
                      <Stack direction={"row"}>
                        <Typography align="left"
                          sx={{
                            position: "absolute", top: "10px", left: "55px",
                            color: 'rgba(255, 255, 255, 0.70)', fontSize: 16, fontFamily: 'Roboto', fontWeight: '700', lineHeight: "24px", wordWrap: 'break-word'
                          }}>
                          ＝
                        </Typography>
                        <FormControl>
                          <Input
                            value={dispMeBallast1Value} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleClickMeBallast1Value(event.target.value) }}
                            type='tel'
                            sx={{
                              position: "absolute", top: "5px", left: "80px", width: "80px", fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                              "& .MuiInput-input": { height: "26px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                              ":hover:after": { borderBottom: "0px solid green", },
                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                              ":after": { borderBottom: "2px solid white", },
                              ":before": { borderBottom: "2px solid #A4A7B1", },
                            }} />
                        </FormControl>
                        <Box 
                          sx={{ position: "absolute", top: "10px", left: "170px", height: "30px", width: "130px",
                            color: 'rgba(255, 255, 255, 0.70)', fontSize: 16, fontFamily: 'Roboto', fontWeight: '700',
                            }}>
                          * Log Speed ^ 
                        </Box>
                        <FormControl>
                          <Input
                            value={dispMeBallast2Value} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleClickMeBallast2Value(event.target.value) }}
                            type='tel'
                            sx={{
                              position: "absolute", top: "5px", left: "280px", width: "80px", fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                              "& .MuiInput-input": { height: "26px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                              ":hover:after": { borderBottom: "0px solid green", },
                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                              ":after": { borderBottom: "2px solid white", },
                              ":before": { borderBottom: "2px solid #A4A7B1", },
                            }} />
                        </FormControl>
                        <Box 
                          sx={{ position: "absolute", top: "10px", left: "365px", height: "30px", width: "130px",
                            color: 'rgba(255, 255, 255, 0.70)', fontSize: 16, fontFamily: 'Roboto', fontWeight: '700',
                            }}>
                          ＋ 
                        </Box>
                        <FormControl>
                          <Input
                            value={dispMeBallast3Value} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleClickMeBallast3Value(event.target.value) }}
                            type='tel'
                            sx={{
                              position: "absolute", top: "5px", left: "385px", width: "80px", fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                              "& .MuiInput-input": { height: "26px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                              ":hover:after": { borderBottom: "0px solid green", },
                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                              ":after": { borderBottom: "2px solid white", },
                              ":before": { borderBottom: "2px solid #A4A7B1", },
                            }} />
                        </FormControl>
                      </Stack>
                    </Box>

                    {/* M/E (Laden) [MT/day] */}
                    <Box sx={{ position: "relative", height: "50px",}}>
                      <Typography align="center"
                        sx={{
                          position: "absolute", top: "20px", left: "40px",
                          color: 'rgba(255, 255, 255)', fontSize: 18, fontFamily: 'Roboto', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word'
                        }}>
                        M/E (Laden) [MT/day]
                      </Typography>
                    </Box>
                    <Box sx={{ position: "relative", height: "60px", }}>
                      <Stack direction={"row"}>
                        <Typography align="left"
                          sx={{
                            position: "absolute", top: "10px", left: "55px",
                            color: 'rgba(255, 255, 255, 0.70)', fontSize: 16, fontFamily: 'Roboto', fontWeight: '700', lineHeight: "24px", wordWrap: 'break-word'
                          }}>
                          ＝
                        </Typography>
                        <FormControl>
                          <Input
                            value={dispMeLaden1Value} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleClickMeLaden1Value(event.target.value) }}
                            type='tel'
                            sx={{
                              position: "absolute", top: "5px", left: "80px", width: "80px", fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                              "& .MuiInput-input": { height: "26px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                              ":hover:after": { borderBottom: "0px solid green", },
                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                              ":after": { borderBottom: "2px solid white", },
                              ":before": { borderBottom: "2px solid #A4A7B1", },
                            }} />
                        </FormControl>
                        <Box 
                          sx={{ position: "absolute", top: "10px", left: "170px", height: "30px", width: "130px",
                            color: 'rgba(255, 255, 255, 0.70)', fontSize: 16, fontFamily: 'Roboto', fontWeight: '700',
                            }}>
                          * Log Speed ^ 
                        </Box>
                        <FormControl>
                          <Input
                            value={dispMeLaden2Value} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleClickMeLaden2Value(event.target.value) }}
                            type='tel'
                            sx={{
                              position: "absolute", top: "5px", left: "280px", width: "80px", fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                              "& .MuiInput-input": { height: "26px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                              ":hover:after": { borderBottom: "0px solid green", },
                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                              ":after": { borderBottom: "2px solid white", },
                              ":before": { borderBottom: "2px solid #A4A7B1", },
                            }} />
                        </FormControl>
                        <Box 
                          sx={{ position: "absolute", top: "10px", left: "365px", height: "30px", width: "130px",
                            color: 'rgba(255, 255, 255, 0.70)', fontSize: 16, fontFamily: 'Roboto', fontWeight: '700',
                            }}>
                          ＋ 
                        </Box>
                        <FormControl>
                          <Input
                            value={dispMeLaden3Value} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleClickMeLaden3Value(event.target.value) }}
                            type='tel'
                            sx={{
                              position: "absolute", top: "5px", left: "385px", width: "80px", fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                              "& .MuiInput-input": { height: "26px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                              ":hover:after": { borderBottom: "0px solid green", },
                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                              ":after": { borderBottom: "2px solid white", },
                              ":before": { borderBottom: "2px solid #A4A7B1", },
                            }} />
                        </FormControl>
                      </Stack>
                    </Box>

                    {/* Auxiliary Equipment [MT/day] */}
                    <Box sx={{ position: "relative", height: "50px",}}>
                      <Typography align="center"
                        sx={{
                          position: "absolute", top: "20px", left: "40px",
                          color: 'rgba(255, 255, 255)', fontSize: 18, fontFamily: 'Roboto', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word'
                        }}>
                        Auxiliary Equipment [MT/day]
                      </Typography>
                    </Box>
                    <Box sx={{ position: "relative", height: "60px", }}>
                      <Stack direction={"row"}>
                        <Typography align="left"
                          sx={{
                            position: "absolute", top: "10px", left: "55px",
                            color: 'rgba(255, 255, 255, 0.70)', fontSize: 16, fontFamily: 'Roboto', fontWeight: '700', lineHeight: "24px", wordWrap: 'break-word'
                          }}>
                          ＝
                        </Typography>
                        <FormControl>
                          <Input
                            value={dispAuxiliaryEquipmentValue}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleClickAuxiliaryEquipmentValue(event.target.value) }}
                            type='tel'
                            sx={{
                              position: "absolute", top: "5px", left: "80px", width: "80px", fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                              "& .MuiInput-input": { height: "26px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                              ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                              ":hover:after": { borderBottom: "0px solid green", },
                              ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                              ":after": { borderBottom: "2px solid white", },
                              ":before": { borderBottom: "2px solid #A4A7B1", },
                            }} />
                        </FormControl>
                      </Stack>
                    </Box>

                  </DialogContent>
                  <DialogActions sx={{
                    height: "50px",
                    bgcolor: "#474C53"
                  }}>
                    <Button
                      sx={{
                        marginLeft: "-8px",
                        marginRight: "-8px",
                        backgroundColor: "rgba(172, 172, 172, 0.7)",
                        width: "300px",
                        height: "50px",
                        padding: "0",
                        fontSize: "20px",
                        fontFamily: "'Roboto', 'Meiryo', sans-serif",
                        fontWeight: "bold",
                        color: "white",
                        borderRadius: "0px",
                        ":hover": {
                          bgcolor: "#FFFFFF",
                          color: "#40475D",
                        },
                        textTransform: 'none'
                      }}
                      onClick={handleNoClickOpenDialogFOCFormulas}
                    >
                      Cancel
                    </Button>
                    <Button
                      sx={{
                        marginLeft: "-8px",
                        marginRight: "-8px",
                        backgroundColor : allInputedflg ? "#e66300" : "rgba(230, 99, 0, 0.15)",
                        width: "300px",
                        height: "50px",
                        padding: "0",
                        fontSize: "20px",
                        fontFamily: "'Roboto', 'Meiryo', sans-serif",
                        fontWeight: "bold",
                        color: allInputedflg ? "white" : "rgba(255, 255, 255, 0.3)",
                        borderRadius: "0px",
                        ":hover": {
                          bgcolor: "#FFFFFF",
                          color: "#40475D",
                        },
                        textTransform: 'none'
                      }}
                      onClick={handleClickSaveFocFormulasDialg}
                      disabled={allInputedflg ? false : true}
                    >
                      Save
                    </Button>
                  </DialogActions>
              </Dialog>
             
            </Stack >

            {success ?
              <Box>
              <Stack alignItems="center" sx={{ position: "relative", top: "0px", left: "22px", height: "25px" }}>
                <Typography
                  align="left"
                  sx={{
                    position: "absolute",
                    top: "0px",
                    left: "45px",
                    color: 'white',
                    fontSize: "14px",
                    fontFamily: "'Roboto'",
                    lineHeight: "24px",
                    wordWrap: 'break-word',
                  }}>
                  M/E (Ballast) [MT/day]
                </Typography>
              </Stack>
              <Stack alignItems="center" sx={{ position: "relative", top: "0px", left: "22px", height: "50px" }}>
                <Typography
                  align="left"
                  sx={{
                    position: "absolute",
                    top: "0px",
                    left: "55px",
                    color: 'rgba(255, 255, 255, 0.7)',
                    fontSize: "14px",
                    fontFamily: "'Roboto'",
                    lineHeight: "24px",
                    wordWrap: 'break-word',
                  }}>
                  {!BallastFormulaDispFlg ? "-" : "＝ " + MeBallast1Value + " * Log Speed ^ " + MeBallast2Value + " ＋ " + MeBallast3Value}
                </Typography>
              </Stack>
              <Stack alignItems="center" sx={{ position: "relative", top: "0px", left: "22px", height: "25px" }}>
                <Typography
                  align="left"
                  sx={{
                    position: "absolute",
                    top: "0px",
                    left: "45px",
                    color: 'white',
                    fontSize: "14px",
                    fontFamily: "'Roboto'",
                    lineHeight: "24px",
                    wordWrap: 'break-word',
                  }}>
                  M/E (Laden) [MT/day]
                </Typography>
              </Stack>
              <Stack alignItems="center" sx={{ position: "relative", top: "0px", left: "22px", height: "50px" }}>
                <Typography
                  align="left"
                  sx={{
                    position: "absolute",
                    top: "0px",
                    left: "55px",
                    color: 'rgba(255, 255, 255, 0.7)',
                    fontSize: "14px",
                    fontFamily: "'Roboto'",
                    lineHeight: "24px",
                    wordWrap: 'break-word',
                  }}>
                  {!LadenFormulaDispFlg ? "-": "＝ " + MeLaden1Value + " * Log Speed ^ " + MeLaden2Value + " ＋ " + MeLaden3Value}
                </Typography>
              </Stack>
              <Stack alignItems="center" sx={{ position: "relative", top: "0px", left: "22px", height: "25px" }}>
                <Typography
                  align="left"
                  sx={{
                    position: "absolute",
                    top: "0px",
                    left: "45px",
                    color: 'white',
                    fontSize: "14px",
                    fontFamily: "'Roboto'",
                    lineHeight: "24px",
                    wordWrap: 'break-word',
                  }}>
                  Auxiliary Equipment [MT/day]
                </Typography>
              </Stack>
              <Stack alignItems="center" sx={{ position: "relative", top: "0px", left: "22px", height: "50px" }}>
                <Typography
                  align="left"
                  sx={{
                    position: "absolute",
                    top: "0px",
                    left: "55px",
                    color: 'rgba(255, 255, 255, 0.7)',
                    fontSize: "14px",
                    fontFamily: "'Roboto'",
                    lineHeight: "24px",
                    wordWrap: 'break-word',
                  }}>
                  {!AuxiliaryEquipmentValDispFlg ? "-" : "＝ " + AuxiliaryEquipmentValue}
                </Typography>
              </Stack>
              </Box>
              :
              <Stack sx={{ position: "relative", height: "130px", width: `${300 - 20}px` }}>
                {loading && (
                  <CircularProgress
                    size={68}
                    sx={{
                      color: "grey",
                      position: 'absolute',
                      top: "100px",
                      left: "100px",
                      zIndex: 1,
                    }}
                  />
                )}
              </Stack>
            }
          </Stack>
        </Stack>
        {/* ↑ Vessel Name & FOC Formulas ↑ */}

        {/* ↓ Border ↓ */}
        <Stack alignItems="center" sx={{ position: "relative", height: "60px" }}>
          <Box sx={{ position: "absolute", top: "30px", width: `80%`, border: '1px rgba(255, 255, 255, 0.30) dotted' }}></Box>
        </Stack>
        {/* ↑ Border ↑ */}

        {/* ↓ VesselInformation ↓ */}
        <Stack alignItems="left" sx={{ position: "relative" }}>
          {
            VesselInformationTab !== true ?
              <>
                {/* title */}
                <Stack sx={{ position: "relative", height: "40px", cursor: "pointer", background: "rgba(59.32, 64.07, 71.19, 0.0)" }} onClick={() => handleSideBarClick(1)}>
                  <Box sx={{ position: "absolute", top: "14px", left: "25px", }}>
                    <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.7)", borderRight: "solid 2px rgb(255, 255, 255, 0.7)", width: "8px", height: "8px", transform: "rotate(45deg)" }}></Box>
                  </Box>
                  <Box sx={{ position: "absolute", top: "0px", left: "42px" }}>
                    <Typography sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                      Vessel Information
                    </Typography>
                  </Box>
                </Stack>
              </>
              :
              <>
                {/* title */}
                <Stack sx={{ position: "relative", height: "40px", cursor: "pointer", background: "rgba(59.32, 64.07, 71.19, 0.0)" }} onClick={() => handleSideBarClick(1)}>
                  <Box sx={{ position: "absolute", top: "12px", left: "25px", }}>
                    <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.7)", borderRight: "solid 2px rgb(255, 255, 255, 0.7)", width: "8px", height: "8px", transform: "rotate(135deg)" }}></Box>
                  </Box>
                  <Box sx={{ position: "absolute", top: "0px", left: "42px" }}>
                    <Typography sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                      Vessel Information
                    </Typography>
                  </Box>
                </Stack>

                {/* main */}
                {success ?

                  <Stack sx={{ position: "relative", height: "330px", width: `calc(100% - 22px)` }}>
                    <Grid container direction="row" sx={{ position: "absolute", left: "22px", top: "0px" }}>

                      {/* Vessel Type */}
                      <Grid item xs={12}>
                        <Grid container direction="row" >
                          <Grid item xs={1}>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography sx={{ color: "rgba(255, 255, 255, 0.50)", fontSize: "13px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                              Vessel Type
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Box sx={{ color: "rgba(255, 255, 255, 0.30)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                              :
                            </Box>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 400, lineHeight: "38px", wordWrap: "break-word" }}>
                              {props.vesselMaster ? props.vesselMaster.VesselType !== "" ? props.vesselMaster.VesselType : "-" : "-"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* Fuel Type */}
                      <Grid item xs={12}>
                        <Grid container direction="row" >
                          <Grid item xs={1}>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography sx={{ color: "rgba(255, 255, 255, 0.50)", fontSize: "13px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                              Fuel Type
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Box sx={{ color: "rgba(255, 255, 255, 0.30)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                              :
                            </Box>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 400, lineHeight: "38px", wordWrap: "break-word" }}>
                              {props.vesselMaster ? props.vesselMaster.OilType !== "" ? props.vesselMaster.OilType : "-" : "-"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* Segmental */}
                      <Grid item xs={12}>
                        <Grid container direction="row" >
                          <Grid item xs={1}>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography sx={{ color: "rgba(255, 255, 255, 0.50)", fontSize: "13px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                              Segmental
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Box sx={{ color: "rgba(255, 255, 255, 0.30)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                              :
                            </Box>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 400, lineHeight: "38px", wordWrap: "break-word" }}>
                              {props.vesselMaster ? props.vesselMaster.Size !== "" ? props.vesselMaster.Size : "-" : "-"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* DeadWeight */}
                      <Grid item xs={12}>
                        <Grid container direction="row" alignItems={"center"}>
                          <Grid item xs={1}>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography sx={{ color: "rgba(255, 255, 255, 0.50)", fontSize: "13px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                              DeadWeight
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Box sx={{ color: "rgba(255, 255, 255, 0.30)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                              :
                            </Box>
                          </Grid>
                          <Grid item xs={2.5}>
                            <Typography align="left" sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 400, lineHeight: "38px", wordWrap: "break-word" }}>
                              {props.vesselMaster ? props.vesselMaster.Deadweight !== "" ? formatNumber(parseFloat(props.vesselMaster.Deadweight)) : "-" : "-"}
                            </Typography>
                          </Grid>
                          <Grid item xs={2.5}>
                            <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.5)', fontSize: "14px", fontFamily: "'Roboto Condensed Regular'", wordWrap: 'break-word' }}>
                              {props.vesselMaster ? props.vesselMaster.Deadweight !== "" ? "ton" : "" : ""}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* Gross */}
                      <Grid item xs={12}>
                        <Grid container direction="row" alignItems={"center"} >
                          <Grid item xs={1}>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography sx={{ color: "rgba(255, 255, 255, 0.50)", fontSize: "13px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                              Gross
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Box sx={{ color: "rgba(255, 255, 255, 0.30)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                              :
                            </Box>
                          </Grid>
                          <Grid item xs={2.5}>
                            <Typography align="left" sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 400, lineHeight: "38px", wordWrap: "break-word" }}>
                              {props.vesselMaster ? props.vesselMaster.Grosstongue !== "" ? formatNumber(parseFloat(props.vesselMaster.Grosstongue)) : "-" : "-"}
                            </Typography>
                          </Grid>
                          <Grid item xs={2.5}>
                            <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.5)', fontSize: "14px", fontFamily: "'Roboto Condensed Regular'", wordWrap: 'break-word' }}>
                              {props.vesselMaster ? props.vesselMaster.Grosstongue !== "" ? "ton" : "" : ""}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* Built Date */}
                      <Grid item xs={12}>
                        <Grid container direction="row" >
                          <Grid item xs={1}>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography sx={{ color: "rgba(255, 255, 255, 0.50)", fontSize: "13px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                              Built Date
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Box sx={{ color: "rgba(255, 255, 255, 0.30)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                              :
                            </Box>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 400, lineHeight: "38px", wordWrap: "break-word" }}>
                              {props.vesselMaster ? props.vesselMaster.BuildDate !== "" ? props.vesselMaster.BuildDate : "-" : "-"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* Hull Cleaning Date */}
                      <Grid item xs={12}>
                        <Grid container direction="row" >
                          <Grid item xs={1}>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography sx={{ color: "rgba(255, 255, 255, 0.50)", fontSize: "13px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                              Hull Cleaning Date
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Box sx={{ color: "rgba(255, 255, 255, 0.30)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                              :
                            </Box>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 400, lineHeight: "38px", wordWrap: "break-word" }}>
                              {props.vesselMaster ? props.vesselMaster.HullCleaningDate !== "" ? props.vesselMaster.HullCleaningDate : "-" : "-"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* Last Dock Date */}
                      <Grid item xs={12}>
                        <Grid container direction="row" >
                          <Grid item xs={1}>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography sx={{ color: "rgba(255, 255, 255, 0.50)", fontSize: "13px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                              Last Dock Date
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Box sx={{ color: "rgba(255, 255, 255, 0.30)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                              :
                            </Box>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 400, lineHeight: "38px", wordWrap: "break-word" }}>
                              {props.vesselMaster ? props.vesselMaster.LastDockDate !== "" ? props.vesselMaster.LastDockDate : "-" : "-"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Stack>
                  :

                  <Stack sx={{ position: "relative", height: "330px", width: `${300 - 20}px` }}>
                    {loading && (
                      <CircularProgress
                        size={68}
                        sx={{
                          color: "grey",
                          position: 'absolute',
                          top: "100px",
                          left: "100px",
                          zIndex: 1,
                        }}
                      />
                    )}
                  </Stack>
                }
              </>
          }
        </Stack>
        {/* ↑ VesselInformation ↑ */}

      </Box>
    </>   
  )
}

export default SimulationSideBar;